import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PaymentMethodsEditForm from "./PaymentMethodsEditForm";

const PaymentMethodsEditDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    padding: theme.spacing(1),
    maxWidth: "40%",
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 15,
  top: 15,
  borderRadius: "50%",
  color: "#fff",
  background: "var(--primary-col) 0% 0% no-repeat padding-box",
  "&:hover": {
    background: "var(--primary-col) 0% 0% no-repeat padding-box",
  },
}));

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

function PaymentMethodsEditModalTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        color: "var(--primary-col)",
        font: "normal normal normal 20px/24px SF Pro Display",
        letterSpacing: "0px",

        display: "flex",
        justifyContent: "center",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <StyledIconButton aria-label="close" onClick={onClose} size="small">
          <CloseIcon fontSize="small" />
        </StyledIconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function PaymentMethodsEditModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  return (
    <>
      <PaymentMethodsEditDialog
        open={open}
        scroll="paper"
        onClose={handleClose}
      >
        <PaymentMethodsEditModalTitle onClose={handleClose}>
          Payment Method
        </PaymentMethodsEditModalTitle>
        <DialogContent>
          <PaymentMethodsEditForm close={handleClose} />
        </DialogContent>
      </PaymentMethodsEditDialog>
    </>
  );
}
