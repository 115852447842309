import { combineReducers } from 'redux';
import SetAuthReducer from './setAuthReducer';
import { persistReducer } from 'redux-persist';
import SetCreditReducer from './setCreditReducer';
import { authenticationSlice } from '../slices/api/auth-slice';
import SetLoadingReducer from './setLoadingReducer';
import localStorage from 'redux-persist/es/storage';
import { videoSlice } from '../slices/api/video-slice';
import { PersistConfig } from 'redux-persist/es/types';
import { analyticsSlice } from '../slices/api/analytics-slice';
import { locationSlice } from '../slices/api/location-slice';
import { videoCreditSlice } from '../slices/api/video-credit-slice';
import { userLocationSlice } from '../slices/api/user-location-slice';
import { paymentMethodSlice } from '../slices/api/payment-methods.slice';
import { companySlice } from '../slices/api/company-slice';
import associateReducer from './associate.reducer';
import { assignedPackageSlice } from '../slices/api/assigned-packages-slice';
import { videoCreditLocalSlice } from '../slices/local/video-credit-slice';
import { requestSlice } from '../slices/api/request-slice';
import { subscriptionSlice } from '../slices/api/subscriptions-slice';
import { videoPackageSlice } from '../slices/api/video-package-slice';
import { packageRequestSlice } from '../slices/api/package-request.slice';
import notificationReducer from './notification.reducer';
import { notificationSlice } from '../slices/api/notifications.slice';
import loadingSlice from '../slices/local/loading-slice';
import layoutSlice from '../slices/local/layout.slice';
import authSlice from '../slices/local/auth.slice';
import { pendingInviteSlice } from '../slices/api/pending-invites.slice';
import { platformSubscriptionSlice } from '../slices/api/platform-subscription-slice';
import { customVideoSlice } from '../slices/api/custom-video-slice';
import forgotPasswordSlice from '../slices/local/forgot.password.slice';
import { videoAnalyticsSlice } from '../slices/api/video-analytics-slice';
import { serviceSlice } from '../slices/api/service-slice';
import { feedBackSlice } from '../slices/api/feedback-slice';
import { productsSlice } from '../slices/api/product-slice';

const persistanceConfiguartion: PersistConfig<any, any, any> = {
  key: 'persist',
  whitelist: ['auth'],
  storage: localStorage,
  blacklist: ['loading', 'creditAmount'],
};

const rootReducer = combineReducers({
  associate: associateReducer,
  creditAmount: SetCreditReducer,
  [authSlice.name]: authSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [loadingSlice.name]: loadingSlice.reducer,
  [videoSlice.reducerPath]: videoSlice.reducer,
  [companySlice.reducerPath]: companySlice.reducer,
  [requestSlice.reducerPath]: requestSlice.reducer,
  [serviceSlice.reducerPath]: serviceSlice.reducer,
  [locationSlice.reducerPath]: locationSlice.reducer,
  [feedBackSlice.reducerPath]: feedBackSlice.reducer,
  [analyticsSlice.reducerPath]: analyticsSlice.reducer,
  [forgotPasswordSlice.name]: forgotPasswordSlice.reducer,
  [customVideoSlice.reducerPath]: customVideoSlice.reducer,
  [videoCreditSlice.reducerPath]: videoCreditSlice.reducer,
  [videoPackageSlice.reducerPath]: videoPackageSlice.reducer,
  [userLocationSlice.reducerPath]: userLocationSlice.reducer,
  [notificationSlice.reducerPath]: notificationSlice.reducer,
  [subscriptionSlice.reducerPath]: subscriptionSlice.reducer,
  [videoCreditLocalSlice.name]: videoCreditLocalSlice.reducer,
  [pendingInviteSlice.reducerPath]: pendingInviteSlice.reducer,
  [paymentMethodSlice.reducerPath]: paymentMethodSlice.reducer,
  [authenticationSlice.reducerPath]: authenticationSlice.reducer,
  [packageRequestSlice.reducerPath]: packageRequestSlice.reducer,
  [videoAnalyticsSlice.reducerPath]: videoAnalyticsSlice.reducer,
  [assignedPackageSlice.reducerPath]: assignedPackageSlice.reducer,
  [platformSubscriptionSlice.reducerPath]: platformSubscriptionSlice.reducer,
  [productsSlice.reducerPath]: productsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export const persistedReducer = persistReducer<any, any>(persistanceConfiguartion, rootReducer);
