import moment from 'moment';
import { Fragment } from 'react';
import AppChip from '@/components/AppChip';
import { CreditRequest, PackageRequest, TableData } from '@/core/types';
import { Avatar, Box, Link, Tooltip, Typography } from '@mui/material';
import ApproveCreditRequestButton from '../components/ApproveCreditRequestButton';
import RejectCreditRequestButton from '../components/RejectCreditRequestButton';
import ViewNotesButton from '../../components/ViewNotesButton';
import { RequestStatus } from '@/core/enums/enums';

export const headers: TableData<CreditRequest>[] = [
  {
    key: 'name',
    label: 'Name',
    render: (item) => (
      <Box display="flex" alignItems="center">
        <Avatar alt={item.user.firstName} src={item.user.profilePic} />
        <Tooltip title={`${item.user.firstName} ${item.user.lastName}`} arrow>
          <Typography
            noWrap
            ml={1}
            variant="subtitle1"
            sx={{
              maxWidth: '15rem',
              width: 'fit-content',
              textDecoration: 'none',
            }}
          >
            {item.user.firstName} {item.user.lastName}
          </Typography>
        </Tooltip>
      </Box>
    ),
  },
  {
    key: 'dateRequested',
    label: 'Date Requested',
    align: 'left',
    render: (item) => (
      <Typography variant="subtitle1" noWrap sx={{ width: 'fit-content' }}>
        {moment(item.createdAt).format('LL')}
      </Typography>
    ),
  },
  {
    key: 'time',
    label: 'Time',
    align: 'left',
    render: (item) => (
      <Typography variant="subtitle1" noWrap sx={{ width: 'fit-content' }}>
        {moment(item.createdAt).format('LL')}
      </Typography>
    ),
  },
  {
    key: 'creditRequested',
    label: 'Credit Requested',
    align: 'left',
    render: (item) => (
      <Typography variant="subtitle1" noWrap sx={{ width: 'fit-content' }}>
        {item.creditRequested}
      </Typography>
    ),
  },
  {
    key: 'status',
    label: 'Status',
    align: 'left',
    render: (item) => {
      switch (item.status) {
        case RequestStatus.APPROVED:
          return <AppChip color="success">{item.requestStatus.name}</AppChip>;
        case RequestStatus.REJECTED:
          return <AppChip color="error">{item.requestStatus.name}</AppChip>;
        case RequestStatus.CANCELLED:
          return <AppChip color="warning">{item.requestStatus.name}</AppChip>;
        case RequestStatus.IDLE:
          return <AppChip color="info">{item.requestStatus.name}</AppChip>;
        default:
          return <AppChip>Unknown</AppChip>;
      }
    },
  },

  {
    key: 'notes',
    label: 'Notes',
    align: 'left',
    render: (item) => <ViewNotesButton request={item} />,
  },
  {
    key: 'actions',
    label: 'Actions',
    align: 'left',
    render: (item, theme, refetch) => (
      <Box>
        {item.status == RequestStatus.IDLE ? (
          <Box display="flex">
            <ApproveCreditRequestButton onApprove={refetch} request={item} />
            <RejectCreditRequestButton onReject={refetch} request={item} />{' '}
          </Box>
        ) : (
          <p className="text-left text-slate-400 ml-4">N/A</p>
        )}
      </Box>
    ),
  },
];
