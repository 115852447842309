import { useEffect, useState } from 'react';
import { headers } from './data/TableData';
import { Helmet } from 'react-helmet-async';
import AppTable from '@/components/AppTable';
import { useAuth } from '@/core/utils/utils';
import { SearchBar } from '@/components/SearchBar';
import DropdownClassic from '@/components/DropdownClassic';
import { RefreshButton } from '@/components/RefreshButton';
import { PaginatedList } from '@/core/constants/constants';
import { DropdownOption, PackageRequest } from '@/core/types';
import TransitionWrapper from '@/components/TransitionWrapper';
import AppTablePagination from '@/components/AppTablePagination';
import { useGetRequestStatusesQuery } from '@/features/state/slices/api/request-slice';
import { useGetPackageRequestsQuery } from '@/features/state/slices/api/package-request.slice';
import { DefaultOptions } from '@/core/enums/enums';

const ManagePackageRequest = () => {
  const [query, setQuery] = useState<any>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [requestStatuses, setRequestStatuses] = useState<any>([]);

  const [packageRequests, setPakageRequests] = useState<PaginatedList<PackageRequest[]>>({
    items: [],
    meta: {
      itemCount: 0,
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      itemsPerPage: 0,
    },
  });

  const { data, isError, refetch, isSuccess, isFetching } = useGetPackageRequestsQuery({ ...query, companyId: user?.id });

  const {
    isError: requestStatusesError,
    // refetch,
    isSuccess: requestStatusesSuccess,
    isFetching: requestStatusesIsFetching,
    data: requestStatusesData,
  } = useGetRequestStatusesQuery({});

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    setQuery((prev: any) => ({
      ...prev,
      page: pageNumber,
      limit: pageLimit,
    }));
  };

  const handleSearch = (searchTerm: string) => {
    const queryData = {
      searchTerm,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  const handleStatusChange = (option: DropdownOption) => {
    const queryData = {
      status: option.id,
    };

    if (queryData.status == 0) {
      queryData.status = null;
    }

    setQuery({
      ...query,
      ...queryData,
    });
  };

  useEffect(() => {
    if (isSuccess && data?.data) {
      setPakageRequests(data?.data);
      setLoading(false);
    }
  }, [isFetching, isSuccess, data]);

  useEffect(() => {
    if (requestStatusesSuccess && !requestStatusesError && requestStatusesData?.data) {
      const options = requestStatusesData?.data.map((x) => ({ id: x.id, value: x.name }));

      options.unshift({ id: 0, value: DefaultOptions.ALL });

      setRequestStatuses(options);
    }
  }, [requestStatusesIsFetching, requestStatusesSuccess, requestStatusesData]);

  return (
    <>
      <Helmet>
        <title>Package Requests</title>
      </Helmet>
      <TransitionWrapper>
        <div>
          <div className="flex justify-between">
            <div>
              <header className="px-1 pt-3 mb-1 border-b border-slate-100 dark:border-slate-700">
                <h4 className="font-semibold text-slate-800 dark:text-slate-100">Package Request</h4>
              </header>
            </div>
            <div className="flex justify-end space-x-3 items-center">
              <SearchBar onChange={handleSearch} />
              <DropdownClassic options={requestStatuses} onSelect={handleStatusChange} />
              <RefreshButton onRefresh={refetch} />
            </div>
          </div>

          <AppTable
            error={isError}
            headers={headers}
            refetch={refetch}
            loading={loading}
            data={packageRequests.items}
            total={data?.data?.meta.totalItems ?? 0}
          />
          <AppTablePagination totalPages={data?.data?.meta?.totalPages ?? 1} onChange={handlePaginationChanged}></AppTablePagination>
        </div>
      </TransitionWrapper>
    </>
  );
};

export default ManagePackageRequest;
