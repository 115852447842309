import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAuth } from '@core/utils/utils';
import { useContext, useState } from 'react';
import Routes from '@/features/router/routes';
import AppButton from '@/components/AppButton';
import DeleteConfirmModal from '@/features/shared/DeleteConfirmModal';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { SalesAssociateContext } from '../../../providers/SalesAssociateProvider';
import { useDeleteSalesAssociateMutation } from '@/features/state/slices/api/company-slice';

export default function DeleteUser() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteSalesAssociate] = useDeleteSalesAssociateMutation();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const { salesAssociate } = useContext(SalesAssociateContext);

  const toggleConfirmDelete = () => {
    setConfirmDelete((prev) => !prev);
  };
  const handleUserDelete = async () => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await deleteSalesAssociate({
        companyId: user?.id,
        userId: salesAssociate!.id,
      }).unwrap();

      if (success) {
        navigate(`/${Routes.App.Users.SalesAssociates.Base}`);
      } else {
        toast.error('Unable to delete associate, please try again or contact your service provider');
      }
    } catch (err: any) {
      toast.error('Failed to delete associate: ', err);
    }
    dispatch(toggleLoading());
  };

  return (
    <div className="p-6 col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-md border border-slate-200 dark:border-slate-700 mt-3">
      <div className="space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold ">Delete Sales Associate</h2>

        <p className="text-slate-500">
          Deleting this Sales Associate will remove their profile and access from your company's records. Consider the implications before
          proceeding.
        </p>

        <div className="flex flex-col px-6 ">
          <div className="flex self-end">
            <AppButton onClick={toggleConfirmDelete} className="bg-red-500 hover:bg-red-400">
              Delete User
            </AppButton>
          </div>
        </div>
        <DeleteConfirmModal
          open={confirmDelete}
          title={'Delete this user?'}
          handleAction={handleUserDelete}
          closeModal={toggleConfirmDelete}
          confirmationText={'This will delete their information and prevent them from using the application'}
        />
      </div>
    </div>
  );
}
