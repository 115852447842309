import moment from 'moment';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { CustomVideoAnalyticViewerEntity, PopularVideoMetrics, VideoAnalyticsViewerEntity } from '@core/types';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Card, Skeleton, Typography } from '@mui/material';
import ProgressiveImage from 'react-progressive-graceful-image';
import LineChart06 from '@/components/Charts/LineChart06';
import { hexToRGB, tailwindConfig } from '@/core/utils/utils';
import { useEffect } from 'react';
import LineChart02 from '@/components/Charts/LineChart02';
import FlipNumbers from 'react-flip-numbers';

type Props = {
  metric: CustomVideoAnalyticViewerEntity;
};

const StyledVideoItem = styled(Box)(({ theme }) => {
  return {
    borderRadius: 1,
    cursor: 'pointer',
    backgroundColor: '#F8F8F8',
    '&:hover, &:focus': {
      backgroundColor: '#EAEAEA',
    },
  };
});

export default function CustomAnalyticItem({ metric }: Props) {
  const {
    video: { video },
    views,
  } = metric;

  const navigate = useNavigate();

  const onNavigateToViewers = () => {
    navigate(video.id.toString());
  };

  // const chartData = {
  //   labels: dataPoints.map((x) => ''),

  //   datasets: [
  //     // Indigo line
  //     {
  //       label: 'Current',
  //       data: dataPoints,
  //       borderColor: tailwindConfig().theme.colors.indigo[500],
  //       fill: false,
  //       borderWidth: 2,
  //       tension: 0,
  //       pointRadius: 0,
  //       pointHoverRadius: 3,
  //       pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
  //       pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
  //       pointBorderWidth: 0,
  //       pointHoverBorderWidth: 0,
  //       clip: 20,
  //     },
  //   ],
  // };

  return (
    <StyledVideoItem p={3} display="flex" height={250} mt={2} onClick={onNavigateToViewers}>
      <Box width="100%" display="flex">
        <Box width="20%" height={350}>
          <ProgressiveImage src={video.videoThumbnail!} placeholder="">
            {(src, loading) =>
              loading ? (
                <Skeleton width={350} height={250} animation="wave" variant="rectangular" sx={{ borderRadius: 1 }} />
              ) : (
                <img
                  src={src}
                  width={350}
                  height={250}
                  crossOrigin="anonymous"
                  alt="Video Thumbnail"
                  style={{ borderRadius: 5, objectFit: 'fill' }}
                />
              )
            }
          </ProgressiveImage>
        </Box>
        <Box width="80%" display="flex">
          <Box ml={5}>
            <h2 className="text-2xl leading-snug text-blue-500 font-bold"> {video.videoName}</h2>

            <p className="text-sm text-slate-500">{moment(video.createdAt).format('LL')}</p>
            <p className="text-sm text-slate-500">{video.description}</p>
          </Box>
          <Box ml="auto">
            <div className="flex items-center justify-center">
              <svg className="h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12Z"
                  className="fill-current text-slate-600"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  className="fill-current text-slate-600"
                  d="M2 12C2 13.6394 2.42496 14.1915 3.27489 15.2957C4.97196 17.5004 7.81811 20 12 20C16.1819 20 19.028 17.5004 20.7251 15.2957C21.575 14.1915 22 13.6394 22 12C22 10.3606 21.575 9.80853 20.7251 8.70433C19.028 6.49956 16.1819 4 12 4C7.81811 4 4.97196 6.49956 3.27489 8.70433C2.42496 9.80853 2 10.3606 2 12ZM12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25Z"
                />
              </svg>
              <h2 className="text-base text-slate-500 m-1 mt-1"> {video.watchCount}</h2>
            </div>
          </Box>
        </Box>
      </Box>
    </StyledVideoItem>
  );
}
