import { useState } from 'react';
import { useNavigate } from 'react-router';
import Routes from '@/features/router/routes';
import { IconButton, TableCell, Tooltip, useTheme } from '@mui/material';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DownloadDoneTwoToneIcon from '@mui/icons-material/DownloadDoneTwoTone';
import {
  useDeleteSalesAssociateMutation,
  useSuspendSalesAssociateMutation,
  useUnSuspendSalesAssociateMutation,
} from '@features/state/slices/api/company-slice';
import { toast } from 'react-hot-toast';
import { useAuth } from '@core/utils/utils';
import { useDispatch } from 'react-redux';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import DeleteConfirmModal from '@features/shared/DeleteConfirmModal';

const ActionsTableCell = ({ rowId, refetch, blocked }: { rowId: number; blocked: boolean; refetch: () => void }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const [suspendAssociate] = useSuspendSalesAssociateMutation();
  const [UnSuspendSalesAssociate] = useUnSuspendSalesAssociateMutation();
  const [deleteSalesAssociate] = useDeleteSalesAssociateMutation();

  const toggleConfirmDelete = () => {
    setConfirmDelete((prev) => !prev);
  };

  const handleEdit = () => {
    navigate(`/${Routes.App.Users.SalesAssociates.Base}/${rowId}`);
  };

  const handleSuspend = async () => {
    dispatch(toggleLoading());
    try {
      const apiResponse = await suspendAssociate({
        companyId: user?.id,
        userId: rowId,
      }).unwrap();

      if (apiResponse.success) {
        toast.success('Associate has been suspended');
        refetch();
      } else {
        toast.error('Unable to suspend associate, please try again or contact your service provider');
      }
    } catch (err: any) {
      toast.error('Failed to suspend associate: ', err);
    }
    dispatch(toggleLoading());
  };

  const handleUnSuspend = async () => {
    dispatch(toggleLoading());
    try {
      const apiResponse = await UnSuspendSalesAssociate({
        companyId: user?.id,
        userId: rowId,
      }).unwrap();

      if (apiResponse.success) {
        toast.success('Associate has been Unsuspended');
        refetch();
      } else {
        toast.error('Unable to Unsuspended associate, please try again or contact your service provider');
      }
    } catch (err: any) {
      toast.error('Failed to Unsuspended associate: ', err);
    }
    dispatch(toggleLoading());
  };

  const handleRemove = async () => {
    dispatch(toggleLoading());
    try {
      const apiResponse = await deleteSalesAssociate({
        companyId: user?.id,
        userId: rowId,
      }).unwrap();

      if (apiResponse.success) {
        toast.success('Associate has been deleted');
        refetch();
      } else {
        toast.error('Unable to delete associate, please try again or contact your service provider');
      }
    } catch (err: any) {
      toast.error('Failed to delete associate: ', err);
    }
    dispatch(toggleLoading());
  };
  return (
    <TableCell align="center" sx={{ px: 0 }}>
      <DeleteConfirmModal
        open={confirmDelete}
        handleAction={handleRemove}
        closeModal={toggleConfirmDelete}
        title={'Delete this user?'}
        confirmationText={'This will delete their information and prevent them from using the application'}
      />
      <Tooltip title={'Edit Associate'} arrow>
        <IconButton
          onClick={handleEdit}
          sx={{
            '&:hover': {
              background: theme.colors.primary.lighter,
            },
            p: 0.3,
            borderRadius: '50%',
            color: theme.palette.primary.main,
          }}
          color="inherit"
          size="small"
        >
          <EditTwoToneIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Remove Associate'} arrow>
        <IconButton
          onClick={toggleConfirmDelete}
          sx={{
            '&:hover': {
              background: theme.colors.error.lighter,
            },
            p: 0.3,
            borderRadius: '50%',
            color: theme.palette.error.main,
          }}
          size={'small'}
        >
          <DeleteTwoToneIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
      <Tooltip title={!blocked ? 'Suspend Associate' : 'Unsuspend Associate'} arrow>
        <IconButton
          onClick={!blocked ? handleSuspend : handleUnSuspend}
          sx={{
            '&:hover': {
              background: theme.colors.error.lighter,
            },
            p: 0.3,
            borderRadius: '50%',
            color: theme.palette.error.main,
          }}
          size={'small'}
        >
          {!blocked && <BlockTwoToneIcon fontSize={'small'} />}
          {blocked && <DownloadDoneTwoToneIcon fontSize={'small'} />}
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export default ActionsTableCell;
