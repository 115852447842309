import useChart from '@core/hooks/useChart';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box } from '@mui/material';

type Props = {
  title: string;
  chartData: any[];
  subheader: string;
  chartLabels: string[];
};

export default function LineChart({ title, subheader, chartLabels, chartData, ...other }: any) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: ['gradient'] },
    labels: chartLabels,
    // xaxis: { type: 'datetime' },
    toolbar: {
      show: true,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: any) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
    chart: {
      zoom: {
        enabled: true,
        type: 'x',
        resetIcon: {
          offsetX: -10,
          offsetY: 0,
          fillColor: '#fff',
          strokeColor: '#37474F',
        },
        selection: {
          background: '#90CAF9',
          border: '#0D47A1',
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 4, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" height={364} series={chartData} options={chartOptions} />
      </Box>
    </Card>
  );
}
