import { useSelector } from 'react-redux';
import LargeLogo from '@/assets/images/logo.png';
import React, { useState, ReactElement } from 'react';
import useResponsive from '@/core/hooks/useResponsive';
import SmallLogo from '@/assets/images/small-logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import { Layout } from '@/features/state/slices/local/layout.slice';
import { Box, Tooltip, alpha, lighten, styled } from '@mui/material';
import { RootState } from '@/features/state/reducers/combinedReducers';
import { topNavConfig } from '@/core/layouts/Dashboard/Sidebar/NavItems';

export const SidebarWrapper = styled(Box)(
  ({ theme }) => `
         
          color: ${theme.colors.alpha.trueWhite[70]};
          position: relative;
          z-index: 7;
          height: 100vh;
          padding-bottom: 68px;
          position: fixed;
          left: 0;
          top: 0;
          background: ${alpha(lighten(theme.header.background!, 0.1), 0.5)},
          boxShadow:
            ${theme.sidebar.boxShadow},
  `,
);

export const StyledNavBox = styled(Box)<Layout>(({ isOpen, layoutValue }) => ({
  flexShrink: 0,
  height: '100vh',
  width: layoutValue,
  scrollbarWidth: 'none',
  padding: isOpen ? 10 : 0,
  transition: 'width 1.5s',
  backgroundColor: '#1e293b',
  transitionDuration: '200ms',
  transitionTimingFunction: 'ease-in-out',
}));

function SidebarLinkGroup({ children, activeCondition }: any) {
  const [open, setOpen] = useState(activeCondition);

  const handleClick = () => {
    setOpen(!open);
  };

  return <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${activeCondition && 'bg-slate-900'}`}>{children(handleClick, open)}</li>;
}

type SidebarProps = {
  layout: Layout;
  onItemClick?: () => void;
};

function Sidebar({ layout, onItemClick }: SidebarProps) {
  const location = useLocation();
  const { pathname } = location;

  return (
    <SidebarWrapper>
      <StyledNavBox isOpen={layout.isOpen} layoutValue={layout.layoutValue}>
        <div
          aria-hidden="true"
          className={`lg:fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 `}
        ></div>

        {/* Sidebar */}
        <div id="sidebar">
          {/* Sidebar header */}
          <div className="flex justify-between mb-10 pr-3 sm:px-2">
            <NavLink end to="/" className="block">
              <img src={SmallLogo} height={80} width={65} />
              {/* {layout.isOpen ? <img src={LargeLogo} height={90} width={140} /> : <img src={SmallLogo} height={80} width={65} />} */}
            </NavLink>
          </div>

          {/* Links */}
          <div className="space-y-8">
            {/* Pages group */}
            <div>
              <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                <span className="lg:hidden lg:sidebar-expanded:block 2xl:block"> {layout.isOpen ? 'Company Admin' : 'Admin'}</span>
              </h3>
              <ul className="mt-3 space-y-2 pl-0">
                {topNavConfig.map((config) => {
                  //const Icon = config.icon;
                  if (config?.children !== null && config?.children != undefined) {
                    return (
                      <SidebarLinkGroup activeCondition={pathname === '/' || pathname.includes(config.path)}>
                        {(handleClick: any, open: any) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 truncate transition duration-150 ${
                                  pathname === '/' || pathname.includes(config.path) ? 'hover:text-slate-200' : 'hover:text-white'
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleClick();
                                }}
                              >
                                <Tooltip title={layout.isOpen ? null : config.title} placement="right-start">
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                      {config!.getIcon(pathname)}
                                      {!layout.isOpen && (
                                        <svg
                                          className={`w-2 h-2 shrink-0 ml-1 fill-current text-slate-400 ${open && 'rotate-180'}`}
                                          viewBox="0 0 12 12"
                                        >
                                          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                        </svg>
                                      )}

                                      {layout.isOpen && <span className="text-sm font-medium ml-3 duration-200">{config.title}</span>}
                                    </div>

                                    {/* Icon */}
                                    <div className="flex shrink-0 ml-2">
                                      <svg
                                        className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && 'rotate-180'}`}
                                        viewBox="0 0 12 12"
                                      >
                                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                      </svg>
                                    </div>
                                  </div>
                                </Tooltip>
                              </a>
                              <div className="2xl:block">
                                <ul className={`mt-1 ${!layout.isOpen && 'p-0'} transition duration-150 space-y-3 ${!open && 'hidden'}`}>
                                  {config?.children?.map((child) => (
                                    <li className={`mb-1 last:mb-0 first:mt-3  ${!layout.isOpen && 'mt-4 mb-4'}`}>
                                      <NavLink
                                        end
                                        onClick={() => {
                                          if (onItemClick) {
                                            onItemClick();
                                          }
                                        }}
                                        to={`/${child.path}`}
                                        className={({ isActive }) =>
                                          'block transition duration-150 truncate ' +
                                          (isActive ? 'text-blue-500' : 'text-slate-400 hover:text-slate-200')
                                        }
                                      >
                                        {layout.isOpen ? (
                                          <span className="text-sm font-medium  2xl:opacity-100 duration-200">{child.title}</span>
                                        ) : (
                                          <div>
                                            <Tooltip title={child.title} placement="right-start">
                                              {child.getIcon(pathname) as ReactElement<any, any>}
                                            </Tooltip>
                                          </div>
                                        )}
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    );
                  } else {
                    return (
                      <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes(config.path) && 'bg-slate-900'}`}>
                        <NavLink
                          end
                          onClick={() => {
                            if (onItemClick) {
                              onItemClick();
                            }
                          }}
                          to={`/${config.path}`}
                          className={`block text-slate-200 truncate transition duration-150 ${
                            pathname.includes(config.path) ? 'hover:text-slate-200' : 'hover:text-white'
                          }`}
                        >
                          <Tooltip title={layout.isOpen ? null : config.title} placement="right-start">
                            <div className="flex items-center">
                              {config!.getIcon(pathname)}
                              {layout.isOpen && <span className="text-sm font-medium ml-3  duration-200">{config.title}</span>}
                            </div>
                          </Tooltip>
                        </NavLink>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </StyledNavBox>
    </SidebarWrapper>
  );
}

export default Sidebar;
