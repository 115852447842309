import AppRoutes from './routes';
import { styled } from '@mui/material';

import NotFoundPage from '../pages/404/404Page';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '@features/pages/Dashboard';
import UnauthorizedPage from '../pages/401/401Page';
import ManageVideos from '@/features/pages/MyVideos';
import ManageSettings from '@/features/pages/Settings';
import DashboardLayout from '@/core/layouts/Dashboard';
import Billing from '@/features/pages/Settings/pages/Billing';
import Account from '@/features/pages/Settings/pages/Account';
import Login from '@/features/pages/Authentication/Login/Login';
import Security from '@/features/pages/Settings/pages/Security';
import Feedback from '@/features/pages/Settings/pages/Feedback';
import VideoAnalyticsDashboard from '@features/pages/Analytics';
import { ProtectedRoute } from '@core/utils/routeAuthentication';
import ManageSubscriptions from '@/features/pages/Subscriptions';
import CreditRequest from '@/features/pages/Requests/CreditRequest';
import ManagePendingUsers from '@/features/pages/Users/PendingUsers';
import SalesAssociates from '@/features/pages/Users/SalesAssociates/pages/UserTable';
import PackageRequest from '@/features/pages/Requests/PackageRequest';
import SetPassword from '@/features/pages/Authentication/SetPassword';
import ForgetPassword from '@/features/pages/Authentication/ForgetPassword';
import ManageCustomVideoRequest from '@/features/pages/Requests/CustomVideoRequest';
import UserDetails from '@/features/pages/Users/SalesAssociates/pages/UserDetails';
import VideoAnalytics from '@/features/pages/Analytics/pages/VideoAnalytics/VideoAnalytics';
import { VideoAnalyticsViewers } from '../pages/Analytics/pages/VideoAnalytics/VideoAnalyticsViewers';
import CustomVideoAnalytics from '@/features/pages/Analytics/pages/CustomVideoAnalytics/CustomVideoAnalytics';
import VideoPackageView from '../pages/MyVideos/components/Packages/VideoPackageView';
import CustomVideosDisplay from '../pages/MyVideos/components/CustomVideos/CustomVideosDisplay';
import PackageDisplay from '../pages/MyVideos/components/Packages/PackageDisplay';
import CustomVideoDetails from '../pages/MyVideos/components/CustomVideos/CustomVideoDetails';
import { CreateCompany } from '../pages/Authentication/Register';
import ManageCompanySubscription from '../pages/Subscriptions/ManageCompanySubscriptions/ManageCompanySubscription';
import { ManageProducts } from '../pages/Products/ManageProducts';
import { CustomVideoAnalyticsViewers } from '../pages/Analytics/pages/CustomVideoAnalytics/components/CustomVideoAnalyticsViewers';

const BaseContainer = styled('div')(({ theme }: any) => ({
  backgroundColor: theme.palette,
}));

export default function Router() {
  return (
    <BaseContainer>
      <Routes>
        <Route path={AppRoutes.AuthRoot}>
          <Route path={AppRoutes.Login} element={<Login />} />
          <Route path={AppRoutes.SetPassword} element={<SetPassword />} />
          <Route path={AppRoutes.ForgotPassword} element={<ForgetPassword />} />
          <Route path={AppRoutes.Register} element={<CreateCompany />} />
        </Route>
        <Route>
          <Route path={AppRoutes.External}>
            <Route path={AppRoutes.Analytics} element={<VideoAnalyticsDashboard />}>
              <Route path={AppRoutes.Videos} element={<VideoAnalytics />} />
              <Route path={AppRoutes.CustomVideos} element={<CustomVideoAnalytics />} />

              <Route path={`${AppRoutes.Videos}/:id`} element={<VideoAnalyticsViewers />} />
            </Route>
          </Route>

          <Route
            path={AppRoutes.AppRoot}
            element={
              <ProtectedRoute>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path={AppRoutes.Dashboard} element={<Dashboard />} />

            <Route path={AppRoutes.Users}>
              <Route path={AppRoutes.PendingUsers} element={<ManagePendingUsers />} />
              <Route path={`${AppRoutes.SalesAssociates}/*`}>
                <Route index element={<SalesAssociates />} />
                <Route path={`:id`} element={<UserDetails />} />
              </Route>
            </Route>

            <Route path={AppRoutes.MyContent} element={<ManageVideos />}>
              <Route path={AppRoutes.VideoPackages} element={<PackageDisplay />} />
              <Route path={AppRoutes.CustomVideos} element={<CustomVideosDisplay />} />
              <Route path={`${AppRoutes.CustomVideos}/:id`} element={<CustomVideoDetails />} />
              <Route path={`${AppRoutes.VideoPackages}/:id`} element={<VideoPackageView />} />
            </Route>

            <Route path={AppRoutes.Subscription} element={<ManageSubscriptions />} />
            <Route path={`${AppRoutes.Subscription}/create`} element={<ManageCompanySubscription />} />
            <Route path={AppRoutes.Products} element={<ManageProducts />} />
            <Route path={AppRoutes.Analytics} element={<VideoAnalyticsDashboard />}>
              <Route path={AppRoutes.Videos} element={<VideoAnalytics />} />
              <Route path={AppRoutes.CustomVideos} element={<CustomVideoAnalytics />} />
              <Route path={`${AppRoutes.CustomVideos}/:id`} element={<CustomVideoAnalyticsViewers />} />
              <Route path={`${AppRoutes.Videos}/:id`} element={<VideoAnalyticsViewers />} />
            </Route>

            <Route path={AppRoutes.Settings} element={<ManageSettings />}>
              <Route path={AppRoutes.Account} element={<Account />} />
              <Route path={AppRoutes.Billing} element={<Billing />} />
              <Route path={AppRoutes.Security} element={<Security />} />
              <Route path={AppRoutes.Feedback} element={<Feedback />} />
            </Route>

            <Route path={AppRoutes.Requests}>
              <Route path={AppRoutes.Package} element={<PackageRequest />} />

              <Route path={AppRoutes.Credit} element={<CreditRequest />} />

              <Route path={AppRoutes.CustomVideo} element={<ManageCustomVideoRequest />} />
            </Route>
          </Route>
        </Route>

        <Route path="/*" element={<NotFoundPage />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/" element={<ProtectedRoute handleBaseRedirect={true} />} />
      </Routes>
    </BaseContainer>
  );
}
