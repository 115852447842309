import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { useSessionStorage } from 'react-use';
import { browserName } from '@core/utils/utils';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default function ExperienceDialog() {
  const [experienceDialog, setExperienceDialog] = useState(false);
  const [hasDialogShown, setHasDialogShown] = useSessionStorage('hasExperienceDialogShown', false); // Using session storage to remember if the dialog has been shown in the current visit

  useEffect(() => {
    const isChrome = browserName === 'Chrome';

    if (!isChrome && !hasDialogShown) {
      setExperienceDialog(true);
      setHasDialogShown(true);
    }
  }, [hasDialogShown]);

  const toggleExperienceDialog = () => {
    setExperienceDialog(false);
  };

  return (
    <Dialog open={experienceDialog} onClose={toggleExperienceDialog} aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{'Please Note!'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {browserName !== 'other' && `We noticed you are using ${browserName}`}{' '}
          {browserName === 'other' && "We noticed you aren't using Chrome"} as your browser. For the best experience using this website, we
          recommend using{' '}
          <a
            target={'_blank'}
            href="https://www.google.com/chrome/?brand=YTUH&gclid=CjwKCAiA_6yfBhBNEiwAkmXy5x_jEwOha1Qkz-DtgdyXKlZgde4fWQtAt5D1jJVdakrT1U1bskFcrxoCoYgQAvD_BwE&gclsrc=aw.ds"
          >
            Chrome
          </a>{' '}
          as your browser.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleExperienceDialog}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
