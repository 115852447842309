import AppButton from '../AppButton';

type NoPaymentProps = {
  onNavigate: () => void;
};

export default function NoPaymentMethod({ onNavigate }: NoPaymentProps) {
  return (
    <div className="mt-5">
      <div className="max-w-2xl m-auto mt-16">
        <div className="text-center px-4">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-2">No payment methods found</h2>
          <div className="mb-6">To start making purchases, kindly add a payment method in your account settings.</div>
          <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
            <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
          </button>
          <div className="text-center col-span-6 order-1 sm:order-none sm:col-span-3 flex justify-center self-center items-center    lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-3">
            <svg className="shrink-0" width="32" height="24">
              <defs>
                <linearGradient x1="1.829%" y1="100%" x2="100%" y2="2.925%" id="c1-a">
                  <stop stopColor="#475569" offset="0%" />
                  <stop stopColor="#1E293B" offset="100%" />
                  <stop stopColor="#9FA1FF" offset="100%" />
                </linearGradient>
              </defs>
              <g fill="none" fillRule="evenodd">
                <rect fill="url(#c1-a)" width="32" height="24" rx="3" />
                <ellipse fill="#E61C24" fillRule="nonzero" cx="12.522" cy="12" rx="5.565" ry="5.647" />
                <ellipse fill="#F99F1B" fillRule="nonzero" cx="19.432" cy="12" rx="5.565" ry="5.647" />
                <path
                  d="M15.977 7.578A5.667 5.667 0 0 0 13.867 12c0 1.724.777 3.353 2.11 4.422A5.667 5.667 0 0 0 18.087 12a5.667 5.667 0 0 0-2.11-4.422Z"
                  fill="#F26622"
                  fillRule="nonzero"
                />
              </g>
            </svg>
          </div>
          <AppButton className="mt-4" onClick={onNavigate}>
            Add a payment method
          </AppButton>
        </div>
      </div>
    </div>
  );
}
