import Swal from 'sweetalert2';
import Center from '@components/Center/Center';
import { SubscriptionPlan, MONTHLY_CONTRACT_KEY, ONE_YEAR_CONTRACT_KEY, SIX_MONTH_CONTRACT_KEY } from '@core/constants/constants';
import FlipNumbers from 'react-flip-numbers';
import { Box, Typography } from '@mui/material';
import { convertDaysToYearsMonthsDays, formatDateDuration, toSentenceCase } from '@/core/utils/utils';

type Props = {
  pricePlan: string;
  isSelected: boolean;
  config: DisplayConfig;
  subscription: SubscriptionPlan & { intervalPrice?: any };
  onSelect: (subscription: SubscriptionPlan, pricingPlan: string) => void;
};

type DisplayConfig = {
  displayTrialPeriod: boolean;
  displayUpFrontBilling: boolean;
};

export const SubscriptionDisplayItem = (props: Props) => {
  const { config, pricePlan, subscription, onSelect, isSelected } = props;

  const determinePrice = () => {
    if (subscription.hasOwnProperty('intervalPrice')) {
      return subscription.intervalPrice;
    }
    switch (pricePlan) {
      case SIX_MONTH_CONTRACT_KEY:
        return subscription.sixMonthContractCost;
      case ONE_YEAR_CONTRACT_KEY:
        return subscription.oneYearContractCost;
      case MONTHLY_CONTRACT_KEY:
        return subscription.monthlyContractCost;
      default:
        return 0;
    }
  };

  const handleAccessCode = () => {
    Swal.fire({
      title: 'Enter Access Code',
      input: 'text',
      inputLabel: 'Access Code',
      inputPlaceholder: 'Enter your access code',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-btn', // This class name is for reference
      },

      didRender: () => {
        const confirmButton = Swal.getConfirmButton();
        if (confirmButton) {
          confirmButton.style.backgroundColor = '#63b3ed'; // Light blue
          confirmButton.style.color = 'white';
          confirmButton.style.fontWeight = 'bold';
          confirmButton.style.padding = '8px 16px';
          confirmButton.style.borderRadius = '8px';
        }
      },
    }).then((result) => {
      if (result.isConfirmed && subscription.accessCodes.includes(result.value)) {
        onSelect(subscription, pricePlan);
        Swal.fire({
          icon: 'success',
          title: 'Access Granted',
          text: 'You have successfully chosen the subscription.',
        });
      } else if (result.isConfirmed) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Access Code',
          text: 'Please check your access code and try again.',
        });
      }
    });
  };

  return (
    <div className="col-lg-4" style={{ height: 700 }}>
      <div className="card mb-5" style={{ height: 700 }}>
        <div className="card-body">
          <Box mt={2} mb={2}>
            <h5 className="card-title text-muted text-uppercase text-center">{subscription?.name}</h5>
          </Box>
          <Box mb={4} textAlign="center">
            {config?.displayUpFrontBilling && <span style={{ color: 'grey' }}>Pay ${subscription?.upFrontBillingCost} upfront and</span>}

            <h6 className="card-price text-center mt-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FlipNumbers
                play
                width={30}
                height={40}
                color="black"
                background="white"
                numberStyles={{ textAlign: 'center' }}
                numbers={`$ ${determinePrice().toString()}`}
              />
              <span className="period"> &nbsp;/month</span>
            </h6>
            {subscription.hasTrialPeriod && subscription.numberOfDaysInTrialPeriod && subscription.numberOfDaysInTrialPeriod != 0 && (
              <Box mt={1}>
                <Typography variant="h3" fontSize={20}>
                  {`FREE TRIAL INCLUDED lasting ${formatDateDuration(
                    convertDaysToYearsMonthsDays(subscription.numberOfDaysInTrialPeriod ?? 100),
                  )}`}
                </Typography>
              </Box>
            )}
          </Box>
          <ul className="fa-ul">
            <Box display="flex" mt={1}>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
              </li>
              Users covered
              <Box ml={0.5} mt={0.5}>
                <FlipNumbers
                  play
                  width={8}
                  height={13.5}
                  color="black"
                  background="white"
                  numberStyles={{ fontWeight: 'bold' }}
                  numbers={`${subscription?.usersCovered}`}
                />
              </Box>
            </Box>

            <Box mt={1}>
              <Typography variant="caption" fontSize={12}>
                FEATURES
              </Typography>
            </Box>

            <Box mt={1}>
              {subscription?.subscriptionItemsIncluded?.map((item) => (
                <Box mt={0.5}>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-check"></i>
                    </span>
                    {toSentenceCase(item)}
                  </li>
                </Box>
              ))}
            </Box>
          </ul>
          <div className="d-grid" style={{ left: 10, right: 10, bottom: 30, position: 'absolute' }}>
            {isSelected ? (
              <a className="btn btn-primary text-uppercase">Selected</a>
            ) : subscription.accessCodes && subscription.accessCodes.length !== 0 ? (
              <a onClick={handleAccessCode} className={`btn ${isSelected ? 'btn-primary' : 'btn-dark'} text-uppercase`}>
                Enter Access Code
              </a>
            ) : (
              <a
                onClick={() => onSelect(subscription, pricePlan)}
                className={`btn ${isSelected ? 'btn-primary' : 'btn-dark'} text-uppercase`}
              >
                Choose
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
