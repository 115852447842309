import React, { useContext, useEffect, useState } from 'react';
import LineChart from '@/components/Charts/LineChart07';
import { hexToRGB, tailwindConfig, useAuth } from '@/core/utils/utils';
import { useGetUserGrowthAnalysisQuery } from '@/features/state/slices/api/analytics-slice';
import { AnalyticsTokenContext } from '@/features/pages/Analytics/providers/AnalyticsTokenProvider';

function UserGrowthPortfolio() {
  const user = useAuth();
  const [displayChart, setDisplayChart] = useState(false);
  const [chartData, setChartData] = useState<any | null>(null);

  const { isFetching, isError, isSuccess, data } = useGetUserGrowthAnalysisQuery({
    companyId: user?.user?.id,
  });

  useEffect(() => {
    if (!isFetching && isSuccess && !isError) {
      setChartData({
        labels: ['', ''],
        datasets: [
          {
            label: 'Growth 1',
            data: [data.data?.growthTimeline?.startValue, data.data?.growthTimeline?.endValue],
            borderColor: tailwindConfig().theme.colors.emerald[500],
            fill: true,
            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.emerald[500])}, 0.08)`,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.emerald[500],
            pointHoverBackgroundColor: tailwindConfig().theme.colors.emerald[500],
            pointBorderWidth: 0,
            pointHoverBorderWidth: 0,
            clip: 20,
          },
        ],
      });

      setTimeout(() => {
        setDisplayChart(true);
      }, 500);
    }
  }, [isFetching, isError, isSuccess]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-3 bg-white dark:bg-slate-800 shadow-md rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700 flex items-center">
        <h5 className="font-semibold text-slate-800 dark:text-slate-100">User Growth Portfolio</h5>
      </header>
      <div className="px-5">
        <div className="text-sm italic mb-2">
          Hey {user.user?.name}, your user base has grown by {data?.data?.growthPercentage}% over the last {data?.data?.growthDate}
        </div>
        <div className="text-3xl font-bold text-slate-800 dark:text-slate-100">
          {data?.data?.growthTimeline?.startValue ?? 0} - {data?.data?.growthTimeline?.endValue ?? 0}{' '}
          <span className="text-sm font-normal">users</span>
        </div>
      </div>
      <div className="grow">{displayChart && <LineChart data={chartData} width={389} height={262} />}</div>
    </div>
  );
}

export default UserGrowthPortfolio;
