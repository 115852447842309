import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useAuth } from '@core/utils/utils';
import { CreditRequest } from '@core/types';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { fetchVideoCredit } from '@features/state/slices/local/video-credit-slice';
import { useRejectVideoCreditRequestMutation } from '@features/state/slices/api/request-slice';
import { useState } from 'react';
import DeleteConfirmModal from '@features/shared/DeleteConfirmModal';

const RejectCreditRequestButton = ({ request, onReject }: { onReject: () => void; request: CreditRequest }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const dispatch = useDispatch<any>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const toggleConfirmDelete = () => {
    setConfirmDelete((prev) => !prev);
  };

  const [rejectCreditRequestMutation] = useRejectVideoCreditRequestMutation();

  const rejectCreditRequest = async () => {
    if (request.status === 3) return;
    dispatch(toggleLoading());
    const requestId = request.id;
    try {
      const result = await rejectCreditRequestMutation({
        requestId,
      }).unwrap();
      if (result.success) {
        toast.success('Successfully rejected credit request');
        // dispatch(fetchVideoCredit({ companyId: user?.id! }));
        onReject();
      } else {
        toast.error(result.error?.message!);
      }
    } catch (err) {
      console.error('Unable to reject request: ', err);
    }
    dispatch(toggleLoading());
  };
  return (
    <>
      <DeleteConfirmModal
        open={confirmDelete}
        closeModal={toggleConfirmDelete}
        handleAction={rejectCreditRequest}
        title={'Delete this request?'}
        confirmationText={`This will cancel the user's request for additional credit.`}
      />
      <Tooltip title={'Reject Request'} arrow>
        <IconButton
          sx={{
            '&:hover': {
              background: theme.colors.error.lighter,
            },
            p: 0.3,
            borderRadius: '50%',
            color: theme.palette.error.main,
          }}
          color="inherit"
          size="small"
          onClick={toggleConfirmDelete}
        >
          <DeleteTwoToneIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default RejectCreditRequestButton;
