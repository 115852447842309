import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

export const StyledButton = styled(Button)<ButtonProps>(() => ({
  background: "var(--primary-col) 0% 0% no-repeat padding-box",
  border: "1px solid var(--primary-col)",
  borderRadius: "5px",

  font: "normal normal normal 15px/18px SF Pro Display",
  letterSpacing: "0px",
}));
