import { Roles } from '@core/enums/enums';
import { Company } from '@core/types';
import { DefaultToastOptions } from 'react-hot-toast';

export const COUNTRY_API_ENDPOINT = 'https://api.countrystatecity.in/v1';
export const ACTION_CONFIRMATION_TEXT = 'confirm';

export const MONTHLY_CONTRACT_KEY = 'monthlyContractPriceId';
//DEPRECATED Two year contract plan
// export const TWO_YEAR_CONTRACT_KEY = 'twoYearContractPriceId';
export const ONE_YEAR_CONTRACT_KEY = 'oneYearContractPriceId';
export const SIX_MONTH_CONTRACT_KEY = 'sixMonthContractPriceId';

export const subscriptionTabs = [
  { value: ONE_YEAR_CONTRACT_KEY, label: '1 year – 20% Discount', weight: 12 },
  { value: SIX_MONTH_CONTRACT_KEY, label: '6 months – 10% Discount', weight: 6 },
  //DEPRECATED new pricing system
  // { value: TWO_YEAR_CONTRACT_KEY, label: '2 Year Contract' },

  { value: MONTHLY_CONTRACT_KEY, label: 'Monthly', weight: 1 },
];

export interface CreateSubscriptionDTO {
  additionalBillingCost: string;
  additionalBillingMonthlyThreshold: string;
  additionalUserCost: string;
  credits: number;
  id: number;
  monthlyContractCost: string;
  name: string;
  oneYearContractCost: string;
  setupCost: string;
  sixMonthContractCost: string;
  subscriptionItemsIncluded: string[];
  twoYearContractCost: string;
  usersCovered: number;
  videoPackageDiscount: number;
}

export interface Auth {
  role: Roles | null;
  token: Token | null;
  user: Company | null;
  subscriptionStatus?: 'None' | 'Subscribed' | 'Expired';
}

export interface Token {
  accessToken: string;
  refreshToken: string;
  actionRequired?: any;
}

export interface Settings {
  animations: Animations;
  stayLoggedIn: boolean;
}

interface Animations {
  enabled: boolean;
  isMenuSticky: boolean;
  currentLayoutValues: LayoutValues;
}

export interface LayoutValues {
  width: number;
  marginLeft: number;
}

export interface Query {
  limit: number;
  page: number;
  searchTerm?: string | null;
  startDate?: string | null;
  endDate?: string | null;
}

export interface Meta {
  itemCount: number;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
}

export interface PaginatedList<T> {
  items: T;
  meta: Meta;
}

export interface PageInfo {
  endCursor: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: number;
}
export interface Edges<T> {
  node: T;
}

export interface Admin {
  id: string;
  role: string;
  name: string;
  email: string;
  phoneNumber: string;
  isSuper: boolean;
  lastLogin: number;
  profilePic: string;
  permissions: string;
}

export interface Subscription {
  id: number;

  name: string;

  additionalBillingCost: number;

  additionalBillingMonthlyThreshold: number;

  sixMonthContractCost: number;

  monthlyContractCost: number;

  twoYearContractCost: number;

  oneYearContractCost: number;

  pricingType: number;

  usersCovered: number;

  additionalUserCost: number;

  setupCost: number;

  videoPackageDiscount: number;

  subscriptionItemsIncluded: string[];

  credits: number;

  createdAt: string;

  updatedAt: string;
}

export interface SubscriptionPlan {
  id: number;

  name: string;

  pricePlan: string;

  upFrontBillingCost: string;

  billingMonthlyThreshold: string;

  sixMonthContractCost: string;

  monthlyContractCost: string;

  twoYearContractCost: string;

  oneYearContractCost: string;

  pricingType: string;

  usersCovered: number;

  additionalUserCost: string;

  setupCost: string;

  videoPackageDiscount: number;

  subscriptionItemsIncluded: string[];

  credits: number;

  createdAt: string;

  updatedAt: string;

  hasTrialPeriod?: boolean;
  numberOfDaysInTrialPeriod?: number;
  accessCodes: string[];
}

export interface Video {
  id: number;
  tag: string;
  price: number;
  discount: number;
  videoId: number;
  companyId: number;
  watchCount: number;
  parentVideoClipId: number;
  parentVideoTimestamp: string;
  videoName: string;
  videoUrl: string;
  videoLength: string;
  videoPreview: string;
  videoPromoUrl: string;
  videoThumbnail: string;
  videoCategory: string;
  videoLengthType: string;
  videoMaskedUrl: string;
  lowResolutionVideoUrl: string;
  lowResolutionThumbail: string;
  highResolutionThumbail: string;
  highResolutionVideoUrl: string;
  mediumResolutionVideoUrl: string;
  createdAt?: string;
}

export interface Address {
  workParish: string;
  homeParish: string;
  workCountry: string;
  homeCountry: string;
  companyName: string;
  homePostalCode: string;
  workPostalCode: string;
  workStreetAddress: string;
  homeStreetAddress: string;
}

export type TransitionType = 'vertical' | 'horizontal';
export const PASSWORD_REGEX = /^(?=.*\d).{8,}$/;
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const verticalAnimation = {
  animate: {
    y: 0,
    transition: { duration: 0.5, type: 'spring' },
  },
  exit: {
    y: -500,
    transition: { duration: 0.5, type: 'spring' },
  },
};

export const toastOptions: DefaultToastOptions = {
  error: {
    duration: 10000,
    style: { backgroundColor: '#EE4B46', color: 'white' },
    iconTheme: {
      primary: 'white',
      secondary: '#EE4B46',
    },
  },
  success: {
    duration: 4000,
    style: { backgroundColor: '#5cb85c', color: 'white' },
    iconTheme: {
      primary: 'white',
      secondary: '#5cb85c',
    },
  },
};
