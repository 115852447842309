import { PackageRequest } from '@core/types';
import { Box, Popover, Typography } from '@mui/material';

interface PopoverProps {
  data: any | null;
  anchor: any;
  open: any;
  closePopup: any;
}

const ViewNotesPopover = ({ data, anchor, open, closePopup }: PopoverProps) => {
  return (
    <Popover
      key={data?.id}
      open={open}
      anchorEl={anchor}
      onClose={closePopup}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        backdropFilter: 'none',
        minWidth: 500,
        maxWidth: 1150,
      }}
      // .MuiPopover-paper : add arrow styles
    >
      <PopoverContent key={data?.id} data={data} />
    </Popover>
  );
};

const PopoverContent = ({ data }: { data?: any | null }) => {
  return (
    <Box
      sx={{
        py: 2,
        px: 3,
        minWidth: 500,
        maxWidth: 1150,
      }}
    >
      <h3>Notes</h3>
      <Box>
        {!data?.reason && <p className="text-slate-500">No Notes given for this package</p>}
        {data?.reason && <p className="text-slate-500">{data?.reason}</p>}
      </Box>
    </Box>
  );
};

export default ViewNotesPopover;
