import FlipNumbers from 'react-flip-numbers';
import { Box, Typography } from '@mui/material';
import { toSentenceCase, useAuth } from '@/core/utils/utils';
import { PurchasableProduct } from '@/core/types';
import { useEffect, useState } from 'react';
import ModalBasic from '@/components/AppModal/ModalBasic';

import BuyProductDialog from './BuyProductDialog';
import { useHasCompanyPurchasedProductQuery, useLazyHasCompanyPurchasedProductQuery } from '@/features/state/slices/api/product-slice';
import { useDispatch } from 'react-redux';
import { updateUser } from '@/features/state/slices/local/auth.slice';
import { useLazyGetCompanyQuery } from '@/features/state/slices/api/company-slice';
import { useFetcher } from 'react-router-dom';
type Props = {
  product: PurchasableProduct;
};

export const PurchasableProductItem = (props: Props) => {
  const dispatch = useDispatch();
  const { product } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user } = useAuth();
  const [fetch, { data: companyPurchased, isFetching }] = useLazyHasCompanyPurchasedProductQuery();
  const [fetchCompany, { data: updatedCompanyData, isFetching: comapnyFetching }] = useLazyGetCompanyQuery();
  useEffect(() => {
    fetch({ productId: product.id, companyId: parseInt(user!.id) });
  }, []);
  if (companyPurchased) {
    console.log(`HAS company purchased ${product.name} ${JSON.stringify(companyPurchased)}`);
  }

  useEffect(() => {
    console.log('ccc updated company data USER UPDATED!', user?.watermark);
  }, [user]);

  useEffect(() => {
    if (updatedCompanyData?.data) {
      console.log('ccc updated company data', updatedCompanyData);
      dispatch(updateUser(updatedCompanyData.data!));
    }
  }, [updatedCompanyData?.data]);

  useEffect(() => {
    fetchCompany({ companyId: parseInt(user!.id) });
  }, [companyPurchased]);

  const isWatermark = (p: PurchasableProduct): boolean => {
    return p.id === 1;
  };

  //Account for a case where it is deleted from the backend and refereshed in that case

  /** A purchase may have a side-effect on a company for example the `watermark purchased prop` */
  const onPurchase = async () => {
    console.log('On purchase ran');
    //BUG Timeout to give the webhooks on the backend to synchronize
    //Pretty finicky backend service should have depency on webhook
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch({ productId: product.id, companyId: parseInt(user!.id) });
        fetchCompany({ companyId: parseInt(user!.id) });
        resolve();
      }, 4000);
    });
  };

  return (
    <div className="col-lg-4" style={{ height: 700 }}>
      <div className="card mb-5 " style={{ height: 700 }}>
        <div className="card-body">
          <Box mt={2} mb={2}>
            <h5 className="card-title text-muted text-uppercase text-center ">{product.name}</h5>
          </Box>
          <Box mb={4} textAlign="center">
            <h6
              className="card-price text-center mt-2  	"
              style={{
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FlipNumbers
                play
                width={30}
                height={40}
                color="black"
                background="white"
                numberStyles={{ textAlign: 'center' }}
                numbers={`$ ${product.price.toString()}`}
              />
              <span className="period"> &nbsp;</span>
              <br></br>
            </h6>
          </Box>
          <ul className="fa-ul">
            <Box mt={1}>
              <Typography variant="caption" fontSize={12}>
                FEATURES
              </Typography>
            </Box>

            <Box mt={1}>{toSentenceCase(product.description)}</Box>
          </ul>
          <div
            className="d-grid"
            style={{
              left: 10,
              right: 10,
              bottom: 30,
              position: 'absolute',
            }}
          >
            {companyPurchased?.data ? (
              <a
                className={`bg-gray-700  text-center font-bold text-white uppercase py-2 px-4 rounded-lg shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                Purchased
              </a>
            ) : isWatermark(product) && user?.canAccessWatermark ? (
              <a
                className={`bg-blue-500 text-center font-bold text-white uppercase py-2 px-4 rounded-lg shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                Watermark Access Granted by Administrator
              </a>
            ) : (
              <a
                onClick={handleOpen}
                className={`bg-blue-500 text-center font-bold text-white uppercase py-2 px-4 rounded-lg shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                Buy
              </a>
            )}
          </div>
        </div>
      </div>
      <ModalBasic
        isOpen={open}
        width="w-full sm:w-5/6 md:w-3/4 lg:w-2/3 xl:w-2/6"
        setIsOpen={handleClose}
        title={`Purchase ${product.name}?`}
        subtitle="You will only have to purchase this once"
      >
        <BuyProductDialog onSuccess={onPurchase} onClose={handleClose} product={product} />
      </ModalBasic>
    </div>
  );
};
