import AppChip from '@/components/AppChip';
import { RequestStatus } from '@/core/enums/enums';
import RejectButton from '../components/RejectButton';
import ApproveButton from '../components/ApproveButton';
import { PackageRequest, TableData } from '@/core/types';
import ViewNotesButton from '../../components/ViewNotesButton';
import PackageViewButton from '../components/PackageDisplayButton';
import { Avatar, Box, Link, Tooltip, Typography } from '@mui/material';
import moment from 'moment';

export const headers: TableData<PackageRequest>[] = [
  {
    key: 'name',
    label: 'Name',
    render: (item) => (
      <Box display="flex" alignItems="center">
        <Avatar alt={item.user.firstName} src={item.user.profilePic} />
        <Tooltip title={`${item.user.firstName} ${item.user.lastName}`} arrow>
          <Typography
            noWrap
            ml={1}
            variant="subtitle1"
            sx={{
              maxWidth: '15rem',
              width: 'fit-content',
              textDecoration: 'none',
            }}
          >
            {item.user.firstName} {item.user.lastName}
          </Typography>
        </Tooltip>
      </Box>
    ),
  },
  {
    key: 'package',
    label: 'Package',
    align: 'left',
    render: (item) => <PackageViewButton request={item} />,
    //  <p>{item.package.packageName}</p>
  },

  {
    key: 'notes',
    label: 'Notes',
    align: 'left',
    render: (item) => <ViewNotesButton request={item} />,
  },
  {
    key: 'status',
    label: 'Status',
    align: 'left',
    render: (item) => {
      switch (item.status) {
        case RequestStatus.APPROVED:
          return <AppChip color="success">{item.requestStatus.name}</AppChip>;
        case RequestStatus.REJECTED:
          return <AppChip color="error">{item.requestStatus.name}</AppChip>;
        case RequestStatus.CANCELLED:
          return <AppChip color="warning">{item.requestStatus.name}</AppChip>;
        case RequestStatus.IDLE:
          return <AppChip color="info">{item.requestStatus.name}</AppChip>;
        default:
          return <AppChip>Unknown</AppChip>;
      }
    },
  },
  {
    key: 'dateRequested',
    label: 'Date Requested',
    align: 'left',
    render: (item) => (
      <Typography variant="subtitle1" noWrap sx={{ width: 'fit-content' }}>
        {moment(item.createdAt).format('LL')}
      </Typography>
    ),
  },
  {
    key: 'actions',
    label: 'Actions',
    align: 'left',
    render: (item, theme, refetch) => (
      <Box>
        {item.status == RequestStatus.IDLE ? (
          <div className="flex space-x-2 items-center">
            <ApproveButton onApprove={refetch} request={item} />
            <RejectButton onReject={refetch} requestId={item.id} />{' '}
          </div>
        ) : (
          <p className="text-left text-slate-400 ml-4">N/A</p>
        )}
      </Box>
    ),
  },
];
