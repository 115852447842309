type Props = {
  width?: number;
  height?: number;
};

export function MasterCard({ height = 24, width = 32 }: Props) {
  return (
    <svg className="shrink-0" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="1.829%" y1="100%" x2="100%" y2="2.925%" id="c1-a">
          <stop stopColor="#475569" offset="0%" />
          <stop stopColor="#1E293B" offset="100%" />
          <stop stopColor="#9FA1FF" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect fill="url(#c1-a)" width="32" height="24" rx="3" />
        <ellipse fill="#E61C24" fillRule="nonzero" cx="12.522" cy="12" rx="5.565" ry="5.647" />
        <ellipse fill="#F99F1B" fillRule="nonzero" cx="19.432" cy="12" rx="5.565" ry="5.647" />
        <path
          d="M15.977 7.578A5.667 5.667 0 0 0 13.867 12c0 1.724.777 3.353 2.11 4.422A5.667 5.667 0 0 0 18.087 12a5.667 5.667 0 0 0-2.11-4.422Z"
          fill="#F26622"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
