import * as yup from 'yup';
import { BaseSubscription, StepProps } from '@core/types';
import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { HelperType } from '@core/enums/enums';
import Center from '@components/Center/Center';
import { fadeInRight } from 'react-animations';
import { HelperText } from '@components/HelperText';
import { yupResolver } from '@hookform/resolvers/yup';
import TransitionWrapper from '@components/TransitionWrapper';
import { SubscriptionPlan, subscriptionTabs } from '@core/constants/constants';
import { Box, Switch, Divider, Typography, FormControlLabel } from '@mui/material';
import { useGetSubscriptionsQuery } from '@features/state/slices/api/subscriptions-slice';
import { SubscriptionDisplay } from '@features/pages/Subscriptions/components/SubscriptionDisplay';
import { useAuth } from '@/core/utils/utils';
import AppTablePagination from '@/components/AppTablePagination';

const schema = yup.object().shape({
  isCustom: yup.boolean().nullable(true),
  priceId: yup.string().required('Pricing Interval is required'),
  subscriptionId: yup.number().required('Subscrition Plan  is required'),
});

export const SubscriptionSelectionStep: React.FC<StepProps> = (props: StepProps) => {
  const { data, steps, activeStep, onCapture, handleBack, handleNext, handleSkip } = props;
  const [query, setQuery] = useState({ page: 1, limit: 10 });
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const auth = useAuth();
  const {
    error,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    data: subscriptionData,
  } = useGetSubscriptionsQuery(
    {
      ...query,
      companyId: auth.user?.id!,
      // companyId: '17',
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [isCustom, setIsCustom] = useState(false);
  const [priceId, setPriceId] = useState<number | any>(null);
  const [pricePlan, setPricePlan] = useState<string | any>(null);
  const [subscriptions, setSubscriptions] = useState<SubscriptionPlan[]>([]);
  const [subscriptionId, setSubscriptionId] = useState<number | any>(null);
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionPlan | null>(null);
  const [completed, setCompleted] = useState(false);

  //FIXME This might be wrong
  function getPriceId(subscription: SubscriptionPlan, plan: keyof SubscriptionPlan) {
    return subscription[plan];
  }

  setValue('priceId', priceId);
  useEffect(() => {
    console.log('QUERY SUB RAN', isLoading, error, isFetching);
    if (!isLoading && !error && !isFetching) {
      console.log('QUERY SUB RAN STATE', subscriptionData?.data?.items);

      setSubscriptions(subscriptionData?.data?.items ?? []);
    }
  }, [isLoading, isError, isSuccess, query, isFetching]);

  useEffect(() => {
    if (data?.selectedSubscription) {
      const priceId = getPriceId(data?.selectedSubscription, data?.selectedSubscription?.pricePlan as keyof SubscriptionPlan);

      setPricePlan(data?.selectedSubscription?.pricePlan);
      setSelectedSubscription(data?.selectedSubscription);
      setValue('subscriptionId', data?.selectedSubscription?.id);
      //FIXME This might be wrong

      setValue('priceId', priceId);
    }

    setIsCustom(data?.subscriptionMetadata?.isCustom ?? false);
  }, [data]);

  useEffect(() => {
    if (completed) {
      setTimeout(() => {
        handleSubmit(captureData)();
      }, 200);

      setCompleted(false);
    }
  }, [completed]);
  const captureData = (data: any) => {
    console.log('capture data fired', selectedSubscription);
    handleNext();
    let capturedState = {
      subscriptionMetadata: { isCustom, ...data },
      selectedSubscription: {
        ...selectedSubscription,
        pricePlan,
      },
    };
    onCapture(capturedState);
  };

  const handleSelection = (subscription: any, pricingPlan: any) => {
    setPricePlan(pricingPlan);
    setSubscriptionId(subscription?.id);
    setPriceId(subscription[pricingPlan]);

    setSelectedSubscription(subscription);

    setValue('subscriptionId', subscription?.id);
    setValue('priceId', subscription[pricingPlan]);
    console.log('Handle selection fired');
    setCompleted(true);
  };

  useEffect(() => {
    console.log('SELCTED SUB', selectedSubscription);
  }, [selectedSubscription]);
  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    setQuery((prev: any) => ({
      ...prev,
      page: pageNumber,
      limit: pageLimit,
    }));
  };
  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5}>
        <Typography variant="subtitle1"></Typography>
        <HelperText title="Choose a subscription" subtitle="Select a pricing interval and choose a corresponsing subscription plan" />

        <SubscriptionDisplay
          onSelect={handleSelection}
          subscriptions={subscriptions}
          selectedSubscription={selectedSubscription}
          selectedTab={selectedSubscription?.pricePlan}
        />
        <AppTablePagination
          totalPages={subscriptionData?.data?.meta.totalPages ?? 1}
          onChange={handlePaginationChanged}
        ></AppTablePagination>

        {/* DEPRECATED Custom-Subscription  */}
        {/* {selectedSubscription && (
          <Center>
            <br></br>
            <br></br>
            <Divider textAlign="center">
              <Typography variant="button" align="center" style={{ fontSize: 11 }} gutterBottom>
                OR
              </Typography>
            </Divider>
            <Typography mt={4} variant="subtitle1" align="center" style={{ fontSize: 11 }} gutterBottom>
              Create a custom subscription using one of the predefined subscriptions as a basis
            </Typography>
            <Typography mt={2} mb={3} gutterBottom align="center" variant="subtitle1" style={{ fontSize: 11, fontWeight: 'bold' }}>
              Selected Subscription: {subscriptionTabs.find((i) => i?.value == pricePlan)?.label} for {selectedSubscription.name}{' '}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  sx={{ m: 1 }}
                  checked={isCustom}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setIsCustom(e.target.checked);
                    setValue('isCustom', e.target.checked);
                  }}
                />
              }
              label={<Typography variant="subtitle1">Do you want to create a custom subscription?</Typography>}
            />
          </Center>
        )} */}

        <br></br>
        {(errors?.subscriptionId?.message || (errors?.priceId?.message as string)) && (
          <HelperText
            type={HelperType.Error}
            title="Please wait"
            subtitle={'Ensure a Pricing Interval and Subscription Plan have been selected before proceeding!'}
          />
        )}
      </Box>

      <Navigator
        steps={steps}
        handleSkip={handleSkip}
        handleBack={handleBack}
        activeStep={activeStep}
        handleNext={handleSubmit(captureData)}
      />
    </TransitionWrapper>
  );
};
