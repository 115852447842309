import React, { useRef, useEffect, useState } from 'react';

import { chartColors } from './ChartJsConfig';
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, CategoryScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import { formatValue } from '@/core/utils/utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, CategoryScale, Tooltip);

function LineChart07({ data, width, height }: any) {
  const [chart, setChart] = useState(null);
  const canvas = useRef(null);

  const darkMode = false;
  const { textColor, gridColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor } = chartColors;

  useEffect(() => {
    const ctx = canvas.current as any;

    const newChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          padding: {
            top: 12,
            bottom: 16,
            left: 20,
            right: 20,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            // border: {
            //   display: false,
            // },
            ticks: {
              maxTicksLimit: 7,
              // callback: (value) => formatValue(value as any),
              color: darkMode ? textColor.dark : textColor.light,
            },
            grid: {
              color: darkMode ? gridColor.dark : gridColor.light,
            },
          },
          x: {
            // border: {
            //   display: false,
            // },
            grid: {
              display: false,
            },
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
              color: darkMode ? textColor.dark : textColor.light,
              align: 'end',
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              // title: () => false, // Disable tooltip title
              //label: (context) => formatValue(context.parsed.y),
            },
            bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
            backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
            borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
      },
    });
    setChart(newChart as any);
    return () => newChart.destroy();
  }, []);

  return <canvas ref={canvas} width={width} height={height}></canvas>;
}

export default LineChart07;
