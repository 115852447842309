import { useState } from 'react';
import { headers } from './TableData';
import { Helmet } from 'react-helmet-async';
import AppTable from '@/components/AppTable';
import { useAuth } from '@/core/utils/utils';
import { SearchBar } from '@/components/SearchBar';
import AddUserButton from './components/AddUserButton';
import { RefreshButton } from '@/components/RefreshButton';
import TransitionWrapper from '@/components/TransitionWrapper';
import AppTablePagination from '@/components/AppTablePagination';
import { useGetCompanyAssociatesQuery } from '@/features/state/slices/api/company-slice';

const ManageSalesAssociates = () => {
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const { user } = useAuth();

  const { data, refetch, isError, isFetching } = useGetCompanyAssociatesQuery({
    id: user?.id,
    params: query,
  });

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    setQuery((prev: any) => ({
      ...prev,
      page: pageNumber,
      limit: pageLimit,
    }));
  };

  const handleSearch = (searchTerm: string) => {
    const queryData = {
      searchTerm,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <TransitionWrapper>
        <div>
          <div className="flex justify-between">
            <div>
              <header className="px-1 pt-3 mb-1 border-b border-slate-100 dark:border-slate-700">
                <h4 className="font-semibold text-slate-800 dark:text-slate-100">Sales Associates</h4>
              </header>
            </div>
            <div className="flex justify-end space-x-3 items-center">
              <AddUserButton />
              <SearchBar onChange={handleSearch} />
              <RefreshButton onRefresh={refetch} />
            </div>
          </div>

          <AppTable
            error={isError}
            headers={headers}
            refetch={refetch}
            loading={isFetching}
            data={data?.data?.items}
            total={data?.data?.meta.totalItems ?? 0}
          />
          <AppTablePagination totalPages={data?.data?.meta?.totalPages ?? 1} onChange={handlePaginationChanged}></AppTablePagination>
        </div>
      </TransitionWrapper>
    </>
  );
};

export default ManageSalesAssociates;
