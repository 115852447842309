import { baseQueryWithReauth } from './base-slice';
import { Endpoints } from '@core/constants/endpoint';
import { PaginatedList } from '@core/constants/constants';
import { ActionResult, ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { Company, SalesAssociate, UpdatePhotoResult, getCompanyDTO } from '@core/types';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';

const descriptionTags = ['Company'] as readonly TagDescription<'Company'>[] & string[];

export const companySlice = createApi({
  reducerPath: BaseEndpoints.Company,
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    getCompany: builder.query<ServiceResult<Company, ApiError>, getCompanyDTO>({
      query: (params) => ({ url: `${BaseEndpoints.Company}/${params.companyId}` }),
    }),
    getCompanies: builder.query<ServiceResult<PaginatedList<Company[]>, ApiError>, any>({
      query: (args) => ({
        url: `/${BaseEndpoints.Company}`,
        params: { ...args },
      }),
      providesTags: descriptionTags,
    }),
    getCompanyAssociates: builder.query<ServiceResult<PaginatedList<SalesAssociate[]>, ApiError>, any>({
      query: (data) => ({
        url: `${BaseEndpoints.Company}/${data.id}/users`,
        params: { ...data.params },
      }),
      providesTags: descriptionTags,
    }),
    getAssociate: builder.query({
      query: ({ companyId, userId }) => ({
        url: `${BaseEndpoints.Company}/${companyId}/user/${userId}`,
      }),
      providesTags: descriptionTags,
    }),
    createCompany: builder.mutation({
      query: (payload) => ({
        url: `/${BaseEndpoints.Company}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: descriptionTags,
    }),
    updateCompanyDetails: builder.mutation({
      query: ({ companyId, userId, details }) => ({
        url: `${BaseEndpoints.Company}/${companyId}/user/${userId}`,
        method: 'PUT',
        body: details,
      }),
      invalidatesTags: descriptionTags,
    }),
    updateProfilePhoto: builder.mutation<ServiceResult<UpdatePhotoResult, ApiError>, any>({
      query: (payload) => ({
        url: `/${BaseEndpoints.Company}/photo`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: descriptionTags,
    }),
    inviteSalesAssociate: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({
        url: `/${BaseEndpoints.Company}/invite`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: descriptionTags,
    }),
    suspendSalesAssociate: builder.mutation({
      query: ({ companyId, userId }) => ({
        url: `${BaseEndpoints.Company}/${companyId}/user/${userId}/suspend`,
        method: 'POST',
      }),
      invalidatesTags: descriptionTags,
    }),
    changePassword: builder.mutation({
      query: (body: any) => ({
        body,
        method: 'POST',
        url: `${BaseEndpoints.Company}/chnage-password/`,
      }),
      invalidatesTags: descriptionTags,
    }),
    unSuspendSalesAssociate: builder.mutation({
      query: ({ companyId, userId }) => ({
        url: `${BaseEndpoints.Company}/${companyId}/user/${userId}/unsuspend`,
        method: 'POST',
      }),
      invalidatesTags: descriptionTags,
    }),
    deleteSalesAssociate: builder.mutation({
      query: ({ companyId, userId }) => ({
        url: `${BaseEndpoints.Company}/${companyId}/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: descriptionTags,
    }),

    editCompany: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({
        url: `/${BaseEndpoints.Company}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Company'],
    }),

    updateAssociatePhoto: builder.mutation({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `${BaseEndpoints.SalesAssociate}/update/account` }),
      invalidatesTags: descriptionTags,
    }),
    recoverPassword: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (body) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Company}/forgot-password`,
      }),
    }),
    verifyCode: builder.mutation<ServiceResult<{ token: string }, ApiError>, any>({
      query: (body) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Company}/forgot-password/verify-code`,
      }),
    }),
    resetPassword: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (body) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Company}/forgot-password/reset`,
      }),
    }),
    changeSalesAssociatePassword: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: ({ body, companyId, userId }) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Company}/${companyId}/user/${userId}/change-password`,
      }),
    }),
  }),
});

export const {
  useGetAssociateQuery,
  useGetCompaniesQuery,
  useVerifyCodeMutation,
  useEditCompanyMutation,
  useResetPasswordMutation,
  useCreateCompanyMutation,
  useChangePasswordMutation,
  useRecoverPasswordMutation,
  useGetCompanyAssociatesQuery,
  useUpdateProfilePhotoMutation,
  useUpdateCompanyDetailsMutation,
  useDeleteSalesAssociateMutation,
  useInviteSalesAssociateMutation,
  useUpdateAssociatePhotoMutation,
  useSuspendSalesAssociateMutation,
  useUnSuspendSalesAssociateMutation,
  useChangeSalesAssociatePasswordMutation,
  useLazyGetCompanyQuery,
} = companySlice;
