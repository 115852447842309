import { Card, CardContent, Grid, Skeleton } from "@mui/material";

const ImageCardSkeleton = () => {
  return (
    <>
      <Grid container spacing={3}>
        {[1, 2, 3, 4, 5].map((item: any, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            <Card>
              <Skeleton variant="rectangular" width={"100%"} height={250} />
              <CardContent>
                <Skeleton width={"90%"} />
                <Skeleton width={"70%"} />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ImageCardSkeleton;
