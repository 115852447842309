import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import toast from 'react-hot-toast';
import { Roles } from '@core/enums/enums';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { InputField } from '@/components/AppInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { determineBorderColor } from '@core/utils/utils';
import Visibility from '@mui/icons-material/Visibility';
import PasswordStrengthBar from 'react-password-strength-bar';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { RootState } from '@features/state/reducers/combinedReducers';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { ForgotPasswordResetSchema, SetPasswordSchema } from '@core/constants/schemas';
import { useResetPasswordMutation } from '@features/state/slices/api/company-slice';
import { Box, Card, Grid, Button, InputLabel, Typography, FormControl, FormHelperText } from '@mui/material';
import { ForgotPassword, setForgotPasswordEmail, setForgotPasswordToken } from '@features/state/slices/local/forgot.password.slice';

type Props = {
  onNext: () => void;
};
const ResetPassword: React.FunctionComponent<Props> = ({}) => {
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotPasswordResetSchema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetPasswordMutation] = useResetPasswordMutation();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const { email, token } = useSelector<RootState>((state) => state.forgotPassword) as ForgotPassword;

  const resetPassword = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await resetPasswordMutation({
        email,
        token,
        userRole: Roles.Company,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      }).unwrap();

      if (error && !success) {
        toast.error(error.message!);
        return;
      }

      dispatch(setForgotPasswordEmail(null));
      dispatch(setForgotPasswordToken(null));

      navigate('/auth/login');

      toast.success('Password reset!');
    } catch (err: any) {
      toast.error(String(err));
    } finally {
      dispatch(toggleLoading());
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box mb={5}>
        <Typography variant="h2">Change your Password</Typography>
        <Typography variant="subtitle1" mt={1}>
          Choose a strong password
        </Typography>
      </Box>
      <Box mt={1}>
        <FormControl variant="standard" style={{ width: '100%' }}>
          <div className="relative">
            <InputField
              required
              placeholder="Enter your new password"
              style={determineBorderColor(errors?.newPassword?.message as any)}
              onChange={(e) => setValue('newPassword', e.target.value)}
              type={showNewPassword ? 'text' : 'password'}
            />
            <button
              type="button"
              className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={toggleNewPassword}
            >
              {showNewPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
            </button>
          </div>

          <PasswordStrengthBar style={{ marginTop: 3 }} password={watch<string>('newPassword')} />

          <FormHelperText style={{ color: 'red' }}>{errors?.newPassword?.message as string}</FormHelperText>
        </FormControl>
      </Box>

      <Box mt={1}>
        <FormControl variant="standard" style={{ width: '100%' }}>
          <div className="relative">
            <InputField
              required
              placeholder="Confirm your new password"
              onChange={(e) => setValue('confirmPassword', e.target.value)}
              style={determineBorderColor(errors?.confirmPassword?.message as any)}
              type={showConfirmPassword ? 'text' : 'password'}
            />

            <button
              type="button"
              className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={toggleConfirmPassword}
            >
              {showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
            </button>
          </div>

          <PasswordStrengthBar style={{ marginTop: 3 }} password={watch<string>('confirmPassword')} />
          <FormHelperText style={{ color: 'red' }}>{errors?.confirmPassword?.message as string}</FormHelperText>
        </FormControl>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <div />

        <Box mt={1}>
          <Button
            type="submit"
            variant="contained"
            className="login__button"
            style={{ float: 'right', borderRadius: 4 }}
            onClick={handleSubmit(resetPassword)}
          >
            Change Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default ResetPassword;
