import {
  Box,
  Radio,
  Slide,
  Dialog,
  Button,
  useTheme,
  RadioGroup,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  FormControlLabel,
  DialogContentText,
} from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { StepProps } from '@core/types';
import { Navigator } from '../Navigator';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import AppChip from '@components/AppChip';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Center from '@components/Center/Center';
import { fadeInRight } from 'react-animations';
import { HelperType } from '@core/enums/enums';
import { HelperText } from '@components/HelperText';
import VisaCard from '@assets/images/visa-card.jpg';
import PaymentMethodsForm from './PaymentMethodForm';
import { yupResolver } from '@hookform/resolvers/yup';
import MasterCard from '@assets/images/master-card.jpg';
import TransitionWrapper from '@components/TransitionWrapper';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useGetPaymentMethodsQuery, useRemovePaymentMethodMutation } from '@features/state/slices/api/payment-methods.slice';
import { useAuth } from '@/core/utils/utils';
import Swal from 'sweetalert2';

const Transition = React.forwardRef(function Transition(props, ref) {
  const SlideComponent = Slide as any;
  return <SlideComponent direction="up" ref={ref} {...props} />;
});

const schema = yup.object().shape({
  paymentMethodId: yup.string().required('Payment Method is required'),
});

export const PaymentInformationForm: React.FC<StepProps> = (props: StepProps) => {
  const theme = useTheme();
  const id = useAuth().user?.id;
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [deleteCardDialog, setDeleteCardDialog] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({} as any);

  const {
    isError,
    isFetching,
    isLoading,
    isSuccess,
    refetch,
    data: methods,
  } = useGetPaymentMethodsQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [deleteCardMutation] = useRemovePaymentMethodMutation();

  useEffect(() => {
    if (isSuccess && !isError && !isLoading) {
      setPaymentMethods(methods.data ?? []);
    }
  }, [isFetching, isError, isSuccess]);

  useEffect(() => {
    setSelectedPayment(props.data.subscriptionMetadata.paymentMethodId);
    setValue('paymentMethodId', props.data.subscriptionMetadata.paymentMethodId);
  }, [props.data]);

  const { data, steps, activeStep, onCapture, handleBack, handleNext, handleSkip } = props;

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const captureData = (capturedState: any) => {
    handleNext();
    onCapture({
      subscriptionMetadata: {
        ...data.subscriptionMetadata,
        ...{ companyId: id, ...capturedState },
      },
    });
  };

  const toggleDialog = () => {
    setPaymentDialog(!paymentDialog);
  };

  const toggleDeleteCardDialog = () => {
    setDeleteCardDialog(!deleteCardDialog);
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>, paymentMethodId: string) => {
    setSelectedPayment(paymentMethodId);
    setValue('paymentMethodId', paymentMethodId);
  };

  const deleteCard = async () => {
    toggleDeleteCardDialog();
    dispatch(toggleLoading());
    try {
      const result = await deleteCardMutation({
        companyId: id,
        cardId: selectedPayment,
      }).unwrap();

      if (result.success) {
        toast.success('Card deleted');
        refetch();
      } else {
        toast.error(result.error?.message!);
      }
    } catch (err) {
      console.error('Unable to delete your video');
    }
    dispatch(toggleLoading());
  };
  useEffect(() => {
    if (id == undefined) {
      Swal.fire({
        icon: 'error',
        title: `Your company id could not be found`,
        text: 'Please login again as some things might malfunction',
      }).then((result) => {
        // window.location.replace('/app/subscriptions/create');
      });
    }
  }, []);
  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5} width={'100%'}>
        {paymentMethods.length > 0 && (
          <Box mb={3}>
            <Button onClick={toggleDialog} variant="contained" startIcon={<AddIcon fontSize="small" />}>
              Add Card
            </Button>
            <br></br>
          </Box>
        )}

        <HelperText title="Manage payment methods" subtitle="Select an existing payment method or add a new one" />

        <Box display="flex" justifyContent="space-between"></Box>

        <Box>
          {!isFetching && paymentMethods?.length == 0 ? (
            <Center>
              <br></br>
              <Center>
                <Typography variant="subtitle1" mt={1} mb={1}>
                  This company has no available payment methods
                </Typography>
                <Button onClick={toggleDialog} variant="contained" startIcon={<AddIcon fontSize="small" />}>
                  Add Card
                </Button>
              </Center>
            </Center>
          ) : (
            !isLoading && (
              <RadioGroup value={selectedPayment} onChange={handleSelect}>
                <br></br>
                {paymentMethods?.map((data: any, index: number) => {
                  return (
                    <FormControlLabel
                      value={data.id}
                      control={<Radio checked={data.id === selectedPayment} />}
                      label={
                        <Box mt={1} width="100%" height="100%" display="flex" alignItems="center">
                          <img
                            crossOrigin="anonymous"
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: 5,
                              objectFit: 'contain',
                            }}
                            src={data?.card?.brand === 'visa' ? VisaCard : MasterCard}
                          />

                          <Typography ml={2} fontWeight={'bold'} variant="subtitle1">
                            ending in **** <AppChip>{data?.card?.last4}</AppChip>
                          </Typography>
                          <IconButton
                            sx={{
                              ml: 2,
                              '&:hover': {
                                background: theme.colors.error.lighter,
                              },
                              color: theme.palette.error.main,
                            }}
                            size="small"
                            color="inherit"
                            onClick={() => {
                              toggleDeleteCardDialog();
                              setSelectedPayment(data.id);
                            }}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      }
                    />
                  );
                })}
              </RadioGroup>
            )
          )}

          {isLoading && (
            <Center>
              <CircularProgress />
            </Center>
          )}
        </Box>
        <br></br>
        {errors?.paymentMethodId?.message && (
          <HelperText type={HelperType.Error} title="Validation Error" subtitle={errors?.paymentMethodId?.message as string} />
        )}
        <Navigator
          steps={steps}
          handleSkip={handleSkip}
          handleBack={handleBack}
          activeStep={activeStep}
          handleNext={handleSubmit(captureData)}
        />
      </Box>

      <Dialog
        open={paymentDialog}
        onClose={toggleDialog}
        maxWidth="xl"
        TransitionComponent={Transition as any}
        PaperProps={{
          sx: {
            width: {
              xs: '90%', // 90% of screen width for extra small devices (mobile)
              sm: '70%', // 70% of screen width for small devices (tablets)
              md: '50%', // 50% of screen width for medium devices (desktops)
              lg: '40%', // 40% of screen width for large screens
              xl: '30%', // 30% of screen width for extra large screens
            },
            height: {
              xs: '80vh', // 80% of viewport height for small devices
              sm: '80vh', // Adjusted for small screens
              md: '800px', // Fixed height for medium and larger devices
            },
            maxWidth: '100%', // Ensures it doesn't overflow the screen width
            maxHeight: '100vh', // Ensure it doesn't overflow the viewport height
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{''}</DialogTitle>
        <DialogContent>
          <PaymentMethodsForm close={toggleDialog} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Dialog open={deleteCardDialog} onClose={toggleDeleteCardDialog} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{'Delete Payment Mthod'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Are you sure you want to delete this card?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDeleteCardDialog}>No</Button>
          <Button onClick={deleteCard}>Yes</Button>
        </DialogActions>
      </Dialog>
    </TransitionWrapper>
  );
};
