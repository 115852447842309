import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { Badge, Box, BoxProps, Chip, Dialog, emphasize, lighten, ListItemButton, ListItemIcon, OutlinedInput, Theme } from '@mui/material';
import { motion } from 'framer-motion';
//import PhoneInput, { Value } from "react-phone-number-input";
import { StyledComponent } from '@emotion/styled';
import { MUIStyledCommonProps } from '@mui/system/createStyled';
import { BorderBottomOutlined } from '@ant-design/icons';
// export const PhoneNumberInput = styled(PhoneInput)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3),
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position: "relative",
//     backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
//     border: "1px solid #ced4da",
//     fontSize: 16,
//     width: "100%",
//     padding: "10px 12px",
//     transition: theme.transitions.create([
//       "border-color",
//       "background-color",
//       "box-shadow",
//     ]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     "&:focus": {
//       boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//       borderColor: theme.palette.primary.main,
//     },
//   },
// }));

export const AppInput = styled(InputBase)(({ theme, placeholderItalic }: any) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const HeaderWrapper = styled(motion.div)<{}>(
  ({ theme }) => `
          height: ${theme.header.height};
          color: ${theme.header.textColor};
          padding: ${theme.spacing(0, 2)};
          right: 0;
          z-index: 6;
          background-color: ${alpha(theme.header.background!, 0.95)};
          backdrop-filter: blur(3px);
          position: fixed;
          justify-content: space-between;
          width: 100%;
          z-index: 7;
          display:flex;
          align-items:center;
          box-shadow: 0px 2px 8px -3px ${alpha(theme.colors.alpha.black[100], 0.2)}, 0px 5px 1px -4px ${alpha(
    theme.colors.alpha.black[100],
    0.1,
  )};
          @media (min-width: ${theme.breakpoints.values.lg}px) {
              width: auto;
          }
  `,
);

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  marginTop: 3,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  '&.active': {
    fontWeight: 'bold',
    color: theme.sidebar.activeText,
    backgroundColor: theme.sidebar.activeBackground,
  },
})) as any;

export const StyledBreadCrumbs = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    // backgroundColor,
    height: theme.spacing(3),
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    backgroundColor: emphasize(theme.palette.grey[100], 0.06),
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(theme.palette.grey[300], 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  };
});

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  marginRight: 5,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.7, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export const NavContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',

  justifyContent: 'center',
}));

export const ProfileBox = styled(Box)(({ theme }) => ({
  width: 'auto',
  height: 'auto',
  padding: 20,
}));

export const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);

export const MotionBox = styled(motion.div)``;

export const StyledIcon = styled('span')(({ theme }) => ({
  width: 45,
  height: 45,
  display: 'flex',
  borderRadius: '10%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  backgroundColor: '#fcfcfb',
  height: 37,
  borderRadius: 0.4,
  border: 0,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 350,
    boxShadow: `0 0 0 1px ${alpha(theme.palette.info.main, 1)}`,
  },
  '& fieldset': {
    // borderWidth: `1px !important`,
    // borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
  '& .MuiInputBase-input': {
    boxShadow: 'none',
  },
}));

interface AnalyticsHeaderBoxProps extends BoxProps {
  active?: boolean;
}

export const AnalyticsHeaderBox = styled(Box)<AnalyticsHeaderBoxProps>(({ theme, active }) => ({
  padding: 10,
  width: '100%',
  cursor: 'pointer',
  position: 'relative',
  backgroundColor: active ? 'white' : '#f8f8f8',
  border: '.5px solid #E7E7E7',
  '&:hover, &:focus': {
    backgroundColor: 'white',
  },
}));

export const AnimatedIndicator = styled(motion.div)(({ theme }) => ({
  top: 0,
  left: 0,
  height: 5,
  width: '100%',
  position: 'absolute',
  backgroundColor: theme.colors.primary.light,
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

interface MyInputProps extends InputBaseProps {
  placeholderItalic?: boolean;
}

export const CustomVideoAppInput = styled(InputBase)(({ theme, placeholderItalic }: any) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '::placeholder': {
    fontStyle: placeholderItalic ? 'italic' : 'normal',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
})) as StyledComponent<InputBaseProps & MUIStyledCommonProps<Theme> & MyInputProps, {}, {}>;

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    borderRadius: 3,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
