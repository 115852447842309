import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { PackageRequest } from '@core/types';
import { AppDialog } from '@/components/AppDialog';
import { useStripe } from '@stripe/react-stripe-js';
import RequestService from '@core/services/RequestService';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { PackageSubscriptionDetails } from '@/components/PackageSubscriptionDetails';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import CheckIcon from '@mui/icons-material/Check';
import ModalBasic from '@/components/AppModal/ModalBasic';
type Props = {
  onApprove: () => void;
  request: PackageRequest;
};

const ApproveButton: React.FC<Props> = ({ onApprove, request: { packageId, id } }) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const toggleSubscriptionDialog = () => setOpen(!open);

  useEffect(() => {}, []);

  const approveRequest = async () => {
    dispatch(toggleLoading());

    try {
      const result = await RequestService.approvePackageRequest({
        requestId: id,
      });

      if (result.success && !result?.data?.actionRequired) {
        onApprove();
        toast.success('Package request successfully approved');
      }

      if (result?.data?.actionRequired && result?.data?.action == 'REQUIRES_SUBSCRIPTION') {
        toggleSubscriptionDialog();
      }
    } catch (e: any) {
      toast.error('An error occured approving package');
    }
    dispatch(toggleLoading());
  };
  return (
    <Tooltip title={'Approve Request'} arrow>
      <Box component={'span'}>
        <IconButton size="small" color="info" onClick={approveRequest}>
          {/* <FileDownloadDoneIcon /> */}
          <svg className="w-6 h-6 text-blue-400" viewBox="0 0 32 32">
            <path
              className="fill-current text-blue-400"
              d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
              id="XMLID_254_"
            />
            <path
              className="fill-current text-blue-400"
              d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
              id="XMLID_256_"
            />
          </svg>
        </IconButton>
        <ModalBasic
          width="w-1/2"
          isOpen={open}
          title="Subscribe to this package"
          setIsOpen={toggleSubscriptionDialog}
          subtitle="Please make the payment to start utlizing this package as soon as possible."
        >
          <PackageSubscriptionDetails onApprove={onApprove} requestId={id} packageId={packageId} />
        </ModalBasic>
      </Box>
    </Tooltip>
  );
};

export default ApproveButton;
