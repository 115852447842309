import { useEffect, useRef, useState } from 'react';

import { Menu as MuiMenu } from '@mui/material';
import { Menu, Transition } from '@headlessui/react';
import { DropdownOption } from '@/core/types';
import { SearchBar } from '../SearchBar';

type Props = {
  error: boolean | null;
  options: DropdownOption[];
  selected?: DropdownOption | null;
  onSearch?: (searchTerm: string) => void;
  onSelect?: (selected: DropdownOption) => void;
};

export default function DropdownFull({ options, onSearch, selected, onSelect, error }: Props) {
  const [selectedItem, setSelected] = useState<any>(2);
  const [displayText, setDisplayText] = useState('');
  const [searchTermInput, setSearchTermInput] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const filteredOptions = options?.filter((option) => option.value.toLowerCase().includes(searchTermInput.toLowerCase()));
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);
  // const bottomRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (selected && selected.id) {
      const selectedValue = options?.find((o) => o.id == selected.id)!;
      const index = options.findIndex((v) => v === selectedValue);
      setSelected(index);
      setDisplayText(selectedValue?.value);
    }
  }, [options, selected]);

  const handleSearch = (searchTerm: string) => {
    setSearchTermInput(searchTerm);
  };

  const handleSelect = (option: DropdownOption, index: number) => {
    if (onSelect) {
      onSelect(option);
    }

    setSelected(index);
    setDisplayText(option.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    // console.log('selected item gonna scroll', itemRefs.current[selectedItem], selectedItem);
    itemRefs.current[selectedItem]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const scrollToSelectedItem = () => {
    // bottomRef.current?.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'nearest',
    // });

    itemRefs.current[selectedItem]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };
  useEffect(() => {
    if (open) {
      // console.log('Should be scrolling', bottomRef.current);
      setTimeout(() => {
        scrollToSelectedItem();
      }, 1);
    }
  }, [open]);
  return (
    <Menu as="div" className="relative inline-flex w-full">
      {({ open }) => {
        setOpen(open);
        return (
          <>
            <Menu.Button
              style={{ backgroundColor: '#FBFBFB', borderRadius: 4 }}
              onClick={handleClick}
              className={`form-input w-full text-sm text-slate-800 dark:text-slate-100 leading-5 py-2 px-3 border-slate-200 hover:border-slate-300 focus:border-slate-300 dark:border-slate-700 dark:hover:border-slate-600 dark:focus:border-slate-600 shadow-sm ${
                error ? 'border-red-500' : ''
              }`}
              aria-label="Select option"
            >
              <span className="flex justify-between items-center">
                <span className="text-slate-500">{displayText}</span>
                <svg className="shrink-0 ml-1 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
                  <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
                </svg>
              </span>
            </Menu.Button>
            <Transition
              className="z-50 max-h-60 overflow-y-auto absolute top-full left-0 w-full overflow-hidden mt-1"
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 -translate-y-2"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <MuiMenu
                open={open}
                id="basic-menu"
                anchorEl={anchorEl}
                style={{ width: 20000 }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Menu.Items className="z-50 font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700 focus:outline-none">
                  <div className="px-3 py-3 sticky top-2">
                    <SearchBar value={searchTermInput} onChange={handleSearch} />
                  </div>
                  {/* <button onClick={scrollToSelectedItem}> Click ME </button> */}
                  {filteredOptions?.map((option, optionIndex) => (
                    <Menu.Item key={optionIndex}>
                      {({ active }) => (
                        <button
                          ref={(el) => {
                            if (el) {
                              itemRefs.current[optionIndex] = el;
                            }
                          }}
                          className={`flex items-center justify-between w-full py-2 px-3 cursor-pointer ${
                            active ? 'bg-slate-50 dark:bg-slate-700/20' : ''
                          } ${optionIndex === selectedItem && 'text-indigo-500'}`}
                          onClick={() => handleSelect(option, optionIndex)}
                        >
                          <span>{option.value}</span>
                          <svg
                            className={`shrink-0 mr-2 fill-current text-indigo-500 ${optionIndex !== selectedItem && 'invisible'}`}
                            width="12"
                            height="9"
                            viewBox="0 0 12 9"
                          >
                            <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                          </svg>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                  {/* <div
                    ref={(el) => {
                      if (el) {
                        bottomRef.current = el;
                      }
                    }}
                    className="list-bottom"
                  >
                    {'Here'}
                  </div> */}
                </Menu.Items>
              </MuiMenu>
            </Transition>
          </>
        );
      }}
    </Menu>
  );
}
