import moment from 'moment';
import { Box } from '@mui/material';
import { TableData } from '@/core/types';
import AppChip from '@/components/AppChip';
import { PendingInviteStatus } from '@/core/enums/enums';
import CancelPendingUserButton from '../components/CancelPendingUserButton';
import DeletePendingUserButton from '../components/DeletePendingUserButton';

export const headers: TableData<any>[] = [
  { key: 'id', label: 'Id', align: 'left' },
  { key: 'email', label: 'Email', align: 'left' },
  {
    key: 'status',
    label: 'Status',
    align: 'left',
    render: (item) => {
      switch (item.statusId) {
        case PendingInviteStatus.ACCEPTED:
          return <AppChip color="success">{item.status.name}</AppChip>;
        case PendingInviteStatus.CANCELLED:
          return <AppChip color="error">{item.status.name}</AppChip>;
        case PendingInviteStatus.PENDING:
          return <AppChip color="info">{item.status.name}</AppChip>;
        default:
          return <AppChip>Unknown</AppChip>;
      }
    },
  },
  {
    key: 'createdAt',
    label: 'Date Invited',
    align: 'left',
    render: (item) => <p>{moment(item.createdAt).format('LL')}</p>,
  },
  {
    key: 'actions',
    label: 'Actions',
    align: 'left',
    render: (item, theme, refetch) => (
      <Box display="flex">
        {item.statusId == PendingInviteStatus.PENDING && <CancelPendingUserButton refetch={refetch} email={item.email} />}
        <DeletePendingUserButton refetch={refetch} id={item.id} />
      </Box>
    ),
  },
];
