import {
  useGetCompanyBaseSubcriptionsQuery,
  useLazyGetCompanyBaseSubcriptionsQuery,
} from '@/features/state/slices/api/subscriptions-slice';

import { useAuth } from './utils';
import { useEffect, useState } from 'react';
///import FadeInOut from '@/components/FadeTransition';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SubscriptionStatus } from '../enums/enums';
import { useLazyGetCompanyQuery } from '@/features/state/slices/api/company-slice';
import { useDispatch } from 'react-redux';
import { updateUser } from '@/features/state/slices/local/auth.slice';

type Props = {
  handleBaseRedirect?: boolean;
  children?: React.ReactNode;
};

export const ProtectedRoute = ({ children, handleBaseRedirect }: Props): JSX.Element => {
  const [query, setQuery] = useState<any>({
    page: 1,
    limit: 100, //TODO Put DUMP CODE HERE
    searchTerm: '',
    dump: true,
  });

  const [trigger, { data, isError, isSuccess, isFetching }] = useLazyGetCompanyBaseSubcriptionsQuery();
  const [triggerGetCompany, { data: cData, isError: cisError, isSuccess: cisSuccess, isFetching: cisFetching }] = useLazyGetCompanyQuery();
  console.log('Protected Route rendered');
  const auth = useAuth();
  const location = useLocation();
  const chooseSubscriptionLocation = '/app/subscriptions/create';
  console.log('Location Here ', location.pathname);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('fecthed passive');
    if (auth && auth.token) {
      console.log(`AUTH CHANGED Subscription ${auth.subscriptionStatus}`);
      trigger({ ...query, companyId: auth.user?.id });
    }
  }, [auth]);
  // useEffect(() => {
  //   if (auth && auth.token && auth.user) {
  //     const c = await triggerGetCompany({ companyId: parseInt(auth.user?.id) }).unwrap();

  //     console.log(`Fetched Company refresh`, c);
  //   }
  // }, []);

  useEffect(() => {
    const fetchCompany = async () => {
      if (auth && auth.token && auth.user) {
        try {
          const c = await triggerGetCompany({ companyId: parseInt(auth.user?.id) }).unwrap();
          console.log(`Fetched Company refresh`, c);
          if (c.data) {
            dispatch(updateUser(c.data));
          }
        } catch (error) {
          console.error('Error fetching company:', error);
        }
      }
    };

    fetchCompany(); // Call the async function
  }, []);
  // Have a lazy query that is ran only when auth is not null
  if (auth && auth.token) {
    if (handleBaseRedirect) {
      if (!auth?.user?.hasInitialPassword) {
        return <Navigate to={'/auth/set-password'} replace state={{ from: location }} />;
      }
      return <Navigate to={'/app/dashboard'} replace state={{ from: location }} />;
    }

    if (!auth?.user?.hasInitialPassword) {
      return <Navigate to={'/auth/set-password'} replace state={{ from: location }} />;
    }
    // const x = [
    //   {
    //     id: 2, // Replace with actual ID
    //     isActive: true,
    //     stripeSubscriptionId: 'sub_1N99ZzTGvVEuJGAwxKlkOPL', // Replace with actual ID
    //     subscriptionAmount: 499, // Replace with actual amount
    //     subscriptionName: 'Advanced Video',
    //     trialPeriod: 1703446000, // Example timestamp (adjust as needed)
    //     isTrial: false,
    //     baseSubscriptionId: 2, // Replace with actual ID (should match baseSubscription.id if provided)
    //     companyId: 5, // Replace with actual company ID
    //     status: 2, // Replace with meaningful status value (e.g., "pending")
    //     subscriptionStatus: {
    //       id: 3, // Replace with actual ID
    //       name: 'Pending',
    //       description: 'Payment is being processed',
    //     },
    //     paymentMethod: 'card_1MjVBNTGvVEuJGAwxH2jIJUY', // Replace with actual ID
    //     isCustom: true,
    //     videoPackageDiscount: '10',
    //     usersCovered: '5',
    //     setupCost: '0',
    //     billingMonthlyThreshold: '10.00',
    //     upFrontBillingCost: '0.00', // Can be 0.00 for certain plans
    //     additionalUserCost: '9.99',
    //     pricePlan: 'sixMonthContractPriceId',
    //     nextBillDate: '2023-11-28T08:00:00.000Z',
    //     billingCount: 0,
    //     createdAt: '2023-05-24T12:15:30.000Z',
    //     updatedAt: '2023-05-24T 12:15:30.000Z',
    //   },
    // ];
    // Right here check the subsciption
    //FIXME Take this out. Our test company id ==  12
    if (data?.data?.items != undefined) {
      console.log(`Subscription checked !! ${auth.user.id} ${auth.user.id.toString() === '12'}`);
      console.log('SUB STATUS', data!.data!.items);
      const noSubscription: boolean = data!.data!.items.length == 0 && !(auth.subscriptionStatus === 'Subscribed');
      if (location.pathname.toString() !== chooseSubscriptionLocation) {
        if (noSubscription) {
          setTimeout(() => {
            Swal.fire({
              icon: 'info',
              title: `Hi ${auth.user!.name} 👋`,
              text: 'Please choose a subscription plan that best fits your needs!',
            }).then((result) => {
              navigate('subscriptions/create', { replace: true });
            });
          }, 1000);
        } else {
          console.log('META ', data.data.meta);
          const isSuscribed =
            data!.data!.items.find((subscription) => {
              console.log('SUB STATUS', subscription.status);
              return subscription.status == SubscriptionStatus.ACTIVE || subscription.status == SubscriptionStatus.CancelAutoRenewal;
            }) !== undefined;
          if (!isSuscribed) {
            setTimeout(() => {
              Swal.fire({
                icon: 'info',
                title: `Hi ${auth.user!.name} 👋`,
                text: "Your subscription has either expired or wasn't completed successfully. Please renew.",
              }).then((result) => {
                // window.location.replace('subscriptions/create');
                navigate('subscriptions/create', { replace: true });
              });
            }, 1000);
          }
        }
      }
    }

    return <div> {children as any} </div>;
  } else {
    console.log('No token !!!', auth, auth?.token);
    return <Navigate to={'/unauthorized'} replace state={{ from: location }} />;
  }
};

ProtectedRoute.defaultProps = {
  handleBaseRedirect: false,
};
