import Header from './Header';
import { Outlet } from 'react-router';
import { styled } from '@mui/styles';
import { FC, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MotionBox } from '@/components/Styled';
import AppBreadcrumbs from '@/components/Breadcrumbs';
import useResponsive from '@core/hooks/useResponsive';
import { Box, alpha, lighten, useTheme } from '@mui/material';
import Sidebar from '@/core/layouts/Dashboard/Sidebar';
import { RootState } from '@features/state/reducers/combinedReducers';
import { CLOSE_SIDEBAR, toggleSidebar } from '@/features/state/slices/local/layout.slice';

const LayoutWrapper = styled(Box)(({ theme }: any) => {
  return {
    flex: 1,
    height: '100%',

    '.MuiPageTitle-wrapper': {
      background: theme.palette.mode === 'dark' ? theme.colors.alpha.trueWhite[5] : theme.colors.alpha.white[50],
      marginBottom: `${theme.spacing(4)}`,
      boxShadow:
        theme.palette.mode === 'dark'
          ? `0 1px 0 ${alpha(
              lighten(theme.colors.primary.main, 0.7),
              0.15,
            )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
          : `0px 2px 4px -3px ${alpha(theme.colors.alpha.black[100], 0.1)}, 0px 5px 12px -4px ${alpha(
              theme.colors.alpha.black[100],
              0.05,
            )}`,
    },
  };
});

const ContentWrapper = styled(MotionBox)(({ theme }) => ({
  flex: 1,
  zIndex: 5,
  display: 'block',
  position: 'relative',
  paddingTop: `${theme.header.height}`,
  [theme.breakpoints.up('lg')]: {},
}));

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = ({ children }: SidebarLayoutProps) => {
  const isDesktop = useResponsive('up', 'lg', 'sm');
  const layout = useSelector((state: RootState) => state.layout);

  return (
    <LayoutWrapper>
      <Header />
      <div className={`${!isDesktop && 'hidden'}`}>
        <Sidebar layout={layout} />
      </div>

      <ContentWrapper animate={{ marginLeft: isDesktop ? layout.layoutValue : 0 }}>
        <Box p={5}>
          <Outlet />
        </Box>
      </ContentWrapper>
    </LayoutWrapper>
  );
};

export default SidebarLayout;
