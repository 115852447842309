import { useParams } from 'react-router';
import { Retry } from '@/components/Retry';
import { useAuth } from '@core/utils/utils';
import { SalesAssociate } from '@/core/types';
import Center from '@/components/Center/Center';
import { CircularProgress } from '@mui/material';
import React, { createContext, useEffect, useState } from 'react';
import { useGetAssociateQuery } from '@/features/state/slices/api/company-slice';

export type SalesAssociateContextType = {
  salesAssociate: SalesAssociate | null;
  refetch: () => void;
};

export const SalesAssociateContext = createContext<SalesAssociateContextType>({
  salesAssociate: null,
  refetch: () => {},
});

type Props = {
  children: React.ReactNode;
};

const SalesAssociateProvider: React.FC<Props> = ({ children }: Props) => {
  const auth = useAuth();

  const { id: userId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<SalesAssociate | null>(null);

  const { data, isError, isSuccess, isFetching, refetch } = useGetAssociateQuery({
    userId: userId,
    companyId: auth.user?.id,
  });

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setUser(data.data);
      setIsLoading(false);
    }
  }, [isSuccess, isError, isFetching]);

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  if (isError) {
    return (
      <Center>
        <Retry refetch={refetch} />
      </Center>
    );
  }

  return <SalesAssociateContext.Provider value={{ salesAssociate: user, refetch }}>{children}</SalesAssociateContext.Provider>;
};

export default SalesAssociateProvider;
