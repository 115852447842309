import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { CreditRequest } from '@core/types';
import { useAuth } from '@core/utils/utils';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { fetchVideoCredit } from '@features/state/slices/local/video-credit-slice';
import { useApproveVideoCreditRequestMutation } from '@features/state/slices/api/request-slice';
import { RootState } from '@features/state/reducers/combinedReducers';
import DownloadDoneTwoToneIcon from '@mui/icons-material/DownloadDoneTwoTone';
import ConfirmModal from '@/features/shared/DeleteConfirmModal';
import { useState } from 'react';

const ApproveCreditRequestButton = ({ request, onApprove }: { onApprove: () => void; request: CreditRequest }) => {
  const theme = useTheme();
  const auth = useAuth();
  const dispatch = useDispatch<any>();
  const [confirm, setConfirm] = useState(false);
  const [approveCreditRequestMutation] = useApproveVideoCreditRequestMutation();

  const { credit, error } = useSelector((state: RootState) => state.videoCredit);

  const toggleConfirm = () => setConfirm(!confirm);

  const approveCreditRequest = async () => {
    dispatch(toggleLoading());
    const requestId = request.id;

    try {
      if (request.creditRequested > credit) {
        throw new Error('Insufficient credits');
      }
      const result = await approveCreditRequestMutation({
        requestId,
      }).unwrap();
      if (result.success) {
        toast.success('Request approved');
        dispatch(fetchVideoCredit({ companyId: auth.user?.id!, accessToken: auth.token?.accessToken }));
        onApprove();
      } else {
        toast.error(result.error?.message!);
      }
    } catch (err: any) {
      toast.error('Unable to approve request: ' + err);
    }
    dispatch(toggleLoading());
  };
  return (
    <div>
      <Tooltip title={'Approve Request'} arrow>
        <IconButton
          sx={{
            '&:hover': {
              background: theme.colors.primary.lighter,
            },
            p: 0.3,
            borderRadius: '50%',
            color: theme.palette.primary.main,
          }}
          color="inherit"
          size="small"
          onClick={toggleConfirm}
        >
          <DownloadDoneTwoToneIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
      <ConfirmModal
        open={confirm}
        closeModal={toggleConfirm}
        confirmButtonClass="bg-blue-500"
        handleAction={approveCreditRequest}
        title={'Are you sure you want to approve this request?'}
        confirmationText={'This will grant the user the credit amount requested'}
      />
    </div>
  );
};

export default ApproveCreditRequestButton;
