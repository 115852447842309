import { Outlet } from 'react-router';
import SettingsSidebar from './components/SettingsSidebar';
import TransitionWrapper from '@/components/TransitionWrapper';

function ManageSettings() {
  return (
    <TransitionWrapper>
      <div className="flex overflow-hidden">
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main className="grow">
            <div className="w-full mx-auto">
              {/* Page header */}
              <div className="mb-8">
                {/* Title */}
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Account Settings</h1>
              </div>

              {/* Content */}
              <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
                <div className="flex flex-col md:flex-row md:-mr-px">
                  <SettingsSidebar />
                  <Outlet />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </TransitionWrapper>
  );
}

export default ManageSettings;
