import toast from 'react-hot-toast';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAuth } from '@core/utils/utils';
import { useEffect, useState } from 'react';
import FlipNumbers from 'react-flip-numbers';

import { HelperText } from '@/components/HelperText';
import { yupResolver } from '@hookform/resolvers/yup';
import { BuyCreditSchema } from '@core/constants/schemas';

import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useBuyVideoCreditMutation } from '@features/state/slices/api/video-credit-slice';
import { useGetPaymentMethodsQuery } from '@features/state/slices/api/payment-methods.slice';
import { Box, Typography, FormHelperText, RadioGroup, Radio, FormControlLabel } from '@mui/material';

import { HelperType } from '@core/enums/enums';
import { InputField } from '../AppInput';
import AppButton from '../AppButton';
import Routes from '@/features/router/routes';
import { useNavigate } from 'react-router';
import NoPaymentMethod from '../NoPayment';
import { MasterCard } from '../Cards/MasterCard';
import { VisaCard } from '../Cards/Visa';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

const BuyCredits: React.FC<Props> = ({ onSuccess, onClose }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [creditAmount, setCreditAmount] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState({} as any);
  const [buyVideoCreditMutation, response] = useBuyVideoCreditMutation();
  const { data, isFetching, isError, isSuccess, isLoading } = useGetPaymentMethodsQuery(user?.id);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BuyCreditSchema),
  });

  const buyVideoCredits = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const result = await buyVideoCreditMutation({
        userId: user?.id,
        creditAmount: data.creditAmount,
        paymentMethodId: data.paymentMethodId,
        stripeCustomerId: user?.stripeCustomerId,
      }).unwrap();

      if (result.success) {
        toast.success('Video credits were successfully purchased');
        onSuccess();
      } else {
        toast.error('Unable to purchase credits');
      }
    } catch (err) {
      console.error('Unable to purchase credits: ', err);
    }
    dispatch(toggleLoading());
  };

  const handleListItemClick = (event: any, index: any, data: any) => {
    setSelectedIndex(index);
    setSelectedPayment(data);
    setValue('paymentMethodId', data.id);
  };

  useEffect(() => {
    if (!isFetching && !isError && isSuccess && data?.success) {
      setPaymentMethods(data.data);
    }
  }, [isFetching, isError, isSuccess, data?.success]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>, paymentMethodId: string) => {
    setSelectedPayment(paymentMethodId);
    setValue('paymentMethodId', paymentMethodId);
  };

  return (
    <Box p={4}>
      <Typography mt={2} mb={2} variant="h1" textAlign="center" sx={{ fontSize: 60 }}>
        <FlipNumbers
          play
          width={35}
          height={50}
          color="black"
          background="white"
          numberStyles={{ textAlign: 'center' }}
          numbers={formatter.format(creditAmount)}
        />
      </Typography>
      <InputField
        type="number"
        placeholder="Enter credit amount"
        onChange={(e) => {
          setValue('creditAmount', Number(e.target.value));
          setCreditAmount(Number(e.target.value));
        }}
      />
      <FormHelperText style={{ color: 'red' }}>{errors?.creditAmount?.message as string}</FormHelperText>
      {!isFetching && !isLoading && !isError && isSuccess && paymentMethods?.length === 0 ? (
        <NoPaymentMethod
          onNavigate={() => {
            onClose();
            navigate(`/${Routes.App.Settings.Billing}`);
          }}
        />
      ) : (
        <Typography mt={4} variant="subtitle1">
          Use payment method
        </Typography>
      )}
      <RadioGroup value={selectedPayment} onChange={handleSelect}>
        <br></br>
        {paymentMethods?.map((data: any, index: number) => {
          return (
            <FormControlLabel
              value={data.id}
              control={<Radio checked={data.id === selectedPayment} />}
              label={
                <div className="col-span-6 order-1 sm:order-none sm:col-span-3 flex items-center space-x-4 lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-3">
                  {data.card.brand == 'visa' ? <VisaCard /> : <MasterCard />}
                  <div>
                    <div className="text-xs">**7328</div>
                  </div>
                </div>
              }
            />
          );
        })}
      </RadioGroup>
      <br></br>
      {errors.paymentMethodId?.message && (
        <HelperText title="Error" type={HelperType.Error} subtitle={errors.paymentMethodId?.message as string} />
      )}
      <br></br>
      {paymentMethods?.length > 0 && (
        <AppButton onClick={handleSubmit(buyVideoCredits)} className="float-right">
          Purchase
        </AppButton>
      )}{' '}
      <br></br>
    </Box>
  );
};

export default BuyCredits;
