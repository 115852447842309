import React from "react";
import { Shimmer } from "react-shimmer";

type SkeletonProps = { width: number };

const PackageSubscriptionSkeleton: React.FC<SkeletonProps> = ({ width }) => {
  return (
    <React.Fragment>
      <Shimmer className="skeleton" width={width} height={50} />
      <br></br>
      <Shimmer className="skeleton" width={width} height={120} />
      <br></br>
      <Shimmer className="skeleton" width={width} height={500} />
    </React.Fragment>
  );
};
export default PackageSubscriptionSkeleton;
