import moment from 'moment';
import { Fragment } from 'react';
import AppChip from '@/components/AppChip';
import EmailIcon from '@mui/icons-material/Email';
import ViewNotesButton from '../../components/ViewNotesButton';
import { CustomVideoRequest, PackageRequest, RequestStatus, TableData } from '@/core/types';
import { Avatar, Box, IconButton, Link, Tooltip, Typography } from '@mui/material';

export const headers: TableData<CustomVideoRequest>[] = [
  {
    key: 'id',
    label: 'ID',
    align: 'left',
  },
  {
    key: 'name',
    label: 'Name',
    render: (item) => (
      <Box display="flex" alignItems="center">
        <Avatar alt={item?.user?.firstName} src={item?.user?.profilePic} />
        <Tooltip title={`${item?.user?.firstName} ${item?.user?.lastName}`} arrow>
          <Typography
            noWrap
            ml={1}
            variant="subtitle1"
            sx={{
              maxWidth: '15rem',
              width: 'fit-content',
              textDecoration: 'none',
            }}
          >
            {item?.user?.firstName} {item?.user?.lastName}
          </Typography>
        </Tooltip>
      </Box>
    ),
  },
  {
    key: 'requestedOn',
    label: 'Requested On',
    align: 'left',
    render: (item) => (
      <Typography variant="subtitle1" noWrap sx={{ width: 'fit-content' }}>
        {moment(item.createdAt).format('LL')}
      </Typography>
    ),
  },

  {
    key: 'actions',
    label: 'Actions',
    align: 'left',
    render: (item, theme, refetch) => (
      <Link href={`mailto://${item.user?.email}`}>
        <IconButton
          sx={{
            '&:hover': {
              background: theme!.colors.primary.lighter,
            },
            color: theme!.palette.primary.main,
          }}
          color="inherit"
          size="small"
        >
          <EmailIcon />
        </IconButton>
      </Link>
    ),
  },
];
