import { SubscriptionPlan, subscriptionTabs, SIX_MONTH_CONTRACT_KEY, ONE_YEAR_CONTRACT_KEY } from '@core/constants/constants';
import { Box, styled, Tab, Tabs } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { BaseSubscription, PurchasableProduct, RequestableProduct } from '@core/types';
import { PurchasableProductItem } from './PurchasableProductItem';
import { RequestableProductItem } from './RequestableProductItem';

type Props = {
  products: (RequestableProduct | PurchasableProduct)[];
};

export const ProductsDisplay: React.FC<Props> = ({ products }) => {
  console.log(`Susbcription`, products[0]);

  return (
    <React.Fragment>
      <section className="pricing py-5" style={{ borderRadius: 15 }}>
        <div className="container">
          <div className="row">
            {products.map((product) =>
              product.request_needed ? <RequestableProductItem product={product} /> : <PurchasableProductItem product={product} />,
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
