import toast from 'react-hot-toast';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAuth } from '@core/utils/utils';
import { useEffect, useState } from 'react';
import FlipNumbers from 'react-flip-numbers';

import { HelperText } from '@/components/HelperText';
import { yupResolver } from '@hookform/resolvers/yup';
import { BuyCreditSchema, BuyProductSchema } from '@core/constants/schemas';

import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useBuyVideoCreditMutation } from '@features/state/slices/api/video-credit-slice';
import { useGetPaymentMethodsQuery } from '@features/state/slices/api/payment-methods.slice';
import { Box, Typography, FormHelperText, RadioGroup, Radio, FormControlLabel } from '@mui/material';

import { HelperType } from '@core/enums/enums';

import Routes from '@/features/router/routes';
import { useNavigate } from 'react-router';
import { useStripe } from '@stripe/react-stripe-js';
import NoPaymentMethod from '@/components/NoPayment';
import { VisaCard } from '@/components/Cards/Visa';

import AppButton from '@/components/AppButton';
import { MasterCard } from '@/components/Cards/MasterCard';
import { PurchasableProduct } from '@/core/types';
import { useCreateProductPaymentMutation } from '@/features/state/slices/api/product-slice';

type Props = {
  onClose: () => void;
  onSuccess: () => Promise<void>;
  product: PurchasableProduct;
};

//NOTE Payment Methods need a type

const BuyProductDialog: React.FC<Props> = ({ onSuccess, onClose, product }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState({} as any);
  const [buyProductMutation, response] = useCreateProductPaymentMutation();
  const { data, isFetching, isError, isSuccess, isLoading } = useGetPaymentMethodsQuery(user?.id);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BuyProductSchema),
  });

  const buyProduct = async (data: any) => {
    console.log('purchase here here');
    dispatch(toggleLoading());
    try {
      const result = await buyProductMutation({
        stripePriceId: product.price_id.toString(),
        paymentMethodId: data.paymentMethodId,
        stripeCustomerId: user?.stripeCustomerId!, //BUG Figure out why a stripeCustomerId might be empty
        stripeProductId: product.product_id,
      }).unwrap();
      //TODO handle payment intent
      console.log(`RESULT SENT FROM PAYMENT${JSON.stringify(result)}`);
      if (result.success && result.data) {
        if (result.data?.status == 'requires_confirmation') {
          const response = await stripe?.confirmCardPayment(result.data!.client_secret!);
          if (response && !response.error) {
            console.log('RESULT after action', JSON.stringify(response.paymentIntent));
            await onSuccess();
            toast.success(`The product "${product.name}" was successfully purchased`);
          }
        }
      } else {
        toast.error('Unable to purchase this product');
      }
    } catch (err) {
      console.error('Unable to purchase this product: ', err);
    } finally {
      dispatch(toggleLoading());
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  useEffect(() => {
    if (!isFetching && !isError && isSuccess && data?.success) {
      setPaymentMethods(data.data);
    }
  }, [isFetching, isError, isSuccess, data?.success]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>, paymentMethodId: string) => {
    setSelectedPayment(paymentMethodId);
    setValue('paymentMethodId', paymentMethodId);
  };

  return (
    <Box p={4}>
      <Typography mt={2} mb={2} variant="h1" textAlign="center" sx={{ fontSize: 60 }}>
        <FlipNumbers
          play
          width={35}
          height={50}
          color="black"
          background="white"
          numberStyles={{ textAlign: 'center' }}
          numbers={formatter.format(product.price)}
        />
      </Typography>
      <FormHelperText style={{ color: 'red' }}>{errors?.creditAmount?.message as string}</FormHelperText>
      {!isFetching && !isLoading && !isError && isSuccess && paymentMethods?.length === 0 ? (
        <NoPaymentMethod
          onNavigate={() => {
            onClose();
            navigate(`/${Routes.App.Settings.Billing}`);
          }}
        />
      ) : (
        <Typography mt={4} variant="subtitle1">
          Use payment method
        </Typography>
      )}
      <RadioGroup value={selectedPayment} onChange={handleSelect}>
        <br></br>
        {paymentMethods?.map((data: any, index: number) => {
          //   console.log('DATA FROM CARD', data);
          return (
            <FormControlLabel
              value={data.id}
              control={<Radio checked={data.id === selectedPayment} />}
              label={
                <div className="col-span-6 order-1 sm:order-none sm:col-span-3 flex items-center space-x-4 lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-3">
                  {data.card.brand == 'visa' ? <VisaCard /> : <MasterCard />}
                  <div>
                    <div className="text-xs"> {`****${data.card.last4}`}</div>
                  </div>
                </div>
              }
            />
          );
        })}
      </RadioGroup>
      <br></br>
      {errors.paymentMethodId?.message && (
        <HelperText title="Error" type={HelperType.Error} subtitle={errors.paymentMethodId?.message as string} />
      )}
      <br></br>
      {paymentMethods?.length > 0 && (
        <AppButton onClick={handleSubmit(buyProduct)} className="float-right">
          Purchase
        </AppButton>
      )}{' '}
      <br></br>
    </Box>
  );
};

export default BuyProductDialog;
