import moment from 'moment';
import { Retry } from '@/components/Retry';
import { useAuth } from '@/core/utils/utils';
import { VideoType } from '@/core/enums/enums';
import Center from '@/components/Center/Center';
import VideoTrend from './components/VideoTrend';
import Calendar from '../../components/Calendar';
import DatePicker from '@/components/DatePicker';
import { SearchBar } from '@/components/SearchBar';
import { PaginatedList, Query } from '@/core/constants/constants';
import { RefreshButton } from '@/components/RefreshButton';
import LineChart from '../../components/AnalyticsLineChart';
import { AnalyticsCard } from '../../components/AnalyticsCard';
import { DateQuery, PopularVideoMetrics, VideoAnalyticsStatistics } from '@core/types';
import { Fragment, useContext, useEffect, useState } from 'react';
import { AnalyticsVideoItem } from '../../components/AnalyticsVideoItem';
import { AnalyticsHeaderItem } from '../../components/AnalyticsHeaderItem';
import { AnalyticsTokenContext } from '../../providers/AnalyticsTokenProvider';
import { useVerifyVideoAccessQuery } from '@/features/state/slices/api/video-slice';
import { Box, Button, Card, Grid, Paper, Typography, CircularProgress, useMediaQuery, Dialog, List, ListItemButton } from '@mui/material';
import { useGetPopularVideosQuery, useGetVideoStatisticsQuery } from '@features/state/slices/api/video-analytics-slice';
import AppTablePagination from '@/components/AppTablePagination';
import MenuIcon from '@mui/icons-material/Menu';
const VideoAnalytics = () => {
  const [loading, setLoading] = useState(true);
  const [canView, setCanView] = useState(false);
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const { isSuccess, isError, isFetching, data } = useVerifyVideoAccessQuery({ companyId: analyticsAuth?.companyId });

  useEffect(() => {
    if (!isError && isSuccess && !isFetching) {
      setCanView(data.data ?? false);
      setLoading(false);
    }
  }, [isSuccess, isError, isFetching]);

  return (
    <div className={`space-y-10 h-screen ${loading && 'hidden'} `}>
      {/* {canView ? (
        <div className="space-y-10 h-screen ">
          <AnalyticsHeaderDisplay />
          <VideoTrend />
          <AnalyticsPopularVideos />
          <Box height={50} />
        </div>
      ) : (
        <div style={{ marginTop: 250 }} className="h-screen flex flex-col items-center">
          <svg className="w-12 h-12 fill-current" viewBox="0 0 20 24">
            <path className="text-slate-500 dark:text-slate-600" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
            <path className="text-slate-300 dark:text-slate-400" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
            <path className="text-slate-400 dark:text-slate-500" d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z" />
          </svg>
          <p className="text-slate-500 mt-2 text-base text-center">
            You currently dont have access to any videos. Purchase clips with credits or request a package in the mobile app to gain access
            to video content.
          </p>
        </div>
      )} */}
      <div className="space-y-10 h-screen ">
        <AnalyticsHeaderDisplay />
        <VideoTrend />
        <AnalyticsPopularVideos />
        <Box height={50} />
      </div>
    </div>
  );
};

function AnalyticsHeaderDisplay() {
  const [query, setQuery] = useState<any>({
    endDate: null,
    startDate: null,
    videoType: VideoType.VIDEO,
  });

  const [loading, setLoading] = useState(true);
  const [errorData, setError] = useState<any>(null);
  const [dateDisplay, setDateDisplay] = useState('');
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const [analyticsData, setAnalyticsData] = useState<VideoAnalyticsStatistics[] | undefined>();
  const currentDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(currentDate.getDate() - 6);
  const defaultEndDate = currentDate;
  const { isError, isFetching, data, isSuccess, refetch } = useGetVideoStatisticsQuery(
    { ...analyticsAuth, ...{ query } },
    { refetchOnMountOrArgChange: true },
  );

  const totalWatchTime = data?.data?.find((x) => x.key == 'totalWatchCount');
  const [selectedAnalyticMetric, setSelectedAnalyticMetric] = useState<VideoAnalyticsStatistics | null | undefined>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  // Detect mobile screen size
  const isMobile = useMediaQuery('(max-width:600px)');

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const handleSelectMetric = (metric: VideoAnalyticsStatistics) => {
    setSelectedAnalyticMetric(metric);
    closeDialog(); // Close the dialog when a metric is selected
  };
  useEffect(() => {
    if (!isFetching && !isError && isSuccess && data) {
      setAnalyticsData(data.data);

      if (data?.data?.length != null && data?.data?.length > 0) {
        setSelectedAnalyticMetric(data?.data![0]);
      }

      if (data.error) {
        setError(data.error);
      }

      setLoading(false);
    }
  }, [isFetching, isError, isSuccess]);

  const handleDateChange = (dates: string[]) => {
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setDateDisplay(`on ${moment(startDate).format('LL')}`);
        setQuery({ ...query, startDate, endDate: startDate });
      } else {
        setDateDisplay(`between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`);
        setQuery({ ...query, startDate, endDate });
      }
    }
  };
  useEffect(() => {
    handleDateChange([defaultStartDate.toISOString(), defaultEndDate.toISOString()]);
  }, []);
  return (
    <Fragment>
      <Typography mt={2} mb={5} fontSize={28} align="center">
        Your sales associates watched your videos {totalWatchTime?.value} times {dateDisplay}
      </Typography>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box />
        <Box display="flex">
          <DatePicker
            sx={{ ml: 1, mr: 1 }}
            onDateChange={handleDateChange}
            defaultEndDate={defaultEndDate}
            defaultStartDate={defaultStartDate}
          />
          <RefreshButton onRefresh={refetch} />
        </Box>
      </Box>

      <AnalyticsCard>
        <Box mb={8}>
          {analyticsData?.length != null && !loading && !errorData && (
            <Box>
              {/* Show button to open dialog on mobile */}
              {isMobile ? (
                <Box display="flex" justifyContent="center" mb={2} mt={3}>
                  <Button variant="contained" color="primary" onClick={openDialog} startIcon={<MenuIcon />}>
                    Select Metric
                  </Button>
                </Box>
              ) : (
                // Make the analytics header items scrollable for mid-sized screens
                <Box display="flex" overflow="auto" className="scrollbar">
                  {analyticsData!.map((analytic, index) => (
                    <AnalyticsHeaderItem
                      index={index}
                      key={index}
                      currentAnalyticMetric={analytic}
                      labelSuffix={analytic.labelSuffix}
                      onSelect={setSelectedAnalyticMetric}
                      selectedAnalyticMetric={selectedAnalyticMetric}
                    />
                  ))}
                </Box>
              )}

              {/* Show selected metric's header on mobile */}
              {isMobile && selectedAnalyticMetric && (
                <Box mt={3}>
                  <AnalyticsHeaderItem
                    index={analyticsData!.indexOf(selectedAnalyticMetric)}
                    currentAnalyticMetric={selectedAnalyticMetric}
                    labelSuffix={selectedAnalyticMetric?.labelSuffix}
                    onSelect={setSelectedAnalyticMetric}
                    selectedAnalyticMetric={selectedAnalyticMetric}
                  />
                </Box>
              )}

              {/* Show the chart or calendar for the selected metric */}
              <Grid item xs={12} md={6} lg={12}>
                {selectedAnalyticMetric?.key === 'popularWatchDay' ? (
                  <Calendar />
                ) : (
                  <LineChart
                    title={selectedAnalyticMetric?.title}
                    subheader={selectedAnalyticMetric?.subtitle}
                    chartData={[
                      {
                        type: 'area',
                        fill: 'gradient',
                        name: selectedAnalyticMetric?.title,
                        data: selectedAnalyticMetric?.dataPoints.map((x) => x?.values),
                      },
                    ]}
                    chartLabels={selectedAnalyticMetric?.dataPoints.map(() => '')}
                  />
                )}
              </Grid>
            </Box>
          )}
        </Box>

        <Center>{loading && <CircularProgress />}</Center>
        <Center>{errorData && <Retry refetch={refetch} />}</Center>

        {/* Dialog for mobile to select an analytics metric */}
        {isMobile && analyticsData && !loading && !errorData ? (
          <Dialog open={dialogOpen} onClose={closeDialog}>
            <List>
              {analyticsData!.map((analytic, index) => (
                <ListItemButton key={index} onClick={() => handleSelectMetric(analytic)}>
                  <Typography>{analytic.title}</Typography>
                </ListItemButton>
              ))}
            </List>
          </Dialog>
        ) : (
          <></>
        )}
      </AnalyticsCard>
    </Fragment>
  );
}

function AnalyticsPopularVideos() {
  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    endDate: '',
    startDate: '',
    searchTerm: '',
  });

  const [loading, setLoading] = useState(true);
  const [errorData, setError] = useState<any>(null);
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const [popularVideos, setPopularVideos] = useState<PaginatedList<PopularVideoMetrics[] | undefined>>();

  const { data, isFetching, isError, isSuccess, refetch } = useGetPopularVideosQuery(
    { ...analyticsAuth, ...{ query } },
    { refetchOnMountOrArgChange: true },
  );
  const currentDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(currentDate.getDate() - 6);
  const defaultEndDate = currentDate;
  useEffect(() => {
    if (!isFetching && !isError && isSuccess && data) {
      setPopularVideos(data.data);

      if (data.error) {
        setError(data.error);
      }

      setLoading(false);
    }
  }, [isFetching, isError, isSuccess]);

  const handleDateChange = (dates: string[]) => {
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setQuery({
          ...query,
          startDate,
          endDate: startDate,
        });
      } else {
        setQuery({
          ...query,
          startDate,
          endDate,
        });
      }
    }
  };

  // useEffect(() => {
  //   handleDateChange([defaultStartDate.toISOString(), defaultEndDate.toISOString()]);
  // }, []);
  const handleSearch = (searchTerm: string) => {
    const queryData = {
      searchTerm,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    const queryData = {
      page: pageNumber,
      limit: pageLimit,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Box />

        <Box display="flex" alignItems="flex-end">
          <div className="mr-2">
            <SearchBar onChange={handleSearch} />
          </div>

          {/* <DatePicker sx={{ ml: 1 }} onDateChange={handleDateChange} defaultEndDate={defaultEndDate} defaultStartDate={defaultStartDate} /> */}
          <RefreshButton onRefresh={refetch} />
        </Box>
      </Box>

      <Paper sx={{ width: '100%' }} elevation={0}>
        <Card>
          <Box p={4}>
            <Typography fontSize={20}>Cummulative Video Performance </Typography>
            <p className="mt-2 text-slate-500">
              This section on the analytics page that showcases the Performance of your Videos ordered by views within the entire lifetime
              of the video. You can click the video for a more detailed analysis.
            </p>
          </Box>
          <Box p={2} sx={{ backgroundColor: 'white' }}>
            <Box>
              {!loading && !isError && isSuccess && (
                <Box>
                  {popularVideos?.items?.map((popularVideo: any) => (
                    <AnalyticsVideoItem metric={popularVideo} />
                  ))}
                </Box>
              )}
            </Box>
            <AppTablePagination totalPages={popularVideos?.meta.totalPages ?? 1} onChange={handlePaginationChanged} />
          </Box>

          <Center>{loading && <CircularProgress />}</Center>
          <Center>{errorData && <Retry refetch={refetch} />}</Center>
        </Card>
      </Paper>
    </Box>
  );
}

export default VideoAnalytics;
