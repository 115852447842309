import { baseQueryWithReauth } from './base-slice';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { PaginatedList } from '@core/constants/constants';
import { ActionResult, ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { toQueryString } from '@/core/utils/utils';

const descriptionTags = ['PendingInvites'] as readonly TagDescription<'PendingInvites'>[] & string[];

export const pendingInviteSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.PendingInvite,
  endpoints: (builder) => ({
    getPendingInvites: builder.query<ServiceResult<PaginatedList<any[]>, ApiError>, any>({
      query: ({ id, params }) => ({
        url: `${BaseEndpoints.PendingInvite}/${id}?${toQueryString(params)}`,
      }),
      providesTags: descriptionTags,
    }),
    deletePendingInvite: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: ({ id }) => ({
        method: ApiMethods.DELETE,
        url: `${BaseEndpoints.PendingInvite}/${id}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    updatePendingInvite: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (body) => ({
        body,
        method: ApiMethods.PUT,
        url: `${BaseEndpoints.PendingInvite}/status`,
      }),
      invalidatesTags: descriptionTags,
    }),
    getPendingInviteStatus: builder.query<ServiceResult<any[], ApiError>, any>({
      query: () => ({
        method: ApiMethods.GET,
        url: `${BaseEndpoints.PendingInvite}/status`,
      }),
      providesTags: descriptionTags,
    }),
  }),
});

export const { useGetPendingInvitesQuery, useDeletePendingInviteMutation, useUpdatePendingInviteMutation, useGetPendingInviteStatusQuery } =
  pendingInviteSlice;
