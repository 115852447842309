import moment from 'moment';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { Environments } from '@core/enums/enums';
import { Auth } from '@core/constants/constants';
import resolveConfig from 'tailwindcss/resolveConfig';
import config from '../../assets/css/tailwind.config';
import { RootState } from '@features/state/reducers/combinedReducers';

export const tailwindConfig = () => {
  return resolveConfig(config) as any;
};

export const hexToRGB = (h: string): string => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = parseInt(`0x${h[1]}${h[1]}`);
    g = parseInt(`0x${h[2]}${h[2]}`);
    b = parseInt(`0x${h[3]}${h[3]}`);
  } else if (h.length === 7) {
    r = parseInt(`0x${h[1]}${h[2]}`);
    g = parseInt(`0x${h[3]}${h[4]}`);
    b = parseInt(`0x${h[5]}${h[6]}`);
  }
  return `${+r},${+g},${+b}`;
};

export function getGreetingOfDay() {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  let greeting = '';

  if (currentHour < 12) {
    greeting = 'Good morning';
  } else if (currentHour < 18) {
    greeting = 'Good afternoon';
  } else {
    greeting = 'Good evening';
  }

  return greeting;
}

export const formatValue = (value: number): string =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(value);

export const formatThousands = (value: number): string =>
  Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(value);

export const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const vendor = navigator.vendor;

  let deviceName = 'Unknown Device';

  // Check for common iOS devices based on user agent
  if (userAgent.match(/iPhone/i)) {
    deviceName = 'iPhone';
  } else if (userAgent.match(/iPad/i)) {
    deviceName = 'iPad';
  } else if (userAgent.match(/iPod/i)) {
    deviceName = 'iPod Touch';
  } else if (userAgent.match(/Mac/i)) {
    deviceName = 'Mac';
  } else if (userAgent.match(/Windows/i)) {
    deviceName = 'Windows PC';
  } else if (userAgent.match(/Android/i)) {
    deviceName = 'Android Device';
  } else if (userAgent.match(/Linux/i)) {
    deviceName = 'Linux PC';
  }

  let browserName = 'Unknown Browser';

  // Check for popular browsers based on user agent
  if (userAgent.indexOf('Chrome') !== -1) {
    browserName = 'Google Chrome';
  } else if (userAgent.indexOf('Firefox') !== -1) {
    browserName = 'Mozilla Firefox';
  } else if (userAgent.indexOf('Safari') !== -1) {
    browserName = 'Apple Safari';
  } else if (userAgent.indexOf('Edge') !== -1) {
    browserName = 'Microsoft Edge';
  } else if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
    browserName = 'Opera';
  } else if (userAgent.indexOf('Trident') !== -1) {
    browserName = 'Microsoft Internet Explorer';
  }

  return `${deviceName} (${browserName}) on ${platform} - Vendor: ${vendor}`;
};
export function sanitizeJWT<T = unknown>(token: any): T {
  if (typeof token === 'string') {
    return {} as unknown as T;
  }
  const cleanToken = { ...token };
  try {
    delete cleanToken?.iss;
    delete cleanToken?.sub;
    delete cleanToken?.aud;
    delete cleanToken?.exp;
    delete cleanToken?.nbf;
    delete cleanToken?.iat;
    delete cleanToken?.jti;
    return cleanToken as T;
  } catch (err) {
    throw new Error('Error cleaning token...');
  }
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function determineBorderColor(property: string | null | undefined): React.CSSProperties {
  const IDLE_BORDER_STYLE = 'none';
  const ERROR_BORDER_STYLE = '1px solid red';
  if (property === undefined || property == null) {
    return { border: IDLE_BORDER_STYLE, borderRadius: 5 };
  }

  return { border: ERROR_BORDER_STYLE, borderWidth: 1, borderRadius: 5 };
}

export function getBase64(file: File) {
  return new Promise<string>((resolve) => {
    let fileInfo;
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result as any;
      resolve(baseURL);
    };
  });
}

export const browserName = (function (agent) {
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'MS Edge';
    case agent.indexOf('edg/') > -1:
      return 'Edge ( chromium based)';
    case agent.indexOf('opr') > -1:
      return 'Opera';
    case agent.indexOf('chrome') > -1:
      return 'Chrome';
    case agent.indexOf('trident') > -1:
      return 'MS IE';
    case agent.indexOf('firefox') > -1:
      return 'Mozilla Firefox';
    case agent.indexOf('safari') > -1:
      return 'Safari';
    default:
      return 'other';
  }
})(window.navigator.userAgent.toLowerCase());

export function flattenStyleObjects(styles: Array<any>) {
  return styles.reduce((merged, style) => {
    return Object.assign(merged, style);
  }, styles[0])[0];
}

export function getStripe() {
  const { REACT_APP_DEV_STRIPE_KEY, REACT_APP_PROD_STRIPE_KEY } = process.env;
  if (process.env.REACT_APP_ENV == Environments.PRODUCTION) {
    console.log('STRIPE', REACT_APP_PROD_STRIPE_KEY);
    return REACT_APP_PROD_STRIPE_KEY;
  }
  if (process.env.NODE_ENV === Environments.LOCAL) {
    return REACT_APP_DEV_STRIPE_KEY;
  } else if (process.env.NODE_ENV === Environments.DEVELOPMENT) {
    return REACT_APP_DEV_STRIPE_KEY;
  } else if (process.env.NODE_ENV === Environments.PRODUCTION) {
    return REACT_APP_PROD_STRIPE_KEY;
  }
}

export function formatDateToCustomString(isoDateString: string) {
  // Parse ISO date string into a Date object
  const date = new Date(isoDateString);

  // Define month names
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Extract components
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const amOrPm = hours >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Create the formatted string
  const formattedString = `${month} ${day}${getOrdinalSuffix(day)}, ${year}, ${formattedHours}:${String(minutes).padStart(
    2,
    '0',
  )} ${amOrPm}`;

  return formattedString;
}

// Function to add ordinal suffix to the day (e.g., 1st, 2nd, 3rd, 4th)
function getOrdinalSuffix(day: any) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function getDomain() {
  const { REACT_APP_SERVER_DEV_BASE_URL, REACT_APP_SERVER_PROD_BASE_URL, REACT_APP_SERVER_LOCAL_BASE_URL } = process.env;

  if (process.env.REACT_APP_ENV == Environments.PRODUCTION) {
    console.log('DOMAIN', REACT_APP_SERVER_PROD_BASE_URL);
    return REACT_APP_SERVER_PROD_BASE_URL;
  }
  if (process.env.NODE_ENV === Environments.LOCAL) {
    return REACT_APP_SERVER_LOCAL_BASE_URL;
  } else if (process.env.NODE_ENV === Environments.DEVELOPMENT) {
    return REACT_APP_SERVER_DEV_BASE_URL;
  } else if (process.env.NODE_ENV === Environments.PRODUCTION) {
    return REACT_APP_SERVER_PROD_BASE_URL;
  }
}

/// Precondition: This fu
export function useAuth(): Auth {
  return useSelector((state: RootState) => state?.auth?.auth)!;
}

export function useAssociate() {
  return useSelector((state: RootState) => state.associate);
}

export const toQueryString = (obj?: object) => {
  if (!obj) {
    return '';
  }

  const keyValuePairs = Object.entries(obj).map(([key, value]) => {
    if (value === null || value === undefined) {
      return null; // Skip adding the key-value pair to the query string
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  });

  // Filter out any null values (where value was null or undefined)
  const filteredPairs = keyValuePairs.filter((pair) => pair !== null);

  return filteredPairs.join('&');
};

export function roleMapper(role: string) {
  switch (role) {
    case 'super_admin':
      return 'Super Admin';
    case 'admin':
      return 'Admin';
    case 'sales_associate':
      return 'Sales Associate';
    case 'company':
      return 'Company';
    default:
      return '';
  }
}

export function formatPhoneNumber(number: string) {
  const isFormatted = /^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/;

  const numbersOnly = /^[0-9]+$/;

  if (!isFormatted.test(number)) {
    number = number.replaceAll(' ', '');
    if (!numbersOnly.test(number)) {
      return number.substring(1, number.length);
    }
    return formatDigits(number);
  }

  return number;
}

function formatDigits(number: string) {
  switch (number.length) {
    case 7:
      number = number.replace(/(\d{3})(\d{4})/, '$1-$2');
      break;
    case 10:
      number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
      break;
    case 11:
      number = number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '($2)$3-$4');
      break;
    default:
      break;
  }
  return number;
}

export const getMomentInTime = (date: string) => {
  const ndate = new Date(date).toLocaleDateString();

  const momentInTime = moment(ndate, 'MM/DD/YYYY').fromNow();

  return momentInTime;
};

export function fNumber(number: any) {
  return numeral(number).format();
}

export function fCurrency(number: any) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number: any) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: any) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: any) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: any, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export const getBrowserName = (function (agent) {
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'MS Edge';
    case agent.indexOf('edg/') > -1:
      return 'Edge ( chromium based)';
    case agent.indexOf('opr') > -1:
      return 'Opera';
    case agent.indexOf('chrome') > -1:
      return 'Chrome';
    case agent.indexOf('trident') > -1:
      return 'MS IE';
    case agent.indexOf('firefox') > -1:
      return 'Mozilla Firefox';
    case agent.indexOf('safari') > -1:
      return 'Safari';
    default:
      return 'other';
  }
})(window.navigator.userAgent.toLowerCase());

export const toSentenceCase = (str: string | null | undefined) => {
  console.log(`String ${str}`);
  if (!str) return str;
  str = str.trim();

  // Regular expression to match sentence-ending punctuation marks
  const sentenceEndings = /([.!?])\s*/g;

  // Split the string into sentences
  const sentences = str.split(sentenceEndings);

  // Process each sentence
  for (let i = 0; i < sentences.length; i++) {
    if (sentences[i].length > 0) {
      sentences[i] = sentences[i].charAt(0).toUpperCase() + sentences[i].slice(1).toLowerCase();
    }
  }

  // Reassemble the string
  return sentences.join('');
};

/**
 * Adds a day to a date string formatted as `2024-07-11` or `19/07/2024`
 *
 * @param dateString E.g. `2024-07-11` or `19/07/2024`
 * @returns A date with the day incremented by one `2024-07-12` or `20/07/2024`
 */
export function addOneDay(dateString: string) {
  console.log('String recieced', dateString);
  let year: number, month: number, day: number;

  if (dateString.includes('-')) {
    // Format YYYY-MM-DD
    const dateParts = dateString.split('-');
    year = parseInt(dateParts[0], 10);
    month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
    day = parseInt(dateParts[2], 10);
  } else if (dateString.includes('/')) {
    // Format DD/MM/YYYY
    const dateParts = dateString.split('/');
    day = parseInt(dateParts[0], 10);
    month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
    year = parseInt(dateParts[2], 10);
  } else {
    throw new Error('Unsupported date format');
  }

  const date = new Date(year, month, day);
  date.setDate(date.getDate() + 1);

  const newYear = date.getFullYear();
  const newMonth = String(date.getMonth() + 1).padStart(2, '0');
  const newDay = String(date.getDate()).padStart(2, '0');

  if (dateString.includes('-')) {
    console.log(`String returned ${newYear}-${newMonth}-${newDay}`);

    return `${newYear}-${newMonth}-${newDay}`;
  } else {
    console.log(`String returned ${newDay}/${newMonth}/${newYear}`);

    return `${newDay}/${newMonth}/${newYear}`;
  }
}

/**
 * Adds one day to a given Date object
 *
 * @param date The Date object to which a day should be added
 * @returns A new Date object with the day incremented by one
 */
export function addOneDayToDate(date: Date): Date {
  // Create a new Date object to avoid mutating the original date
  const newDate = new Date(date);

  // Add one day
  newDate.setDate(newDate.getDate() + 1);

  return newDate;
}

export function convertDaysToYearsMonthsDays(days: number) {
  const startDate = moment().startOf('day'); // Today's date at the start of the day
  const endDate = moment(startDate).add(days, 'days'); // Add the number of days

  const years = endDate.diff(startDate, 'years');
  startDate.add(years, 'years');

  const months = endDate.diff(startDate, 'months');
  startDate.add(months, 'months');

  const remainingDays = endDate.diff(startDate, 'days');

  return { years, months, days: remainingDays };
}

export function formatDateDuration(duration: { years: number; months: number; days: number }): string {
  const parts: string[] = [];

  if (duration.years > 0) {
    parts.push(`${duration.years} ${duration.years === 1 ? 'year' : 'years'}`);
  }

  if (duration.months > 0) {
    parts.push(`${duration.months} ${duration.months === 1 ? 'month' : 'months'}`);
  }

  if (duration.days > 0) {
    parts.push(`${duration.days} ${duration.days === 1 ? 'day' : 'days'}`);
  }

  return parts.join(', ');
}
