import { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useAuth } from '@core/utils/utils';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PaymentMethodsEditModal from './PaymentMethodsEditModal';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Box, Tooltip, useTheme, IconButton } from '@mui/material';
import DeleteConfirmModal from '@features/shared/DeleteConfirmModal';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useRemovePaymentMethodMutation } from '@features/state/slices/api/payment-methods.slice';
import { MasterCard } from '@/components/Cards/MasterCard';
import { VisaCard } from '@/components/Cards/Visa';

const ListItem = ({ data, refetch }: { data: any; refetch: any }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteCardMutation] = useRemovePaymentMethodMutation();
  const [deleteCardDialog, setDeleteCardDialog] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({} as any);

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const toggleConfirmDelete = () => {
    setConfirmDelete((prev) => !prev);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleDeleteCardDialog = () => {
    setDeleteCardDialog(!deleteCardDialog);
  };

  const deleteCard = async () => {
    toggleDeleteCardDialog();
    dispatch(toggleLoading());
    try {
      const result = await deleteCardMutation({
        companyId: user!.id,
        cardId: selectedPayment,
      }).unwrap();

      if (result.success) {
        toast.success('Card deleted');
        refetch();
      } else {
        toast.error(result.error?.message!);
      }
    } catch (err) {
      console.error('Unable to delete your video');
    }
    dispatch(toggleLoading());
  };

  return (
    <>
      <DeleteConfirmModal
        open={confirmDelete}
        handleAction={deleteCard}
        closeModal={toggleConfirmDelete}
        title={'Delete Payment Method?'}
        confirmationText={"This will delete this card's information from your account"}
      />
      <PaymentMethodsEditModal open={open} handleClose={handleClose} />

      <Box mt={1} width="100%" height="100%" display="flex" alignItems="center">
        <div className="flex items-center space-x-2 ">
          {data.card.brand == 'visa' ? <VisaCard /> : <MasterCard />}
          <div>
            <div className="text-xs">**{data?.card?.last4}</div>
          </div>
        </div>

        {/* <Tooltip title={'Edit Card Details'}>
          <IconButton
            sx={{
              ml: 4,
              '&:hover': {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
          >
            <EditTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
        <Tooltip title={'Delete Card'}>
          <IconButton
            sx={{
              ml: 3,
              '&:hover': {
                background: theme.colors.error.lighter,
              },
              color: theme.palette.error.main,
            }}
            size="small"
            color="inherit"
            onClick={() => {
              toggleConfirmDelete();
              setSelectedPayment(data.id);
            }}
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default ListItem;
function setDeleteCardDialog(arg0: boolean) {
  throw new Error('Function not implemented.');
}
