import { Avatar, Link, useTheme } from '@mui/material';
import { PaginatedList, Query } from '@/core/constants/constants';
import { VideoAnalyticsVideoEntity, VideoAnalyticsViewer } from '@/core/types';
import Routes from '@/features/router/routes';
import { formatDateToCustomString } from '@/core/utils/utils';

type Props = {
  title?: string;
  totalViews: number;
  viewers: PaginatedList<VideoAnalyticsViewer[]>;
  subTitle?: string;
};

export default function AnalyticsVideoViewers({ title, viewers, totalViews, subTitle }: Props) {
  const theme = useTheme();

  const queryParams = new URLSearchParams(location.search);

  const secret = queryParams.get('secret');

  const isAdminViewing = secret != null;

  return (
    <div>
      <div className="col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-md border border-slate-200 dark:border-slate-700 mt-3">
        <div className="flex justify-content-between items-center">
          <div className="flex justify between">
            <header className="px-3 py-4 border-b border-slate-100 dark:border-slate-700">
              <h5 className="font-semibold text-slate-800 dark:text-slate-100">{title}</h5>
              <h5 className="text-sm italic mb-2">{subTitle}</h5>
            </header>
          </div>
          <div className="pr-10 flex items-center">
            <p className="font-bold text-lg">{totalViews}</p> <p className="ml-1">views</p>
          </div>
        </div>
        <p className="px-3 text-slate-500 dark:text-slate-100">
          This comprehensive table presents valuable data on the users who viewed your video content, including how long they watched each
          video and the specific dates of their viewing sessions. Gain a deeper understanding of viewer behaviors and preferences to
          optimize your content strategy and enhance user experiences.
        </p>

        <div className="p-3">
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full dark:text-slate-300">
              {/* Table header */}
              <thead className="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm">
                <tr>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">User</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Viewed On</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Watch Duration</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">Skip Factor</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">Time Skipped</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Actions</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm divide-y divide-slate-100 dark:divide-slate-700 space-y-3">
                {/* Row */}

                {viewers.items.map((viewer) => (
                  <tr className="mb-3">
                    <td className="px-2  py-3 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="shrink-0 rounded-full mr-2 sm:mr- 3 bg-indigo-500">
                          <Avatar
                            src={viewer.user.profilePic ?? ''}
                            alt={`${viewer.user.firstName} ${viewer.user.lastName}`}
                            sx={{ width: 30, height: 30, backgroundColor: theme.colors.primary.dark }}
                          />
                        </div>
                        <div className="font-medium text-slate-500 dark:text-slate-100">
                          {viewer.user.firstName} {viewer.user.lastName}
                        </div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap text-slate-500 dark:text-slate-100">{formatDateToCustomString(viewer.date)}</td>
                    <td className="p-2 whitespace-nowrap text-slate-500 dark:text-slate-100">
                      <div className="flex items-center">
                        <svg className="w-4 h-4 fill-current text-slate-400 dark:text-slate-600 shrink-0 mr-2" viewBox="0 0 16 16">
                          <path d="M4.3 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.1.4 5.7.4 2.9 3.1L.7.9 0 7.3l6.4-.7-2.1-2.1zM15.6 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.1 2 4.9 2 1.8 0 3.6-.7 4.9-2l2.2 2.2.8-6.4z" />
                        </svg>
                        {viewer.watchDuration}
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-center text-slate-400 dark:text-slate-600">{viewer.skipFactor ?? 0}%</div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-center text-slate-400 dark:text-slate-600">{viewer?.timeSkipped ?? 'N/A'}</div>
                    </td>

                    <td className="p-2 whitespace-nowrap">
                      {isAdminViewing ? (
                        <span className="text-left font-medium text-slate-400">View</span>
                      ) : (
                        <Link
                          className="text-left font-medium text-emerald-500"
                          href={`/${Routes.App.Users.SalesAssociates.ById.replace(':id', viewer.user.id.toString())}`}
                        >
                          View
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
