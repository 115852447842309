import { tailwindConfig } from '@/core/utils/utils';
import { useContext, useEffect, useState } from 'react';
import PolarChart from '@/components/Charts/PolarChart';
import { AnalyticsTokenContext } from '../../../providers/AnalyticsTokenProvider';
import { useGetCustomVideoDeviceUsageQuery } from '@/features/state/slices/api/video-analytics-slice';

export const hexToRGB = (h: string): string => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = parseInt(`0x${h[1]}${h[1]}`);
    g = parseInt(`0x${h[2]}${h[2]}`);
    b = parseInt(`0x${h[3]}${h[3]}`);
  } else if (h.length === 7) {
    r = parseInt(`0x${h[1]}${h[2]}`);
    g = parseInt(`0x${h[3]}${h[4]}`);
    b = parseInt(`0x${h[5]}${h[6]}`);
  }
  return `${+r},${+g},${+b}`;
};
export default function DeviceUsageAnalysis() {
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const { isFetching, isError, isSuccess, data } = useGetCustomVideoDeviceUsageQuery(analyticsAuth);

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setChartData({
        labels: data.data?.labels,
        datasets: [
          {
            label: 'Device Usage Analysis',
            data: data.data?.percentages,
            backgroundColor: [
              `rgba(${hexToRGB(tailwindConfig().theme.colors.indigo[500])}, 0.8)`,
              `rgba(${hexToRGB(tailwindConfig().theme.colors.sky[400])}, 0.8)`,
              `rgba(${hexToRGB(tailwindConfig().theme.colors.emerald[500])}, 0.8)`,
            ],
            hoverBackgroundColor: [
              `rgba(${hexToRGB(tailwindConfig().theme.colors.indigo[600])}, 0.8)`,
              `rgba(${hexToRGB(tailwindConfig().theme.colors.sky[500])}, 0.8)`,
              `rgba(${hexToRGB(tailwindConfig().theme.colors.emerald[600])}, 0.8)`,
            ],
            borderWidth: 0,
          },
        ],
      });

      setTimeout(() => {
        setShowChart(true);
      }, 500);
    }
  }, [isSuccess, isError, isFetching]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-sm rounded-md border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <p className="font-semibold text-slate-800 dark:text-slate-100 text-base">Device Usage Analysis</p>
        <p className="text-slate-500 xs">
          This analysis examines the distribution of video views across different devices, including tablets, mobile devices, and unknown
          devices.
        </p>
      </header>
      {showChart && <PolarChart data={chartData} width={250} height={272} />}
    </div>
  );
}
