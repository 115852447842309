export enum PendingInviteStatus {
  PENDING = 1,
  CANCELLED = 2,
  ACCEPTED = 3,
}

export enum VideoProcessingStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum VideoMode {
  Edit = 'Edit',
  Create = 'Create',
}
/**
 * An enumeration of supported country ISO codes example UnitedStates would be "US".
 */
export enum CountryISO {
  /**
   * The United States of America.
   */
  UnitedStates = 'US',
}
export enum SubscriptionStatus {
  ACTIVE = 1,
  EXPIRED = 2,
  INCOMPLETE = 3,
  FAILED = 4,
  CANCELLED = 5,
  CancelAutoRenewal = 6,
}
export enum RequestStatus {
  IDLE = 1,
  APPROVED = 2,
  CANCELLED = 3,
  REJECTED = 4,
}
export enum VideoType {
  VIDEO = 'video',
  CUSTOM = 'custom',
}

export enum TabValues {
  Videos = 'videos',
  CustomVideo = 'custom-videos',
}

export enum DefaultOptions {
  ALL = 'All',
}

export enum Roles {
  All = 'all',
  Admin = 'admin',
  Company = 'company',
  SuperAdmin = 'super_admin',
  SalesAssociate = 'sales_associate',
}

export enum Environments {
  LOCAL = 'test',
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

export enum CardTypes {
  Visa = 'visa',
  MasterCard = 'mastercard',
}

export enum PaymentResponseStatus {
  Success = 'success',
  Failed = 'failed',
}

export enum TableAlignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum HelperType {
  Info = 1,
  Error = 2,
}

export enum BaseEndpoints {
  Auth = 'auth',
  Admin = 'admins',
  Videos = 'videos',
  Health = 'health',
  Feedback = 'feedback',
  Service = 'service',
  Request = 'requests',
  Company = 'companies',
  Industry = 'industries',
  Analytics = 'analytics',
  VideoCredit = 'credits',
  SalesAssociate = 'users',
  VideoPackage = 'packages',
  Webhook = 'stripe-webhook',
  CustomVideo = 'custom-videos',
  PackageType = 'package-types',
  Subscription = 'subscriptions',
  Products = 'products',
  ProductsPurchased = 'product-purchases',
  Notification = 'notifications',
  PackageAccess = 'package-access',
  PaymentMethod = 'payment-methods',
  PendingInvite = 'pending-invites',
  VideoCategory = 'video-categories',
  VideoAnalytics = 'video-analytics',
  PackageCategory = 'package-categories',
}

export enum ApiMethods {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}
