import { SubscriptionPlan, subscriptionTabs, SIX_MONTH_CONTRACT_KEY, ONE_YEAR_CONTRACT_KEY } from '@core/constants/constants';
import { Box, styled, Tab, Tabs } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { SubscriptionDisplayItem } from './SubscriptionDisplay/SubscriptionItem';
import { BaseSubscription } from '@core/types';

type Props = {
  selectedTab?: string | null;
  subscriptions: SubscriptionPlan[];
  selectedSubscription: SubscriptionPlan | null;
  onSelect: (subscription: SubscriptionPlan | null, pricingPlan: string) => void;
};

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
);
export const SubscriptionDisplay: React.FC<Props> = ({ onSelect, selectedTab, subscriptions, selectedSubscription }) => {
  useEffect(() => {
    if (selectedTab) {
      setCurrentTab(selectedTab);
    }
  }, [selectedTab]);
  const [currentTab, setCurrentTab] = useState<string>(ONE_YEAR_CONTRACT_KEY);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
    onSelect(selectedSubscription, value);
  };
  console.log(`Susbcription`, subscriptions[0]);
  return (
    <React.Fragment>
      <Box mt={2} mb={2} display="flex" textAlign="center" alignSelf="center" alignItems="center" overflow={'auto'}>
        <TabsWrapper
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {subscriptionTabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </TabsWrapper>
      </Box>
      <section className="pricing py-5" style={{ borderRadius: 15 }}>
        <div className="container">
          <div className="row">
            {subscriptions.map((subscription) => (
              <SubscriptionDisplayItem
                onSelect={onSelect}
                pricePlan={currentTab}
                subscription={subscription}
                config={{
                  // subscription.pricePlan == SIX_MONTH_CONTRACT_KEY
                  displayTrialPeriod: false,
                  displayUpFrontBilling: false,
                }}
                isSelected={subscription.id === selectedSubscription?.id}
              />
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
