import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const {
  REACT_APP_COUNTRY_STATE_API_KEY,
  REACT_APP_COUNTRY_API,
} = process.env;

export const locationSlice = createApi({
  reducerPath: "locations",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_COUNTRY_API,
    prepareHeaders: (headers, { getState }) => {
      headers.set("X-CSCAPI-KEY", REACT_APP_COUNTRY_STATE_API_KEY as string);
      return headers;
    },
  }),
  tagTypes: ["Location"],
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: () => 'countries',
      providesTags: ["Location"],
    }),

    getProvinces: builder.query({
      query: (code: string) => `countries/${code}/states`,
      providesTags: ["Location"],
    }),
  }),
});
export const { useLazyGetCountriesQuery, useLazyGetProvincesQuery } = locationSlice;


