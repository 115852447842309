import { Transition } from '@components/CustomVideoTransition';
import { AppInput, BootstrapDialog, CustomVideoAppInput } from '@components/Styled';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { BootstrapDialogTitle } from '@components/BootstrapDialog';
import { Box, Button, DialogActions, DialogContent, DialogContentText, TextField, Typography } from '@mui/material';
import { ACTION_CONFIRMATION_TEXT } from '@core/constants/constants';

interface IDialogProps {
  title: string;
  subtitle: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export interface IDialogRef {
  openDialog: () => void;
  closeDialog: () => void;
}

export const CustomVideoAppDialog = forwardRef<IDialogRef, IDialogProps>((props, ref: any) => {
  const [open, setOpen] = useState(false);
  const [confirmation, setConfirmation] = useState('');

  const onConfirm = () => {
    setOpen(false);
    props.onConfirm();
  };

  const handleDialogClose = () => {
    setOpen(false);
    if (props.onCancel !== null && props.onCancel !== undefined) {
      props!.onCancel();
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      openDialog: () => {
        setOpen(true);
      },
      closeDialog: handleDialogClose,
    }),
    [],
  );
  console.log('here in dialog');
  return (
    <BootstrapDialog
      open={open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
        {props.title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-slide-description">{props.subtitle}</DialogContentText>
        <Box mt={2} mb={1}>
          <Typography fontWeight="bold" component={'span'} variant="subtitle1">
            To {props.title.toLowerCase()}, type{' '}
            <Typography component={'span'} fontStyle={'italic'}>
              {ACTION_CONFIRMATION_TEXT}
            </Typography>{' '}
            in the field below.
          </Typography>
        </Box>
        <CustomVideoAppInput
          placeholderItalic={true}
          onChange={(e: any) => setConfirmation(e.target.value)}
          fullWidth
          placeholder={ACTION_CONFIRMATION_TEXT}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={confirmation !== ACTION_CONFIRMATION_TEXT} variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
});
