import React from 'react';
import Iconify from '@/components/iconify/Iconify';
import { useNavigate } from 'react-router';
import Center from '@/components/Center/Center';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

type Props = {};

export const NoPackages: React.FC<Props> = () => {
  const theme = useTheme();
  return (
    <Box mt={10}>
      <Grid container justifyContent="center">
        <Center>
          <Iconify
            icon="fluent:box-20-filled"
            sx={{
              width: 200,
              height: 200,
              color: theme.colors.primary.dark,
            }}
          />
          <Typography mt={1} textAlign="center" variant="h3">
            You have no packages
          </Typography>
          <Typography mt={1} textAlign="center" variant="subtitle1">
            Check out the store for new packages or approve requests from your sales associates
          </Typography>
        </Center>
      </Grid>
    </Box>
  );
};
