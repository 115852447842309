import Sidebar from '../Sidebar';
import { motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import CreditHeaderButton from './Buttons/Credit';
import useResponsive from '@core/hooks/useResponsive';
import { useDispatch, useSelector } from 'react-redux';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import NotificationHeaderButton from './Buttons/Notifications';
import { RootState } from '@features/state/reducers/combinedReducers';
import DropdownProfile from '@/core/layouts/Dashboard/Header/Buttons/DropdownProfile/DropdownProfile';
import { Box, Slide, styled, Tooltip, useTheme, IconButton, useScrollTrigger, Drawer } from '@mui/material';
import { CLOSE_SIDEBAR, OPEN_SIDEBAR, toggleSidebar } from '@features/state/slices/local/layout.slice';

const HeaderWrapper = styled(motion.div)(
  ({ theme }: any) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%; z-index: 7;
        display:flex;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
        background: ${theme.colors.alpha.white[100]};
        box-shadow: 0 1px 1px #00000029;
`,
);

interface Props {
  children: React.ReactElement;
}

function AppHeader() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive('up', 'lg', 'sm');
  const layout = useSelector((state: RootState) => state.layout);

  const toggleSidebarStaus = () => {
    if (isDesktop) {
      if (layout.isOpen) {
        dispatch(toggleSidebar(CLOSE_SIDEBAR));
      } else {
        dispatch(toggleSidebar(OPEN_SIDEBAR));
      }
    } else {
      setOpen(true);
    }
  };

  return (
    <HideOnScroll>
      <div>
        <HeaderWrapper initial={{ left: isDesktop ? layout.layoutValue : 0 }} animate={{ left: isDesktop ? layout.layoutValue : 0 }}>
          <Box ml={2} display="flex" alignItems="center">
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebarStaus}>
                <MenuTwoToneIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
            <CreditHeaderButton />
            <NotificationHeaderButton align="right" />
            <DropdownProfile align="right" />
          </Box>
        </HeaderWrapper>
        <Drawer open={open} variant="temporary" onClose={(e) => setOpen(false)}>
          <Box
            sx={{
              boxShadow: 3,
              width: OPEN_SIDEBAR.layoutValue,
            }}
          >
            <Sidebar layout={OPEN_SIDEBAR} onItemClick={() => setOpen(false)} />
          </Box>
        </Drawer>
      </div>
    </HideOnScroll>
  );
}

function HideOnScroll(props: Props) {
  const { children } = props;

  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default AppHeader;
