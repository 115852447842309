import { Box, CircularProgress, Grid, InputAdornment, debounce, useTheme } from '@mui/material';
import { NoVideos } from './NoVideos';
import { CustomVideo } from '@core/types';
import { useEffect, useState } from 'react';
import { useAuth } from '@core/utils/utils';
import ImageCardSkeleton from '@features/shared/ImageCardSkeleton';
import { useGetCompanyCustomVideosQuery, useLazySearchCompanyCustomVideosQuery } from '@features/state/slices/api/custom-video-slice';

import moment from 'moment';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';
import { BackButton } from '@/components/BackButton';
import AppChip from '@/components/AppChip';
import TransitionWrapper from '@/components/TransitionWrapper';
import { fadeInRight } from 'react-animations';
import { Helmet } from 'react-helmet-async';
import AppPageHeader from '@/components/PageHeader';
import { StyledSearch } from '@/components/Styled';
import Center from '@/components/Center/Center';
import AppTablePagination from '@/components/AppTablePagination';
import AppTablePaginationCustomVideo from '@/components/AppTablePaginationCustomVideo';
import { EmptyTable } from '@/components/EmptyTable';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import { Retry } from '@/components/Retry';
import { CustomVideoListing } from './CustomVideoListing';
import { useSelector } from 'react-redux';
import { RootState } from '@/features/state/reducers/combinedReducers';
import { RefreshButton } from '@/components/RefreshButton';
import Iconify from '@/components/iconify copy';
export default function CustomVideosDisplay() {
  const { user } = useAuth();
  const theme = useTheme();
  const [customVideo, setCustomVideo] = useState<CustomVideo[]>({} as any);
  const [loading, setLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState({
    page: 1,
    limit: limit,
    searchTerm: '',
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const company = useSelector((state: RootState) => state?.auth.auth?.user);
  const { data, refetch, isError, isSuccess, isLoading, isFetching } = useGetCompanyCustomVideosQuery({
    query,
    id: user!.id,
  });
  const [
    searchTrigger,
    { data: searchData, isSuccess: isSearchSuccess, isFetching: isSearchFetching, isError: isSearchError },
    lastPromiseInfo,
  ] = useLazySearchCompanyCustomVideosQuery();

  const isSearching = query.searchTerm != '' || query.searchTerm == undefined;
  const contentAvailable = isSuccess && data?.data?.items !== null && data!.data!.items!.length > 0 && !isFetching;
  const searchcontentAvailable =
    isSearchSuccess && searchData?.data?.items !== null && searchData!.data!.items!.length > 0 && !isSearchFetching;
  const handlePaginationChanged = (limit: number, page: number) => {
    setQuery({
      page,
      limit,
      searchTerm: query.searchTerm,
    });
    refetch();
  };

  const handleSearch = (e: any) => {
    console.log('QUERY Called ');

    setQuery({
      page: query.page,
      limit: query.limit,
      searchTerm: e.target.value,
    });
  };
  const f = () => {
    console.log('QUERY SENT ROOT');
    searchTrigger({
      page: query.page,
      limit: query.limit,
      searchTerm: query.searchTerm,
      id: user!.id,
    });
  };

  useEffect(() => {
    console.log('QUERY SENT is searhing', isSearching);
    if (isSearching) {
      f();
    }
  }, [query]);

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState('10');

  // useEffect(() => {
  //   console.log('QUERY VARS ', isError, isSuccess, isFetching);
  //   if (!isFetching && !isError && isSuccess && data?.data) {
  //     console.log('QUERY DATA FROM Custom Video', data?.data!);
  //     setCustomVideo(data?.data?.items);
  //   }
  // }, [isError, isSuccess, isFetching]);
  console.log('QUERY STATE', query);
  return (
    <TransitionWrapper duration={0.3} animation={fadeInRight}>
      <Helmet>
        <title>Sales Assist - Custom Videos</title>
      </Helmet>

      <AppPageHeader
        title="Custom Videos"
        subtitle={`${company?.name}, these are your Custom Videos`}
        // trailing={
        //   <Button
        //     variant="contained"
        //     style={{ borderRadius: 20 }}
        //     sx={{ mt: { xs: 2, md: 0 }, borderRadius: 20 }}
        //     onClick={navigateToUpload}
        //   >
        //     Upload Custom Video
        //   </Button>
        // }
      />

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <StyledSearch
            fullWidth
            sx={{
              height: 45,
              borderRadius: 0.8,
            }}
            onChange={debounce(handleSearch, 300)}
            placeholder="Search Custom Videos"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{
                    color: 'text.disabled',
                    width: 20,
                    height: 20,
                  }}
                />
              </InputAdornment>
            }
          />

          {/* {selectedFilters.map((filter) => (
            <Tooltip title={filter} placement="top">
              <AppChip sx={{ ml: 1 }}>{filter}</AppChip>
            </Tooltip>
          ))} */}
        </Box>

        {/* <Box display="flex" alignItems="center" justifyContent="center">
          <Filter
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            selectedFilters={selectedFilters}
            onCloseFilter={handleCloseFilter}
            onFilterChange={setSelectedFilters}
            filters={[
              {
                title: 'Status',
                filters: ['Processed', 'Completed', 'Failed'],
              },
            ]}
          />
          <RefreshButton onRefresh={refetch} />
        </Box> */}
      </Box>
      <Grid container direction={'row'} justifyContent="center" alignItems={'stretch'} spacing={3}>
        <Grid item xs={12} mt={4}>
          {(!isSearching && isFetching) ||
            (isSearching && isSearchFetching && (
              <Center>
                <Box mt={10}>
                  <CircularProgress />
                </Box>
              </Center>
            ))}

          {((!isSearching && data?.data?.items?.length != null && data?.data?.items?.length == 0) ||
            (isSearching && !searchcontentAvailable && !isSearchFetching)) && (
            <EmptyTable
              subtitle={
                isSearching
                  ? 'There are currently no custom videos that match your query'
                  : 'There are currently no custom videos on the system'
              }
              icon={<MovieFilterIcon sx={{ color: theme.colors.primary.dark, fontSize: 130 }} />}
            />
          )}

          {(!isSearching && isError && !isSuccess && !isFetching) ||
            (isSearching && isSearchError && !isSearchSuccess && !isSearchFetching && <Retry refetch={isSearching ? f : refetch} />)}

          {((!isSearching && contentAvailable) || (isSearching && searchcontentAvailable)) && (
            <Box>
              <CustomVideoListing
                videos={isSearching ? searchData?.data?.items ?? [] : data?.data?.items ?? []}
                refetch={isSearching ? f : refetch}
                // handleSetVideoMode={handleSetVideoMode}
                // handleSetCustomVideo={handleSetCustomVideo}
              />
              <AppTablePaginationCustomVideo
                onChange={handlePaginationChanged}
                totalPages={isSearching ? searchData?.data?.meta.totalPages ?? 1 : data?.data?.meta.totalPages ?? 1}
                page={page}
                setPage={setPage}
                limit={fetchLimit}
                setLimit={setFetchLimit}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </TransitionWrapper>
  );
}
