import _ from 'lodash';
import { motion } from 'framer-motion';

import Logo from '@assets/images/logo.png';

import { NavContentWrapper, ProfileBox } from '@/components/Styled';
import { alpha, Avatar, Box, Button, Popover, Tooltip, Typography } from '@mui/material';

import toast from 'react-hot-toast';
import { Company } from '@core/types';
import { useDispatch } from 'react-redux';
import SmallLogo from '../../../../../assets/images/small-logo.png';
import { Layout } from '@features/state/slices/local/layout.slice';
import { CloudUploadFill } from '@styled-icons/bootstrap/CloudUploadFill';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { updateProfilePic } from '@features/state/slices/local/auth.slice';
import { useUpdateProfilePhotoMutation } from '@features/state/slices/api/company-slice';
import { roleMapper } from '@core/utils/utils';
import { styled } from '@mui/material/styles';

import Transition from '../../../../../../components/Transition';

import { useAuth } from '@/core/utils/utils';
import PersonIcon from '@mui/icons-material/Person';
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { logOut } from '@/features/state/slices/local/auth.slice';

import { StyledBadge } from '@/components/Styled';
import { Link, useNavigate } from 'react-router-dom';
import Routes from '@/features/router/routes';

function ProfileDropdown({ align }: any) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const [url, setUrl] = useState('');
  const uploadInputRef = useRef<any>(null as any);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [photo, setPhoto] = useState<File | null>(null as any);
  const [updateProfilePhotoMutation] = useUpdateProfilePhotoMutation();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: any) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleSignOut = () => {
    setDropdownOpen(!dropdownOpen);
    dispatch(logOut());
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      dispatch(toggleLoading());

      const photo = e.target?.files![0];

      const formData = new FormData();

      formData.append('photo', photo);
      formData.append('id', String(user?.id!));

      const { success, error, data } = await updateProfilePhotoMutation(formData).unwrap();

      if (!success && error) {
        toast.error(error?.message!);
        return;
      }

      dispatch(updateProfilePic(data?.url!));

      toast.success('Profile photo successfully updated');
    } catch (e) {
      toast.error('An error occured');
    }
    dispatch(toggleLoading());
  };

  const handleReferenceClick = async () => uploadInputRef.current && uploadInputRef.current.click();
  console.log('Company Fetched', user?.watermark, user?.canAccessWatermark);
  return (
    <div className="relative inline-flex mr-3 ml-3">
      <StyledBadge
        ref={ref}
        variant="dot"
        overlap="circular"
        onClick={handleOpen}
        className="cursor-pointer"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {user?.profilePic && <Avatar sx={{ width: 32, height: 32 }} alt={user?.name} src={user?.profilePic} />}
        {!user?.profilePic && (
          <div
            className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full`}
          >
            <PersonIcon fontSize="medium" />
          </div>
        )}
      </StyledBadge>
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="flex items-center truncate">
          <span className="truncate ml-2 text-xs font-medium dark:text-slate-300 group-hover:text-slate-800 dark:group-hover:text-slate-200">
            {user?.name}
          </span>
          <svg className="w-2 h-2 shrink-0 ml-1 fill-current text-slate-400" viewBox="0 0 12 12">
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Popover
        open={isOpen}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={ref.current}
        onClose={handleClose}
      >
        <ProfileBox>
          <Avatar alt="photoURL" src={user?.profilePic} sx={{ height: 300, width: 300, ml: 4 }} />

          {user?.watermark ? (
            user?.canAccessWatermark ? (
              <div className="flex items-baseline space-x-2 ml-4">
                <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                <h6 className="text-sm">Your logo will be applied as a watermark</h6>
              </div>
            ) : (
              <div className="flex items-baseline space-x-2 ml-4">
                <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                <h6 className="text-sm">You have purchased your watermark but access has be restricted. Contact Admin</h6>
              </div>
            )
          ) : user?.canAccessWatermark ? (
            <div className="flex items-baseline space-x-2 ml-4">
              <div className="w-2 h-2 bg-green-500 rounded-full"></div>
              <h6 className="text-sm">You have been given temporary access to a watermark.</h6>
            </div>
          ) : (
            <button
              className="flex items-baseline space-x-2 ml-4 bg-red-100 hover:bg-green-100 px-2 py-1 rounded-lg transition duration-300"
              onClick={() => {
                navigate('/app/products');
                setTimeout(handleClose, 400);
              }}
            >
              <h6 className="text-sm text-gray-800 mb-0">Your logo will not be applied as a watermark. Click to view more</h6>
            </button>
          )}

          {
            <div className="d-flex p-3">
              <Fragment>
                <input type="file" accept="image/*" ref={uploadInputRef} style={{ display: 'none' }} onChange={(e) => handleUpload(e)} />
                <Button onClick={() => handleReferenceClick()} variant="contained">
                  Upload &nbsp;
                  <CloudUploadFill size="20" />
                </Button>
              </Fragment>

              <Box ml={2} className="d-md-block text-start">
                <div className="fw-normal">Choose Image</div>
                <div style={{ marginBottom: 5 }} className="text-gray small mb-10">
                  JPG, GIF or PNG. Max size of 800K
                </div>
              </Box>
            </div>
          }
        </ProfileBox>
      </Popover>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === 'right' ? 'right-0' : 'left-0'
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 dark:border-slate-700">
            <div className="font-medium text-slate-800 dark:text-slate-100">{user?.name}</div>
            <div className="text-xs text-slate-500 dark:text-slate-400 italic">{user?.email}</div>
          </div>
          <ul style={{ paddingLeft: 0 }}>
            <li>
              <Link
                to={`/${Routes.App.Settings.Account}`}
                style={{ justifyContent: 'flex-start' }}
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="no-underline font-medium text-sm text-blue-400 hover:text-blue-500 dark:hover:text-blue-500 flex items-start py-1 px-3"
              >
                Account Settings
              </Link>
            </li>
            <li>
              <div
                onClick={handleSignOut}
                style={{ justifyContent: 'flex-start' }}
                className="cursor-pointer no-underline font-medium text-sm text-blue-400 hover:text-blue-500 dark:hover:text-blue-500 flex items-center py-1 px-3"
              >
                Sign Out
              </div>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default ProfileDropdown;
