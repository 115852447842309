import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LineChart from '@/components/Charts/LineChart01';
import { hexToRGB, tailwindConfig, useAuth } from '@/core/utils/utils';
import { useGetActiveUsersQuery } from '@/features/state/slices/api/analytics-slice';

function ActiveUsersAnalytics() {
  const auth = useAuth();
  const [displayChart, setDisplayChart] = useState(false);
  const [chartData, setChartData] = useState<any | null>(null);
  const { isFetching, isError, isSuccess, data } = useGetActiveUsersQuery({
    companyId: auth?.user?.id,
  });

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setChartData({
        labels: data.data?.dates,
        datasets: [
          {
            data: data.data?.dataPoints,
            fill: true,
            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.emerald[500])}, 0.08)`,
            borderColor: tailwindConfig().theme.colors.emerald[500],
            borderWidth: 2,
            tension: 0.4, // Adjust this value to control the smoothness of the line
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.emerald[500],
            pointHoverBackgroundColor: tailwindConfig().theme.colors.emerald[500],
            pointBorderWidth: 0,
            pointHoverBorderWidth: 0,
          },
        ],
      });

      setTimeout(() => {
        setDisplayChart(true);
      }, 500);
    }
  }, [isFetching, isError, isSuccess]);

  return (
    <div className="flex flex-col col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h5 className="font-semibold text-slate-800 dark:text-slate-100">Active Users Right Now</h5>
      </header>
      {/* Card content */}
      <div className="flex flex-col h-full">
        {/* Live visitors number */}
        <div className="px-5 py-3">
          <div className="flex items-center">
            {/* Red dot */}
            <div className="relative flex items-center justify-center w-3 h-3 mr-3" aria-hidden="true">
              <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-50"></div>
              <div className="relative inline-flex rounded-full w-1.5 h-1.5 bg-emerald-500"></div>
            </div>
            {/* Vistors number */}
            <div>
              <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">{data?.data?.count}</div>
              <div className="text-sm text-slate-500 dark:text-slate-400">Live Users</div>
            </div>
          </div>
        </div>

        <div>{displayChart && <LineChart data={chartData} width={389} height={90} />}</div>

        {/* Table */}
        <div className="grow px-5 pt-3 pb-1">
          <div className="overflow-x-auto">
            <table className="table-auto w-full dark:text-slate-300">
              {/* Table header */}
              <thead className="text-xs uppercase text-slate-400 dark:text-slate-500">
                <tr>
                  <th className="py-2">
                    <div className="font-semibold text-left">Active Users</div>
                  </th>
                  <th className="py-2">
                    <div className="font-semibold text-right">Active at</div>
                  </th>
                </tr>
              </thead>

              <tbody className="text-sm divide-y divide-slate-100 dark:divide-slate-700">
                {data?.data?.users?.map((user) => (
                  <tr>
                    <td className="py-2">
                      <div className="text-left font-normal text-slate-500">
                        {user.firstName} {user.lastName}
                      </div>
                    </td>
                    <td className="py-2">
                      <div className="font-normal text-right text-slate-500">{user.activeAt}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveUsersAnalytics;
