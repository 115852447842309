import { baseQueryWithReauth } from './base-slice';
import { ActionResult, ApiError, ServiceResult } from '@/core';
import { ApiMethods, BaseEndpoints } from '@/core/enums/enums';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['Feedback'] as readonly TagDescription<'Feedback'>[] & string[];

export const feedBackSlice = createApi({
  reducerPath: 'feedback',
  tagTypes: descriptionTags,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createFeedback: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (body) => ({
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Feedback}`,
        body,
      }),
    }),
  }),
});
export const { useCreateFeedbackMutation } = feedBackSlice;
