import { RequestableProduct } from '@/core/types';
import React from 'react';
import toast from 'react-hot-toast';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAuth } from '@core/utils/utils';
import { useEffect, useState } from 'react';
import FlipNumbers from 'react-flip-numbers';

import { HelperText } from '@/components/HelperText';
import { yupResolver } from '@hookform/resolvers/yup';
import { BuyCreditSchema } from '@core/constants/schemas';

import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useBuyVideoCreditMutation } from '@features/state/slices/api/video-credit-slice';
import { useGetPaymentMethodsQuery } from '@features/state/slices/api/payment-methods.slice';
import { Box, Typography, FormHelperText, RadioGroup, Radio, FormControlLabel } from '@mui/material';

import { HelperType } from '@core/enums/enums';

import Routes from '@/features/router/routes';
import { useNavigate } from 'react-router';
import { InputField } from '@/components/AppInput';
import NoPaymentMethod from '@/components/NoPayment';
import { VisaCard } from '@/components/Cards/Visa';

import AppButton from '@/components/AppButton';
import { MasterCard } from '@/components/Cards/MasterCard';
import { PurchasableProduct } from '@/core/types';
import { useCreateProductPaymentMutation, useCreateProductRequestMutation } from '@/features/state/slices/api/product-slice';
type Props = { onClose: () => void; onSuccess: () => void; product: RequestableProduct };

const RequestProductDialog = ({ onSuccess, onClose, product }: Props) => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [requestProductMutation, response] = useCreateProductRequestMutation();

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(BuyCreditSchema),
  });

  const requestProduct = async () => {
    dispatch(toggleLoading());
    try {
      const result = await requestProductMutation({
        id: product.id,
        companyId: parseInt(user!.id),
      }).unwrap();

      if (result.success) {
        toast.success(`A request for "${product.name}" was sent successfully. You will be contacted by our team shortly`);
        onSuccess();
      } else {
        toast.error('Unable to send a request for this product');
      }
    } catch (err) {
      console.error('Unable to send a request for this product: ', err);
    }
    dispatch(toggleLoading());
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <Box p={4}>
      <AppButton
        onClick={() => {
          requestProduct();
        }}
        className="float-right"
      >
        Send a Request
      </AppButton>

      <br></br>
    </Box>
  );
};

export default RequestProductDialog;
