import * as React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@features/state/reducers/combinedReducers';
import { toggleLoading } from '@features/state/slices/local/loading-slice';

const style = {
  top: '50%',
  left: '50%',
  color: 'white',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  '&:focus': {
    border: 'none',
    outline: 'none',
    opacity: [0.9, 0.8, 0.7],
  },
};
type Props = {};

const LoadingProvider: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state?.loading.isLoading);

  return (
    <div>
      <Modal
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        open={loading ?? false}
        BackdropComponent={Backdrop}
        sx={{ background: 'rgba(0, 0, 0, 0.15)' }}
      >
        <Fade in={loading ?? false}>
          <Box sx={style}>
            <CircularProgress size={70} color="inherit" />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default LoadingProvider;
