import { BaseEndpoints } from '@core/enums/enums';
import { baseQueryWithReauth } from './base-slice';
import { ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedList, Subscription } from '@core/constants/constants';

const descriptionTags = ['PlatformSubscription'] as readonly TagDescription<'PlatformSubscription'>[] & string[];

export const platformSubscriptionSlice = createApi({
  tagTypes: descriptionTags,
  reducerPath: 'platform-subscriptions',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPlatformSubscriptions: builder.query<ServiceResult<PaginatedList<Subscription[]>, ApiError>, any>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.Subscription}/base`,
      }),
      providesTags: descriptionTags,
    }),
  }),
});

export const { useGetPlatformSubscriptionsQuery } = platformSubscriptionSlice;
