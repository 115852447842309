import { useEffect, useState } from 'react';
import { DropdownOption } from '@/core/types';
import DropdownFull from '../Dropdown/DropdownFull';
import { useGetCountriesQuery } from '@/features/state/slices/api/service-slice';
import { CountryISO } from '@/core/enums/enums';

type Props = {
  error?: boolean | null;
  defaultVal?: string;
  onSelect?: (selected: DropdownOption) => void;
};

export default function CountrySelect({ defaultVal: value, onSelect, error = true }: Props) {
  const [countryOptions, setCountryOptions] = useState<DropdownOption[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<DropdownOption | null>(null);

  const handleCountrySelect = (option: DropdownOption) => {
    console.log('handle select fired');
    setSelectedCountry(option);

    if (onSelect) {
      onSelect(option);
    }
  };

  const {
    data: countryData,
    isFetching: isFetchingCountries,
    isError: isErrorCountries,
    isSuccess: isSuccessCountries,
  } = useGetCountriesQuery(
    {
      limit: 10,
      page: 1,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (!isErrorCountries && !isFetchingCountries && isSuccessCountries && countryData?.data != null) {
      const countries = countryData.data?.map((item) => ({ id: item.iso2, value: item.name }));
      setCountryOptions(countries);
      // console.log('data', countryData);
      var selected: DropdownOption;

      if (value) {
        console.log('value is here');
        const country = countryData.data?.find((p) => p.iso2 == value)!;
        console.log('country found', country);
        selected = { id: country.iso2, value: country.name };
        handleCountrySelect(selected);
      } else {
        selected = { id: countryData.data[0].iso2, value: countryData.data[0].name };
        handleCountrySelect(selected);
      }
    }
  }, [isErrorCountries, isSuccessCountries, isFetchingCountries]);
  console.log('Selected Country ', selectedCountry);
  return (
    <>
      <DropdownFull selected={selectedCountry} options={countryOptions} onSelect={handleCountrySelect} error={error} />
    </>
  );
}
