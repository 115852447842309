import Swal from 'sweetalert2';
import { getDomain } from '@core/utils/utils';
import { RootState } from '@features/state/reducers/combinedReducers';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export const baseQuery = fetchBaseQuery({
  validateStatus(response, body) {
    return [200, 201, 400, 409, 404].includes(response.status);
  },
  baseUrl: getDomain(),
  prepareHeaders: (headers, { getState, endpoint }) => {
    // headers.set("Content-Type", "multipart/form-data");
    const token = (getState() as RootState)?.auth?.auth?.token;
    // console.log(`TOKENN ${JSON.stringify(token?.accessToken)}`);
    if (token && endpoint != 'getCompanyAnalytics') {
      headers.set('Authorization', `Bearer ${token.accessToken}`);
    } else {
      headers.set('Authorization', String(headers.get('Authorization')));
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    localStorage.clear();
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Your session has expired. We are redirecting you to the login screen',
    }).then((result) => {
      console.log('AUTH STATE', (api.getState() as RootState)?.auth);
      window.location.replace('/auth/login');
    });
  }
  return result;
};
