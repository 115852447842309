import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import AppButton from '@/components/AppButton';
import { InputField } from '@/components/AppInput';
import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ConfirmModal from '@/features/shared/DeleteConfirmModal';
import { toggleLoading } from '@/features/state/slices/local/loading-slice';
import { ResetSalesAssociatePasswordSchema } from '@/core/constants/schemas';
import { useChangeSalesAssociatePasswordMutation } from '@/features/state/slices/api/company-slice';
import { SalesAssociateContext } from '../../../providers/SalesAssociateProvider';
import { useAuth } from '@/core/utils/utils';

export default function ChangeUserPassword() {
  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ResetSalesAssociatePasswordSchema),
  });

  const { user } = useAuth();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { salesAssociate, refetch } = useContext(SalesAssociateContext);

  const toggleSetModal = () => setModalOpen(!isModalOpen);
  const toggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const [changePassword] = useChangeSalesAssociatePasswordMutation();

  useEffect(() => {
    if (errors != null) {
      if (Object.keys(errors).length > 0) {
        toggleSetModal();
      }
    }
  }, [errors]);

  const changeUserPassword = async (body: any) => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await changePassword({ ...{ body }, companyId: user?.id, userId: salesAssociate?.id }).unwrap();

      if (!success || error) {
        toast.error(error!.message!);
        return;
      }
      reset();
      toggleSetModal();
      toast.success('User password successfully changed');
    } catch (err) {
      toast.error(String(err));
    } finally {
      dispatch(toggleLoading());
    }
  };

  return (
    <div className="p-6 col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-md border border-slate-200 dark:border-slate-700 mt-3">
      <div className="space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold ">Change Password</h2>

        <p className="text-slate-500">
          Please a new password and confirm the new password to update your sales associate's credentials. They will recieve an email that
          their password has been changed
        </p>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/2">
            <label className="block text-sm font-medium mb-1" htmlFor="business-id">
              New Password
            </label>
            <div className="relative">
              <InputField
                errors={errors?.newPassword}
                value={watch('newPassword')}
                type={showNewPassword ? 'text' : 'password'}
                onChange={(e) => setValue('newPassword', e.target.value)}
              />
              <button
                type="button"
                className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={toggleNewPassword}
              >
                {showNewPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
              </button>
            </div>

            <label className="block text-sm text-rose-500 mb-1 h-5">{errors?.newPassword?.message as any}</label>
          </div>
          <div className="sm:w-1/2">
            <label className="block text-sm font-medium mb-1" htmlFor="location">
              Confirm Password
            </label>
            <div className="relative">
              <InputField
                errors={errors?.confirmPassword}
                value={watch('confirmPassword')}
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={(e) => setValue('confirmPassword', e.target.value)}
              />
              <button
                type="button"
                className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={toggleConfirmPassword}
              >
                {showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
              </button>
            </div>
            <label className="block text-sm text-rose-500 mb-1 h-5">{errors?.confirmPassword?.message as any}</label>
          </div>
        </div>
        <div className="flex flex-col px-6 ">
          <div className="flex self-end">
            <AppButton onClick={toggleSetModal}>Proceed</AppButton>
          </div>
        </div>

        <ConfirmModal
          open={isModalOpen}
          closeModal={toggleSetModal}
          handleAction={handleSubmit(changeUserPassword)}
          title={'Are sure you want to change this users password?'}
          confirmationText={`This will immediately change this user's password `}
        />
      </div>
    </div>
  );
}
