import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { CssBaseline } from '@mui/material';
import Router from '@features/router/router';
import { getStripe } from '@core/utils/utils';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { toastOptions } from '@core/constants/constants';
import ThemeProvider from '../assets/theme/ThemeProvider';
import LoadingProvider from '@/components/Loading/Loading';
import ExperienceDialog from '@/components/ExperienceDialog';

import '../assets/css/styles.css';
import '../assets/css/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

type Props = {};

const stripePromise = loadStripe(getStripe()!);

const App: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider>
        <Router />
        <CssBaseline />
        <LoadingProvider />
        <ExperienceDialog />
        <Toaster toastOptions={toastOptions} />
      </ThemeProvider>
    </Elements>
  );
};
export default App;
