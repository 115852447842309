type Props = {
  children: React.ReactNode;
};

export function AnalyticsCard(props: Props) {
  return (
    <div className="col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-md border border-slate-200 dark:border-slate-700">
      {props.children}
    </div>
  );
}
