import { PaymentMethod, VideoPackage } from '@core/types';
import { useAuth } from '@core/utils/utils';
import { Box, Button, List, ListItem, Radio, RadioGroup } from '@mui/material';
import { useGetPaymentMethodsQuery } from '@features/state/slices/api/payment-methods.slice';
import { useGetVideoPackageByIdQuery } from '@features/state/slices/api/video-package-slice';
import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBIcon, MDBInput, MDBCardBody, MDBContainer } from 'mdb-react-ui-kit';
import { Shimmer } from 'react-shimmer';
import { AppInput } from '@/components/Styled/Input';
import VisaCard from '@assets/images/visa-card.jpg';
import MasterCard from '@assets/images/master-card.jpg';
import React, { useCallback, useEffect, useState } from 'react';
import PackageSubscriptionSkeleton from './components/PackageSubscriptionSkeleton';
import {
  useActivatePackageSubscriptionMutation,
  useCreatePackageSubscriptionMutation,
} from '@features/state/slices/api/subscriptions-slice';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useDispatch } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import SubscriptionActive from '../../assets/images/subscription.png';
import RequestService from '@core/services/RequestService';
import AppButton from '../AppButton';
import NoPaymentMethod from '../NoPayment';
import { useNavigate } from 'react-router';
import Routes from '@/features/router/routes';

type Props = {
  packageId: number;
  requestId: number;
  onApprove: () => void;
};

export const PackageSubscriptionDetails: React.FC<Props> = ({ onApprove, requestId, packageId }) => {
  const { user } = useAuth();

  const stripe = useStripe();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [width, setWidth] = useState(900);

  const [activateSubscription] = useActivatePackageSubscriptionMutation();

  const [subscriptionActivated, setSubscriptionActivated] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({} as any);

  const [videoPackage, setPackage] = useState<VideoPackage>(null as unknown as VideoPackage);

  const [createSubscription] = useCreatePackageSubscriptionMutation();

  const { data, isFetching, isError, isSuccess } = useGetVideoPackageByIdQuery({
    packageId,
  });

  const {
    data: paymentMethods,
    isError: paymentsError,
    isSuccess: paymentSuccess,
    isFetching: paymentMethodsLoading,
  } = useGetPaymentMethodsQuery(user?.id);

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setPackage(data.data!);
    }
  }, [isFetching, isError, isSuccess]);

  const node = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const subscribeToPackage = async () => {
    if (Object.keys(selectedPaymentMethod).length == 0) {
      toast.error('A payment method is required to subscribe to this package');
      return;
    }
    dispatch(toggleLoading());

    try {
      const result = await createSubscription({
        companyId: user?.id,
        packageId: videoPackage.id,
        stripeCustomerId: user?.stripeCustomerId,
        paymentMethodId: selectedPaymentMethod?.id,
      }).unwrap();

      if (result.success && !result?.data?.actionRequired) {
        toast.success('Your subscription is active');
      }

      if (result?.data?.actionRequired && result?.data?.action == 'REQUIRES_3DS') {
        const data = result?.data as any;

        try {
          const response = await stripe?.confirmCardPayment(data.data.clientSecret!);

          if (response?.error) {
            throw new Error();
          }
          const activationResult = await activateSubscription({
            id: data.data.subscriptionId,
          }).unwrap();

          if (!activationResult.success) {
            throw new Error();
          } else {
            setSubscriptionActivated(true);
            toast.success('Your subscription is active');

            const result = await RequestService.approvePackageRequest({
              requestId: requestId,
            });

            if (result.success) {
              onApprove();
              toast.success('Package request successfully approved');
            }
          }
        } catch (e: any) {
          console.log(e);
          toast.error('An error occured while activating subscription.');
        }
      }
    } catch (e: any) {
      toast.error('An error occured subscribing to this package');
    }
    dispatch(toggleLoading());
  };

  return (
    <MDBContainer ref={node}>
      {!subscriptionActivated ? (
        <React.Fragment>
          {isFetching ? (
            <PackageSubscriptionSkeleton width={width} />
          ) : (
            <div className="p-2">
              <div className="px-3 py-4 border-2 rounded  d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center">
                  <img src="https://i.imgur.com/S17BrTx.webp" className="rounded" width="60" />
                  <div className="d-flex flex-column ms-4">
                    <span className="h5 mb-1">{videoPackage?.packageName}</span>
                    <span className="small text-muted">{videoPackage?.packageDescription}</span>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <sup className="dollar font-weight-bold text-muted">$</sup>
                  <span className="h2 mx-1 mb-0">{videoPackage?.price}</span>
                  <span className="text-muted font-weight-bold mt-2">/ {videoPackage?.interval ?? 'month'}</span>
                </div>
              </div>
              <h4 className="mt-5">Payment details</h4>

              {paymentMethods?.data?.length == 0 && (
                <NoPaymentMethod
                  onNavigate={() => {
                    onApprove();
                    navigate(`/${Routes.App.Settings.Billing}`);
                  }}
                />
              )}

              <RadioGroup aria-label="Your plan" name="people" defaultValue="Individual">
                {paymentMethodsLoading ? (
                  <Box mb={5}>
                    {' '}
                    <Shimmer width={width} height={50} />
                  </Box>
                ) : (
                  <Box mt={2}>
                    {paymentMethods?.data?.map((item: any, index: number) => (
                      <Box display="flex">
                        <Radio
                          value={item}
                          checked={selectedPaymentMethod?.id === item.id}
                          onClick={() => setSelectedPaymentMethod(item)}
                          sx={{}}
                        />

                        <div className="">
                          <div className="d-flex flex-row align-items-center">
                            <img width="70" className="rounded" src={item?.card?.brand === 'visa' ? VisaCard : MasterCard} />
                            <div className="d-flex flex-column ms-3">
                              <span className="h5 mb-1">{item?.card?.brand.toUpperCase()}</span>
                              <span className="small text-muted">ending in **** {item?.card?.last4}</span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    ))}
                  </Box>
                )}
              </RadioGroup>

              <Box mb={3} style={{ float: 'right' }}>
                <AppButton disabled={paymentMethods?.data?.length == 0} className="mt-3" onClick={subscribeToPackage}>
                  Subscribe
                </AppButton>
              </Box>
            </div>
          )}
        </React.Fragment>
      ) : (
        <MDBCardBody className="p-md-5 text-center">
          <div>
            <h4>Your Subscription is active</h4>
            <p className="text-muted pb-2">Please make the payment to start utlizing this package as soon as possible</p>
            <img src={SubscriptionActive} height={400} width={500} />
          </div>
        </MDBCardBody>
      )}
    </MDBContainer>
  );
};
