import { CustomVideo, Video } from '@core/types';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { baseQueryWithReauth } from './base-slice';
import { ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedList } from '@/core/constants/constants';

const descriptionTags = ['CustomVideo'] as readonly TagDescription<'CustomVideo'>[] & string[];

export const customVideoSlice = createApi({
  tagTypes: descriptionTags,
  baseQuery: baseQueryWithReauth,
  reducerPath: BaseEndpoints.CustomVideo,

  endpoints: (builder) => ({
    getCompanyCustomVideos: builder.query<ServiceResult<PaginatedList<CustomVideo[]>, ApiError>, any>({
      query: (args) => {
        return { params: { ...args.query }, url: `/${BaseEndpoints.CustomVideo}/v2/${args.id}` };
      },
      providesTags: descriptionTags,
    }),
    // verifyCustomVideoAccess: builder.query<ServiceResult<boolean, ApiError>, any>({
    //   query: (args: any) => ({
    //     url: `/${BaseEndpoints.CustomVideo}/verify/${args.companyId}`,
    //   }),
    //   providesTags: descriptionTags,
    // }),
    deleteCompanyCustomVideo: builder.mutation({
      query: (args) => ({
        method: ApiMethods.DELETE,
        url: `/${BaseEndpoints.CustomVideo}/${args.videoId}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    searchCompanyCustomVideos: builder.query<ServiceResult<PaginatedList<CustomVideo[]>, ApiError>, any>({
      query: (args) => {
        console.log(`QUERY SENT ${JSON.stringify(args)} `);
        return {
          params: { ...args },
          url: `/${BaseEndpoints.CustomVideo}/search/${args.id}`,
        };
      },
      providesTags: descriptionTags,
    }),
  }),
});

export const {
  // useVerifyCustomVideoAccessQuery,
  useGetCompanyCustomVideosQuery,
  useDeleteCompanyCustomVideoMutation,
  useLazySearchCompanyCustomVideosQuery,
} = customVideoSlice;
