import { persistStore, FLUSH, PAUSE, PURGE, PERSIST, REGISTER, REHYDRATE } from 'redux-persist';
import { AnyAction, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { videoSlice } from '../slices/api/video-slice';
import { requestSlice } from '../slices/api/request-slice';
import { companySlice } from '../slices/api/company-slice';
import { locationSlice } from '../slices/api/location-slice';
import { analyticsSlice } from '../slices/api/analytics-slice';
import { authenticationSlice } from '../slices/api/auth-slice';
import { videoCreditSlice } from '../slices/api/video-credit-slice';
import { userLocationSlice } from '../slices/api/user-location-slice';
import { subscriptionSlice } from '../slices/api/subscriptions-slice';
import { videoPackageSlice } from '../slices/api/video-package-slice';
import { notificationSlice } from '../slices/api/notifications.slice';
import { paymentMethodSlice } from '../slices/api/payment-methods.slice';
import { packageRequestSlice } from '../slices/api/package-request.slice';
import { persistedReducer, RootState } from '../reducers/combinedReducers';
import { assignedPackageSlice } from '../slices/api/assigned-packages-slice';
import { pendingInviteSlice } from '../slices/api/pending-invites.slice';
import { platformSubscriptionSlice } from '../slices/api/platform-subscription-slice';
import { customVideoSlice } from '../slices/api/custom-video-slice';
import { videoAnalyticsSlice } from '../slices/api/video-analytics-slice';
import { serviceSlice } from '../slices/api/service-slice';
import { feedBackSlice } from '../slices/api/feedback-slice';
import { productsSlice } from '../slices/api/product-slice';

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
const store: Store<RootState, any> & {
  dispatch: any;
} = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      thunk,
      videoSlice.middleware,
      serviceSlice.middleware,
      companySlice.middleware,
      requestSlice.middleware,
      feedBackSlice.middleware,
      locationSlice.middleware,
      analyticsSlice.middleware,
      videoCreditSlice.middleware,
      customVideoSlice.middleware,
      notificationSlice.middleware,
      subscriptionSlice.middleware,
      videoPackageSlice.middleware,
      userLocationSlice.middleware,
      paymentMethodSlice.middleware,
      pendingInviteSlice.middleware,
      authenticationSlice.middleware,
      videoAnalyticsSlice.middleware,
      packageRequestSlice.middleware,
      assignedPackageSlice.middleware,
      platformSubscriptionSlice.middleware,
      productsSlice.middleware,
    ]),

  enhancers: [],
});

const persistor = persistStore(store);

export { store, persistor };
