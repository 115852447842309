import * as yup from 'yup';

const requiredText = 'This is a required field';

const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`;
};

export const AuthenticationSchema = yup.object().shape({
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),
  password: yup.string().required('Password is required').min(8, 'Must be at least 8 characters'),
});

export const BuyCreditSchema = yup.object().shape({
  paymentMethodId: yup.string().required('A selected payment method is required'),

  creditAmount: yup.number().required('Credit amount is required').min(1),
});

export const BuyProductSchema = yup.object().shape({
  paymentMethodId: yup.string().required('A selected payment method is required'),
});

export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),
});

export const RegisterSchema = yup.object().shape({
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),
  firstName: yup.string().max(255).required('First Name is required'),
  lastName: yup.string().max(255).required('Last Name is required'),
  password: yup.string().required('Password is required').min(8, 'Must be at least 8 characters'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password'), null], 'Passwords do not match'),
  terms: yup.boolean(),
});

export const VideoSchema = yup.object().shape({
  price: yup.string().required('Price is required'),
  name: yup.string().required('Video Name is required').max(50, '50 characters maximum'),
  /*file: yup.mixed().test("value", "Video is required", (value) => {
    if (value.length === 0) return false;
    return true;
  }),*/
});

export const CreateAdminSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),

  phoneNumber: yup.string().required('Phone number is required'),
  role: yup.string().required('Role is required').default('admin'),
  password: yup.string().required('Password is required').min(8, 'Must be at least 8 characters'),
});

//

export const CreateCompanySchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),

  phoneNumber: yup.string().required('Phone number is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  addressLine1: yup.string().required('Address Line 1 is required'),
  addressLine2: yup.string(),
  postalCode: yup.string().required('Postal Code is required'),
  city: yup.string().required('City is required'),
});

export const PaymentMethodSchema = yup.object().shape({
  number: yup.string().required(requiredText).matches(/[0-9]/, 'Card number must only contain numeric digits'),

  expMonth: yup
    .string()
    .required(requiredText)
    .matches(/[0-9]/, 'Expiration month must only contain numeric digits')
    .length(2, 'Expiration month must be 2 digits'),

  expYear: yup
    .string()
    .required(requiredText)
    .matches(/[0-9]/, 'Expiration year must only contain numeric digits')
    .length(4, 'Expiration year must be 4 digits'),

  cvc: yup.string().required(requiredText).matches(/[0-9]/, 'CVC must only contain numeric digits').length(3, 'CVC must be 3 digits'),

  name: yup.string().required(requiredText),

  city: yup.string().required(requiredText),

  state: yup.string().required(requiredText),

  country: yup.string().required(requiredText),

  line1: yup.string().required(requiredText),

  line2: yup.string(),

  postalCode: yup.string().required(requiredText),

  phoneNumber: yup.string(),

  email: yup.string().email('Email address is invalid').max(255).required(requiredText),
});

export const PaymentMethodEditSchema = yup.object().shape({
  name: yup.string().required(requiredText),

  city: yup.string().required(requiredText),

  state: yup.string().required(requiredText),

  country: yup.string().required(requiredText),

  line1: yup.string().required(requiredText),

  line2: yup.string(),

  postalCode: yup.string().required(requiredText),

  phoneNumber: yup.string(),

  email: yup.string().email('Email address is invalid').max(255).required(requiredText),
});

export const CompanyDetailsEditSchema = yup.object().shape({
  city: yup.string().required(requiredText),
  email: yup.string().required(requiredText),
  state: yup.string().required(requiredText),
  country: yup.string().required(requiredText),
  postalCode: yup.string().required(requiredText),
  phoneNumber: yup.string().required(requiredText),
  addressLine1: yup.string().required(requiredText),
  addressLine2: yup.string(),
  companyName: yup.string().required(requiredText),
});

export const SetPasswordSchema = yup.object().shape({
  oldPassword: yup.string().max(255).required('Current password is required'),
  newPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, getCharacterValidationError('symbol')),
  confirmPassword: yup
    .string()
    .required('Confim Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
export const ResetPasswordSchema = yup.object().shape({
  oldPassword: yup.string().required('Old password is required'),
  newPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, getCharacterValidationError('symbol')),

  confirmPassword: yup
    .string()
    .required('Confim Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export const ForgotPasswordResetSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Must be at least 8 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password must contain at least one uppercase letter, one number, and one special character',
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const SalesAssociateSchema = yup.object().shape({
  firstName: yup.string().required(requiredText),
  lastName: yup.string().required(requiredText),
  username: yup.string().required(requiredText),
  blocked: yup.boolean().required(requiredText),
  phoneNumber: yup.string().required(requiredText),
  canUseCredits: yup.boolean().required(requiredText),
  email: yup.string().email('Email address is invalid').max(255).required(requiredText),
});

export const ResetSalesAssociatePasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
  confirmPassword: yup
    .string()
    .required('Confim Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
