import { Box, useTheme } from '@mui/material';
import AppButton from '@/components/AppButton';
import ModalBasic from '@/components/AppModal/ModalBasic';

interface ModalProps {
  open: boolean;
  title: string;
  closeModal: () => void;
  confirmationText: string;
  handleAction: () => void;
  confirmButtonClass?: string;
}

const ConfirmModal = ({ open, closeModal, title, confirmationText, handleAction, confirmButtonClass }: ModalProps) => {
  const theme = useTheme();
  return (
    <>
      <ModalBasic width="w-1/4" title={title} isOpen={open} setIsOpen={closeModal} subtitle={confirmationText}>
        <Box p={3} className="flex float-right space-x-2">
          <AppButton style={{ backgroundColor: 'transparent', color: 'black' }} onClick={closeModal}>
            <span className="text-slate-500">Cancel</span>
          </AppButton>
          <AppButton className={` ${confirmButtonClass ? confirmButtonClass : 'bg-red-500 hover:bg-red-400'} `} onClick={handleAction}>
            Confirm
          </AppButton>
        </Box>
      </ModalBasic>
    </>
  );
};

export default ConfirmModal;
