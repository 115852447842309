import React from 'react';

export function InputField(
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { errors?: any },
) {
  return (
    <input
      {...props}
      style={{ backgroundColor: '#FBFBFB', borderRadius: 4 }}
      className={`form-input font-normal text-slate-600 w-full text-sm leading-5 py-2 px-3 border-[1.5px] border-slate-200 ${
        props.errors?.email ? 'border-rose-500' : ''
      } focus:border-blue-300 `}
    />
  );
}
