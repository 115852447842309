import Logo from '@assets/images/small-logo.png';
import { Link, Typography } from '@mui/material';
import AuthImage from '@/assets/images/auth-image.jpg';
import { BackButton } from '@/components/BackButton';
import useResponsive from '@/core/hooks/useResponsive';
import TransitionWrapper from '@/components/TransitionWrapper';

type Props = {
  children: React.ReactNode;
  allowBackNavigation?: boolean;
};

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit">Sales Assist</Link> {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const AuthLayout = ({ children, allowBackNavigation = false }: Props) => {
  const isDesktop = useResponsive('up', 'lg', 'sm');

  return (
    <div className="min-h-[100vh] flex items-center justify-center bg-white dark:bg-slate-900">
      <div className="flex w-full">
        <div className={`w-1/2 h-screen ${!isDesktop && 'hidden'}`} aria-hidden="true">
          {/* <video style={{ borderRadius: 0, objectFit: 'cover' }} className="login__video-grid" autoPlay muted loop>
            <source src={RoofingVideo} type="video/mp4" />
          </video> */}
          <img src={AuthImage} className="h-full w-full object-cover" />
        </div>

        <div className={`${isDesktop ? 'w-1/2' : 'w-full'}`}>
          <div className="px-4 flex flex-col justify-between">
            <div className="flex justify-between items-center">
              {allowBackNavigation ? <BackButton /> : <div />}
              <div className="flex items-center justify-end h-16 px-8 sm:px-6 lg:px-8 space-x-4">
                <img crossOrigin="anonymous" src={Logo} height={50} width={60} alt="Logo" />
              </div>
            </div>
            <div className="mt-[25vh]">
              <TransitionWrapper>{children}</TransitionWrapper>{' '}
            </div>
          </div>
          {/* <Copyright /> */}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
