import PackageCard from './PackageCard';
import { Grid } from '@mui/material';
import { VideoPackage } from '@core/types';

type Props = {
  packages: VideoPackage[];
};

export default function PackageList(props: Props) {
  const { packages, ...other } = props;

  return (
    <Grid container spacing={15} {...other}>
      {packages.map((videoPackage) => (
        <Grid key={videoPackage.id} item xs={12} sm={6} md={3}>
          <PackageCard package={videoPackage} />
        </Grid>
      ))}
    </Grid>
  );
}
