import { BackButton } from '@/components/BackButton';
import TransitionWrapper from '@/components/TransitionWrapper';
import { PurchasableProduct, RequestableProduct, StepProps } from '@/core/types';
import { useGetProductsQuery, useHasCompanyPurchasedProductQuery } from '@/features/state/slices/api/product-slice';
import { Box, Card, CircularProgress, Typography } from '@mui/material';
import React from 'react';

import { SubscriptionPlan } from '@/core/constants/constants';
import Center from '@/components/Center/Center';
import { ProductsDisplay } from '@/features/pages/Products/ProductsDisplay';
import { Navigator } from '../Navigator';

export const ChooseProducts = (props: StepProps) => {
  const { data: products, isFetching, isSuccess, isError } = useGetProductsQuery();
  const { data, steps, activeStep, onCapture, handleBack, handleNext, handleSkip } = props;
  return (
    <TransitionWrapper>
      <Card sx={{ padding: 3 }}>
        <Box sx={{ width: '100% ' }}>
          <Box display={'flex'}>
            <Typography ml={1} variant="h2">
              Our Products
            </Typography>
          </Box>
          <Typography variant="subtitle1" mt={1}>
            {'Here you can purchase or request our products ( You can purchase them any time on our products page! )'}
          </Typography>
          <br></br>
          {products?.data ? (
            <ProductsDisplay products={products!.data.items} />
          ) : (
            <Center>
              <CircularProgress />
            </Center>
          )}
        </Box>
        <Navigator steps={steps} handleSkip={handleSkip} handleBack={handleBack} activeStep={activeStep} handleNext={handleNext} />
      </Card>
    </TransitionWrapper>
  );
};
