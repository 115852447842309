import { baseQueryWithReauth } from './base-slice';
import { PackageRequest } from '@core/types';
import { PaginatedList } from '@core/constants/constants';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { ActionResult, ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { toQueryString } from '@/core/utils/utils';

const descriptionTags = ['PackageRequest'] as readonly TagDescription<'PackageRequest'>[] & string[];

export const packageRequestSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  reducerPath: 'package-request',
  endpoints: (builder) => ({
    getPackageRequests: builder.query<ServiceResult<PaginatedList<PackageRequest[]>, ApiError>, any>({
      query: (args) => ({
        url: `${BaseEndpoints.Request}/package/${args.companyId}?${toQueryString(args)}`,
      }),
      providesTags: descriptionTags,
    }),
    createPackageRequest: builder.mutation({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `${BaseEndpoints.Request}/package` }),
      invalidatesTags: descriptionTags,
    }),
    approvePackageRequest: builder.mutation({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `${BaseEndpoints.Request}/package/approve` }),
      invalidatesTags: descriptionTags,
    }),
    rejectPackageRequest: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `${BaseEndpoints.Request}/package/reject` }),
      invalidatesTags: descriptionTags,
    }),
  }),
});

export const {
  useGetPackageRequestsQuery,
  useApprovePackageRequestMutation,
  useRejectPackageRequestMutation,
  useCreatePackageRequestMutation,
} = packageRequestSlice;
