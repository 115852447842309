import React from 'react';
import Iconify from '@components/iconify copy';
import { useNavigate } from 'react-router';
import Center from '@components/Center/Center';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

type Props = {};

export const NoVideos: React.FC<Props> = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToCreateVideos = () => {
    navigate('upload');
  };
  return (
    <Box>
      <Grid container justifyContent="center">
        <Center>
          <Iconify
            icon="mdi:video-youtube"
            sx={{
              width: 200,
              height: 200,
              color: theme.colors.primary.dark,
            }}
          />
          <Typography mt={1} textAlign="center" variant="subtitle2">
            No custom videos are currently on the system
          </Typography>

          <Box mt={2}>
            <Button variant="outlined" onClick={navigateToCreateVideos} startIcon={<AddTwoToneIcon fontSize="small" />}>
              Upload video
            </Button>
          </Box>
        </Center>
      </Grid>
    </Box>
  );
};
