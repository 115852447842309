import styled, { keyframes } from "styled-components";
import {
  fadeInDown,
  fadeInRight,
  Animation,
  fadeInLeft,
} from "react-animations";
import { Box } from "@mui/material";

type Props = {
  children: any;
  duration?: number;
  animation?: Animation;
};

const SlideDiv = styled.div<{ animation: Animation; duration: number }>`
  animation: ${({ duration }) => duration}s
    ${({ animation }) => keyframes`${animation}`};
`;

function TransitionWrapper({ children, animation, duration }: Props) {
  return (
    <SlideDiv duration={duration!} animation={animation!}>
      <Box>{children}</Box>
    </SlideDiv>
  );
}

TransitionWrapper.defaultProps = {
  duration: 0.5,
  animation: fadeInRight,
};
export default TransitionWrapper;

// import styled, { keyframes } from "styled-components";
// import Slide from "@mui/material/Slide";
// import { Box } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import { fadeInDown, fadeInRight, Animation, fadeInLeft } from "react-animations";

// type Props = {
//   children: any;
//   duration?: number;
//   animation?: Animation;
// };

// const slideAnimation = keyframes`${fadeInDown}`;

// const SlideDiv = styled.div<{ animation: Animation; duration: number }>`
//   animation: ${({ duration }) => duration}s
//     ${({ animation }) => keyframes`${animation}`};
// `;

// /*
// NB: I re-created the slide animation for dashboard routes
// using mui components since we use it for most things
// */
// function TransitionWrapper({ children }: Props) {
//   const theme = useTheme();

//   return (
//     <>
//       <Slide
//         direction="left"
//         appear
//         in
//         timeout={200}
//         easing={{
//           enter: theme.transitions.easing.easeIn,
//           exit: theme.transitions.easing.easeOut,
//         }}
//         mountOnEnter
//         unmountOnExit
//       >
//         <Box>{children}</Box>
//       </Slide>
//     </>
//   )
// }

// /* function TransitionWrapper({ children, animation, duration }: Props) {
//   return (
//     <SlideDiv duration={duration!} animation={animation!}>
//       {children}
//     </SlideDiv>
//   );
// }

// TransitionWrapper.defaultProps = {
//   duration: 0.5,
//   animation: fadeInRight,
// }; */
// export default TransitionWrapper;
