import React, { useState } from 'react';
import styled from 'styled-components';
import FlipNumbers from 'react-flip-numbers';
import { Box, Typography } from '@mui/material';
import ArrowUp from '@assets/images/arrow_up.png';
import ArrowDown from '@assets/images/arrow_down.png';
import { VideoAnalyticsStatistics } from '@core/types';
import { AnalyticsHeaderBox, AnimatedIndicator } from '@/components/Styled';

type AnalyticsHeaderItemProps = {
  index: number;
  labelSuffix?: string | undefined;
  currentAnalyticMetric: VideoAnalyticsStatistics;
  selectedAnalyticMetric?: VideoAnalyticsStatistics | null | undefined;
  onSelect: (metric: VideoAnalyticsStatistics | null | undefined) => void;
};

const AnalyticsIcon = styled.img`
  width: 26px;
  height: 26px;
  margin-left: 12px;
`;

export function AnalyticsHeaderItem({
  index,
  onSelect,
  labelSuffix,
  currentAnalyticMetric,
  selectedAnalyticMetric,
}: AnalyticsHeaderItemProps) {
  const [clickedIndex, setClickedIndex] = useState<number>(0);
  const active = currentAnalyticMetric === selectedAnalyticMetric;

  const { title, subtitle, hasIncreased, value, key } = currentAnalyticMetric;
  const isTime = key.toLowerCase().includes('time') && !isNaN(value);
  // const isTime = false;
  const onKeySelect = () => {
    setClickedIndex(index);
    onSelect(currentAnalyticMetric);
  };

  // Function to convert minutes to hh:mm:ss
  const formatTime = (minutes: number) => {
    const totalSeconds = Math.floor(minutes * 60);
    const hours = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const formattedValue = isTime ? formatTime(value) : value;

  return (
    <AnalyticsHeaderBox
      active={active}
      onClick={onKeySelect}
      sx={{
        // Make the box responsive by adjusting its size based on screen width
        width: {
          xs: '100%', // Full width on extra small screens (iPad size)
          sm: '48%', // Slightly smaller on small screens
          md: '33%', // One-third width on medium screens (like tablets)
          lg: '25%', // Quarter width on larger screens
        },
        // Add margin and padding to ensure spacing
        padding: { xs: 1, sm: 2 },
        margin: { xs: '8px 0', sm: '8px' }, // Ensure space between the boxes
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        fontSize={{
          xs: 14, // Smaller font for small screens
          sm: 16, // Default font size for medium and above
        }}
        fontWeight={600}
        color="grey"
      >
        {title}
      </Typography>
      <Box display="flex" alignItems="center" mt={2} flexWrap="wrap">
        {isTime ? (
          <Typography
            fontSize={{
              xs: 20, // Smaller font size for mobile/tablet
              sm: 25, // Larger font size for bigger screens
            }}
          >
            {formattedValue}
          </Typography>
        ) : !isNaN(value) ? (
          <CustomFlipNumbers value={value} />
        ) : (
          <Typography
            fontSize={{
              xs: 20, // Smaller font size for mobile/tablet
              sm: 25, // Larger font size for bigger screens
            }}
          >
            {value}
          </Typography>
        )}
        {labelSuffix && (
          <Typography ml={1} mr={1} fontSize={14}>
            {isTime ? 'hrs:mins:secs' : labelSuffix}
          </Typography>
        )}
        <AnalyticsIcon src={hasIncreased ? ArrowUp : ArrowDown} />
      </Box>
      <Typography
        color="grey"
        mt={1}
        fontSize={{
          xs: 10, // Small font size for subtitles on smaller screens
          sm: 12, // Default font size for larger screens
        }}
      >
        {subtitle}
      </Typography>
      {active && <AnimatedIndicator />}
    </AnalyticsHeaderBox>
  );
}

const CustomFlipNumbers = ({ value }: { value: number }) => {
  const [integerPart, decimalPart] = String(value).split('.');

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FlipNumbers
        play
        width={22}
        height={35}
        color="black"
        duration={3}
        numbers={integerPart}
        background="transparent"
        numberStyles={{ textAlign: 'center', fontWeight: 'bold' }}
      />
      {decimalPart && <span style={{ fontSize: '35px', fontWeight: 'bold', margin: '0 5px' }}>.</span>}
      {decimalPart && (
        <FlipNumbers
          play
          width={22}
          height={35}
          color="black"
          duration={3}
          numbers={decimalPart}
          background="transparent"
          numberStyles={{ textAlign: 'center', fontWeight: 'bold' }}
        />
      )}
    </div>
  );
};
