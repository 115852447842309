import { useEffect, useState } from 'react';
import { TabValues } from '@core/enums/enums';
import AppRoutes from '@/features/router/routes';
import TransitionWrapper from '@/components/TransitionWrapper';
import { Tabs, Tab, Grid, Box, Typography } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AnalyticsTokenProvider from './providers/AnalyticsTokenProvider';
import { Helmet } from 'react-helmet-async';

const VideoAnalyticsDashboard = () => {
  const location = useLocation();

  const baseUrl = window.location.origin;

  const queryParams = new URLSearchParams(location.search);

  const urlParts = window.location.pathname.split('/');

  const lastSegment = urlParts[urlParts.length - 1];

  const secret = queryParams.get('secret');

  const isAdminViewing = secret != null;

  const [currentTab, setCurrentTab] = useState(lastSegment);

  const tabs = [
    { value: TabValues.CustomVideo, label: 'Custom Videos' },
    { value: TabValues.Videos, label: 'Videos' },
  ];

  const route = `${isAdminViewing ? '/external/analytics' : `/${AppRoutes.AppRoot}/${AppRoutes.Analytics}`}`;

  const handleTabsChange = (event: any, value: any) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    let value = TabValues.CustomVideo;

    if (window.location.pathname.includes(`${route}/${TabValues.Videos}`)) {
      value = TabValues.Videos;
    }
    setCurrentTab(value);
  }, []);

  return (
    <TransitionWrapper>
      <Helmet>
        <title>Analytics</title>
      </Helmet>
      <AnalyticsTokenProvider>
        <Box sx={{ p: isAdminViewing ? 4 : 0, width: '100%', margin: '0 auto' }}>
          <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3" textAlign={'left'} fontSize={35}>
                Analytics
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={currentTab}
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                onChange={handleTabsChange}
              >
                {tabs.map((tabItem) => (
                  <Tab
                    key={tabItem.value}
                    value={tabItem.value}
                    label={tabItem.label}
                    onClick={() => {
                      const url = `${baseUrl}${route}/${tabItem.value}${isAdminViewing ? '?secret=' + secret : ''}`;

                      window.location.replace(url);
                    }}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={12} justifyContent={'center'}>
              <Outlet />
            </Grid>
          </Grid>
        </Box>
      </AnalyticsTokenProvider>
    </TransitionWrapper>
  );
};

export default VideoAnalyticsDashboard;
