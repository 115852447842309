import { useState } from 'react';
import React, { Fragment } from 'react';
import { useAuth } from '@core/utils/utils';
import AddIcon from '@mui/icons-material/Add';
import AppButton from '@/components/AppButton';
import Center from '@/components/Center/Center';
import CreditCard from '@/components/CreditCard';
import TransitionWrapper from '@/components/TransitionWrapper';
import PaymentMethodsForm from '../components/PaymentMethodForm';
import PaymentMethodsList from '../components/PaymentMethodsList';
import PaymentMethodsHeader from '../components/PaymentMethodsHeader';
import { Box, Button, Card, CircularProgress, Slide, Typography } from '@mui/material';
import { useGetPaymentMethodsQuery, useRemovePaymentMethodMutation } from '@features/state/slices/api/payment-methods.slice';
import { Helmet } from 'react-helmet-async';

const Transition = React.forwardRef(function Transition(props, ref) {
  const SlideComponent = Slide as any;
  return <SlideComponent direction="up" ref={ref} {...props} />;
});

function Billing() {
  const { user } = useAuth();
  const { data: paymentMethods, isFetching, refetch } = useGetPaymentMethodsQuery(user?.id);
  const [paymentDialog, setPaymentDialog] = useState(false);

  const toggleDialog = () => {
    setPaymentDialog(!paymentDialog);
  };

  const hasPaymentMethods = paymentMethods?.data?.length > 0;

  return (
    <div className="grow">
      <Helmet>
        <title>Settings - Billing</title>
      </Helmet>
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <div>
          <div className="flex justify-between">
            <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-4">Billing</h2>
            {!hasPaymentMethods ? <div /> : <AppButton onClick={toggleDialog}>Add Card</AppButton>}
          </div>

          <div className="text-sm">Welcome to the Billing section, where you can easily manage your payment methods and credit cards.</div>
        </div>

        {paymentDialog ? (
          <Box>
            <PaymentMethodsForm close={toggleDialog} />
          </Box>
        ) : (
          <Fragment>
            <Box sx={{ py: 2 }}>
              {isFetching ? (
                <CircularProgress />
              ) : hasPaymentMethods ? (
                <PaymentMethodsList refetch={refetch} paymentMethods={paymentMethods?.data ?? []} />
              ) : (
                <Center>
                  <CreditCard />
                  <Typography variant="subtitle1" mt={1} mb={1}>
                    You have no available payment methods
                  </Typography>
                  <AppButton onClick={toggleDialog}>Add Card</AppButton>
                </Center>
              )}
            </Box>
          </Fragment>
        )}
      </div>

      <footer>
        <div className="flex flex-col px-6 py-5 "></div>
      </footer>
    </div>
  );
}

export default Billing;
