import { useEffect, useState } from 'react';
import { DropdownOption } from '@/core/types';
import DropdownFull from '../Dropdown/DropdownFull';
import { useGetProvincesByCountryQuery } from '@/features/state/slices/api/service-slice';

type ProvinceSelectProps = {
  value?: string | null;
  error?: boolean | null;
  countryIso?: string | null;
  onSelect: (option: DropdownOption) => void;
};

export default function ProvinceSelect({ countryIso, onSelect, value, error = true }: ProvinceSelectProps) {
  const [provinceOptions, setProvinceOptions] = useState<DropdownOption[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<DropdownOption | null>(null);

  const { data, isFetching, isError, isSuccess } = useGetProvincesByCountryQuery({ iso: countryIso }, { refetchOnMountOrArgChange: true });

  const handleProvinceSelect = (option: DropdownOption) => {
    // console.log('Province Select ', option);
    onSelect(option);
    setSelectedProvince(option);
  };
  useEffect(() => {
    if (!isError && !isFetching && isSuccess && data?.data != null && data?.data?.length > 0) {
      const provinces = data?.data.map((item) => ({ id: item.iso2, value: item.name }));
      provinces.sort((a, b) => {
        if (a.value === b.value) {
          return 0;
        }
        if (a.value < b.value) {
          return -1;
        }

        return 1;
      });
      setProvinceOptions(provinces);
    }
  }, [isError, isSuccess, isFetching, countryIso]);

  useEffect(() => {
    // console.log('Value recieved ', value);
    if (value) {
      const selected = provinceOptions.find((o) => o.id == value);

      if (selected) {
        handleProvinceSelect(selected);
      }
    } else {
      handleProvinceSelect(provinceOptions[0]);
    }
  }, [value, provinceOptions]);

  // console.log('Selected Province', selectedProvince);

  return <DropdownFull options={provinceOptions} onSelect={handleProvinceSelect} selected={selectedProvince} error={error} />;
}
