import moment from 'moment';
import { useState } from 'react';
import Masonry from '@mui/lab/Masonry';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { VideoPackage } from '@core/types';
import AppChip from '@/components/AppChip';
import { styled } from '@mui/material/styles';
import Routes from '@/features/router/routes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Card, Stack, Button, Typography } from '@mui/material';
import AppButton from '@/components/AppButton';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const StyledSubscribeButton = styled(Button)({
  right: 0,
  bottom: 0,
  zIndex: 1,
  height: 40,
  width: '40%',
  top: '50%',
  left: '50%',
  color: 'white',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
});

type Props = {
  package: VideoPackage;
};

export default function PackageCard(props: Props) {
  const {
    package: { packageName, packageLength, packageDescription, videos, price, discount, keywords, id },
  } = props;

  const navigate = useNavigate();

  let options = {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 2,
  };
  let formatter = new Intl.NumberFormat('en-US', options);

  const [isHovering, setIsHovering] = useState(false);

  const handleViewVideoPackage = () => {
    navigate(`/${Routes.App.MyContent.Packages}/${id}`);
  };

  return (
    <Card sx={{ width: 470 }}>
      <Box
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={handleViewVideoPackage}
        sx={{ position: 'relative', cursor: 'pointer', p: 1 }}
      >
        {/* <StyledProductImg alt={packageName} src={videos[0]?.highResolutionThumbnail} /> */}
        <Masonry columns={3}>
          {videos.slice(0, 3).map((item, index) => (
            <div key={index}>
              <img
                src={`${item.videoThumbnail}`}
                srcSet={`${item.videoThumbnail}`}
                loading="lazy"
                style={{
                  width: 250,
                  height: 150,
                  borderRadius: 5,
                  display: 'block',
                  objectFit: 'cover',
                }}
              />
            </div>
          ))}
        </Masonry>

        {isHovering && (
          <StyledSubscribeButton color="inherit" variant="outlined">
            View
          </StyledSubscribeButton>
        )}
      </Box>

      <Stack spacing={1} direction="column" sx={{ p: 1, pb: 2 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            noWrap
            variant="h3"
            component={Link}
            to={''}
            sx={{
              color: 'inherit',
              cursor: 'pointer',
              underline: 'hover',
              textDecoration: 'none',
              '&:hover': {
                color: 'inherit',
                textDecoration: 'underline',
              },
            }}
          >
            {packageName}
          </Typography>

          <Box display="flex" justifyContent="center" alignItems="center" ml={1}>
            <AccessTimeIcon sx={{ fontSize: 13 }} />
            <Typography ml={0.5} variant="subtitle1" noWrap>
              {moment.utc(packageLength).format('mm:ss')}
            </Typography>
          </Box>
        </Box>
        <Typography textOverflow={'ellipsis'} variant="subtitle1" noWrap>
          {packageDescription}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div>
            {keywords?.split(',').map((word, index) => (
              <AppChip key={index} sx={{ mr: 0.5, mb: 0.5 }}>
                {word}
              </AppChip>
            ))}
          </div>

          <AppButton onClick={handleViewVideoPackage} style={{ height: 36, fontSize: 13 }}>
            View
          </AppButton>
        </Box>
      </Stack>
    </Card>
  );
}
