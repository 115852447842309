import jwt_decode from 'jwt-decode';
import toast from 'react-hot-toast';
import { Roles } from '@core/enums/enums';
import { useLocation } from 'react-router';
import { useAuth } from '@core/utils/utils';
import React, { createContext, useEffect, useState } from 'react';
import { useDecodeSecretMutation } from '@features/state/slices/api/auth-slice';
import Center from '@/components/Center/Center';
import { CircularProgress } from '@mui/material';

interface AnalyticsTokenContextProps {
  analyticsAuth?: AnalyticsAuthProps | null;
}

interface AnalyticsAuthProps {
  companyId: number | null;
  token: string | null | undefined;
}

export const AnalyticsTokenContext = createContext<AnalyticsTokenContextProps>({
  analyticsAuth: null,
});

type Props = {
  children: React.ReactNode;
};
const AnalyticsTokenProvider: React.FC<Props> = ({ children }: Props) => {
  const auth = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [decodeSecret] = useDecodeSecretMutation();
  const queryParams = new URLSearchParams(location.search);
  const [analyticsAuth, setAnalyticsAuth] = useState<AnalyticsAuthProps | null>(null);

  const intializeAnalytics = async () => {
    let companyId: number | null = null;
    let token: string | null | undefined;

    // Accessing query parameters
    const secret = queryParams.get('secret');

    if (secret) {
      const { success, data } = await decodeSecret({ secret }).unwrap();

      if (!success) {
        toast.error('An error occurred');
        return;
      }

      token = data;
      const user = jwt_decode<any>(token!);
      companyId = user?.user?.id;
    } else if (auth.user?.role === Roles.Company) {
      companyId = parseInt(auth.user?.id!);
      token = auth.token?.accessToken;
    }

    setAnalyticsAuth({ companyId, token });
    setLoading(false);
  };

  useEffect(() => {
    intializeAnalytics();
  }, []);

  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  return <AnalyticsTokenContext.Provider value={{ analyticsAuth }}>{children}</AnalyticsTokenContext.Provider>;
};

export default AnalyticsTokenProvider;
