import { BackButton } from '@/components/BackButton';
import TransitionWrapper from '@/components/TransitionWrapper';
import { PurchasableProduct, RequestableProduct } from '@/core/types';
import { useGetProductsQuery, useHasCompanyPurchasedProductQuery } from '@/features/state/slices/api/product-slice';
import { Box, Card, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { ProductsDisplay } from './ProductsDisplay';
import { SubscriptionPlan } from '@/core/constants/constants';
import Center from '@/components/Center/Center';

export const ManageProducts = () => {
  const { data, isFetching, isSuccess, isError } = useGetProductsQuery();

  return (
    <TransitionWrapper>
      <Card sx={{ padding: 3 }}>
        <Box sx={{ width: '100% ' }}>
          <Box display={'flex'}>
            <Typography ml={1} variant="h2">
              Our Products
            </Typography>
          </Box>
          <Typography variant="subtitle1" mt={1}>
            {'Here you can purchase or request our products'}
          </Typography>
          <br></br>
          {data?.data ? (
            <ProductsDisplay products={data!.data.items} />
          ) : (
            <Center>
              <CircularProgress />
            </Center>
          )}
        </Box>
      </Card>
    </TransitionWrapper>
  );
};
