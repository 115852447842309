import { baseQuery } from './base-slice';
import { AccessLog } from '@/core/types';
import { Endpoints } from '@core/constants/endpoint';
import { ApiError, ServiceResult } from '@core/index';
import { PaginatedList } from '@core/constants/constants';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { toQueryString } from '@/core/utils/utils';

const descriptionTags = ['Auth'] as readonly TagDescription<'Auth'>[] & string[];

export const authenticationSlice = createApi({
  reducerPath: 'authentication',
  baseQuery: baseQuery,
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    decodeSecret: builder.mutation<ServiceResult<string, ApiError>, any>({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `/${BaseEndpoints.Auth}/decode-secret` }),
      invalidatesTags: descriptionTags,
    }),
    authenticateCompany: builder.mutation<ServiceResult<any, ApiError>, any>({
      query: (payload) => ({
        url: `/${BaseEndpoints.Auth}/company`,
        method: ApiMethods.POST,
        body: payload,
      }),
      invalidatesTags: descriptionTags,
    }),
    registerCompany: builder.mutation({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: Endpoints.RegisterCompany }),
      invalidatesTags: descriptionTags,
    }),
    changeCompanyPassword: builder.mutation({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `/${BaseEndpoints.Auth}/company/set-password` }),
      invalidatesTags: descriptionTags,
    }),
    getAccessLogs: builder.query<ServiceResult<PaginatedList<AccessLog[]>, ApiError>, any>({
      query: (args) => ({
        url: `/${BaseEndpoints.Auth}/company/${args.companyId}/access-logs?${toQueryString(args.params)}`,
      }),
      providesTags: descriptionTags,
    }),
  }),
});
export const {
  useGetAccessLogsQuery,
  useDecodeSecretMutation,
  useRegisterCompanyMutation,
  useAuthenticateCompanyMutation,
  useChangeCompanyPasswordMutation,
} = authenticationSlice;
