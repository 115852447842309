import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useAuth } from '@core/utils/utils';
import { ProfileBox } from '@/components/Styled';
import { useTheme, Avatar } from '@mui/material';
import { InputField } from '@/components/AppInput';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useRef, Fragment } from 'react';
import { CompanyDetailsEditSchema } from '@core/constants/schemas';
import CountrySelect from '@/components/CountrySelect/CountrySelect';
import ProvinceSelect from '@/components/ProvinceSelect/ProvinceSelect';
import { updateProfilePic, updateUser } from '@features/state/slices/local/auth.slice';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useEditCompanyMutation, useUpdateProfilePhotoMutation } from '@features/state/slices/api/company-slice';
import AppButton from '@/components/AppButton';
import { Helmet } from 'react-helmet-async';
import { CountryISO } from '@/core/enums/enums';
import { useNavigate } from 'react-router';

function Account() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const uploadInputRef = useRef<any>(null as any);
  const [editCompanyDetails] = useEditCompanyMutation();
  const [photo, setPhoto] = useState<File | null>(null as any);
  const [updateProfilePhotoMutation] = useUpdateProfilePhotoMutation();
  const navigate = useNavigate();
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CompanyDetailsEditSchema),
    defaultValues: {
      city: user?.city,
      email: user?.email,
      state: user?.state,
      country: user?.country,
      companyName: user?.name,
      postalCode: user?.postalCode,
      phoneNumber: user?.phoneNumber,
      addressLine1: user?.addressLine1,
      addressLine2: user?.addressLine2,
    },
  });

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      dispatch(toggleLoading());

      const photo = e.target?.files![0];

      const formData = new FormData();

      formData.append('photo', photo);
      formData.append('id', String(user?.id!));

      const { success, error, data } = await updateProfilePhotoMutation(formData).unwrap();

      if (!success && error) {
        toast.error(error?.message!);
        return;
      }

      dispatch(updateProfilePic(data?.url!));

      toast.success('Profile photo successfully updated');
    } catch (e) {
      toast.error('An error occured');
    }
    dispatch(toggleLoading());
  };

  const handleDetailsSubmit = async (data: any) => {
    try {
      dispatch(toggleLoading());

      const requestData = formatData(data);

      const apiResponse = await editCompanyDetails(requestData).unwrap();

      if (apiResponse.success) {
        toast.success('Information updated successfully.');

        dispatch(updateUser(data));
      }

      if (!apiResponse.success) {
        toast.error(`${apiResponse?.error?.message}`);
      }

      dispatch(toggleLoading());
    } catch (err: any) {
      dispatch(toggleLoading());
      toast.error('Error updating information: ' + err);
    }
  };

  const formatData = (data: any) => {
    const formData = new FormData();

    formData.append('id', String(user?.id));
    formData.append('city', data.city);
    formData.append('name', data.companyName);
    formData.append('email', data.email);
    formData.append('state', data.state);
    formData.append('country', data.country);

    formData.append('postalCode', data.postalCode);
    formData.append('phoneNumber', data.phoneNumber);
    formData.append('addressLine1', data.addressLine1);
    formData.append('addressLine2', data.addressLine2);
    return formData;
  };

  const province = watch<'state'>('state');
  const country = watch<'country'>('country', CountryISO.UnitedStates);

  const handleReferenceClick = async () => uploadInputRef.current && uploadInputRef.current.click();

  return (
    <div className="grow">
      <Helmet>
        <title>Settings - Account</title>
      </Helmet>
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-1">My Account</h2>

        <section>
          <div className="flex items-center">
            <div className="mr-2">
              <ProfileBox>
                <Avatar alt="photoURL" src={user?.profilePic} sx={{ height: 100, width: 100 }} />

                <div className="d-flex p-3">
                  <Fragment>
                    <input
                      type="file"
                      accept="image/*"
                      ref={uploadInputRef}
                      style={{ display: 'none' }}
                      onChange={(e) => handleUpload(e)}
                    />
                  </Fragment>
                </div>
              </ProfileBox>
            </div>
            <div>
              {user?.watermark ? (
                user?.canAccessWatermark ? (
                  <div className="flex items-baseline space-x-2 ml-4">
                    <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                    <h6 className="text-sm">Your logo will be applied as a watermark</h6>
                  </div>
                ) : (
                  <div className="flex items-baseline space-x-2 ml-4">
                    <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                    <h6 className="text-sm">You have purchased your watermark but access has be restricted. Contact Admin</h6>
                  </div>
                )
              ) : user?.canAccessWatermark ? (
                <div className="flex items-baseline space-x-2 ml-4">
                  <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                  <h6 className="text-sm">You have been given temporary access to a watermark.</h6>
                </div>
              ) : (
                <button
                  className="flex items-baseline space-x-2 ml-4 bg-red-100 hover:bg-green-100 px-2 py-1 rounded-lg transition duration-300"
                  onClick={() => {
                    navigate('/app/products');
                  }}
                >
                  <h6 className="text-sm text-gray-800 mb-0">Your logo will not be applied as a watermark. Click to view more</h6>
                </button>
              )}
              <button onClick={() => handleReferenceClick()} className="btn-sm bg-blue-500 hover:bg-blue-400 text-white">
                Change
              </button>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Business Profile</h2>
          <div className="text-sm">Admin, edit your business profile information below.</div>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Name
              </label>
              <InputField
                type="text"
                errors={errors.companyName}
                value={watch('companyName')}
                onChange={(e) => setValue('companyName', e.target.value)}
              />
              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.companyName?.message}
              </label>
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="business-id">
                Email
              </label>
              <InputField value={watch('email')} onChange={(e) => setValue('email', e.target.value)} type="text" />

              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.email?.message}
              </label>
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="location">
                Phone Number
              </label>
              <InputField value={watch('phoneNumber')} onChange={(e) => setValue('phoneNumber', e.target.value)} type="text" />
              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.phoneNumber?.message}
              </label>
            </div>
          </div>

          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/2">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Address Line 1
              </label>
              <InputField value={watch('addressLine1')} onChange={(e) => setValue('addressLine1', e.target.value)} type="text" />
              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.addressLine1?.message}
              </label>
            </div>
            <div className="sm:w-1/2">
              <label className="block text-sm font-medium mb-1" htmlFor="business-id">
                Address Line 2
              </label>
              <InputField value={watch('addressLine2')} onChange={(e) => setValue('addressLine2', e.target.value)} type="text" />
              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.addressLine2?.message}
              </label>
            </div>
          </div>

          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/4">
              <label className="block text-sm font-medium mb-1" htmlFor="location">
                City
              </label>
              <InputField value={watch('city')} onChange={(e) => setValue('city', e.target.value)} type="text" />
              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.city?.message}
              </label>
            </div>
            <div className="sm:w-1/4">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Country
              </label>
              <CountrySelect
                defaultVal={country}
                error={errors.country?.message != null}
                onSelect={(option) => setValue('country', option?.id, { shouldValidate: true })}
              />
              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.country?.message}
              </label>
            </div>
            <div className="sm:w-1/4">
              <label className="block text-sm font-medium mb-1" htmlFor="business-id">
                State
              </label>
              <ProvinceSelect
                value={province}
                countryIso={country}
                error={errors.state?.message != null}
                onSelect={(option) => setValue('state', option?.id, { shouldValidate: true })}
              />
              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.state?.message}
              </label>
            </div>
            <div className="sm:w-1/4">
              <label className="block text-sm font-medium mb-1" htmlFor="location">
                Postal Code
              </label>
              <InputField value={watch('postalCode')} onChange={(e) => setValue('postalCode', e.target.value)} type="text" />
              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.postalCode?.message}
              </label>
            </div>
          </div>
        </section>
      </div>

      <footer>
        <div className="flex flex-col px-6 py-5">
          <div className="flex self-end">
            <AppButton
              onClick={handleSubmit(handleDetailsSubmit)}
              //className="btn btn-color bg-blue-500 hover:bg-blue-600 text-semibold text-white ml-3"
            >
              Save
            </AppButton>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Account;
