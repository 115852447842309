import { IconButton, useTheme } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router';

type Props = {
  onBackPress?: () => void;
};

export const BackButton: React.FC<Props> = ({ onBackPress }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <IconButton
      color="inherit"
      onClick={() => {
        if (onBackPress) {
          onBackPress();
        } else {
          navigate(-1);
        }
      }}
      sx={{
        '&:hover': {
          background: theme.colors.primary.lighter,
        },
        color: theme.palette.primary.main,
      }}
    >
      <KeyboardBackspaceIcon />
    </IconButton>
  );
};
