

export const SET_CREDIT_ACTION = "SET_CREDIT_ACTION";

export interface CreditState {
  creditAmount: number;
}

export interface SetCreditActionStore {
  type: typeof SET_CREDIT_ACTION;
  payload: number ;
}

export type SetCreditActionType = SetCreditActionStore;
