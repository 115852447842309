import * as React from 'react';
import toast from 'react-hot-toast';
import jwt_decode from 'jwt-decode';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import AuthLayout from '@/core/layouts/Auth';
import Routes from '@/features/router/routes';
import { getDeviceInfo } from '@core/utils/utils';
import Typography from '@mui/material/Typography';
import { InputField } from '@/components/AppInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthenticationSchema } from '@core/constants/schemas';
import { setAuth } from '@features/state/slices/local/auth.slice';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useAuthenticateCompanyMutation } from '@features/state/slices/api/auth-slice';
import { Helmet } from 'react-helmet-async';

export default function Login() {
  const {
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AuthenticationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);

  const [authenticateCompanyMutation] = useAuthenticateCompanyMutation();

  const togglePassword = () => setShowPassword(!showPassword);

  const handleAuthentication = async (formData: any) => {
    try {
      const { email, password } = formData;
      dispatch(toggleLoading());

      const request = {
        email,
        password,
        accessLogData: {
          companyEmail: email,
          device: getDeviceInfo(),
        },
      };

      const { error, success, data } = await authenticateCompanyMutation(request).unwrap();

      if (data?.actionRequired && data.action == 'REQUIRES_PASSWORD_SET') {
        navigate('/auth/set-password');
        const token = data!;

        const { accessToken } = token;

        const user = jwt_decode<any>(accessToken);

        const auth = {
          token,
          user: user.user,
          role: user.role,
        };

        dispatch(setAuth(auth));
        dispatch(toggleLoading());
        return;
      }

      if (success === false && error) {
        const message = error?.message as string;

        toast.error(message);
        setResponseError(message);

        dispatch(toggleLoading());
        return;
      }

      const token = data!;

      const { accessToken } = token;

      const user = jwt_decode<any>(accessToken);

      const auth = {
        token,
        user: user.user,
        role: user.role,
      };

      dispatch(setAuth(auth));

      navigate(`/${Routes.App.Dashboard}`);
    } catch (err) {
      toast.error('An error occured');
    }

    dispatch(toggleLoading());
  };

  const setResponseError = (message: string) => {
    setError('email', { type: 'focus' }, { shouldFocus: true });

    setError('password', { type: 'focus', message }, { shouldFocus: true });
  };

  return (
    <AuthLayout>
      {/* Content */}
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <div className="max-w-sm mx-auto w-full px-4 py-8">
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Welcome back! ✨</h1>
        {/* Form */}
        <form>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Email
              </label>
              <InputField value={watch('email')!} onChange={(e) => setValue('email', e.target.value)} type="email" />
              {errors.email?.message && (
                <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                  {errors.email?.message}
                </label>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="password">
                Password
              </label>
              <div className="relative">
                <InputField
                  value={watch('password')!}
                  onChange={(e) => setValue('password', e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                />
                <button
                  type="button"
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={togglePassword}
                >
                  {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                </button>
              </div>
              <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
                {errors.password?.message}
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between mt-6">
            <div className="mr-1">
              <a className="text-sm underline hover:no-underline text-slate-500" href="/auth/register">
                Register
              </a>
            </div>
            <div className="mr-1">
              <a className="text-sm underline hover:no-underline text-slate-500" href="/auth/forgot-password">
                Forgot Password?
              </a>
            </div>

            <Button onClick={handleSubmit(handleAuthentication)} sx={{ borderRadius: 0.4 }} variant="contained">
              Sign In
            </Button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}
