import { useEffect, useState } from 'react';
import { headers } from './data/TableData';
import { Helmet } from 'react-helmet-async';
import AppTable from '@/components/AppTable';
import { useAuth } from '@/core/utils/utils';
import { SearchBar } from '@/components/SearchBar';
import { CustomVideoRequest } from '@/core/types';
import { RefreshButton } from '@/components/RefreshButton';
import { PaginatedList } from '@/core/constants/constants';
import TransitionWrapper from '@/components/TransitionWrapper';
import AppTablePagination from '@/components/AppTablePagination';
import {
  useGetCustomVideoRequestsQuery,
  useGetRequestStatusesQuery,
  useGetVideoCreditRequestsQuery,
} from '@/features/state/slices/api/request-slice';

const ManageCustomVideoRequest = () => {
  const [query, setQuery] = useState<any>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [customVideoRequests, setCustomVideoRequest] = useState<PaginatedList<CustomVideoRequest[]>>({
    items: [],
    meta: {
      itemCount: 0,
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      itemsPerPage: 0,
    },
  });

  const { data, isError, refetch, isSuccess, isFetching } = useGetCustomVideoRequestsQuery({ ...query, companyId: user?.id });

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    setQuery((prev: any) => ({
      ...prev,
      page: pageNumber,
      limit: pageLimit,
    }));
  };

  const handleSearch = (searchTerm: string) => {
    const queryData = {
      searchTerm,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  useEffect(() => {
    if (isSuccess && data?.data) {
      setCustomVideoRequest(data?.data);
      setLoading(false);
    }
  }, [isFetching, isSuccess, data]);

  return (
    <>
      <Helmet>
        <title>Custom Video Requests - Sales Assist</title>
      </Helmet>
      <TransitionWrapper>
        <div>
          <div className="flex justify-between">
            <div>
              <header className="px-1 pt-3 mb-1 border-b border-slate-100 dark:border-slate-700">
                <h4 className="font-semibold text-slate-800 dark:text-slate-100">Custom Video Requests</h4>
              </header>
            </div>
            <div className="flex justify-end space-x-3 items-center">
              <SearchBar onChange={handleSearch} />
              <RefreshButton onRefresh={refetch} />
            </div>
          </div>

          <AppTable
            error={isError}
            headers={headers}
            refetch={refetch}
            loading={loading}
            data={customVideoRequests.items}
            total={data?.data?.meta.totalItems ?? 0}
          />
          <AppTablePagination totalPages={data?.data?.meta.totalPages ?? 1} onChange={handlePaginationChanged}></AppTablePagination>
        </div>
      </TransitionWrapper>
    </>
  );
};

export default ManageCustomVideoRequest;
