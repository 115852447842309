import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Roles } from '@core/enums/enums';
import { AppInput } from '@/components/Styled';
import { BackButton } from '@/components/BackButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { determineBorderColor } from '@core/utils/utils';
import { ForgotPasswordSchema } from '@core/constants/schemas';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useRecoverPasswordMutation } from '@features/state/slices/api/company-slice';
import { setForgotPasswordEmail } from '@features/state/slices/local/forgot.password.slice';
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import { InputField } from '@/components/AppInput';
import AppButton from '@/components/AppButton';
import Center from '@/components/Center/Center';

type Props = {
  onNext: () => void;
};

const SendRecoveryEmail: React.FunctionComponent<Props> = ({ onNext }) => {
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const dispatch = useDispatch();

  const [recoverPasswordMutation] = useRecoverPasswordMutation();

  const recoverPassword = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await recoverPasswordMutation({
        email: data.email,
        userRole: Roles.Company,
      }).unwrap();

      if (error && !success) {
        toast.error(error.message!);
        return;
      }

      dispatch(setForgotPasswordEmail(data.email!));

      onNext();

      toast.success('Email sent!');
    } catch (err: any) {
      toast.error(String(err));
    } finally {
      dispatch(toggleLoading());
    }
  };

  return (
    <div>
      <Center>
        <Typography component="h1" variant="h2">
          Forgot Password?
        </Typography>
        <br></br>
        <Typography component="p" variant="subtitle1">
          No worries, we'll send you reset instructions.
        </Typography>
        <br></br>
        <Box noValidate className="login__form" component="form">
          <Box>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <label className="mb-2 text-slate-500 text-xs">Email Address</label>
              <InputField required id="email" placeholder="" onChange={(e) => setValue('email', e.target.value)} />
            </FormControl>

            <FormHelperText style={{ color: 'red' }}>{errors?.email?.message as string}</FormHelperText>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="center">
            <Box mt={1}>
              <AppButton
                // sx={{ borderRadius: 0.4 }}
                onClick={handleSubmit(recoverPassword)}
              >
                Continue
              </AppButton>
            </Box>
          </Box>

          {/* <Copyright className="login__copyright" /> */}
        </Box>
      </Center>
    </div>
  );
};

export default SendRecoveryEmail;
