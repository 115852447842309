import { SubscriptionStatus } from '@/core/enums/enums';
import { Auth } from '@core/constants/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthSlice {
  auth: Auth | null;
}

const initialState: AuthSlice = {
  auth: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<Auth | null>) {
      state.auth = action.payload;
    },

    updateUser(state, action: PayloadAction<object>) {
      state.auth!.user = {
        ...state.auth!.user,
        ...action.payload,
      } as any;
    },
    logOut(state) {
      window.location.replace(`/auth/login`);
      setTimeout(() => {
        state.auth = null;
      }, 100);
    },
    updateProfilePic(state, action: PayloadAction<string>) {
      state.auth!.user!.profilePic = action.payload;
    },
    setSubscriptionStatus(state, action: PayloadAction<'None' | 'Subscribed' | 'Expired'>) {
      state.auth!.subscriptionStatus = action.payload;
    },
  },
});
export const { setAuth, logOut, updateUser, updateProfilePic, setSubscriptionStatus } = authSlice.actions;
export default authSlice;
