import { ReactNode } from 'react';
import Routes from '@/features/router/routes';

//NOTE Sidebar config
interface SidebarNavItem {
  children?: SidebarBaseItem[];
}

interface SidebarBaseItem {
  path: string;
  title: string;
  getIcon: (pathname: string) => ReactNode | JSX.Element;
}

type SidebarNavItemType = SidebarNavItem & SidebarBaseItem;

export const topNavConfig: SidebarNavItemType[] = [
  {
    title: 'Dashboard',
    path: Routes.App.Dashboard,
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path
          className={`fill-current ${pathname.includes(Routes.App.Dashboard) ? 'text-blue-300' : 'text-slate-400'}`}
          d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
        />
        <path
          className={`fill-current ${pathname.includes(Routes.App.Dashboard) ? 'text-blue-600' : 'text-slate-700'}`}
          d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
        />
        <path
          className={`fill-current ${pathname.includes(Routes.App.Dashboard) ? 'text-blue-500' : 'text-slate-600'}`}
          d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
        />
      </svg>
    ),
  },
  {
    title: 'Users',
    path: Routes.App.Users.Root,
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path
          className={`fill-current ${pathname.includes(Routes.App.Users.Root) ? 'text-blue-500' : 'text-slate-600'}`}
          d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
        />
        <path
          className={`fill-current ${pathname.includes(Routes.App.Users.Root) ? 'text-blue-300' : 'text-slate-400'}`}
          d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
        />
      </svg>
    ),
    children: [
      {
        title: 'Sales Associates',
        path: Routes.App.Users.SalesAssociates.Base,
        getIcon: (pathname) => (
          <svg className="shrink-0 h-6 w-6" viewBox="0 0 512 512">
            <title />
            <path
              className={`fill-current ${pathname.includes(Routes.SalesAssociates) ? 'text-blue-300' : 'text-slate-500'}`}
              d="M332.64,64.58C313.18,43.57,286,32,256,32c-30.16,0-57.43,11.5-76.8,32.38-19.58,21.11-29.12,49.8-26.88,80.78C156.76,206.28,203.27,256,256,256s99.16-49.71,103.67-110.82C361.94,114.48,352.34,85.85,332.64,64.58Z"
            />
            <path
              className={`fill-current ${pathname.includes(Routes.SalesAssociates) ? 'text-blue-300' : 'text-slate-500'}`}
              d="M432,480H80A31,31,0,0,1,55.8,468.87c-6.5-7.77-9.12-18.38-7.18-29.11C57.06,392.94,83.4,353.61,124.8,326c36.78-24.51,83.37-38,131.2-38s94.42,13.5,131.2,38c41.4,27.6,67.74,66.93,76.18,113.75,1.94,10.73-.68,21.34-7.18,29.11A31,31,0,0,1,432,480Z"
            />
          </svg>
        ),
      },
      {
        title: 'Pending Invites',
        path: Routes.App.Users.PendingUsers,
        getIcon: (pathname) => (
          <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
            <g id="info" />
            <g id="icons">
              <path
                className={`fill-current ${pathname.includes(Routes.App.Users.PendingUsers) ? 'text-blue-300' : 'text-slate-500'}`}
                d="M21.5,11.1l-17.9-9C2.7,1.7,1.7,2.5,2.1,3.4l2.5,6.7L16,12L4.6,13.9l-2.5,6.7c-0.3,0.9,0.6,1.7,1.5,1.2l17.9-9   C22.2,12.5,22.2,11.5,21.5,11.1z"
              />
            </g>
          </svg>
        ),
      },
    ],
  },

  // {
  //   title: 'Requests',
  //   path: Routes.App.Requests.Root,
  //   getIcon: (pathname) => (
  //     <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
  //       <path
  //         className={`fill-current ${pathname.includes(Routes.App.Requests.Root) ? 'text-blue-500' : 'text-slate-600'}`}
  //         d="M19 5h1v14h-2V7.414L5.707 19.707 5 19H4V5h2v11.586L18.293 4.293 19 5Z"
  //       />
  //       <path
  //         className={`fill-current ${pathname.includes(Routes.App.Requests.Root) ? 'text-blue-300' : 'text-slate-400'}`}
  //         d="M5 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm14 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8ZM5 23a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm14 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z"
  //       />
  //     </svg>
  //   ),
  //   children: [
  //     // DEPRECATED Credit System
  //     // DEPRECATED Packages
  //     {
  //       title: 'Credit',
  //       path: Routes.App.Requests.Credit,
  //       getIcon: (pathname) => (
  //         <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
  //           <path
  //             className={`fill-current ${pathname.includes(Routes.App.Requests.Credit) ? 'text-blue-500' : 'text-slate-500'}`}
  //             d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z"
  //           />
  //           <path
  //             className={`fill-current ${pathname.includes(Routes.App.Requests.Credit) ? 'text-blue-300' : 'text-slate-500'}`}
  //             d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z"
  //           />
  //         </svg>
  //       ),
  //     },
  //     {
  //       title: 'Package',
  //       path: Routes.App.Requests.Package,
  //       getIcon: (pathname) => (
  //         <svg className="shrink-0 h-6 w-6" viewBox="0 0 12 12">
  //           <g>
  //             <g>
  //               <polygon
  //                 className={`fill-current ${pathname.includes(Routes.App.Requests.Package) ? 'text-blue-500' : 'text-slate-500'}`}
  //                 points="2.75,3.815918 8.190918,1.095459 6,0 0.559082,2.720459   "
  //               />
  //             </g>
  //             <g>
  //               <polygon
  //                 className={`fill-current ${pathname.includes(Routes.App.Requests.Package) ? 'text-blue-500' : 'text-slate-500'}`}
  //                 points="9.309082,1.654541 3.8681641,4.375 6,5.440918 11.440918,2.720459   "
  //               />
  //             </g>
  //             <g>
  //               <polygon
  //                 className={`fill-current ${pathname.includes(Routes.App.Requests.Package) ? 'text-blue-500' : 'text-slate-600'}`}
  //                 points="5.5,6.309082 0,3.559082 0,9.25 5.5,12   "
  //               />
  //             </g>
  //             <g>
  //               <polygon
  //                 className={`fill-current ${pathname.includes(Routes.App.Requests.Package) ? 'text-blue-500' : 'text-slate-700'}`}
  //                 points="6.5,6.309082 6.5,12 12,9.25 12,3.559082   "
  //               />
  //             </g>
  //           </g>
  //         </svg>
  //       ),
  //     },
  //     {
  //       title: 'Custom Video',
  //       path: Routes.App.Requests.CustomVideo,
  //       getIcon: (pathname) => (
  //         <svg className="shrink-0 h-6 w-6" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
  //           <g data-name="Layer 2" id="Layer_2">
  //             <path
  //               className={`fill-current ${pathname.includes(Routes.App.Requests.CustomVideo) ? 'text-blue-500' : 'text-slate-500'}`}
  //               d="M28.29,21H3.71A2.71,2.71,0,0,1,1,18.25V5.67A2.71,2.71,0,0,1,3.71,3H28.29A2.71,2.71,0,0,1,31,5.67V18.25A2.71,2.71,0,0,1,28.29,21ZM3.71,5A.71.71,0,0,0,3,5.67V18.25a.71.71,0,0,0,.71.71H28.29a.71.71,0,0,0,.71-.71V5.67A.71.71,0,0,0,28.29,5Z"
  //             />
  //             <path
  //               className={`fill-current ${pathname.includes(Routes.App.Requests.CustomVideo) ? 'text-blue-500' : 'text-slate-600'}`}
  //               d="M20.26,29H11.74a1.66,1.66,0,0,1-1.31-.64A1.62,1.62,0,0,1,10.12,27c.57-2.35,3-4.06,5.88-4.06s5.31,1.71,5.88,4.06a1.62,1.62,0,0,1-.31,1.39A1.66,1.66,0,0,1,20.26,29Zm-8-2h7.57A4.07,4.07,0,0,0,16,25,4.07,4.07,0,0,0,12.22,27Z"
  //             />
  //             <rect
  //               className={`fill-current ${pathname.includes(Routes.App.Requests.CustomVideo) ? 'text-blue-500' : 'text-slate-500'}`}
  //               height="4"
  //               width="2"
  //               x="15"
  //               y="19.96"
  //             />
  //             <rect
  //               className={`fill-current ${pathname.includes(Routes.App.Requests.CustomVideo) ? 'text-blue-500' : 'text-slate-600'}`}
  //               height="16"
  //               rx="1.71"
  //               ry="1.71"
  //               width="28"
  //               x="2"
  //               y="3.96"
  //             />
  //           </g>
  //         </svg>
  //       ),
  //     },
  //   ],
  // },
  {
    title: 'Analytics',
    path: Routes.App.Analytics.CustomVideos,
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <g id="info" />
        <g id="icons">
          <g id="statistics">
            <path
              className={`fill-current ${pathname.includes('analytics') ? 'text-blue-500' : 'text-slate-400'}`}
              d="M5,12H3c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-6C7,12.9,6.1,12,5,12z"
            />
            <path
              className={`fill-current ${pathname.includes('analytics') ? 'text-blue-500' : 'text-slate-500'}`}
              d="M13,2h-2C9.9,2,9,2.9,9,4v16c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2V4C15,2.9,14.1,2,13,2z"
            />
            <path
              className={`fill-current ${pathname.includes('analytics') ? 'text-blue-500' : 'text-slate-600'}`}
              d="M21,8h-2c-1.1,0-2,0.9-2,2v10c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2V10C23,8.9,22.1,8,21,8z"
            />
          </g>
        </g>
      </svg>
    ),
  },
  {
    title: 'Subscriptions',
    path: Routes.App.Subscriptions.Base,
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path
          className={`fill-current ${pathname.includes(Routes.Subscription) ? 'text-blue-300' : 'text-slate-400'}`}
          d="M13 6.068a6.035 6.035 0 0 1 4.932 4.933H24c-.486-5.846-5.154-10.515-11-11v6.067Z"
        />
        <path
          className={`fill-current ${pathname.includes(Routes.Subscription) ? 'text-blue-500' : 'text-slate-700'}`}
          d="M18.007 13c-.474 2.833-2.919 5-5.864 5a5.888 5.888 0 0 1-3.694-1.304L4 20.731C6.131 22.752 8.992 24 12.143 24c6.232 0 11.35-4.851 11.857-11h-5.993Z"
        />
        <path
          className={`fill-current ${pathname.includes(Routes.Subscription) ? 'text-blue-600' : 'text-slate-600'}`}
          d="M6.939 15.007A5.861 5.861 0 0 1 6 11.829c0-2.937 2.167-5.376 5-5.85V0C4.85.507 0 5.614 0 11.83c0 2.695.922 5.174 2.456 7.17l4.483-3.993Z"
        />
      </svg>
    ),
  },

  {
    title: 'Products',
    path: Routes.App.Products.Base,
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 12 12">
        <g>
          <g>
            <polygon
              className={`fill-current ${pathname.includes(Routes.Products) ? 'text-blue-500' : 'text-slate-500'}`}
              points="2.75,3.815918 8.190918,1.095459 6,0 0.559082,2.720459   "
            />
          </g>
          <g>
            <polygon
              className={`fill-current ${pathname.includes(Routes.Products) ? 'text-blue-500' : 'text-slate-500'}`}
              points="9.309082,1.654541 3.8681641,4.375 6,5.440918 11.440918,2.720459   "
            />
          </g>
          <g>
            <polygon
              className={`fill-current ${pathname.includes(Routes.Products) ? 'text-blue-500' : 'text-slate-600'}`}
              points="5.5,6.309082 0,3.559082 0,9.25 5.5,12   "
            />
          </g>
          <g>
            <polygon
              className={`fill-current ${pathname.includes(Routes.Products) ? 'text-blue-500' : 'text-slate-700'}`}
              points="6.5,6.309082 6.5,12 12,9.25 12,3.559082   "
            />
          </g>
        </g>
      </svg>
    ),
  },
  //FIXME My Videos Tab commented out
  // {
  //   title: 'My Videos',
  //   path: Routes.App.MyContent.CustomVideos,
  //   getIcon: (pathname) => (
  //     <svg className="shrink-0 h-6 w-6" viewBox="0 0 32 32">
  //       <path
  //         className={`fill-current ${pathname.includes(Routes.MyContent) ? 'text-blue-600' : 'text-slate-500'}`}
  //         d="M31.4,8.6c-0.2-1.6-1.6-3-3.3-3.1c-9.8-0.7-14.6-0.7-24.3,0C2.2,5.6,0.8,7,0.6,8.6c-0.8,5.9-0.8,8.9,0,14.8  c0.2,1.6,1.7,3,3.3,3.1C8.7,26.8,12.4,27,16,27s7.3-0.2,12.2-0.5c1.6-0.1,3-1.5,3.3-3.1C32.2,17.5,32.2,14.5,31.4,8.6z M21.1,17.7  l-6,4C14.8,21.9,14.4,22,14,22c-0.3,0-0.7-0.1-0.9-0.2c-0.7-0.4-1.1-1-1.1-1.8V12c0-0.8,0.4-1.4,1.1-1.8c0.7-0.4,1.4-0.3,2.1,0.1  l6,4c0.6,0.4,0.9,1,0.9,1.7S21.7,17.3,21.1,17.7z"
  //       />
  //     </svg>
  //   ),
  // },
  {
    title: 'Settings',
    path: Routes.Settings,
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path
          className={`fill-current ${pathname.includes(Routes.Settings) ? 'text-blue-500' : 'text-slate-600'}`}
          d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
        />
        <path
          className={`fill-current ${pathname.includes(Routes.Settings) ? 'text-blue-300' : 'text-slate-400'}`}
          d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
        />
        <path
          className={`fill-current ${pathname.includes(Routes.Settings) ? 'text-blue-500' : 'text-slate-600'}`}
          d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
        />
        <path
          className={`fill-current ${pathname.includes(Routes.Settings) ? 'text-blue-300' : 'text-slate-400'}`}
          d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
        />
      </svg>
    ),
    children: [
      {
        title: 'Account',
        path: Routes.App.Settings.Account,
        getIcon: (pathname) => (
          <svg viewBox="0 0 24 24" className="shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg">
            <path
              className={`fill-current ${pathname.includes('account') ? 'text-blue-300' : 'text-slate-400'}`}
              d="m2.344 15.271 2 3.46a1 1 0 0 0 1.366.365l1.396-.806c.58.457 1.221.832 1.895 1.112V21a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.598a8.094 8.094 0 0 0 1.895-1.112l1.396.806c.477.275 1.091.11 1.366-.365l2-3.46a1.004 1.004 0 0 0-.365-1.366l-1.372-.793a7.683 7.683 0 0 0-.002-2.224l1.372-.793c.476-.275.641-.89.365-1.366l-2-3.46a1 1 0 0 0-1.366-.365l-1.396.806A8.034 8.034 0 0 0 15 4.598V3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1.598A8.094 8.094 0 0 0 7.105 5.71L5.71 4.904a.999.999 0 0 0-1.366.365l-2 3.46a1.004 1.004 0 0 0 .365 1.366l1.372.793a7.683 7.683 0 0 0 0 2.224l-1.372.793c-.476.275-.641.89-.365 1.366zM12 8c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4z"
            />
          </svg>
        ),
      },
      {
        title: 'Security',
        path: Routes.App.Settings.Security,
        getIcon: (pathname) => (
          <svg viewBox="0 0 512 512" className="shrink-0 h-5 w-5">
            <path
              className={`fill-current ${pathname.includes('security') ? 'text-blue-300' : 'text-slate-400'}`}
              d="M336 0c-97.2 0-176 78.8-176 176c0 14.71 2.004 28.93 5.406 42.59l-156 156C3.371 380.6 0 388.8 0 397.3V496C0 504.8 7.164 512 16 512l96 0c8.836 0 16-7.164 16-16v-48h48c8.836 0 16-7.164 16-16v-48h57.37c4.242 0 8.312-1.688 11.31-4.688l32.72-32.72C307.1 349.1 321.3 351.1 336 351.1c97.2 0 176-78.8 176-176S433.2 0 336 0zM376 176c-22.09 0-40-17.91-40-40S353.9 96 376 96S416 113.9 416 136S398.1 176 376 176z"
            />
          </svg>
        ),
      },
      {
        title: 'Billing',
        path: Routes.App.Settings.Billing,
        getIcon: (pathname) => (
          <svg viewBox="0 0 512 512" className="shrink-0 h-6 w-6">
            <g id="_x33_63-visa_x2C__Credit_card">
              <g>
                <path
                  d="M485.999,115.445v281.108c0,21.165-17.17,38.335-38.333,38.335H64.334    c-21.163,0-38.333-17.17-38.333-38.335V115.445c0-21.164,17.17-38.334,38.333-38.334h383.332    C468.829,77.111,485.999,94.282,485.999,115.445z"
                  className={`fill-current ${pathname.includes('billing') ? 'text-blue-300' : 'text-slate-400'}`}
                />
                <g>
                  <path
                    d="M421.791,192.111h-24.837c-7.666,0-13.498,2.237-16.771,10.302l-47.678,113.8h33.702     c0,0,5.511-15.332,6.708-18.606h41.209c0.956,4.392,3.832,18.606,3.832,18.606h29.709L421.791,192.111z M382.179,272.211     c2.637-7.106,12.778-34.738,12.778-34.738c-0.16,0.239,2.636-7.268,4.233-11.9l2.235,10.702c0,0,6.07,29.708,7.428,35.937     H382.179z"
                    fill="#FFFFFF;"
                  />
                  <path
                    d="M164.32,192.111l-31.385,84.653l-3.435-17.171l-11.181-57.021     c-1.837-7.906-7.507-10.142-14.535-10.461H52.116l-0.56,2.476c12.618,3.195,23.879,7.827,33.701,13.656l28.591,107.813h33.94     l50.471-123.944H164.32z"
                    fill="#FFFFFF;"
                  />
                  <polygon points="211.197,192.111 191.152,316.214 223.178,316.214 243.302,192.111" fill="#FFFFFF;" />
                  <path
                    d="M307.989,241.865c-11.26-5.67-18.128-9.504-18.128-15.333c0.159-5.271,5.829-10.702,18.448-10.702     c10.461-0.24,18.128,2.237,23.877,4.711l2.876,1.358l4.392-26.833c-6.31-2.477-16.371-5.272-28.75-5.272     c-31.704,0-53.985,16.932-54.145,41.049c-0.24,17.809,15.971,27.713,28.11,33.701c12.379,6.068,16.611,10.063,16.611,15.413     c-0.159,8.307-10.063,12.14-19.247,12.14c-12.777,0-19.646-1.998-30.105-6.627l-4.234-1.999l-4.472,27.871     c7.505,3.435,21.402,6.47,35.777,6.631c33.701,0.078,55.663-16.614,55.902-42.328     C335.063,261.511,326.438,250.729,307.989,241.865z"
                    fill="#FFFFFF;"
                  />
                </g>
              </g>
            </g>
            <g id="Layer_1" />
          </svg>
        ),
      },
      {
        title: 'Feedback',
        path: Routes.App.Settings.Feedback,
        getIcon: (pathname) => (
          <svg viewBox="0 0 512 512" className="shrink-0 h-6 w-6">
            <g>
              <path
                className={`fill-current ${pathname.includes('feedback') ? 'text-blue-300' : 'text-slate-400'}`}
                d="M80,186H20c-11.046,0-20,8.954-20,20v250c0,11.046,8.954,20,20,20h60c11.046,0,20-8.954,20-20V206   C100,194.954,91.046,186,80,186z"
              />
              <path
                className={`fill-current ${pathname.includes('feedback') ? 'text-blue-300' : 'text-slate-400'}`}
                d="M337.137,186c-15.4,0-25.247-16.411-18-30l36.434-68.313C368.057,64.275,351.092,36,324.559,36h0   c-9.322,0-18.261,3.703-24.853,10.294L183.431,162.569C168.429,177.572,160,197.92,160,219.137V396c0,44.183,35.817,80,80,80   h165.644c27.304,0,51.165-18.435,58.057-44.855l46.577-178.544c1.143-4.383,1.722-8.894,1.722-13.423v0   C512,209.809,488.191,186,458.822,186H337.137z"
              />
            </g>
          </svg>
        ),
      },
    ],
  },
];
