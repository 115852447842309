import { baseQueryWithReauth } from './base-slice';
import { VideoCredit } from '@core/types';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { ActionResult, ApiError, ServiceResult } from '@core/index';
import { createApi, TagDescription } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['VideoCredit'] as readonly TagDescription<'VideoCredit'>[] & string[];

export const videoCreditSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  reducerPath: 'video-credits',
  endpoints: (builder) => ({
    getVideoCredit: builder.query<ServiceResult<VideoCredit, ApiError>, any>({
      query: (args: { id: string }) => `${BaseEndpoints.VideoCredit}/company/${args.id}`,
      providesTags: descriptionTags,
    }),
    assignCredits: builder.mutation({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `/${BaseEndpoints.VideoCredit}/assign` }),
      invalidatesTags: descriptionTags,
    }),
    buyVideoCredit: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `/${BaseEndpoints.VideoCredit}/buy` }),
    }),
  }),
});

export const { useGetVideoCreditQuery, useAssignCreditsMutation, useBuyVideoCreditMutation } = videoCreditSlice;
