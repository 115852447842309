import Center from '@components/Center/Center';

import FlipNumbers from 'react-flip-numbers';
import { Box, Typography } from '@mui/material';
import { toSentenceCase } from '@/core/utils/utils';
import { RequestableProduct } from '@/core/types';
import RequestProductDialog from './RequestProductDialog';
import ModalBasic from '@/components/AppModal/ModalBasic';
import { useState } from 'react';
type Props = {
  product: RequestableProduct;
};

type DisplayConfig = {
  displayTrialPeriod: boolean;
  displayUpFrontBilling: boolean;
};

export const RequestableProductItem = (props: Props) => {
  const { product } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="col-lg-4" style={{ height: 700 }}>
      <div className="card mb-5 " style={{ height: 700 }}>
        <div className="card-body">
          <Box mt={2} mb={2}>
            <h5 className="card-title text-muted text-uppercase text-center ">{product.name}</h5>
          </Box>
          <Box mb={4} textAlign="center">
            <h6
              className="card-price text-center mt-2  	"
              style={{
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" fontSize={12}>
                Request a Quote
              </Typography>

              <br></br>
            </h6>
          </Box>
          <ul className="fa-ul">
            <Box mt={1}>
              <Typography variant="caption" fontSize={12}>
                FEATURES
              </Typography>
            </Box>

            <Box mt={1}>{toSentenceCase(product.description)}</Box>
          </ul>
          <div
            className="d-grid"
            style={{
              left: 10,
              right: 10,
              bottom: 30,
              position: 'absolute',
            }}
          >
            <a
              onClick={handleOpen}
              className={` bg-green-400 text-center font-bold text-white uppercase py-2 px-4 rounded-lg shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              Send a Request
            </a>
          </div>
        </div>
      </div>
      <ModalBasic
        isOpen={open}
        width="w-full sm:w-5/6 md:w-3/4 lg:w-2/3 xl:w-2/6"
        setIsOpen={handleClose}
        title={`Send a request for quotation for ${product.name}?`}
        subtitle="You will be contacted with more information"
      >
        <RequestProductDialog onSuccess={() => {}} onClose={handleClose} product={product} />
      </ModalBasic>
    </div>
  );
};
