import React from 'react';
import { Video } from '@core/types';
import 'videojs-http-source-selector';
import 'videojs-contrib-quality-levels';
import videojs, { VideoJsPlayer } from 'video.js';

import 'video.js/dist/video-js.css';

type Props = { video: any; videoType: string; onReady: (player: any) => void };

export default function VideoPlayer({ video, onReady, videoType }: Props) {
  const videoRef = React.useRef<any | undefined>(null as any);
  const playerRef = React.useRef<VideoJsPlayer | null>(null);

  const determineUrl = (videoType: string) => {
    switch (videoType) {
      case 'clip':
        return video.videoPreview;
      case 'full':
        return video.highResolutionVideo;
      default:
        return video.videoUrl;
    }
  };

  const options: videojs.PlayerOptions = {
    fluid: true,
    autoplay: false,
    controls: true,
    responsive: true,
    poster: video.highResolutionThumbnail,
    plugins: {
      httpSourceSelector: {
        default: 'auto',
      },
    },
    sources: [
      {
        src: determineUrl(videoType),
        type: 'application/x-mpegURL',
      },
    ],
    controlBar: {
      // List the components you want to include in the control bar
      // Omit 'fullscreenToggle' to remove the fullscreen button
      pictureInPictureToggle: false,
      fullscreenToggle: false,
    },
    userActions: {
      doubleClick: false,
    },
  };

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef?.current.appendChild(videoElement);

      const player = (playerRef!.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      }));
      window.videojs = player as any;
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay!);
      player.src(options.sources!);

      // player.on("changeRes", function () {
      //  // const resolution = player.getCurrentRes();
      // });
    }
  }, [options, videoRef]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        const dispose = null as any;
        player.dispose();
        playerRef.current! = dispose;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div style={{ borderRadius: 10 }} ref={videoRef}></div>
    </div>
  );
}
