import { SET_ASSOCIATE } from "@core/typings/associate";

const initialState: any = {
  credit: null,
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  packages: null,
  phoneNumber: null,
  profilePic: null,
};

export default function associateReducer(
  state = initialState,
  action: any
): any {
  switch (action.type) {
    case SET_ASSOCIATE:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}
