import { Fragment, useState } from 'react';
import { toast } from 'react-hot-toast';
import AppChip from '@/components/AppChip';
import { useDispatch } from 'react-redux';
import { getMomentInTime } from '@core/utils/utils';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import DeleteConfirmModal from '@features/shared/DeleteConfirmModal';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useDeletePendingInviteMutation } from '@features/state/slices/api/pending-invites.slice';
import { Checkbox, IconButton, TableCell, TableRow, Tooltip, useTheme } from '@mui/material';

type Props = {
  id: any;
  refetch: any;
};

export default function DeletePendingUserButton({ id, refetch }: Props) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const toggleConfirmDelete = () => {
    setConfirmDelete((prev) => !prev);
  };

  const [deletePendingInvite] = useDeletePendingInviteMutation();

  const handleRemovePendingUser = async () => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await deletePendingInvite({
        id: id,
      }).unwrap();

      if (!success) {
        toast.error('Unable to delete invite');
        return;
      }

      toast.success('Invite has been deleted');

      if (refetch) {
        refetch();
      }
    } catch (err: any) {
      toast.error('Failed to delete invite: ', err);
    }
    dispatch(toggleLoading());
  };
  return (
    <Fragment>
      <DeleteConfirmModal
        open={confirmDelete}
        closeModal={toggleConfirmDelete}
        title={"Delete this user's invite?"}
        handleAction={handleRemovePendingUser}
        confirmationText={'This will delete the invite from our system'}
      />
      <Tooltip title={'Cancel Invite'} arrow>
        <IconButton
          onClick={toggleConfirmDelete}
          sx={{
            '&:hover': {
              background: theme.colors.error.lighter,
            },
            p: 0.3,
            borderRadius: '50%',
            color: theme.palette.error.main,
          }}
          size={'small'}
        >
          <DeleteTwoToneIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}
