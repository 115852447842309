import { StepProps } from '@core/types';
import { Navigator } from '../Navigator';
import { fadeInRight } from 'react-animations';
import Center from '@components/Center/Center';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import TransitionWrapper from '@components/TransitionWrapper';
import { MONTHLY_CONTRACT_KEY, ONE_YEAR_CONTRACT_KEY, SIX_MONTH_CONTRACT_KEY, subscriptionTabs } from '@core/constants/constants';
import Completion from '@assets/images/subscription.jpg';
import { SubscriptionDisplayItem } from '@features/pages/Subscriptions/components/SubscriptionDisplay/SubscriptionItem';
import Swal from 'sweetalert2';
import { CheckOutSubscriptionItem } from '../CheckOutSubscriptionItem';

interface CompletionProp extends StepProps {
  createSubscription: () => Promise<boolean>;
}
export const CompletionStep: React.FC<CompletionProp> = (props: CompletionProp) => {
  const { data, steps, activeStep, handleBack, handleNext, handleSkip, createSubscription } = props;
  console.log('Data from sub', JSON.stringify(data));
  const selectedSubscription = data.subscriptionMetadata.isCustom ? data.customSubscription : data.selectedSubscription;
  const isTrial = data.subscriptionMetadata.isCustom
    ? data.customSubscription.isTrial
    : data.selectedSubscription.pricePlan == SIX_MONTH_CONTRACT_KEY;
  const hasUpFrontBillingCost = data.subscriptionMetadata.isCustom
    ? data.customSubscription.hasUpFrontBillingCost
    : data.selectedSubscription.pricePlan == SIX_MONTH_CONTRACT_KEY;
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box
        mt={10}
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row', // Stack vertically on mobile
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} width={isMobile ? '100%' : 'auto'}>
          <CheckOutSubscriptionItem
            isSelected={true}
            onSelect={(a, b) => null}
            config={{
              displayTrialPeriod: false,
              displayUpFrontBilling: false,
            }}
            subscription={selectedSubscription}
            pricePlan={selectedSubscription.pricePlan}
          />

          <Center>
            <Box>
              <img crossOrigin="anonymous" height={isMobile ? 200 : 400} width={isMobile ? 300 : 650} src={Completion} />
              <Center>
                <Typography variant="subtitle1" mt={1} mb={1}>
                  Click below to purchase this subscription
                </Typography>
                <Button
                  onClick={async () => {
                    const success = await createSubscription();
                    console.log('SUB SUCCESS ', success);
                    if (success) {
                      handleNext();
                    }
                  }}
                  variant="contained"
                  sx={{
                    width: isMobile ? '100%' : 'auto',
                  }}
                >
                  Purchase Subscription
                </Button>
              </Center>
            </Box>
          </Center>
        </Box>
      </Box>

      <Navigator
        steps={steps}
        handleSkip={handleSkip}
        handleBack={handleBack}
        activeStep={activeStep}
        handleNext={() => {
          Swal.fire({
            icon: 'info',
            title: `Please Wait!`,
            text: 'Create your subcription first',
          });
        }}
      />
    </TransitionWrapper>
  );
};
