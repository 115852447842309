import { baseQueryWithReauth } from './base-slice';
import { PaginatedList } from '@core/constants/constants';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { ActionResult, ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import {
  RequestableProduct,
  PurchasableProduct,
  Product,
  ProductPaymentDTO,
  ProductRequestDTO,
  HasCompanyPurchasedProductRequestDTO,
  ProductSalesReceipt,
} from '@core/types';

import { PaymentIntent } from '@stripe/stripe-js/types/api';

const descriptionTags = ['Subscriptions'] as readonly TagDescription<'Subscriptions'>[] & string[];

export const productsSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.Products,
  endpoints: (builder) => ({
    getProducts: builder.query<ServiceResult<PaginatedList<(RequestableProduct | PurchasableProduct)[]>, ApiError>, void>({
      query: () => `${BaseEndpoints.Products}`,
      providesTags: descriptionTags,
      transformResponse: (response: ServiceResult<PaginatedList<Product[]>, ApiError>) => {
        // console.log('RESPONSE FROM PRODUCTS', response.data);
        const finalArray: (RequestableProduct | PurchasableProduct)[] = [];

        if (response.success) {
          response.data!.items.map((e) => {
            if (e.request_needed) {
              finalArray.push(e as RequestableProduct);
            } else {
              finalArray.push(e as PurchasableProduct);
            }
          });
        }
        const finalres: ServiceResult<PaginatedList<(RequestableProduct | PurchasableProduct)[]>, ApiError> = {
          data: !response.error ? { items: finalArray, meta: response.data!.meta } : undefined,
          success: response.success,
          error: response.error,
          statusCode: response.statusCode,
        };
        // console.log('MODDED RESPONSE', finalres);
        return finalres;
      },
    }),
    createProductPayment: builder.mutation<ServiceResult<PaymentIntent, ApiError>, ProductPaymentDTO>({
      query: (payload) => ({
        url: `${BaseEndpoints.Products}/pay`,
        method: 'POST',
        body: payload,
      }),
    }),
    createProductRequest: builder.mutation<any, ProductRequestDTO>({
      query: (payload) => ({
        url: `${BaseEndpoints.Products}/request`,
        method: 'POST',
        body: payload,
      }),
    }),

    hasCompanyPurchasedProduct: builder.query<ServiceResult<ProductSalesReceipt, ApiError>, HasCompanyPurchasedProductRequestDTO>({
      query: (params) => ({
        url: `${BaseEndpoints.ProductsPurchased}/${params.productId}/company/${params.companyId}`,
      }),
    }),
    // getCompanyBaseSubcriptions: builder.query<ServiceResult<PaginatedList<CompanyBaseSubscription[]>, ApiError>, any>({
    //   query: (params) => ({
    //     url: `${BaseEndpoints.Subscription}/company/base/${params.companyId}?${toQueryString(params)}`,
    //   }),
    //   providesTags: descriptionTags,
    // }),
  }),
});

export const {
  useCreateProductRequestMutation,
  useGetProductsQuery,
  useCreateProductPaymentMutation,

  useHasCompanyPurchasedProductQuery,
  useLazyHasCompanyPurchasedProductQuery,
} = productsSlice;
