import { BackButton } from '@/components/BackButton';
import TransitionWrapper from '@/components/TransitionWrapper';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';
import { VideoPackage } from '@core/types';
import ImageCardSkeleton from '@features/shared/ImageCardSkeleton';
import { useGetVideoPackageByIdQuery } from '@features/state/slices/api/video-package-slice';
import { Box, Card, CardContent, Container, Divider, Grid, lighten, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

const VideoPackageView = () => {
  const { id: videoPackageId } = useParams();

  const [videoPackage, setVideoPackage] = useState<VideoPackage>({} as VideoPackage);

  const { data, isFetching, isSuccess, isLoading } = useGetVideoPackageByIdQuery({
    packageId: videoPackageId,
  });

  useEffect(() => {
    if (data) {
      setVideoPackage(data.data!);
    }
  }, [isFetching, isSuccess, data]);

  return (
    <Container sx={{ minWidth: '100%' }}>
      <Helmet>
        <title>Sales Assist - Video Package</title>
      </Helmet>
      <TransitionWrapper>
        <BackButton />
        <Card sx={{ p: 3, mt: 1 }}>
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold">{videoPackage.packageName}</h2>
          <Typography variant="subtitle1">
            Explore the videos included in this package. You can view, play, and manage the videos right here. Enjoy the seamless experience
            with our interactive video player.
          </Typography>
          <div className="mt-5">
            <span className="flex">
              <strong>Description:</strong> &nbsp; <p>{videoPackage?.packageDescription}</p>
            </span>
            <Grid pl={0} container spacing={1}>
              {videoPackage?.videos?.length === 0 && (
                <Grid item xs={12}>
                  <Typography component={'div'} textAlign="center" variant="h4">
                    This package does not currently have any videos
                  </Typography>
                </Grid>
              )}

              {videoPackage?.videos?.map((video: any) => (
                <Grid item key={video.id} xs={12} md={4}>
                  <VideoPlayer video={video as any} videoType={'full'} onReady={(e) => null} />
                  <br></br>
                  <span className="mt-4">
                    <strong>{video.videoName}</strong>
                  </span>
                  <p className="text-slate-400">{video.description}</p>
                </Grid>
              ))}
            </Grid>
          </div>
        </Card>
      </TransitionWrapper>
    </Container>
  );
};

export default VideoPackageView;
