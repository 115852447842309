import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import AppButton from '../AppButton';

type Props = {
  refetch: any;
};
export const Retry: React.FC<Props> = ({ refetch }) => {
  return (
    <Stack sx={{ pb: 5 }} className="retry__container" spacing={2} alignItems="center">
      <svg className="w-12 h-12 fill-current" viewBox="0 0 20 24">
        <path className="text-slate-500 dark:text-slate-600" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
        <path className="text-slate-300 dark:text-slate-400" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
        <path className="text-slate-400 dark:text-slate-500" d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z" />
      </svg>
      <Typography variant="subtitle2" gutterBottom>
        An error occured completing your request. Click the button below to retry
      </Typography>
      <AppButton onClick={refetch}>
        Retry &nbsp; <ReplayIcon sx={{ color: 'white' }} />
      </AppButton>
    </Stack>
  );
};
