import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

type Props = {
  onRefresh: () => void;
};
export const RefreshButton: React.FC<Props> = ({ onRefresh }) => {
  return (
    <Tooltip title={"Refresh"} placement="top" arrow>
      <IconButton onClick={onRefresh}>
        <RefreshIcon sx={{ width: 24, height: 24, color: "text.disabled" }} />
      </IconButton>
    </Tooltip>
  );
};
