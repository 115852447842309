import { baseQueryWithReauth } from './base-slice';
import { Endpoints } from '@core/constants/endpoint';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['PaymentMethod'] as readonly TagDescription<'PaymentMethod'>[] & string[];

export const paymentMethodSlice = createApi({
  reducerPath: BaseEndpoints.PaymentMethod,
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    getPaymentMethods: builder.query({
      query: (stripeCustomerId) => `${BaseEndpoints.PaymentMethod}/${stripeCustomerId}`,
      providesTags: descriptionTags,
    }),
    findPaymentMethod: builder.query({
      query: (paymentMethodID) => `${BaseEndpoints.PaymentMethod}/${paymentMethodID}`,
    }),
    addPaymentMethod: builder.mutation({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: BaseEndpoints.PaymentMethod }),
      invalidatesTags: descriptionTags,
    }),
    modifyPaymentMethod: builder.mutation({
      query: (payload) => ({
        body: payload.body,
        method: ApiMethods.PUT,
        url: `${BaseEndpoints.PaymentMethod}/${payload.paymentMethodId}`,
      }),
    }),
    removePaymentMethod: builder.mutation({
      query: (payload) => ({
        method: ApiMethods.DELETE,
        body: { paymentMethodId: payload.cardId },
        url: `/${BaseEndpoints.PaymentMethod}/${payload.companyId}`,
      }),
    }),
  }),
});

export const {
  useGetPaymentMethodsQuery,
  useFindPaymentMethodQuery,
  useAddPaymentMethodMutation,
  useModifyPaymentMethodMutation,
  useRemovePaymentMethodMutation,
} = paymentMethodSlice;
