import moment from 'moment';
import { Fragment } from 'react';
import AppChip from '@/components/AppChip';
import { SalesAssociate, TableData } from '@/core/types';
import { Avatar, Box, Link, Tooltip, Typography } from '@mui/material';
import ActionsTableCell from './components/ActionsTableCell';

export const headers: TableData<SalesAssociate>[] = [
  {
    key: 'name',
    label: 'Name',
    render: (item) => (
      <Box display="flex" alignItems="center">
        <Avatar alt={item.firstName} src={item.profilePic} />
        <Tooltip title={`${item.firstName} ${item.lastName}`} arrow>
          <Typography
            noWrap
            ml={0.5}
            variant="subtitle1"
            sx={{
              maxWidth: '15rem',
              width: 'fit-content',
              textDecoration: 'none',
            }}
          >
            {item.firstName} {item.lastName}
          </Typography>
        </Tooltip>
      </Box>
    ),
  },

  { key: 'email', label: 'Email', align: 'left' },
  { key: 'phoneNumber', label: 'Phone Number', align: 'left' },
  //DEPRECATED Credit System
  // {
  //   key: 'creditAmount',
  //   label: 'Credits',
  //   align: 'left',
  //   render: (item) => <Typography variant="subtitle1">{item?.credit?.creditAmount ?? 'N/A'}</Typography>,
  // },
  {
    key: 'activity',
    label: 'Activity',
    align: 'left',
    render: (item) => (
      <Fragment>{!item?.isOnline ? <AppChip color="error">Offline</AppChip> : <AppChip color="success">Online</AppChip>}</Fragment>
    ),
  },
  {
    key: 'blocked',
    label: 'Account Status',
    align: 'left',
    render: (item) => (
      <Fragment>{item.blocked ? <AppChip color="error">Blocked</AppChip> : <AppChip color="success">Active</AppChip>}</Fragment>
    ),
  },
  {
    key: 'lastLogin',
    label: 'Last Login',
    align: 'left',
    render: (item) => <Typography>{moment(item.lastLogin).format('LL')}</Typography>,
  },
  {
    key: 'actions',
    label: 'Actions',
    align: 'left',
    render: (item, theme, refetch) => <ActionsTableCell refetch={refetch} blocked={item.blocked} rowId={item.id} />,
  },
];
