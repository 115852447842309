import { Grid } from "@mui/material";
import ListItem from "./ListItem";

const PaymentMethodsList = ({
  paymentMethods,
  refetch,
}: {
  paymentMethods: any;
  refetch: any;
}) => {
  return (
    <Grid>
      {paymentMethods?.map((method: any) => (
        <Grid item xs={12} key={method.id}>
          <ListItem data={method} refetch={refetch} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PaymentMethodsList;
