import { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Roles } from '@/core/enums/enums';
import { useAuth } from '@/core/utils/utils';
import AppButton from '@/components/AppButton';
import Success from '@/assets/images/success.png';
import { toggleLoading } from '@/features/state/slices/local/loading-slice';
import { useCreateFeedbackMutation } from '@/features/state/slices/api/feedback-slice';
import Center from '@/components/Center/Center';
import { Helmet } from 'react-helmet-async';

function Feedback() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [score, setScore] = useState(5);
  const [createFeedbackMutation] = useCreateFeedbackMutation();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [description, setDescription] = useState<string | null>(null);

  const handleScoreSelect = (score: number) => {
    setScore(score);
  };

  const createFeedback = async () => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await createFeedbackMutation({
        score,
        description,
        userId: user!.id,
        userRole: Roles.Company,
      }).unwrap();

      if (error && !success) {
        toast.error(error.message!);
        return;
      }

      setFeedbackSubmitted(true);
    } catch (err: any) {
      toast.error(String(err));
    } finally {
      dispatch(toggleLoading());
    }
  };

  return (
    <div className="grow">
      <Helmet>
        <title>Settings - Feedback</title>
      </Helmet>
      {feedbackSubmitted ? (
        <Center>
          <div className=" p-10 flex flex-col items-center justify-center">
            <h1 className="h2 font-playfair-display text-slate-800 mb-5 text-center">We appreciate your feedback</h1>
            <h6 className="w-3/4 h6 text-sm mb-4 p-10 text-center text-slate-500">
              Your valuable input is the driving force behind our continuous growth and improvement. Your opinions matter to us, and we take
              them to heart in our mission to deliver the best experience possible. Thank you for being an essential part of our journey!
            </h6>

            <img src={Success} height={200} width={200} alt="" />
          </div>
        </Center>
      ) : (
        <div>
          {/* Panel body */}

          <div className="p-6 space-y-6">
            <div>
              <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-4">Give Feedback</h2>
              <div className="text-sm">Our product depends on customer feedback to improve the overall experience!</div>
            </div>

            {/* Rate */}
            <section>
              <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-6">
                How likely would you recommend us to a friend or colleague?
              </h3>
              <div className="w-full max-w-xl">
                <div className="relative">
                  <div className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700" aria-hidden="true"></div>
                  <ul className="relative flex justify-between w-full">
                    {[1, 2, 3, 4, 5].map((scoreValue) => (
                      <li className="flex" key={scoreValue}>
                        <button
                          className={`w-3 h-3 rounded-full ${
                            score === scoreValue
                              ? 'bg-blue-500 border-2 border-blue-500'
                              : 'bg-white dark:bg-slate-800 border-2 border-slate-400 dark:border-slate-500'
                          }`}
                          onClick={() => handleScoreSelect(scoreValue)}
                        >
                          <span className="sr-only">{scoreValue}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-full flex justify-between text-sm text-slate-500 dark:text-slate-400 italic mt-3">
                  <div>Not at all</div>
                  <div>Unlikely</div>
                  <div>Neutral</div>
                  <div>Likely</div>
                  <div>Extremely likely</div>
                </div>
              </div>
            </section>

            {/* Tell us in words */}
            <section>
              <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-5 mt-3">Tell us in words</h3>
              {/* Form */}
              <label className="sr-only" htmlFor="feedback">
                Leave a feedback
              </label>
              <textarea
                rows={4}
                id="feedback"
                placeholder="I really enjoy…"
                onChange={(e) => setDescription(e.target.value)}
                className="font-normal text-slate-600 w-full text-sm leading-5 py-2 px-3 border-slate-200 hover:border-slate-300 focus:border-slate-300 dark:border-slate-700 dark:hover:border-slate-600 dark:focus:border-slate-600 shadow-sm"
              ></textarea>
            </section>
          </div>

          {/* Panel footer */}
          <footer>
            <div className="flex flex-col px-6 py-5 dark:border-slate-700">
              <div className="flex self-end">
                <AppButton onClick={createFeedback}>Submit</AppButton>
              </div>
            </div>
          </footer>
        </div>
      )}
    </div>
  );
}

export default Feedback;
