import { useState } from 'react';
import toast from 'react-hot-toast';
import { Roles } from '@core/enums/enums';
import { BackButton } from '@/components/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import CountdownTimer from '@/components/CountDownTimer';
import VerificationInput from 'react-verification-input';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { RootState } from '@features/state/reducers/combinedReducers';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { setForgotPasswordToken } from '@features/state/slices/local/forgot.password.slice';
import { useRecoverPasswordMutation, useVerifyCodeMutation } from '@features/state/slices/api/company-slice';

type Props = {
  onNext: () => void;
};

const VerifyEmailCode: React.FunctionComponent<Props> = ({ onNext }) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [resetCount, setResetCount] = useState(0);
  const [verifyCodeMutation] = useVerifyCodeMutation();
  const [recoverPasswordMutation] = useRecoverPasswordMutation();
  const email = useSelector<RootState>((state) => state.forgotPassword.email) as string;

  const verifyCode = async () => {
    dispatch(toggleLoading());

    try {
      const { error, data, success } = await verifyCodeMutation({
        code,
        email,
        userRole: Roles.Company,
      }).unwrap();

      if (error && !success) {
        toast.error(error.message!);
        return;
      }

      toast.success('Code verified!');

      dispatch(setForgotPasswordToken(data?.token!));
      onNext();
    } catch (err: any) {
      toast.error(String(err));
    } finally {
      dispatch(toggleLoading());
    }
  };

  const resendCode = async () => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await recoverPasswordMutation({
        email,
        userRole: Roles.Admin,
      }).unwrap();

      if (error && !success) {
        toast.error(error.message!);
        return;
      }

      toast.success('Email sent!');
    } catch (err: any) {
      toast.error(String(err));
    } finally {
      dispatch(toggleLoading());
    }
  };

  const triggerResendCode = async () => {
    await resendCode();

    setResetCount((prevCount) => prevCount + 1);
  };

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <br></br>
      <br></br>
      <MarkEmailUnreadIcon />
      <Typography component="h1" variant="h2">
        Check your email
      </Typography>
      <br></br>
      <Typography component="h6" variant="subtitle1">
        We sent a verification code to
      </Typography>

      <Typography component="h6" variant="h6">
        {email}
      </Typography>

      <Box mt={5} mb={4}>
        <VerificationInput onComplete={(code) => setCode(code)} validChars="0-9" inputProps={{ inputMode: 'numeric' }} />
      </Box>
      <Box display="flex">
        <Typography sx={{ color: 'grey' }}>Code expires in: &nbsp; </Typography> <CountdownTimer initialTime={600} reset={resetCount} />
      </Box>

      <Box width={400}>
        <Button sx={{ borderRadius: 0.4 }} fullWidth variant="contained" className="login__button" onClick={verifyCode}>
          Confirm Code
        </Button>
      </Box>
      <Typography component="p" variant="subtitle1">
        Didn't receive an email?{' '}
        <Link underline="none" sx={{ cursor: 'pointer' }} onClick={triggerResendCode}>
          Click to resend
        </Link>
      </Typography>
      <br></br>
    </Grid>
  );
};

export default VerifyEmailCode;
