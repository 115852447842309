import { baseQueryWithReauth } from './base-slice';
import { PaginatedList, Query } from '@core/constants/constants';
import { toQueryString } from '@core/utils/utils';
import { ApiError, ServiceResult } from '@core/index';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import {
  CustomVideoAnalyticViewerEntity,
  DeviceUsageAnalysis,
  PopularVideoMetrics,
  SkipFactorAnalysis,
  Video,
  VideoAnalyticTrend,
  VideoAnalyticsData,
  VideoAnalyticsStatistics,
  VideoAnalyticsViewerEntity,
  VideoViewsAnalyticsData,
} from '@core/types';

const descriptionTags = ['Analytics'] as readonly TagDescription<'Analytics'>[] & string[];

export const videoAnalyticsSlice = createApi({
  reducerPath: BaseEndpoints.VideoAnalytics,
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    getVideoStatistics: builder.query<ServiceResult<VideoAnalyticsStatistics[], ApiError>, any>({
      query: (args: { companyId: string; token: string; query: any }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/video/company/${args.companyId}/statistics?${toQueryString(args.query)}`,
      }),
      providesTags: descriptionTags,
    }),
    getCustomVideoStatistics: builder.query<ServiceResult<VideoAnalyticsStatistics[], ApiError>, any>({
      query: (args: { companyId: string; token: string; query: any }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/custom-video/company/${args.companyId}/statistics?${toQueryString(args.query)}`,
      }),
      providesTags: descriptionTags,
    }),
    getPopularVideos: builder.query<ServiceResult<PaginatedList<PopularVideoMetrics[]>, ApiError>, any>({
      query: (args: { companyId: string; token: string; query: any }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/video/company/${args.companyId}/get-popular-videos?${toQueryString(args.query)}`,
      }),
      providesTags: descriptionTags,
    }),
    getVideoTrend: builder.query<ServiceResult<VideoAnalyticsData, ApiError>, any>({
      query: (args: { companyId: string; token: string; query: any }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/video/company/${args.companyId}/get-watch-trend?${toQueryString(args.query)}`,
      }),
      providesTags: descriptionTags,
    }),
    getCustomVideoTrend: builder.query<ServiceResult<VideoAnalyticTrend[], ApiError>, any>({
      query: (args: { companyId: string; token: string; query: any }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/custom-video/company/${args.companyId}/get-watch-trend?${toQueryString(args.query)}`,
      }),
      providesTags: descriptionTags,
    }),
    getBatchCustomVideoViewers: builder.query<ServiceResult<PaginatedList<CustomVideoAnalyticViewerEntity[]>, ApiError>, any>({
      //Wrong data-type went from VideoAnalyticViewerEntity to CustomVideoAnalyticViewerEntity
      query: (args: { companyId: string; token: string; query?: Query }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/custom-video/company/${args.companyId}/viewers?${toQueryString(args.query)}`,
      }),
      providesTags: descriptionTags,
      transformResponse: (response: ServiceResult<PaginatedList<CustomVideoAnalyticViewerEntity[]>, ApiError>, meta: any) => {
        console.log('Here returning');
        return response;
        return {
          data: {
            items: [
              {
                video: {
                  // This is just a Custom-Video Model It has some properties missing but just send the whole thing
                  video: {
                    id: 1,
                    videoJobId: 'job_001',
                    videoRetryJson: '{}',
                    videoId: 'vid_001',
                    adminId: 10,
                    downloadableUrl: 'https://example.com/download/vid_001',
                    companyId: 100,
                    watchCount: 150,
                    videoName: 'Introduction to AI',
                    videoUrl: 'https://example.com/videos/vid_001',
                    videoLength: 1200,
                    canUserAccess: true,
                    videoThumbnail: 'https://example.com/thumbnails/vid_001.jpg',
                    description: 'An introductory video on AI.',
                    ultraLowResolutionVideo: 'https://example.com/videos/vid_001_ultra_low.mp4',
                    lowResolutionVideo: 'https://example.com/videos/vid_001_low.mp4',
                    lowResolutionThumbnail: 'https://example.com/thumbnails/vid_001_low.jpg',
                    highResolutionThumbnail: 'https://example.com/thumbnails/vid_001_high.jpg',
                    highResolutionVideo: 'https://example.com/videos/vid_001_high.mp4',
                    mediumResolutionVideo: 'https://example.com/videos/vid_001_medium.mp4',
                    status: 'active',
                    createdAt: '2024-06-01T12:00:00Z',
                    updatedAt: '2024-06-01T12:00:00Z',
                    transcodingErrorMessage: undefined,
                  },
                },
                views: {
                  items: [
                    {
                      id: 1,
                      date: '2024-06-10',
                      skipFactor: 0.1,
                      timeSkipped: 60,
                      watchDuration: '10:00',
                      user: {
                        id: 1,
                        lastName: 'Doe',
                        firstName: 'John',
                        profilePic: 'https://example.com/profiles/johndoe.jpg',
                      },
                    },
                    {
                      id: 2,
                      date: '2024-06-11',
                      skipFactor: 0.05,
                      timeSkipped: 30,
                      watchDuration: '15:00',
                      user: {
                        id: 2,
                        lastName: 'Smith',
                        firstName: 'Jane',
                        profilePic: 'https://example.com/profiles/janesmith.jpg',
                      },
                    },
                  ],
                  totalItems: 2,
                  currentPage: 1,
                  totalPages: 1,
                  meta: { itemCount: 1, totalItems: 1, totalPages: 1, currentPage: 1, itemsPerPage: 1 },
                },
              },
              {
                video: {
                  video: {
                    id: 2,
                    videoJobId: 'job_002',
                    videoRetryJson: '{}',
                    videoId: 'vid_002',
                    adminId: 11,
                    downloadableUrl: 'https://example.com/download/vid_002',
                    companyId: 101,
                    watchCount: 200,
                    videoName: 'Advanced AI Techniques',
                    videoUrl: 'https://example.com/videos/vid_002',
                    videoLength: 1800,
                    canUserAccess: true,
                    videoThumbnail: 'https://example.com/thumbnails/vid_002.jpg',
                    description: 'A video on advanced AI techniques.',
                    ultraLowResolutionVideo: 'https://example.com/videos/vid_002_ultra_low.mp4',
                    lowResolutionVideo: 'https://example.com/videos/vid_002_low.mp4',
                    lowResolutionThumbnail: 'https://example.com/thumbnails/vid_002_low.jpg',
                    highResolutionThumbnail: 'https://example.com/thumbnails/vid_002_high.jpg',
                    highResolutionVideo: 'https://example.com/videos/vid_002_high.mp4',
                    mediumResolutionVideo: 'https://example.com/videos/vid_002_medium.mp4',
                    status: 'active',
                    createdAt: '2024-06-02T12:00:00Z',
                    updatedAt: '2024-06-02T12:00:00Z',
                    transcodingErrorMessage: undefined,
                  },
                },
                views: {
                  items: [
                    {
                      id: 3,
                      date: '2024-06-12',
                      skipFactor: 0.2,
                      timeSkipped: 120,
                      watchDuration: '20:00',
                      user: {
                        id: 3,
                        lastName: 'Brown',
                        firstName: 'Charlie',
                        profilePic: 'https://example.com/profiles/charliebrown.jpg',
                      },
                    },
                    {
                      id: 4,
                      date: '2024-06-13',
                      skipFactor: 0,
                      timeSkipped: 0,
                      watchDuration: '18:00',
                      user: {
                        id: 4,
                        lastName: 'Johnson',
                        firstName: 'Emily',
                        profilePic: 'https://example.com/profiles/emilyjohnson.jpg',
                      },
                    },
                  ],
                  totalItems: 2,
                  currentPage: 1,
                  totalPages: 1,
                  meta: { itemCount: 1, totalItems: 1, totalPages: 1, currentPage: 1, itemsPerPage: 1 },
                },
              },
            ],
            meta: { itemCount: 2, totalItems: 2, totalPages: 1, currentPage: 1, itemsPerPage: 2 },
          },
          success: true,
          statusCode: 200,
        };
      },
      transformErrorResponse: (response, meta) => {
        console.log('An error occured here ');
      },
    }),
    getCustomVideoViewers: builder.query<ServiceResult<VideoAnalyticsViewerEntity, ApiError>, any>({
      //BUG Video Analytics Mock Data Injected
      //doesn't exist
      query: (args: { companyId: string; customVideoId: number; token: string; query?: Query }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/custom-video/${args.customVideoId}/company/${args.companyId}/viewers?${toQueryString(
          args.query,
        )}`,
      }),
      providesTags: descriptionTags,

      transformResponse: (response: any, meta) => {
        return response;
        return {
          data: {
            video: {
              videoId: 1,
              duration: '100',
              videoName: 'Introduction to AI',
              totalWatchTime: '50',
            },

            views: {
              items: [
                {
                  id: 1,
                  date: '2024-06-10',
                  skipFactor: 0.1,
                  timeSkipped: 60,
                  watchDuration: '10:00',
                  user: {
                    id: 1,
                    lastName: 'Doe',
                    firstName: 'John',
                    profilePic: 'https://example.com/profiles/johndoe.jpg',
                  },
                },
                {
                  id: 2,
                  date: '2024-06-11',
                  skipFactor: 0.05,
                  timeSkipped: 30,
                  watchDuration: '15:00',
                  user: {
                    id: 2,
                    lastName: 'Smith',
                    firstName: 'Jane',
                    profilePic: 'https://example.com/profiles/janesmith.jpg',
                  },
                },
              ],
              totalItems: 2,
              currentPage: 1,
              totalPages: 1,
              meta: { itemCount: 1, totalItems: 1, totalPages: 1, currentPage: 1, itemsPerPage: 1 },
            },
          },
          success: true,
        };
      },
    }),
    getVideoViewers: builder.query<ServiceResult<VideoAnalyticsViewerEntity, ApiError>, any>({
      query: (args: { companyId: string; videoId: number; token: string; query?: Query }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/video/${args.videoId}/company/${args.companyId}/viewers?${toQueryString(args.query)}`,
      }),
      providesTags: descriptionTags,
    }),
    getCustomVideoSkipFactorAnalysis: builder.query<ServiceResult<SkipFactorAnalysis, ApiError>, any>({
      query: (args: { companyId: string; token: string }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/custom-video/company/${args.companyId}/skip-factor-analysis`,
      }),
      providesTags: descriptionTags,
    }),
    getCustomVideoDeviceUsage: builder.query<ServiceResult<DeviceUsageAnalysis, ApiError>, any>({
      query: (args: { companyId: string; token: string }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/custom-video/company/${args.companyId}/get-device-usage`,
      }),
      providesTags: descriptionTags,
    }),
    getCustomVideoDailyTrend: builder.query<ServiceResult<Record<string, number>, ApiError>, any>({
      query: (args: { companyId: string; token: string }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/custom-video/company/${args.companyId}/get-daily-trend`,
      }),
      providesTags: descriptionTags,
    }),
    getCustomVideoViews: builder.query<ServiceResult<VideoViewsAnalyticsData, ApiError>, any>({
      //BUG Video Analytics Mock Data Injected
      //doesn't exist
      query: (args: { companyId: number; videoId: number; token: string; query: any }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/custom-video/${args.videoId}/company/${args.companyId}/views?${toQueryString(args.query)}`,
      }),
      providesTags: descriptionTags,
      transformResponse: (response: any, meta) => {
        return response;
        return { data: { dates: ['2024-06-18'], dataPoints: [1, 2, 3, 4], totalViews: 10 }, success: true };
      },
    }),
    getVideoViews: builder.query<ServiceResult<VideoViewsAnalyticsData, ApiError>, any>({
      query: (args: { companyId: number; videoId: number; token: string; query: any }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/video/${args.videoId}/company/${args.companyId}/views?${toQueryString(args.query)}`,
      }),
      providesTags: descriptionTags,
    }),
  }),
});
export const {
  useGetVideoViewsQuery,
  useGetVideoTrendQuery,
  useGetVideoViewersQuery,
  useGetPopularVideosQuery,
  useGetVideoStatisticsQuery,
  useGetCustomVideoTrendQuery,
  useGetBatchCustomVideoViewersQuery,
  useGetCustomVideoStatisticsQuery,
  useGetCustomVideoDailyTrendQuery,
  useGetCustomVideoDeviceUsageQuery,
  useGetCustomVideoSkipFactorAnalysisQuery,
  useGetCustomVideoViewersQuery,
  useGetCustomVideoViewsQuery,
} = videoAnalyticsSlice;
