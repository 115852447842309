import { Video } from '@core/types';
import { baseQueryWithReauth } from './base-slice';
import { Endpoints } from '@core/constants/endpoint';
import { ApiError, ServiceResult } from '@core/index';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { createApi, TagDescription } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['Packages'] as readonly TagDescription<'Packages'>[] & string[];

export const videoSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.Videos,
  endpoints: (builder) => ({
    getVideoById: builder.query<ServiceResult<Video, ApiError>, { id: string | undefined }>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.Videos}/${args.id}`,
      }),
      providesTags: descriptionTags,
    }),
    getVideos: builder.query({
      query: (id) => `${BaseEndpoints.Videos}/company/${id}`,
      providesTags: descriptionTags,
    }),

    getUserCustomVideos: builder.query({
      query: (args: any) => `/${BaseEndpoints.Videos}/custom/user/${args.id}`,
      providesTags: descriptionTags,
    }),
    uploadVideo: builder.mutation({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: Endpoints.UploadVideo }),
      invalidatesTags: descriptionTags,
    }),
    getVideoRecommendation: builder.query<ServiceResult<Video[], ApiError>, any>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.Videos}/recommended/${args.id}`,
      }),
      providesTags: descriptionTags,
    }),
    verifyVideoAccess: builder.query<ServiceResult<boolean, ApiError>, any>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.Videos}/verify/${args.companyId}`,
      }),
      providesTags: descriptionTags,
    }),
  }),
});
export const {
  useGetVideoByIdQuery,
  useLazyGetVideosQuery,
  useUploadVideoMutation,
  useVerifyVideoAccessQuery,
  useGetUserCustomVideosQuery,
  useGetVideoRecommendationQuery,
} = videoSlice;
