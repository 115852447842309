import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Layout = {
  isOpen: boolean;
  layoutValue: any;
};

export const OPEN_SIDEBAR = {
  isOpen: true,
  layoutValue: 240,
};

export const CLOSE_SIDEBAR = {
  isOpen: false,
  layoutValue: 70,
};

const layoutSlice = createSlice({
  name: 'layout',

  initialState: CLOSE_SIDEBAR,
  reducers: {
    toggleSidebar(state, action: PayloadAction<Layout>) {
      state.isOpen = action.payload.isOpen;
      state.layoutValue = action.payload.layoutValue;
    },
  },
});
export const { toggleSidebar } = layoutSlice.actions;
export default layoutSlice;
