import {
  CreditState,
  SET_CREDIT_ACTION,
  SetCreditActionType,
} from "@core/typings/credit";

const initialState: CreditState = {
  creditAmount: 0,
};

export default function SetCreditReducer(
  state = initialState,
  action: SetCreditActionType
): CreditState {
  switch (action.type) {
    case SET_CREDIT_ACTION:
      return {
        creditAmount: action.payload,
      };
    default:
      return state;
  }
}
