import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import Routes from '@/features/router/routes';
import { styled } from '@mui/material/styles';
import AppButton from '@/components/AppButton';
import Center from '@/components/Center/Center';
import UnauthorizedVector from '@/assets/images/401.png';
import { Box, Typography, Container } from '@mui/material';

const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`,
);
type Props = {};
const UnauthorizedPage: React.FunctionComponent<Props> = (props: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Status - 401</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Center>
            <Box textAlign="center">
              {' '}
              <Center>
                <img alt="401" height={650} width={650} src={UnauthorizedVector} />{' '}
              </Center>
              <Typography variant="h2" sx={{ my: 2 }}>
                Oops you cant access this page
              </Typography>
              <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ mb: 4 }}>
                Don't worry, you can either sign in to view the content or reach out to the administrator for assistance.
              </Typography>
            </Box>
            <AppButton onClick={() => navigate(`/${Routes.Auth.Login}`)}>Go to Login</AppButton>
          </Center>
        </Container>
      </MainContent>
    </>
  );
};

export default UnauthorizedPage;
