import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LineChart from '@/components/Charts/LineChart01';
import { hexToRGB, tailwindConfig, useAuth } from '@/core/utils/utils';
import { useGetActiveUsersQuery } from '@/features/state/slices/api/analytics-slice';
import { useGetVideoByIdQuery } from '@/features/state/slices/api/video-slice';
import { Shimmer } from 'react-shimmer';
import VideoPlayer from '@/components/VideoPlayer copy';

function CompanyPortalTrainingVideo() {
  const auth = useAuth();
  const { isFetching, isError, isSuccess, data } = useGetVideoByIdQuery({
    id: '712',
  });

  useEffect(() => {}, [isFetching, isError, isSuccess]);

  return (
    <div className="flex flex-col col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h5 className="font-semibold text-slate-800 dark:text-slate-100">Company Portal Walkthrough</h5>
      </header>
      {/* Card content */}
      <div className="flex flex-col h-full">
        {/* Live visitors number */}
        <div className="px-5 py-3">
          <div className="flex items-center">
            {!isFetching && !isError && isSuccess && data.data ? (
              <Box sx={{ width: '100%' }}>
                <Box position={'relative'}>
                  <VideoPlayer video={data.data} videoType={'full'} onReady={(e) => {}} />
                </Box>
              </Box>
            ) : (
              <Shimmer width={800} height={600} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyPortalTrainingVideo;
