'use client';

import './ChartJsConfig';
import 'chartjs-adapter-moment';
import { chartColors } from './ChartJsConfig';
import { useRef, useState, useEffect } from 'react';
import { Chart, PolarAreaController, RadialLinearScale, Tooltip, Legend } from 'chart.js';
import type { ChartData } from 'chart.js';
import { tailwindConfig } from '@/core/utils/utils';

Chart.register(PolarAreaController, RadialLinearScale, Tooltip, Legend);

interface PolarProps {
  data: ChartData;
  width: number;
  height: number;
}

export default function PolarChart({ data, width, height }: PolarProps) {
  const darkMode = false;
  const [chart, setChart] = useState<Chart | null>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const legend = useRef<HTMLUListElement>(null);

  const { gridColor, textColor, backdropColor, tooltipTitleColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor } = chartColors;

  useEffect(() => {
    const ctx = canvas.current;
    if (!ctx) return;

    const newChart = new Chart(ctx, {
      type: 'polarArea',
      data: data,
      options: {
        layout: {
          padding: 24,
        },
        scales: {
          r: {
            grid: {
              color: darkMode ? gridColor.dark : gridColor.light,
            },
            ticks: {
              color: darkMode ? textColor.dark : textColor.light,
              backdropColor: darkMode ? backdropColor.dark : backdropColor.light,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const value = tooltipItem.raw as any;
                return Math.ceil(value).toString() + '%';
              },
            },
            titleColor: darkMode ? tooltipTitleColor.dark : tooltipTitleColor.light,
            bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
            backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
            borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [
        {
          id: 'htmlLegend',
          afterUpdate(c, args, options) {
            const ul = legend.current;
            if (!ul) return;
            // Remove old legend items
            while (ul.firstChild) {
              ul.firstChild.remove();
            }
            // Reuse the built-in legendItems generator
            const items = c.options.plugins?.legend?.labels?.generateLabels?.(c);
            items?.forEach((item) => {
              const li = document.createElement('li');
              li.style.margin = '10px';
              // Button element
              const button = document.createElement('button');
              button.classList.add(
                'btn-xs',
                'bg-white',
                'p-1',
                'dark:bg-slate-800',
                'text-slate-500',
                'dark:text-slate-400',
                'border',
                'rounded-md',
                'flex',
                'align-items-center',
                'border-slate-200',
                'dark:border-slate-700',
                'shadow-md',
              );
              button.style.opacity = item.hidden ? '.3' : '';
              button.onclick = () => {
                c.toggleDataVisibility(item.index!);
                c.update();
              };
              // Color box
              const box = document.createElement('span');
              box.style.display = 'block';
              box.style.width = tailwindConfig().theme.width[2];
              box.style.height = tailwindConfig().theme.height[2];
              box.style.backgroundColor = item.fillStyle as string;
              box.style.borderRadius = tailwindConfig().theme.borderRadius.sm;
              box.style.marginRight = tailwindConfig().theme.margin[1];
              box.style.pointerEvents = 'none';
              // Label
              const label = document.createElement('span');
              label.style.display = 'flex';
              label.style.alignItems = 'center';
              const labelText = document.createTextNode(item.text);
              label.appendChild(labelText);
              li.appendChild(button);
              button.appendChild(box);
              button.appendChild(label);
              ul.appendChild(li);
            });
          },
        },
      ],
    });
    setChart(newChart);
    return () => newChart.destroy();
  }, []);

  return (
    <div className="grow flex flex-col justify-center">
      <div>
        <canvas ref={canvas} width={width} height={height}></canvas>
      </div>
      <div className="px-5 pt-2 pb-6">
        <ul ref={legend} className="flex flex-wrap justify-center -m-1"></ul>
      </div>
    </div>
  );
}
