import 'flatpickr/dist/flatpickr.css';

import Flatpickr from 'react-flatpickr';
import { Box, SxProps, Theme } from '@mui/material';
import { Hook, Options } from 'flatpickr/dist/types/options';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { Instance } from 'flatpickr/dist/types/instance';

type Props = {
  width?: number;
  align?: 'left' | 'right';
  sx?: SxProps<Theme> | undefined;
  onDateChange: (value: string[]) => void;
  defaultStartDate: Date;
  defaultEndDate: Date;
  child?: boolean;
};

export default function SyncedDatePicker(props: Props) {
  const { align, onDateChange, width = 300, defaultStartDate, defaultEndDate, child } = props;

  const flatpickrInstanceRef = useRef<Instance | null>(null);

  const onReady: Hook = (selectedDates: any, dateStr: any, instance: Instance) => {
    flatpickrInstanceRef.current = instance;
    (instance.element as HTMLInputElement).value = dateStr.replace('to', '-');
    const customClass = align ?? '';
    instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
  };

  const onChange: Hook = (selectedDates: any, dateStr: any, instance: Instance) => {
    (instance.element as HTMLInputElement).value = dateStr.replace('to', '-');
    onDateChange(selectedDates.map((x: any) => moment(x).format('YYYY-MM-DD')));
  };

  const options: Options = {
    mode: 'range',
    static: true,
    monthSelectorType: 'static',
    dateFormat: 'M j, Y',
    defaultDate: [defaultStartDate, defaultEndDate],
    prevArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady,
    onChange,
  };

  useEffect(() => {
    if (flatpickrInstanceRef.current) {
      flatpickrInstanceRef.current.setDate([defaultStartDate, defaultEndDate]);
    }

    if (child) {
      console.log('Child Date Rebuilt', defaultStartDate, defaultEndDate);
      console.log('Child Date Rebuilt Options', [defaultStartDate, defaultEndDate]);
    }
  }, [defaultStartDate, defaultEndDate, child]);

  return (
    <div className="relative">
      <Flatpickr
        className="text-sm cursor-pointer pl-9 border-slate-300 rounded-sm text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium w-[16.5rem] h-[2.6rem]"
        options={options}
        onReady={onReady}
        onChange={onChange}
      />
      <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
        <svg className="w-4 h-4 fill-current text-slate-500 dark:text-slate-400 ml-3" viewBox="0 0 16 16">
          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
        </svg>
      </div>
    </div>
  );
}
