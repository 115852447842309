import { useState } from 'react';
import { Retry } from '../Retry';
import Center from '../Center/Center';
import { TableData } from '@/core/types';
import { CircularProgress, useTheme } from '@mui/material';

type Props = {
  total?: number;
  error: boolean;
  title?: string;
  loading: boolean;
  subtitle?: string;
  refetch: () => void;
  headers: TableData<any>[];
  data: any[] | undefined;
};

export default function AppTable({ title, loading, error, total, refetch, subtitle, data, headers }: Props) {
  const theme = useTheme();
  const [isCheck, setIsCheck] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(data!.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };

  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  if (error) {
    return <Retry refetch={refetch} />;
  }

  return (
    <div>
      <div className="col-span-full bg-white dark:bg-slate-800 shadow-sm rounded-md border border-slate-200 dark:border-slate-700 mt-3">
        <header className="px-3 py-4">
          <h6 className="font-semibold text-slate-800 dark:text-slate-100">
            Total <span className="text-slate-400 dark:text-slate-500 font-medium">{total}</span>
          </h6>
        </header>

        <div className="">
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full dark:text-slate-300">
              {/* Table header */}
              <thead className=" text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
                <tr>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="flex items-center">
                      <label className="inline-flex">
                        <span className="sr-only">Select all</span>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                          className="dark:bg-slate-900/30 border focus:ring-0 focus:ring-offset-0 dark:disabled:bg-slate-700/30 dark:disabled:border-slate-700 dark:disabled:hover:border-slate-700"
                        />
                      </label>
                    </div>
                  </th>
                  {headers.map((header) => (
                    <th key={header.key} className={`p-2 whitespace-nowrap ${header.align ? `text-${header.align}` : ''}`}>
                      <div className="font-semibold text-left">{header.label}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm divide-y divide-slate-100 dark:divide-slate-700">
                {/* Rows */}
                {data?.map((item, index) => (
                  <tr key={index} className="mt-2">
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="flex items-center">
                        <label className="inline-flex">
                          <span className="sr-only">Select</span>
                          <input
                            type="checkbox"
                            onChange={handleClick}
                            checked={isCheck.includes(item.id)}
                            className="dark:bg-slate-900/30 border focus:ring-0 focus:ring-offset-0 dark:disabled:bg-slate-700/30 dark:disabled:border-slate-700 dark:disabled:hover:border-slate-700"
                          />
                        </label>
                      </div>{' '}
                    </td>
                    {headers.map((header) => (
                      <td key={header.key} className={`p-2 whitespace-nowrap text-slate-500 ${header.align ? `text-${header.align}` : ''}`}>
                        {header.render ? header.render(item, theme, refetch) : item[header.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
