import { Fragment, useState } from 'react';
import { PackageRequest } from '@/core/types';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import ModalBasic from '@/components/AppModal/ModalBasic';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';

type Props = {
  request: PackageRequest;
};

export default function PackageViewButton({ request }: Props) {
  const [open, setOpen] = useState(false);

  const handleViewPackage = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Tooltip title={'View Package'} arrow>
        <div className="flex items-center">
          <IconButton onClick={handleViewPackage}>
            <svg className="shrink-0 h-6 w-6 cursor-pointer" viewBox="0 0 12 12">
              <g>
                <g>
                  <polygon
                    className={`fill-current text-slate-400 hover:bg-blue-600`}
                    points="2.75,3.815918 8.190918,1.095459 6,0 0.559082,2.720459"
                  />
                </g>
                <g>
                  <polygon
                    className={`fill-current text-slate-400`}
                    points="9.309082,1.654541 3.8681641,4.375 6,5.440918 11.440918,2.720459"
                  />
                </g>
                <g>
                  <polygon className={`fill-current text-slate-400`} points="5.5,6.309082 0,3.559082 0,9.25 5.5,12" />
                </g>
                <g>
                  <polygon className={`fill-current text-slate-400`} points="6.5,6.309082 6.5,12 12,9.25 12,3.559082" />
                </g>
              </g>
            </svg>
          </IconButton>
          <span className="text-slate-400 ml-2 cursor-pointer hover:text-slate-500" onClick={handleViewPackage}>
            {request?.package?.packageName}
          </span>
        </div>
      </Tooltip>
      <ModalBasic
        width="w-3/4"
        isOpen={open}
        setIsOpen={handleClose}
        title={request?.package?.packageName}
        subtitle="This modal displays a curated collection of videos included in the package. Please note that these videos are currently not owned by the viewer and are for preview purposes only. As an admin, you can manage and assign these videos to users accordingly. Explore the contents and decide how they can enhance your platform."
      >
        <div className="p-4">
          <span className="flex">
            <strong>Description:</strong> &nbsp; <p>{request?.package?.packageDescription}</p>
          </span>
          <Grid pl={0} container spacing={1}>
            {request.package?.videos?.length === 0 && (
              <Grid item xs={12}>
                <Typography component={'div'} textAlign="center" variant="h4">
                  This package does not currently have any videos
                </Typography>
              </Grid>
            )}

            {request.package?.videos?.map((video: any) => (
              <Grid item key={video.id} xs={12} md={4}>
                <VideoPlayer video={video as any} videoType={'full'} onReady={(e) => null} />
                <br></br>
                <span className="mt-4">
                  <strong>{video.videoName}</strong>
                </span>
                <p className="">{video.description}</p>
              </Grid>
            ))}
          </Grid>
        </div>
      </ModalBasic>
    </Fragment>
  );
}
