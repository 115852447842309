import moment from 'moment';
import { useParams } from 'react-router';
import { Retry } from '@/components/Retry';

import Center from '@/components/Center/Center';
import DatePicker from '@/components/DatePicker';
import { Query } from '@core/constants/constants';
import { useContext, useState, useEffect } from 'react';
import { VideoAnalyticsViewerEntity } from '@/core/types';
import { RefreshButton } from '@/components/RefreshButton';
import { Box, CircularProgress, useTheme } from '@mui/material';
import AnalyticsVideoViewersTable from '../../components/AnalyticsViewers';
import { AnalyticsTokenContext } from '../../providers/AnalyticsTokenProvider';
import PaginationClassic from '@/components/PaginationClassic/PaginationClassic';
import { useGetVideoViewersQuery, useGetVideoViewsQuery } from '@features/state/slices/api/video-analytics-slice';
import { BackButton } from '@/components/BackButton';
import { SearchBar } from '@/components/SearchBar';
import AppTablePagination from '@/components/AppTablePagination';
import LineChart02 from '@/components/Charts/LineChart02';
import { addOneDay, addOneDayToDate, hexToRGB, tailwindConfig } from '@/core/utils/utils';
import { DateTime } from 'luxon';
import SyncedDatePicker from '../CustomVideoAnalytics/components/SyncedDatePicker';

export function VideoAnalyticsViewers() {
  const { id: videoId } = useParams();
  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    endDate: '',
    startDate: '',
    searchTerm: '',
  });

  const [loading, setLoading] = useState(true);
  const [errorData, setError] = useState<any>(null);
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const [dateDisplay, setDateDisplay] = useState('for the past month');
  const [analyticViewers, setAnalyticsViewers] = useState<VideoAnalyticsViewerEntity | undefined>();
  const currentDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(currentDate.getDate() - 6);
  const defaultEndDate = currentDate;
  const { data, isFetching, isError, isSuccess, refetch } = useGetVideoViewersQuery(
    { ...analyticsAuth, ...{ videoId }, ...{ query } },
    { refetchOnMountOrArgChange: true },
  );
  const [init, setInit] = useState(false);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  useEffect(() => {
    if (!isFetching && !isError && isSuccess && data) {
      setAnalyticsViewers(data.data);

      if (data.error) {
        setError(data.error);
      }

      setLoading(false);
    }
  }, [isFetching, isError, isSuccess]);

  // const handleDateChange = (dates: string[]) => {
  //   setInit(true);

  //   if (dates.length > 1) {
  //     // console.log(`Dates ${dates[0]}`);
  //     // console.log(`Dates converted h ${new Date(addOneDay(dates[0]))}`);

  //     const startDate = dates[0];
  //     const endDate = dates[1];
  //     if (startDate == endDate) {
  //       setDateDisplay(`on ${moment(startDate).format('LL')}`);
  //       setQuery({
  //         ...query,
  //         startDate,
  //         endDate: startDate,
  //       });
  //     } else {
  //       setDateDisplay(`between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`);
  //       setQuery({
  //         ...query,
  //         startDate,
  //         endDate,
  //       });
  //     }
  //   }
  // };

  const handleDateChange = (dates: string[]) => {
    setInit(true);
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setDateDisplay(`on ${moment(startDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate: startDate,
        });
        //Done to offset discrepancy with time zones
        setStartDate(new Date(startDate));
        setEndDate(new Date(endDate));
        // setStartDate(new Date(addOneDay(startDate)));
        // setEndDate(new Date(addOneDay(endDate)));
      } else {
        setDateDisplay(`between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate,
        });
        setStartDate(new Date(startDate));
        setEndDate(new Date(endDate));
      }
    }
  };

  const initDate = (dates: string[]) => {
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setDateDisplay(`on ${moment(startDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate: startDate,
        });
      } else {
        setDateDisplay(`between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate,
        });
      }
    }
  };

  // useEffect(() => {
  //   handleDateChange([defaultStartDate.toISOString(), defaultEndDate.toISOString()]);
  // }, []);

  useEffect(() => {
    console.log('Start Date datestring', startDate.toLocaleDateString());
    initDate([startDate.toISOString(), endDate.toISOString()]);
  }, []);

  const handleSearch = (searchTerm: string) => {
    const queryData = {
      searchTerm,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    const queryData = {
      page: pageNumber,
      limit: pageLimit,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  return (
    <div>
      <Box>
        <div className="flex justify-content-between mt-5">
          <div className="flex">
            <BackButton />
          </div>
          <div />
          <div className="flex align-items-center justify-content-between mr-4">
            <SearchBar placeholder="User Search" onChange={handleSearch} />
            &nbsp;&nbsp;&nbsp;
            {init ? (
              <SyncedDatePicker
                sx={{ ml: 2, mr: 2 }}
                onDateChange={handleDateChange}
                // defaultStartDate={defaultStartDate}
                // defaultEndDate={defaultEndDate}

                // defaultStartDate={new Date(startDate.toLocaleDateString())}
                // defaultEndDate={new Date(addOneDay(endDate.toLocaleDateString()))}
                // defaultStartDate={new Date(startDate.toDateString())}
                defaultStartDate={addOneDayToDate(startDate)}
                defaultEndDate={addOneDayToDate(endDate)}
                // defaultStartDate={startDate}
                // defaultEndDate={endDate}
              />
            ) : (
              <SyncedDatePicker
                sx={{ ml: 2, mr: 2 }}
                onDateChange={handleDateChange}
                // defaultStartDate={defaultStartDate}
                // defaultEndDate={defaultEndDate}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
              />
            )}
            <RefreshButton onRefresh={refetch} />
          </div>
        </div>

        {analyticViewers && (
          <Box>
            <AnalyticsVideoViewersTable
              viewers={analyticViewers?.views!}
              totalViews={analyticViewers?.views.meta.totalItems ?? 0}
              title={`Analytics Viewers`}
              subTitle={`Here are your video viewers ${dateDisplay}`}
            />
            <AppTablePagination totalPages={analyticViewers?.views.meta.totalPages} onChange={handlePaginationChanged} />
          </Box>
        )}

        <Center>{loading && <CircularProgress />}</Center>
        <Center>{errorData && <Retry refetch={refetch} />}</Center>
        <VideoViewTrend
          videoName={analyticViewers?.video?.videoName}
          syncedStartDate={startDate}
          syncStartDate={setStartDate}
          syncedEndDate={endDate}
          syncEndDate={setEndDate}
        />
      </Box>
    </div>
  );
}

function VideoViewTrend({
  videoName,
  syncedStartDate,
  syncedEndDate,
  syncEndDate,

  syncStartDate,
}: {
  videoName?: string;
  syncedStartDate: Date;
  syncedEndDate: Date;
  syncStartDate: React.Dispatch<React.SetStateAction<Date>>;
  syncEndDate: React.Dispatch<React.SetStateAction<Date>>;
}) {
  const { id: videoId } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorData, setError] = useState<any>(null);
  const [dateDisplay, setDateDisplay] = useState('for the past month');
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const [chartData, setChartData] = useState<any | undefined>();

  const [query, setQuery] = useState<any>({
    endDate: '',
    startDate: '',
  });

  const { data, isFetching, isError, isSuccess, refetch } = useGetVideoViewsQuery(
    { ...analyticsAuth, ...{ videoId }, ...{ query } },
    { refetchOnMountOrArgChange: true },
  );
  const currentDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(currentDate.getDate() - 6);
  const defaultEndDate = currentDate;

  const [init, setInit] = useState(false);
  useEffect(() => {
    if (!isFetching && !isError && isSuccess && data) {
      console.log(`Video Trend Data ${JSON.stringify(data.data)}`);
      setChartData({
        labels: data.data!.dataPoints.map((x: any) => ''),
        datasets: [
          {
            label: '',
            data: data.data!.dataPoints,
            borderColor: tailwindConfig().theme.colors.indigo[500],
            fill: true,
            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
            pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
            pointBorderWidth: 0,
            pointHoverBorderWidth: 0,
            clip: 20,
          },
        ],
      });

      if (data.error) {
        setError(data.error);
      }

      setLoading(false);
    }
  }, [isFetching, isError, isSuccess]);

  const handleDateChange = (dates: string[]) => {
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setDateDisplay(`on ${moment(startDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate: startDate,
        });
      } else {
        setDateDisplay(`between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate,
        });
      }
    }
  };

  const initDate = (dates: string[]) => {
    console.log('Start Date initStart Date called with ', dates);
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setDateDisplay(`on ${moment(addOneDayToDate(new Date(startDate))).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate: startDate,
        });
      } else {
        setDateDisplay(`between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate,
        });
      }
      setInit(true);
    }
  };
  const updateQuery = (dates: string[]) => {
    console.log('Start Date initStart Date called with ', dates);
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        // setDateDisplay(`on ${moment(addOneDayToDate(new Date(startDate))).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate: startDate,
        });
      } else {
        // setDateDisplay(`between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate,
        });
      }
    }
  };
  const updateDateDisplayed = (dates: string[]) => {
    console.log('Start Date initStart Date called with ', dates);
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setDateDisplay(`on ${moment(addOneDayToDate(new Date(startDate))).format('LL')}`);
        // setQuery({
        //   ...query,
        //   startDate,
        //   endDate: startDate,
        // });
      } else {
        setDateDisplay(
          `between ${moment(addOneDayToDate(new Date(startDate))).format('LL')} and ${moment(addOneDayToDate(new Date(endDate))).format(
            'LL',
          )}`,
        );
        // setQuery({
        //   ...query,
        //   startDate,
        //   endDate,
        // });
      }
    }
  };

  // useEffect(() => {
  //   handleDateChange([defaultStartDate.toISOString(), defaultEndDate.toISOString()]);
  // }, []);

  useEffect(() => {
    if (init) {
      updateQuery([syncedStartDate.toISOString(), syncedEndDate.toISOString()]);
      updateDateDisplayed([syncedStartDate.toISOString(), syncedEndDate.toISOString()]);
    } else {
      initDate([syncedStartDate.toISOString(), syncedEndDate.toISOString()]);
    }
  }, [syncedStartDate, syncedEndDate]);

  return (
    <div>
      {/* <div className="flex align-items-center justify-content-between mr-4 mb-2"> */}
      <div className="flex items-end justify-end mr-4 mb-2">
        {/* <div className="flex">
          <BackButton />
          <div />
        </div> */}
        <div className="flex">
          {/* <DatePicker
            sx={{ ml: 2, mr: 2 }}
            onDateChange={handleDateChange}
            defaultEndDate={defaultEndDate}
            defaultStartDate={defaultStartDate}
          /> */}
          <RefreshButton onRefresh={refetch} />
        </div>
      </div>

      <div className="mt-3 flex flex-col col-span-full sm:col-span-12 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
        <br></br>
        <header className="px-5 border-b border-slate-100 dark:border-slate-700 flex items-center">
          <h5 className="font-semibold text-slate-800 dark:text-slate-100">{videoName} Views</h5>
        </header>
        <div className="px-5">
          <div className="text-sm italic mb-2">Here are your video views {dateDisplay}</div>
          <div className="flex items-center">
            <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">{data?.data?.totalViews}</div>
            <div className="text-sm">{/* <span className="font-medium text-amber-500">97.4%</span> */}</div>
          </div>
        </div>
        {/* Chart built with Chart.js 3 */}
        <div className="grow">
          {/* Change the height attribute to adjust the chart height */}
          {chartData && <LineChart02 data={chartData} width={389} height={262} />}
        </div>
      </div>

      <Center>{loading && <CircularProgress />}</Center>
      <Center>{errorData && <Retry refetch={refetch} />}</Center>
    </div>
  );
}
