import PropTypes from 'prop-types';

import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, SvgIcon, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import ApexChart from 'react-apexcharts';
import styled from 'styled-components';
import DateSelect from '@/components/DateSelect/DateSelect';
import DatePicker from '@/components/DatePicker';
import { RefreshButton } from '@/components/RefreshButton';

export const Chart = styled(ApexChart)``;

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: (value: any) => `${value} views`, // Add percentage to the tooltip label
      },
      item: {
        formatter: (value: any) => `${value} views`, // Add percentage to the tooltip label
      },
      title: {
        // You can also customize the title if needed
        formatter: (seriesName: any) => seriesName,
      },
      style: {
        fontSize: '12px',
      },
      x: {
        show: true,
        formatter: (value: any) => `Month: ${value}`, // Optionally format x-axis tooltip value
      },
      marker: {
        show: true,
      },
    },
    colors: [theme.palette.primary.main, alpha(theme.palette.primary.main, 0.25)],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: 'solid',
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: '40px',
      },
    },
    stroke: {
      colors: ['transparent'],
      show: true,
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      labels: {
        // formatter: (value: any) => (value > 0 ? `${value}K` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

export const CustomVideoTrend = (props: any) => {
  const { chartSeries, sx } = props;
  const chartOptions = useChartOptions();

  return (
    <Box>
      <Card sx={sx}>
        <CardHeader
          title={
            <Typography fontWeight={500} fontSize={20}>
              Custom Video Trend
            </Typography>
          }
          action={<RefreshButton onRefresh={props.refetch} />}
        />

        <p className="px-3 mt-1 text-slate-500 dark:text-slate-100">
          This section shows the trend of video views over time, comparing the views for each month of the current year with the views for
          the corresponding months of the previous year. The data is presented in a bar chart format, with each bar representing a month.
          The height of each bar represents the number of video views for that month.
        </p>

        <CardContent>
          <Chart height={500} options={chartOptions} series={chartSeries} type="bar" width="100%" />
        </CardContent>
        <Divider />
      </Card>
    </Box>
  );
};

CustomVideoTrend.protoTypes = {
  chartSeries: PropTypes.array.isRequired,
  sx: PropTypes.object,
};
