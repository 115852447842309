import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AppTable from '@/components/AppTable';
import { useAuth } from '@/core/utils/utils';
import { SearchBar } from '@/components/SearchBar';
import DropdownClassic from '@/components/DropdownClassic';
import { RefreshButton } from '@/components/RefreshButton';
import { PaginatedList } from '@/core/constants/constants';
import { formatter } from '../data/TableData';
import TransitionWrapper from '@/components/TransitionWrapper';
import AppTablePagination from '@/components/AppTablePagination';
import { CompanyBaseSubscription, DropdownOption, TableData } from '@/core/types';
import {
  useGetSubscriptionStatusesQuery,
  useGetCompanyBaseSubcriptionsQuery,
  useCancelCompanyBaseSubscriptionImmediatelyMutation,
  useCancelCompanyBaseSubscriptionRenewalMutation,
} from '@/features/state/slices/api/subscriptions-slice';
import { DefaultOptions, SubscriptionStatus } from '@/core/enums/enums';
import { Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CancelIcon from '@mui/icons-material/Cancel';
import RenewIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import AppChip from '@/components/AppChip';
import moment from 'moment';
import React from 'react';
import MessageDisplay, { IMessageDisplayRef } from '@components/MessageDIsplay/MessageDisplay';
import toast from 'react-hot-toast';

const CustomVideoSubscriptionsTable = () => {
  const [query, setQuery] = useState<any>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const { user } = useAuth();

  const [statuses, setStatuses] = useState<any>([]);
  const navigate = useNavigate();
  // const [packageRequests, setPakageRequests] = useState<PaginatedList<CompanyBaseSubscription[]>>({
  //   items: [],
  //   meta: {
  //     itemCount: 0,
  //     totalItems: 0,
  //     totalPages: 0,
  //     currentPage: 0,
  //     itemsPerPage: 0,
  //   },
  // });

  const { data, isError, refetch, isSuccess, isFetching } = useGetCompanyBaseSubcriptionsQuery({ ...query, companyId: user?.id });

  const {
    data: subscriptionStatusesData,
    isError: subscriptionStatusesError,
    isSuccess: subscriptionStatusesSuccess,
    isFetching: subscriptionStatusesIsFetching,
  } = useGetSubscriptionStatusesQuery({});

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    setQuery((prev: any) => ({
      ...prev,
      page: pageNumber,
      limit: pageLimit,
    }));
  };

  const handleSearch = (searchTerm: string) => {
    const queryData = {
      searchTerm,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  const handleStatusChange = (option: DropdownOption) => {
    const queryData = {
      status: option.id,
    };

    if (queryData.status == 0) {
      queryData.status = null;
    }
    setQuery({
      ...query,
      ...queryData,
    });
  };

  // useEffect(() => {
  //   if (isSuccess && data?.data) {
  //     setPakageRequests(data?.data);
  //   }
  // }, [isFetching, isSuccess, data]);
  const [cancelSusbcriptionMutation, response] = useCancelCompanyBaseSubscriptionImmediatelyMutation();
  const [cancelSusbcriptionRenewalMutation, renewalResponse] = useCancelCompanyBaseSubscriptionRenewalMutation();
  const [cancelDialog, setCancelDialog] = useState(false);
  const messageRef = React.createRef<IMessageDisplayRef>();

  const toggleCancelDialog = () => {
    setCancelDialog(!cancelDialog);
  };

  const cancelSubscription = async (selectedSubscription: CompanyBaseSubscription) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to cancel this subscription? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Select Cancellation Option',
          text: 'Would you like to cancel the subscription immediately or just cancel auto-renewal?',
          icon: 'question',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Cancel Immediately',
          denyButtonText: 'Cancel Auto Renewal',
          confirmButtonColor: '#3085d6',
          denyButtonColor: '#f39c12',
          cancelButtonColor: '#d33',
        }).then(async (subResult) => {
          if (subResult.isConfirmed) {
            try {
              const response = await cancelSusbcriptionMutation({
                subscriptionId: selectedSubscription?.id,
                stripeSubscriptionId: selectedSubscription?.stripeSubscriptionId,
              }).unwrap();

              if (response.success) {
                messageRef.current?.showSuccess('Subscription successfully cancelled');
                Swal.fire({
                  title: 'Subscription was successfully cancelled',
                  text: 'You will be now redirected to the subscriptions purchase page',
                  icon: 'success',
                });
                setTimeout(async () => {
                  // await onRefresh();
                  //TODO Check subscription status
                  // navigate('create');
                }, 1000);
              } else {
                console.log('ERROR CANCEL ', response.error?.message!);
                Swal.fire({
                  title: 'We cannot cancel your subscription Immediately at this time',
                  text: 'Please contact your administrator',
                  icon: 'error',
                });
              }
            } catch (error) {
              console.log('ERROR CANCEL ', error);
              Swal.fire({
                title: 'We cannot cancel your subscription Immediately at this time',
                text: 'Please contact your administrator',
                icon: 'error',
              });
            }
          } else if (subResult.isDenied) {
            try {
              const response = await cancelSusbcriptionRenewalMutation({
                subscriptionId: selectedSubscription?.id,
                stripeSubscriptionId: selectedSubscription?.stripeSubscriptionId,
              }).unwrap();

              if (response.success) {
                messageRef.current?.showSuccess('Subscription successfully cancelled');
                Swal.fire({
                  title: 'Subscription Auto-Renewal was successfully cancelled',
                  text: 'You will be able to access your features until the subscription expires',
                  icon: 'success',
                });
                setTimeout(async () => {
                  // await onRefresh();
                  //TODO Check subscription status
                  // navigate('create');
                }, 1000);
              } else {
                console.log('ERROR CANCEL ', response.error?.message!);
                Swal.fire({
                  title: 'We cannot cancel your subscription auto-renewal at this time',
                  text: 'Please contact your administrator',
                  icon: 'error',
                });
              }
            } catch (error) {
              console.log('ERROR CANCEL ', error);
              Swal.fire({
                title: 'We cannot cancel your subscription auto-renewal at this time',
                text: 'Please contact your administrator',
                icon: 'error',
              });
            }
          }
        });
      }
    });
  };
  const onRefresh = async () => {
    const result = await refetch().unwrap();

    if (!result.success) {
      toast.error(result.error?.message!);
    }

    // setSubscription(result.data!);
  };

  useEffect(() => {
    if (subscriptionStatusesSuccess && !subscriptionStatusesError && subscriptionStatusesData?.data) {
      const options = subscriptionStatusesData?.data.map((x) => ({ id: x.id, value: x.name }));

      options.unshift({ id: 0, value: DefaultOptions.ALL });
      setStatuses(options);
    }
  }, [subscriptionStatusesIsFetching, subscriptionStatusesSuccess, subscriptionStatusesData]);
  // const mockCompanyBaseSubscription: CompanyBaseSubscription = {
  //   id: 1,
  //   isActive: true,
  //   stripeSubscriptionId: 'sub_1N98ZzTGvVEuJGAwxI2lkPQL',
  //   subscriptionAmount: 299,
  //   subscriptionName: 'Basic Video',
  //   trialPeriod: 1703078000, // Example timestamp (adjust as needed)
  //   isTrial: false,
  //   baseSubscriptionId: 1,
  //   companyId: 12,
  //   status: 1, // Replace with meaningful status value (e.g., "active")
  //   subscriptionStatus: {
  //     id: 1,
  //     name: 'Active',
  //     description: null,
  //   },
  //   paymentMethod: 'card_1MjVBNTGvVEuJGAwxH1jIJsD',
  //   isCustom: false,
  //   videoPackageDiscount: '0',
  //   usersCovered: '3',
  //   setupCost: '0',
  //   billingMonthlyThreshold: '5.00',
  //   upFrontBillingCost: '299.00',
  //   additionalUserCost: '9.99',
  //   pricePlan: 'monthlyContractPriceId',
  //   nextBillDate: '2023-11-21T08:00:00.000Z',
  //   billingCount: 0,
  //   createdAt: '2023-05-23T19:15:30.000Z',
  //   updatedAt: '2023-05-23T19:15:30.000Z',
  // };

  const customSubscriptionHeaders: TableData<CompanyBaseSubscription>[] = [
    // {
    //   key: 'id',
    //   label: 'ID',
    // },
    {
      key: 'name',
      label: 'Name',
      render: (item) => (
        <Tooltip title={item?.subscriptionName} arrow>
          <Typography noWrap variant="subtitle1">
            {item?.subscriptionName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      key: 'amount',
      label: 'Amount',
      align: 'left',
      render: (item) => (
        <Typography variant="subtitle1" noWrap sx={{ width: 'fit-content' }}>
          {formatter.format(item?.subscriptionAmount)}
        </Typography>
      ),
    },
    {
      key: 'status',
      label: 'Status',
      align: 'left',
      render: (item) => {
        switch (item.status) {
          case SubscriptionStatus.ACTIVE:
            return <AppChip color="success">{item.subscriptionStatus.name}</AppChip>;
          case SubscriptionStatus.FAILED:
            return <AppChip color="error">{item.subscriptionStatus.name}</AppChip>;
          case SubscriptionStatus.CANCELLED:
            return <AppChip color="error">{item.subscriptionStatus.name}</AppChip>;
          case SubscriptionStatus.EXPIRED:
            return <AppChip color="warning">{item.subscriptionStatus.name}</AppChip>;
          case SubscriptionStatus.INCOMPLETE:
            return <AppChip color="info">{item.subscriptionStatus.name}</AppChip>;
          case SubscriptionStatus.CancelAutoRenewal:
            return <AppChip color="warning">{item.subscriptionStatus.name}</AppChip>;
          default:
            return <AppChip>Unknown</AppChip>;
        }
      },
    },

    {
      key: 'created',
      label: 'Created',
      align: 'left',
      render: (item) => <Box>{moment(item?.createdAt).format('LL')}</Box>,
    },
    {
      key: 'expires',
      label: 'Expires',
      align: 'left',
      render: (item) => <Box>{moment(item?.nextBillDate).format('LL')}</Box>,
    },

    // {
    //   key: 'actions',
    //   label: 'Actions',
    //   align: 'left',
    //   render: (item, theme, refetch) => <Box></Box>,
    // },

    {
      key: 'actions',
      label: 'Actions',
      align: 'left',
      render: (item) => {
        const handleMoreActions = () => {
          // navigate('create');

          Swal.fire({
            icon: 'info',
            title: `No more Actions available at this time`,
            text: 'More actions will be available with more updates',
          });
        };

        const handleCancelAction = (subsciption: CompanyBaseSubscription) => {
          cancelSubscription(subsciption);
          // Swal.fire({
          //   icon: 'info',
          //   title: `No more Actions available at this time`,
          //   text: 'More actions will be available with more updates',
          // });
        };

        const handleRenewAction = () => {
          navigate('create');
        };

        const renderActionButton = () => {
          switch (item.status) {
            case SubscriptionStatus.ACTIVE:
              return (
                <>
                  <Tooltip title="Cancel Subscription">
                    <IconButton onClick={() => handleCancelAction(item)}>
                      <CancelIcon color="error" />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleMoreActions}>
                    <MoreVertIcon />
                  </IconButton>
                </>
              );
            case SubscriptionStatus.FAILED:
            case SubscriptionStatus.EXPIRED:
            case SubscriptionStatus.CANCELLED:
              return (
                <>
                  <Tooltip title="Renew Subscription">
                    <IconButton>
                      <RenewIcon onClick={handleRenewAction} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleMoreActions}>
                    <MoreVertIcon />
                  </IconButton>
                </>
              );
            case SubscriptionStatus.INCOMPLETE:
              return (
                <>
                  <Tooltip title="Contact Administrator">
                    <IconButton>
                      <ErrorIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleMoreActions}>
                    <MoreVertIcon />
                  </IconButton>
                </>
              );
            case SubscriptionStatus.CancelAutoRenewal:
              return (
                <>
                  <Tooltip title={`You're subscription will expire on ${item.nextBillDate}`}>
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleMoreActions}>
                    <MoreVertIcon />
                  </IconButton>
                </>
              );
            default:
              return (
                <>
                  <Tooltip title="Unknown Status, Contact Administrator">
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleMoreActions}>
                    <MoreVertIcon />
                  </IconButton>
                </>
              );
          }
        };

        return <>{renderActionButton()}</>;
      },
    },
  ];
  return (
    <>
      <Helmet>
        <title>Subscriptions</title>
      </Helmet>
      <TransitionWrapper>
        <div>
          <div className="flex justify-between">
            <div>
              <header className="px-1 pt-3 mb-1 border-b border-slate-100 dark:border-slate-700">
                <h4 className="font-semibold text-slate-800 dark:text-slate-100">Subscriptions</h4>
              </header>
            </div>
            <div className="flex justify-end space-x-3 items-center flex-wrap">
              <SearchBar onChange={handleSearch} />
              <DropdownClassic options={statuses} onSelect={handleStatusChange} />
              <RefreshButton onRefresh={refetch} />
              {/* TODO Add a Edit/Add Subscription Button  */}
              {/* <Tooltip title={"Add A Subscription"} placement="top" arrow>
      <IconButton onClick={onRefresh}>
        <RefreshIcon sx={{ width: 24, height: 24, color: "text.disabled" }} />
      </IconButton>
    </Tooltip> */}
            </div>
          </div>

          <AppTable
            error={isError}
            refetch={refetch}
            loading={isFetching}
            data={data?.data?.items ?? []}
            headers={customSubscriptionHeaders}
            total={data?.data?.meta.totalItems ?? 0}
          />
          <AppTablePagination totalPages={data?.data?.meta.totalPages ?? 1} onChange={handlePaginationChanged}></AppTablePagination>
        </div>
      </TransitionWrapper>
    </>
  );
};

export default CustomVideoSubscriptionsTable;
