import { Helmet } from 'react-helmet-async';
import { useAuth } from '@core/utils/utils';
import { useState, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, Grid, Container, Box } from '@mui/material';
import TransitionWrapper from '@/components/TransitionWrapper';
import PackageSubscriptionsTable from './components/PackageSubscriptionsTable';
import CustomVideoSubscriptionsTable from './components/CustomVideoSubscriptionsTable';

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
);

function ManageSubscriptions() {
  const { user } = useAuth();

  const [currentTab, setCurrentTab] = useState<string>('custom_video_subscription');

  const tabs = [
    { value: 'custom_video_subscription', label: 'Sales Assist Subscription' },
    //DEPRECATED Package Subsciption
    // { value: 'package_subscription', label: 'Package Subscription' },
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <>
      <Helmet>
        <title>Subscriptions</title>
      </Helmet>
      <TransitionWrapper>
        <Box sx={{ minWidth: '100%' }}>
          <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
            <Grid item xs={12}>
              <TabsWrapper
                onChange={handleTabsChange}
                value={currentTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </TabsWrapper>
            </Grid>
            <Grid item xs={12}>
              {currentTab === 'custom_video_subscription' && <CustomVideoSubscriptionsTable />}
              {currentTab === 'package_subscription' && <PackageSubscriptionsTable />}
            </Grid>
          </Grid>
        </Box>
      </TransitionWrapper>
    </>
  );
}

export default ManageSubscriptions;
