import { baseQueryWithReauth } from './base-slice';
import { VideoPackage } from '@core/types';
import { Endpoints } from '@core/constants/endpoint';
import { ApiError, ServiceResult } from '@core/index';
import { PaginatedList } from '@core/constants/constants';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['Packages'] as readonly TagDescription<'Packages'>[] & string[];

export const videoPackageSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.VideoPackage,
  endpoints: (builder) => ({
    getVideoPackages: builder.query<ServiceResult<PaginatedList<VideoPackage[]>, ApiError>, any>({
      query: () => `${BaseEndpoints.VideoPackage}`,
      providesTags: descriptionTags,
    }),
    getUserVideoPackages: builder.query<ServiceResult<PaginatedList<VideoPackage[]>, ApiError>, any>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.VideoPackage}/company/${args.id}`,
        params: { ...args },
      }),
      providesTags: descriptionTags,
    }),
    getVideoPackageById: builder.query<ServiceResult<VideoPackage, ApiError>, any>({
      query: (args) => `${BaseEndpoints.VideoPackage}/${args.packageId}`,
      providesTags: descriptionTags,
    }),
    createVideoPackage: builder.mutation({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `${BaseEndpoints.VideoPackage}` }),
      invalidatesTags: descriptionTags,
    }),
  }),
});

export const { useGetUserVideoPackagesQuery, useGetVideoPackagesQuery, useCreateVideoPackageMutation, useGetVideoPackageByIdQuery } =
  videoPackageSlice;
