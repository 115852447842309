import Center from '@components/Center/Center';
import { SubscriptionPlan, MONTHLY_CONTRACT_KEY, ONE_YEAR_CONTRACT_KEY, SIX_MONTH_CONTRACT_KEY } from '@core/constants/constants';
import FlipNumbers from 'react-flip-numbers';
import { Box, Typography } from '@mui/material';
import { convertDaysToYearsMonthsDays, formatDateDuration, toSentenceCase } from '@/core/utils/utils';
type Props = {
  pricePlan: string;
  isSelected: boolean;
  config: DisplayConfig;
  subscription: SubscriptionPlan & { intervalPrice?: any };
  onSelect: (subscription: SubscriptionPlan, pricingPlan: string) => void;
};

type DisplayConfig = {
  displayTrialPeriod: boolean;
  displayUpFrontBilling: boolean;
};

export const CheckOutSubscriptionItem = (props: Props) => {
  const { config, pricePlan, subscription, onSelect, isSelected } = props;

  const determinePrice = () => {
    if (subscription.hasOwnProperty('intervalPrice')) {
      return subscription.intervalPrice;
    }
    switch (pricePlan) {
      case SIX_MONTH_CONTRACT_KEY:
        return subscription.sixMonthContractCost;
      //DEPRECATED Two year contract plan
      // case TWO_YEAR_CONTRACT_KEY:
      //   return subscription.twoYearContractCost;
      case ONE_YEAR_CONTRACT_KEY:
        return subscription.oneYearContractCost;
      case MONTHLY_CONTRACT_KEY:
        return subscription.monthlyContractCost;

      default:
        return 0;
    }
  };

  const priceWeight = (): number => {
    const pricePlan = subscription.pricePlan;
    switch (pricePlan) {
      case SIX_MONTH_CONTRACT_KEY:
        return 6;
      case ONE_YEAR_CONTRACT_KEY:
        return 12;
      case MONTHLY_CONTRACT_KEY:
        return 1;
    }
    return -1;
  };

  const subscriptionIntervalTitle = (): string => {
    if (subscription.hasOwnProperty('intervalPrice')) {
      return subscription.intervalPrice;
    }
    switch (pricePlan) {
      case SIX_MONTH_CONTRACT_KEY:
        return 'For Six Months (and renewed every six months)';
      //DEPRECATED Two year contract plan
      // case TWO_YEAR_CONTRACT_KEY:
      //   return subscription.twoYearContractCost;
      case ONE_YEAR_CONTRACT_KEY:
        return 'For One Year (and renewed yearly)';
      case MONTHLY_CONTRACT_KEY:
        return 'For One Month (and renewed monthly)';

      default:
        return 'Custom or Unkown Interval selected Please contact your administrator before selecting ';
    }
  };
  const chooseSubscription = (subscription: SubscriptionPlan) => {
    onSelect(subscription, pricePlan);
  };

  return (
    <div className="col-lg-4" style={{ height: 700 }}>
      <div className="card mb-5 " style={{ height: 700 }}>
        <div className="card-body">
          <Box mt={2} mb={2}>
            <h5 className="card-title text-muted text-uppercase text-center ">{subscription?.name}</h5>
          </Box>
          <Box mb={4} textAlign="center">
            {config?.displayUpFrontBilling && <span style={{ color: 'grey' }}>Pay ${subscription?.upFrontBillingCost} unfront and</span>}

            <h6
              className="card-price text-center mt-2  	"
              style={{
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FlipNumbers
                play
                width={30}
                height={40}
                color="black"
                background="white"
                numberStyles={{ textAlign: 'center' }}
                numbers={`$ ${determinePrice().toString()}`}
              />
              <span className="period"> &nbsp;/month</span>
              <br></br>
            </h6>

            <div className="py-6"> {toSentenceCase(subscriptionIntervalTitle())}</div>
          </Box>

          <ul className="fa-ul">
            <Box display="flex" mt={1}>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
              </li>
              Users covered
              <Box ml={0.5} mt={0.5}>
                <FlipNumbers
                  play
                  width={8}
                  height={13.5}
                  color="black"
                  numberStyles={{ fontWeight: 'bold' }}
                  background="white"
                  numbers={`${subscription?.usersCovered}`}
                />
              </Box>
            </Box>

            <Box mt={1}>
              <Typography variant="caption" fontSize={12}>
                FEATURES
              </Typography>
            </Box>

            <Box mt={1}>
              {subscription?.subscriptionItemsIncluded?.map((item) => (
                <Box mt={0.5}>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-check"></i>
                    </span>
                    {toSentenceCase(item)}
                  </li>{' '}
                </Box>
              ))}
            </Box>
          </ul>
          <Center>
            <Typography variant="h4" mt={1} mb={4}>
              Your Final Price: ${(determinePrice() * priceWeight()).toFixed(2)}
            </Typography>
          </Center>
          {subscription.hasTrialPeriod && subscription.numberOfDaysInTrialPeriod && subscription.numberOfDaysInTrialPeriod != 0 && (
            <>
              <Box mt={1} textAlign="center">
                <Typography variant="h3" fontSize={15}>
                  {`FREE TRIAL INCLUDED lasting ${formatDateDuration(
                    convertDaysToYearsMonthsDays(subscription.numberOfDaysInTrialPeriod ?? 100),
                  )}`}
                </Typography>
                <Center>
                  <Typography variant="body2" fontSize={13}>
                    You will only be charged after the free trial period ends.
                  </Typography>
                </Center>
              </Box>
            </>
          )}
          <div
            className="d-grid"
            style={{
              left: 10,
              right: 10,
              bottom: 30,
              position: 'absolute',
            }}
          >
            {isSelected ? (
              <a className="btn btn-primary text-uppercase">Selected</a>
            ) : (
              <a
                onClick={() => chooseSubscription(subscription)}
                className={`btn ${isSelected ? 'btn-primary' : 'btn-dark'} text-uppercase`}
              >
                Choose
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
