import UserForm from './components/UserForm';
import DeleteUser from './components/DeleteUser';
import { BackButton } from '@/components/BackButton';
import AddUserCredit from './components/AddUserCredit';
import ChangeUserPassword from './components/ChangeUserPassword';
import SalesAssociateProvider from '../../providers/SalesAssociateProvider';
import TransitionWrapper from '@/components/TransitionWrapper';

const SalesAssociateDetails = () => {
  return (
    <TransitionWrapper>
      <SalesAssociateProvider>
        <BackButton />
        <main className="grid grid-cols-5 gap-6 ">
          <div className="col-span-3 grow">
            <UserForm />
          </div>
          <div className="col-span-2 space-y-6">
            {/* <AddUserCredit /> */}
            <ChangeUserPassword />
            <DeleteUser />
          </div>
        </main>
      </SalesAssociateProvider>
    </TransitionWrapper>
  );
};

export default SalesAssociateDetails;
