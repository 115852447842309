import React, { useState, useEffect } from 'react';

type Props = {
  events?: any[];
};

function Calendar({ events = [] }: Props) {
  const today = new Date();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [month, setMonth] = useState(today.getMonth());

  const [year, setYear] = useState(today.getFullYear());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [startingBlankDays, setStartingBlankDays] = useState([]);
  const [endingBlankDays, setEndingBlankDays] = useState([]);

  const isToday = (date: any) => {
    const day = new Date(year, month, date);
    return today.toDateString() === day.toDateString() ? true : false;
  };

  const getEvents = (date: any) => {
    return events.filter((e) => {
      //alert(new Date(year, month, date).toDateString());
      return new Date(e.eventStart).toDateString() === new Date(year, month, date).toDateString();
    });
  };

  const eventColor = (color: any) => {
    switch (color) {
      case 'sky':
        return 'text-white bg-sky-500';
      case 'indigo':
        return 'text-white bg-indigo-500';
      case 'yellow':
        return 'text-white bg-amber-500';
      case 'emerald':
        return 'text-white bg-emerald-500';
      case 'red':
        return 'text-white bg-rose-400';
      default:
        return '';
    }
  };

  const getDays = () => {
    const days = new Date(year, month + 1, 0).getDate();

    // starting empty cells (previous month)
    const startingDayOfWeek = new Date(year, month).getDay();
    let startingBlankDaysArray = [];
    for (let i = 1; i <= startingDayOfWeek; i++) {
      startingBlankDaysArray.push(i);
    }

    // ending empty cells (next month)
    const endingDayOfWeek = new Date(year, month + 1, 0).getDay();
    let endingBlankDaysArray = [];
    for (let i = 1; i < 7 - endingDayOfWeek; i++) {
      endingBlankDaysArray.push(i);
    }

    // current month cells
    let daysArray = [];
    for (let i = 1; i <= days; i++) {
      daysArray.push(i);
    }

    setStartingBlankDays(startingBlankDaysArray as any);
    setEndingBlankDays(endingBlankDaysArray as any);
    setDaysInMonth(daysArray as any);
  };

  useEffect(() => {
    getDays();
  }, []);

  return (
    <div className="flex overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="grow">
          <div className="w-full mx-auto">
            {/* <div className="sm:flex sm:justify-between sm:items-center mb-4">
    
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
           
                <button
                  className="btn px-2.5 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300 disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed"
                  disabled={month === 0 ? true : false}
                  onClick={() => {
                    setMonth(month - 1);
                    getDays();
                  }}
                >
                  <span className="sr-only">Previous month</span>
                  <wbr />
                  <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                    <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
                  </svg>
                </button>

           
                <button
                  className="btn px-2.5 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300 disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed"
                  disabled={month === 11 ? true : false}
                  onClick={() => {
                    setMonth(month + 1);
                    getDays();
                  }}
                >
                  <span className="sr-only">Next month</span>
                  <wbr />
                  <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                    <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                  </svg>
                </button>

                <hr className="w-px h-full bg-slate-200 dark:bg-slate-700 border-none mx-1" />
              </div>
            </div> */}

            {/* Calendar table */}
            <div className="bg-white dark:bg-slate-800 rounded-sm shadow overflow-hidden">
              {/* Days of the week */}
              <div className="grid grid-cols-7 gap-px border-b border-slate-200 dark:border-slate-700">
                {dayNames.map((day) => {
                  return (
                    <div className="px-1 py-3" key={day}>
                      <div className="text-slate-500 text-sm font-medium text-center lg:hidden">{day.substring(0, 3)}</div>
                      <div className="text-slate-500 dark:text-slate-400 text-sm font-medium text-center hidden lg:block">{day}</div>
                    </div>
                  );
                })}
              </div>

              {/* Day cells */}
              <div className="grid grid-cols-7 gap-px bg-slate-200 dark:bg-slate-700">
                {/* Diagonal stripes pattern */}
                <svg className="sr-only">
                  <defs>
                    <pattern id="stripes" patternUnits="userSpaceOnUse" width="5" height="5" patternTransform="rotate(135)">
                      <line
                        className="stroke-current text-slate-200 dark:text-slate-700 opacity-50"
                        x1="0"
                        y="0"
                        x2="0"
                        y2="5"
                        strokeWidth="2"
                      />
                    </pattern>
                  </defs>
                </svg>

                {/* Days of the current month */}
                {daysInMonth.map((day) => {
                  return (
                    <div className="relative bg-white dark:bg-slate-800 h-55 sm:h-28 lg:h-55 overflow-hidden" key={day}>
                      <div className="h-full flex flex-col justify-between">
                        {/* Events */}
                        <div className="grow flex flex-col relative p-0.5 sm:p-1.5 overflow-hidden">
                          {getEvents(day).map((event) => {
                            return (
                              <button className="relative h-55 w-full text-left mb-1" key={event.eventName}>
                                <div className={`px-2 py-0.5 rounded overflow-hidden ${eventColor(event.eventColor)}`}>
                                  {/* Event name */}
                                  <div className="text-xs font-semibold truncate">{event.eventName}</div>
                                  {/* Event time */}
                                  <div className="h-40" />
                                  <div className="text-xs uppercase truncate hidden sm:block">
                                    {/* Start date */}

                                    {event.eventStart && (
                                      <span>
                                        {
                                          //event.eventStart.toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' })
                                        }
                                      </span>
                                    )}
                                    {/* End date */}
                                    {event.eventEnd && (
                                      <span>
                                        -{' '}
                                        <span>
                                          {event.eventEnd instanceof Date
                                            ? event.eventEnd.toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' })
                                            : ''}
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </button>
                            );
                          })}
                          <div
                            className="absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-white dark:from-slate-800 to-transparent pointer-events-none"
                            aria-hidden="true"
                          ></div>
                        </div>
                        {/* Cell footer */}
                        <div className="flex justify-between items-center p-0.5 sm:p-1.5">
                          {/* More button (if more than 2 events) */}
                          {getEvents(day).length > 2 && (
                            <button className="text-xs text-slate-500 dark:text-slate-300 font-medium whitespace-nowrap text-center sm:py-0.5 px-0.5 sm:px-2 border border-slate-200 dark:border-slate-700 rounded">
                              <span className="md:hidden">+</span>
                              <span>{getEvents(day).length - 2}</span> <span className="hidden md:inline">more</span>
                            </button>
                          )}
                          {/* Day number */}
                          <button
                            className={`inline-flex ml-auto w-6 h-6 items-center justify-center text-xs sm:text-sm dark:text-slate-300 font-medium text-center rounded-full hover:bg-indigo-100 dark:hover:bg-slate-600 ${
                              isToday(day) && 'text-indigo-500'
                            }`}
                          >
                            {day}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Calendar;
