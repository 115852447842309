export default class Routes {
  public static Users = 'users';
  /** app */
  public static AppRoot = 'app';
  /** auth */
  public static AuthRoot = 'auth';
  public static Requests = 'requests';
  public static Settings = 'settings';
  public static MyVideos = 'my-videos';
  public static MyContent = 'content';
  public static Analytics = 'analytics';

  public static VideoPackages = 'packages';
  public static Subscription = 'subscriptions';
  public static Products = 'products';
  /**login */
  public static Login = 'login';
  public static SetPassword = 'set-password';
  public static ForgotPassword = 'forgot-password';
  public static Register = 'register';

  public static Dashboard = 'dashboard';
  public static PendingUsers = 'pending';
  public static SalesAssociates = 'sales-associates';
  public static SalesAssociatesById = 'sales-associates/:id';

  public static Videos = 'videos';
  public static Credit = 'credit';
  public static Package = 'package';
  public static CustomVideo = 'custom-video';
  /** custom-videos */
  public static CustomVideos = 'custom-videos';

  public static Account = 'account';
  public static Billing = 'billing';
  public static Feedback = 'feeback';
  public static Security = 'security';

  public static External = 'external';

  public static Auth = {
    Root: Routes.AuthRoot,
    Login: Routes.AuthRoot + '/' + Routes.Login,
    SetPassword: Routes.AuthRoot + '/' + Routes.SetPassword,
    ForgotPassword: Routes.AuthRoot + '/' + Routes.ForgotPassword,
  };

  public static App = {
    Dashboard: Routes.AppRoot + '/' + Routes.Dashboard,
    Users: {
      Root: Routes.Users,
      PendingUsers: Routes.AppRoot + '/' + Routes.Users + '/' + Routes.PendingUsers,
      SalesAssociates: {
        Base: Routes.AppRoot + '/' + Routes.Users + '/' + Routes.SalesAssociates,
        ById: Routes.AppRoot + '/' + Routes.Users + '/' + Routes.SalesAssociatesById,
      },
    },
    Requests: {
      Root: Routes.Requests,
      Credit: Routes.AppRoot + '/' + Routes.Requests + '/' + Routes.Credit,
      Package: Routes.AppRoot + '/' + Routes.Requests + '/' + Routes.Package,
      CustomVideo: Routes.AppRoot + '/' + Routes.Requests + '/' + Routes.CustomVideo,
    },
    Subscriptions: {
      Root: Routes.Subscription,
      Base: Routes.AppRoot + '/' + Routes.Subscription,
    },
    Products: {
      /** products */
      Root: Routes.Products,
      /** app/purchases */
      Base: Routes.AppRoot + '/' + Routes.Products,
    },
    Analytics: {
      Root: Routes.Analytics,
      Videos: Routes.AppRoot + '/' + Routes.Analytics + '/' + Routes.Videos,
      CustomVideos: Routes.AppRoot + '/' + Routes.Analytics + '/' + Routes.CustomVideos,
    },
    MyContent: {
      Root: Routes.MyContent,
      Base: Routes.AppRoot + '/' + Routes.MyContent,
      CustomVideos: Routes.AppRoot + '/' + Routes.MyContent + '/' + Routes.CustomVideos,
      Packages: Routes.AppRoot + '/' + Routes.MyContent + '/' + Routes.VideoPackages,
    },
    Settings: {
      Root: Routes.Settings,
      Account: Routes.AppRoot + '/' + Routes.Settings + '/' + Routes.Account,
      Billing: Routes.AppRoot + '/' + Routes.Settings + '/' + Routes.Billing,
      Security: Routes.AppRoot + '/' + Routes.Settings + '/' + Routes.Security,
      Feedback: Routes.AppRoot + '/' + Routes.Settings + '/' + Routes.Feedback,
    },
  };

  public static ExternalApp: {};
}
