import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Roles } from '@core/enums/enums';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import { useAuth } from '@core/utils/utils';
import { Helmet } from 'react-helmet-async';
import AppTable from '@/components/AppTable';
import AppButton from '@/components/AppButton';
import DatePicker from '@/components/DatePicker';
import { InputField } from '@/components/AppInput';
import { AccessLog, TableData } from '@/core/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import { RefreshButton } from '@/components/RefreshButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ResetPasswordSchema } from '@core/constants/schemas';
import AppTablePagination from '@/components/AppTablePagination';
import { RootState } from '@features/state/reducers/combinedReducers';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useChangeCompanyPasswordMutation, useGetAccessLogsQuery } from '@/features/state/slices/api/auth-slice';
import { ForgotPassword, setForgotPasswordEmail, setForgotPasswordToken } from '@features/state/slices/local/forgot.password.slice';

export const headers: TableData<AccessLog>[] = [
  {
    key: 'id',
    label: 'ID',
  },

  { key: 'device', label: 'Device', align: 'left' },
  { key: 'ipAddress', label: 'Ip Address', align: 'left' },
  {
    key: 'location',
    label: 'Location',
    align: 'left',
  },
  {
    key: 'date',
    label: 'Date/Time',
    align: 'left',
    render: (item) => <Typography>{moment(item.createdAt).format('MMMM Do YYYY, h:mm a')}</Typography>,
  },
];

function Security() {
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const { user } = useAuth();
  const errors: any = formErrors;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    startDate: '',
    endDate: '',
  });

  const { isFetching, data, isError, refetch } = useGetAccessLogsQuery(
    {
      params: query,
      companyId: user?.id,
    },
    { refetchOnFocus: true },
  );

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [changePasswordMutation] = useChangeCompanyPasswordMutation();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleOldPassword = () => setShowOldPassword(!showOldPassword);
  const toggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const { email, token } = useSelector<RootState>((state) => state.forgotPassword) as ForgotPassword;
  const currentDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(currentDate.getDate() - 6);

  const defaultEndDate = currentDate;
  const changePassword = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await changePasswordMutation({
        email,
        token,
        userRole: Roles.Admin,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      }).unwrap();

      if (error && !success) {
        toast.error(error.message!);
        return;
      }

      dispatch(setForgotPasswordEmail(null));
      dispatch(setForgotPasswordToken(null));

      navigate('/auth/login');

      toast.success('Password reset!');
    } catch (err: any) {
      toast.error(String(err));
    } finally {
      dispatch(toggleLoading());
    }
  };

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    setQuery((prev: any) => ({
      ...prev,
      page: pageNumber,
      limit: pageLimit,
    }));
  };

  const handleDateChange = (dates: string[]) => {
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setQuery({
          ...query,
          startDate,
          endDate: startDate,
        });
      } else {
        setQuery({
          ...query,
          startDate,
          endDate,
        });
      }
    }
  };
  useEffect(() => {
    handleDateChange([defaultStartDate.toISOString(), defaultEndDate.toISOString()]);
  }, []);

  return (
    <div className="grow overflow-y-auto h-full">
      <Helmet>
        <title>Settings - Security</title>
      </Helmet>
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-2">Security</h2>
        <p className="text-slate-500">
          Admin, you can change your account password here. This essential feature allows you to update your current password for improved
          security.
        </p>

        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mt-10">Change Password</h2>

        <p className="text-slate-500">
          Please enter your old password, new password, and confirm the new password to update your account credentials.
        </p>

        <div className="sm:flex flex-col lg:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-full lg:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="oldPassword">
              Old Password
            </label>
            <div className="relative">
              <InputField
                errors={errors?.oldPassword}
                value={watch('oldPassword')}
                type={showOldPassword ? 'text' : 'password'}
                onChange={(e) => setValue('oldPassword', e.target.value)}
              />
              <button
                type="button"
                className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={toggleOldPassword}
              >
                {showOldPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
              </button>
            </div>
            <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
              {errors?.oldPassword?.message}
            </label>
          </div>

          <div className="sm:w-full lg:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="newPassword">
              New Password
            </label>
            <div className="relative">
              <InputField
                errors={errors?.newPassword}
                value={watch('newPassword')}
                type={showNewPassword ? 'text' : 'password'}
                onChange={(e) => setValue('newPassword', e.target.value)}
              />
              <button
                type="button"
                className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={toggleNewPassword}
              >
                {showNewPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
              </button>
            </div>
            <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
              {errors?.newPassword?.message}
            </label>
          </div>

          <div className="sm:w-full lg:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <div className="relative">
              <InputField
                errors={errors?.confirmPassword}
                value={watch('confirmPassword')}
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={(e) => setValue('confirmPassword', e.target.value)}
              />
              <button
                type="button"
                className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={toggleConfirmPassword}
              >
                {showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
              </button>
            </div>
            <label className="block text-sm text-rose-500 mb-1 h-5" htmlFor="email">
              {errors?.confirmPassword?.message}
            </label>
          </div>
        </div>

        <div className="flex justify-end px-6">
          <AppButton onClick={handleSubmit(changePassword)}>Save</AppButton>
        </div>

        <div>
          <div className="flex justify-between items-center flex-wrap">
            <div>
              <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold">Access Logs</h2>
              <p>Track login activities to your account, including IP addresses, browsers, and devices used for authentication.</p>
            </div>

            <div className="flex justify-end space-x-3 items-center">
              <DatePicker
                sx={{ mr: 1 }}
                onDateChange={handleDateChange}
                defaultEndDate={defaultEndDate}
                defaultStartDate={defaultStartDate}
              />
              <RefreshButton onRefresh={refetch} />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <AppTable
            error={isError}
            headers={headers}
            refetch={refetch}
            loading={isFetching}
            data={data?.data?.items}
            total={data?.data?.meta.totalItems ?? 0}
          />
        </div>

        <AppTablePagination totalPages={data?.data?.meta?.totalPages ?? 1} onChange={handlePaginationChanged} />
      </div>
    </div>
  );
}

export default Security;
