import { CSSProperties } from 'react';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  className?: string | undefined;
  children?: React.ReactNode;
};

export default function AppButton({ children, disabled, style = { height: 43 }, onClick, className }: Props) {
  return (
    <button
      style={style}
      onClick={onClick}
      disabled={disabled}
      className={`px-3 py-2 font-medium text-sm inline-flex items-center justify-center border border-transparent rounded-[4px] leading-5 shadow-sm transition duration-150 ease-in-out bg-blue-500 hover:bg-blue-600 text-white ${className} ${
        disabled && 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed'
      }`}
    >
      {children}
    </button>
  );
}
