import { Helmet } from 'react-helmet-async';
import WelcomeBanner from './components/WelcomeBanner';
import TransitionWrapper from '@/components/TransitionWrapper';
import UserGrowthPortfolio from './components/UserGrowthPortfolio';
import ActiveUsersAnalytics from './components/ActiveUsersAnalytics';
import CreditRequestAnalytics from './components/CreditRequestAnalytics';
import ContentRequestPortfolio from './components/ContentRequestPortfolio';
import PackageRequestAnalytics from './components/PackageRequestAnalytics';
import ActiveSubscriptionsAnalytics from './components/ActiveSubscriptionsAnalytics';
import { Box } from '@mui/material';
import CompanyPortalTrainingVideo from './components/CompanyPortalTrainingVideo';
import SalesAssistMobileTrainingVideo from './components/SalesAssistTrainingVideo';

export default function DashboardAppPage() {
  return (
    <>
      <Helmet>
        <title>Dashboard </title>
      </Helmet>
      <TransitionWrapper>
        <main className="grow">
          <div className="w-full ">
            <WelcomeBanner />

            <div className="grid grid-cols-12 gap-6">
              {/* <CreditRequestAnalytics />

              <PackageRequestAnalytics /> */}

              {/* <ActiveSubscriptionsAnalytics /> */}
            </div>

            <div className="grid grid-cols-12 gap-6 mt-4">
              <ActiveUsersAnalytics />

              <UserGrowthPortfolio />
            </div>
            <div className="grid grid-cols-12 gap-6 mt-4">
              <CompanyPortalTrainingVideo />

              <SalesAssistMobileTrainingVideo />
            </div>
          </div>
        </main>
      </TransitionWrapper>
    </>
  );
}
