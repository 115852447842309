import { createApi, fetchBaseQuery, TagDescription } from '@reduxjs/toolkit/query/react';

const { REACT_APP_IP_API } = process.env;

const descriptionTags = ['UserLocation'] as readonly TagDescription<'UserLocation'>[] & string[];

export const userLocationSlice = createApi({
  reducerPath: 'user-location',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_IP_API,
  }),
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    getUserLocation: builder.query({
      query: () => '',
      providesTags: descriptionTags,
    }),
  }),
});
export const { useLazyGetUserLocationQuery } = userLocationSlice;
