import { baseQueryWithReauth } from './base-slice';
import { Notification } from '@core/types';
import { BaseEndpoints } from '@core/enums/enums';
import { ApiError, ServiceResult } from '@core/index';
import { PaginatedList } from '@core/constants/constants';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['Notifications'] as readonly TagDescription<'Notifications'>[] & string[];

export const notificationSlice = createApi({
  reducerPath: BaseEndpoints.Notification,
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    getCompanyNotifications: builder.query<ServiceResult<PaginatedList<Notification[]>, ApiError>, any>({
      query: (companyID) => ({
        url: `${BaseEndpoints.Notification}/company/${companyID}`,
        params: { limit: 10, page: 1 },
      }),
      providesTags: descriptionTags,
    }),
  }),
});

export const { useGetCompanyNotificationsQuery } = notificationSlice;
