import { useFindPaymentMethodQuery, useModifyPaymentMethodMutation } from '@features/state/slices/api/payment-methods.slice';
import { CircularProgress, Divider, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PaymentMethodEditSchema } from '@core/constants/schemas';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { StyledButton } from '@/components/Styled/StyledButton';
import { useSearchParams } from 'react-router-dom';

const PaymentMethodsEditForm = ({ close }: { close: () => void }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PaymentMethodEditSchema),
  });
  const [searchParams] = useSearchParams();
  const paymentMethodID = searchParams.get('p_id');

  const { data: paymentMethod = {}, isFetching, isSuccess } = useFindPaymentMethodQuery(paymentMethodID);

  const [modifyPaymentMethod, editResponse] = useModifyPaymentMethodMutation();

  useEffect(() => {
    console.log('pm: ', paymentMethod);
    if (paymentMethod.data) {
      setInitialValues(paymentMethod.data);
    }
  }, [paymentMethod]);

  const setInitialValues = (data: any) => {
    //
    setValue('name', data.billingDetails.name);

    setValue('city', data.billingDetails.address.city);

    setValue('state', data.billingDetails.address.state);

    setValue('country', data.billingDetails.address.country);

    setValue('line1', data.billingDetails.address.line1);

    setValue('line2', data.billingDetails.address.line2);

    setValue('postalCode', data.billingDetails.address.postal_code);

    setValue('phoneNumber', data.billingDetails.phone);

    setValue('email', data.billingDetails.email);
  };

  const editPaymentMethod = async (data: any) => {
    const updatedMethod = formatData(data);

    try {
      const apiResponse = await modifyPaymentMethod({
        paymentMethodId: paymentMethod.data.id,
        body: updatedMethod,
      }).unwrap();

      if (apiResponse.success) {
        toast.success('Payment method successfully updated');
        close();
      } else {
        toast.error('Unable to update payment method');
      }
    } catch (err) {
      console.error('Failed to update payment method: ', err);
    }
  };

  const formatData = (data: any) => {
    return {
      expMonth: data.expMonth,
      expYear: data.expYear,
      address: {
        city: data.city,
        country: data.country,
        line1: data.line1,
        line2: data.line2,
        postalCode: data.postalCode,
        state: data.state,
      },
      details: {
        name: data.name,
        email: data.email,
        phone: data.phoneNumber,
      },
    };
  };

  return (
    <Grid container rowSpacing={3} columnSpacing={3}>
      <Divider
        light
        variant="fullWidth"
        sx={{
          mt: 3,
          ml: 1,
          width: '100%',
        }}
      />
      <FormRow errors={errors} register={register} name={'name'} label={'Name on card'} size={12} />

      <FormRow errors={errors} register={register} name={'line1'} label={'Street Address'} size={12} />
      <FormRow errors={errors} register={register} name={'line2'} label={'Apt/Suite'} size={12} />
      <FormRow errors={errors} register={register} name={'city'} label={'City'} size={6} />
      <FormRow errors={errors} register={register} name={'state'} label={'State'} size={6} />
      <FormRow errors={errors} register={register} name={'country'} label={'Country'} size={6} />
      <FormRow errors={errors} register={register} name={'postalCode'} label={'Postal/Zip Code'} size={6} />
      <FormRow errors={errors} register={register} name={'email'} label={'Email'} size={6} />
      <FormRow errors={errors} register={register} name={'phoneNumber'} label={'Phone Number'} size={6} />

      <Grid item xs={12} px={7} mt={1}>
        <StyledButton
          fullWidth
          size="large"
          variant="contained"
          disabled={editResponse.isLoading}
          onClick={handleSubmit(editPaymentMethod)}
        >
          {editResponse.isLoading && <CircularProgress />}
          Save Changes
        </StyledButton>
      </Grid>
    </Grid>
  );
};

const FormRow = ({
  register,
  errors,
  name,
  label,
  size,
  defaultValue,
}: {
  name: string;
  size: number;
  label: string;
  errors: any;
  register: any;
  defaultValue?: any;
}) => {
  return (
    <Grid item xs={size} py={0}>
      {/*  */}
      <FormControl variant="standard" style={{ width: '100%' }}>
        <TextField
          fullWidth
          id={name}
          {...register(name, {
            maxLength: 80,
          })}
          label={label}
          variant="standard"
          margin="dense"
          size="small"
          defaultValue={defaultValue}
          error={errors[name]?.message !== undefined}
        />
      </FormControl>

      <FormHelperText style={{ color: 'red' }}>{errors[name]?.message as string}</FormHelperText>
    </Grid>
  );
};

export default PaymentMethodsEditForm;
