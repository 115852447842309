import { baseQueryWithReauth } from './base-slice';
import { PaginatedList } from '@core/constants/constants';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { ActionResult, ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { CreditRequest, CustomVideoRequest, RequestStatus } from '@core/types';
import { toQueryString } from '@/core/utils/utils';

const descriptionTags = ['Request'] as readonly TagDescription<'Request'>[] & string[];

export const requestSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.Request,
  endpoints: (builder) => ({
    getVideoCreditRequests: builder.query<ServiceResult<PaginatedList<CreditRequest[]>, ApiError>, any>({
      query: (args) => ({
        url: `${BaseEndpoints.Request}/credit/${args.companyId}?${toQueryString(args)}`,
      }),

      providesTags: descriptionTags,
    }),
    getCustomVideoRequests: builder.query<ServiceResult<PaginatedList<CustomVideoRequest[]>, ApiError>, any>({
      query: (args) => ({
        url: `${BaseEndpoints.Request}/custom-video/${args.companyId}?${toQueryString(args)}`,
      }),

      providesTags: descriptionTags,
    }),
    getRequestStatuses: builder.query<ServiceResult<RequestStatus[], ApiError>, any>({
      query: () => ({
        url: `${BaseEndpoints.Request}/status`,
      }),

      providesTags: descriptionTags,
    }),
    approveVideoCreditRequest: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `${BaseEndpoints.Request}/credit/approve` }),
    }),
    rejectVideoCreditRequest: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `${BaseEndpoints.Request}/credit/reject` }),
    }),
  }),
});
export const {
  useGetRequestStatusesQuery,
  useGetCustomVideoRequestsQuery,
  useGetVideoCreditRequestsQuery,
  useApproveVideoCreditRequestMutation,
  useRejectVideoCreditRequestMutation,
} = requestSlice;
