import Card from './Card';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import AppButton from '@/components/AppButton';
import { InputField } from '@/components/AppInput';
import { BackButton } from '@/components/BackButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentMethodSchema } from '@core/constants/schemas';
import { determineBorderColor, useAuth } from '@core/utils/utils';
import CountrySelect from '@/components/CountrySelect/CountrySelect';
import ProvinceSelect from '@/components/ProvinceSelect/ProvinceSelect';
import { useAddPaymentMethodMutation } from '@features/state/slices/api/payment-methods.slice';
import { Box, Divider, FormControl, FormHelperText, Grid, InputLabel, TextField, Typography } from '@mui/material';
import Center from '@/components/Center/Center';
import TransitionWrapper from '@/components/TransitionWrapper';
import { CountryISO } from '@/core/enums/enums';

const PaymentMethodsForm = ({ close }: { close: () => void }) => {
  const {
    watch,
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PaymentMethodSchema),
  });

  const { user } = useAuth();
  const dispatch = useDispatch();
  // const [state, setState] = useState('AR');
  // const [country, setCountry] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [currentStates, setCurrentStates] = useState([]);

  const [addPaymentMethod, response] = useAddPaymentMethodMutation();

  const submitPaymentMethod = async (data: any) => {
    const newPaymentMethod = formatData(data);
    try {
      const apiResponse = await addPaymentMethod(newPaymentMethod).unwrap();

      if (apiResponse.success) {
        toast.success('Payment method successfully added');
        reset();
        close();
      } else {
        toast.error(apiResponse?.error?.message ?? 'An errorr occured');
      }
    } catch (err: any) {
      toast.error(err.toString());
    }
  };

  const formatData = (data: any) => {
    return {
      cvc: data.cvc,
      number: data.number,
      expMonth: data.expMonth,
      expYear: data.expYear,
      companyId: user!.id,
      address: {
        city: data.city,
        country: data.country,
        line1: data.line1,
        line2: data.line2,
        postalCode: data.postalCode,
        state: data.state,
      },
      details: {
        name: data.name,
        email: data.email,
        phone: data.phoneNumber,
      },
    };
  };

  const provinceIso = watch<'state'>('state');
  const countryIso = watch<'country'>('country');

  return (
    <TransitionWrapper>
      <div className="w-full lg:w-1/2">
        <Box display="flex" alignItems="center">
          <BackButton onBackPress={close} />
          <div className="w-full lg:w-1/2">
            <Typography variant="h4">Add a Card </Typography>
          </div>
        </Box>
        <p className="text-slate-400 mt-1">
          Use this section to add a new card to your account. Entering your card details securely allows you to make payments and manage
          subscriptions hassle-free.
        </p>
      </div>

      <div className="flex flex-col lg:flex-row">
        {/* Form Section */}
        <div className="w-full lg:w-1/2">
          {/* Card number input */}
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputField
                value={watch('number')}
                onChange={(e) => setValue('number', e.target.value)}
                placeholder={'Card number'}
                style={determineBorderColor(errors?.number?.message as any)}
              />
            </FormControl>
            <FormHelperText style={{ color: 'red' }}>{errors?.number?.message as string}</FormHelperText>
          </Box>

          {/* Name on card input */}
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputField value={watch('name')} onChange={(e) => setValue('name', e.target.value)} placeholder={'Name on card'} />
            </FormControl>
            <FormHelperText style={{ color: 'red' }}>{errors?.name?.message as string}</FormHelperText>
          </Box>

          {/* Expiration Month, Expiration Year, and CVC inputs */}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box mt={2}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputField
                    value={watch('expMonth')}
                    onChange={(e) => setValue('expMonth', e.target.value)}
                    placeholder={'Expiration Month'}
                  />
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>{errors?.expMonth?.message as string}</FormHelperText>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mt={2}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputField
                    value={watch('expYear')}
                    onChange={(e) => setValue('expYear', e.target.value)}
                    placeholder={'Expiration Year'}
                  />
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>{errors?.expYear?.message as string}</FormHelperText>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box mt={2}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputField value={watch('cvc')} onChange={(e) => setValue('cvc', e.target.value)} placeholder={'CVC'} />
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>{errors?.cvc?.message as string}</FormHelperText>
              </Box>
            </Grid>
          </Grid>

          {/* Address inputs */}
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputField value={watch('line1')} onChange={(e) => setValue('line1', e.target.value)} placeholder={'Address Line 1'} />
            </FormControl>
            <FormHelperText style={{ color: 'red' }}>{errors?.line1?.message as string}</FormHelperText>
          </Box>

          <Box mt={2}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputField value={watch('line2')} onChange={(e) => setValue('line2', e.target.value)} placeholder={'Address Line 2'} />
            </FormControl>
            <FormHelperText style={{ color: 'red' }}>{errors?.line2?.message as string}</FormHelperText>
          </Box>

          {/* Country and State inputs */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <CountrySelect
                  defaultVal={CountryISO.UnitedStates}
                  onSelect={(option) => setValue('country', option?.id, { shouldValidate: true })}
                />
                <FormHelperText style={{ color: 'red' }}>{errors?.country?.message as string}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <ProvinceSelect
                  value={provinceIso}
                  countryIso={countryIso}
                  onSelect={(option) => setValue('state', option?.id, { shouldValidate: true })}
                />
                <FormHelperText style={{ color: 'red' }}>{errors?.state?.message as string}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {/* City and Postal Code inputs */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box mt={2}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputField value={watch('city')} onChange={(e) => setValue('city', e.target.value)} placeholder={'City'} />
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>{errors?.city?.message as string}</FormHelperText>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mt={2}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputField
                    value={watch('postalCode')}
                    onChange={(e) => setValue('postalCode', e.target.value)}
                    placeholder={'Postal Code'}
                  />
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>{errors?.postalCode?.message as string}</FormHelperText>
              </Box>
            </Grid>
          </Grid>

          {/* Email and Phone Number inputs */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box mt={2}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputField value={watch('email')} onChange={(e) => setValue('email', e.target.value)} placeholder={'Email'} />
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>{errors?.email?.message as string}</FormHelperText>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mt={2}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputField
                    value={watch('phoneNumber')}
                    onChange={(e) => setValue('phoneNumber', e.target.value)}
                    placeholder={'Phone Number'}
                  />
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>{errors?.phoneNumber?.message as string}</FormHelperText>
              </Box>
            </Grid>
          </Grid>

          {/* Save Button */}
          <Box sx={{ float: 'right', display: 'flex' }} mt={2}>
            <AppButton onClick={handleSubmit(submitPaymentMethod)}>Save</AppButton>
          </Box>
        </div>

        {/* Credit Card Display */}
        <div className="w-full lg:w-1/2 mt-6 lg:mt-0">
          <Card
            style={{ width: 350, height: 200 }}
            focused={true}
            cvc={watch('cvc') ?? ''}
            name={watch('name') ?? ''}
            number={watch('number') ?? ''}
            expiry={`${watch('expMonth') ?? ''}/${watch('expYear') ?? ''}`}
          />
        </div>
      </div>
    </TransitionWrapper>
  );
};

export default PaymentMethodsForm;
