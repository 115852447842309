import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Grid, Button, Typography } from '@mui/material';

import { RootState } from '@features/state/reducers/combinedReducers';
import PageTitleWrapper from '@components/PageTitleWrapper';
import PageTitleWrapperEnable from '../PageTitleWrapperEnable';

type Props = {
  title: string;
  enable?: boolean;
  subtitle: string;
  trailing?: JSX.Element;
  onAction?: () => void;
};

function AppPageHeader({ enable, onAction, title, subtitle, trailing }: Props) {
  return (
    <PageTitleWrapperEnable enable={enable}>
      <br></br>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item style={{ padding: 0 }}>
          <Typography variant="h3" component="h3" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#909090' }}>
            {subtitle}
          </Typography>
        </Grid>
        <Grid item>{trailing && trailing}</Grid>
      </Grid>
      <br></br>
      <br></br>
    </PageTitleWrapperEnable>
  );
}

AppPageHeader.defaultProps = {
  enable: false,
};

export default AppPageHeader;
