import { Fragment, useState } from 'react';

import ViewNotesPopover from './ViewNotesPopover';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Tooltip, Typography } from '@mui/material';

type Props = {
  request: any;
};

export default function ViewNotesButton({ request }: Props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopup = Boolean(anchorEl);

  const closePopup = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Fragment>
      <Tooltip title="View Notes" arrow>
        <IconButton onClick={(event) => handleClick(event)}>
          <VisibilityIcon sx={{}} />
        </IconButton>
      </Tooltip>
      <ViewNotesPopover anchor={anchorEl} open={openPopup} closePopup={closePopup} data={request} />
    </Fragment>
  );
}
