import { useEffect, useState } from 'react';
import PackageList from './PackageList';
import AppFilter from '@/components/AppFilter';
import { StyledSearch } from '@/components/Styled';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Stack } from '@mui/material';
import AppTablePagination from '@/components/AppTablePagination';
import ImageCardSkeleton from '@features/shared/ImageCardSkeleton';
import products from '@features/shared/mock';
import ItemSort from '@features/shared/ItemSort';
import CartWidget from '@features/shared/CartWidget';
import { useGetUserVideoPackagesQuery } from '@features/state/slices/api/video-package-slice';
import { useAuth } from '@core/utils/utils';
import { VideoPackage } from '@core/types';
import { NoPackages } from './NoPackages';
import { SearchBar } from '@/components/SearchBar';

export default function PackageDisplay() {
  const { user } = useAuth();
  const [openFilter, setOpenFilter] = useState(false);
  const [hasPackages, setHasPackages] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    limit: 10,
  });

  const [packages, setPackages] = useState<VideoPackage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { data, refetch, isError, isSuccess, isFetching } = useGetUserVideoPackagesQuery({
    id: user?.id,
    searchTerm: '',
    page: paginationParams.page,
    limit: paginationParams.limit,
  });

  console.log('data :-> ', data?.data);

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setPackages(data?.data?.items!);

      if (data?.data?.items?.length !== null) {
        setHasPackages(data!.data!.items!.length > 0);
      }
    }
  }, [isError, isSuccess, isFetching]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    setPaginationParams((prev: any) => ({
      ...prev,
      page: pageNumber,
      limit: pageLimit,
    }));
  };

  return (
    <Box position={'relative'}>
      {loading && <ImageCardSkeleton />}
      {!loading && data?.data?.items.length == 0 && <NoPackages />}
      {!loading && hasPackages && (
        <Box>
          <Box mt={4} mb={4} display="flex" alignItems="center" justifyContent="space-between">
            <SearchBar onChange={(e) => null} />
          </Box>
          <PackageList packages={packages} />

          <AppTablePagination
            totalPages={data?.data?.meta?.totalPages ?? 1}
            onChange={(limit, offset) => handlePaginationChanged(limit, offset)}
          />
        </Box>
      )}
    </Box>
  );
}
