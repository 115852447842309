import { tailwindConfig } from '@/core/utils/utils';
import { useContext, useEffect, useState } from 'react';
import DoughnutChart from '@/components/Charts/Doughnut';
import { AnalyticsTokenContext } from '../../../providers/AnalyticsTokenProvider';
import { useGetCustomVideoSkipFactorAnalysisQuery } from '@/features/state/slices/api/video-analytics-slice';

export default function SkipFactorAnalysis() {
  const [chartData, setChartData] = useState({});
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const { isFetching, isError, isSuccess, data } = useGetCustomVideoSkipFactorAnalysisQuery(analyticsAuth);

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setChartData({
        labels: ['Beginning', 'Middle', 'End'],
        datasets: [
          {
            label: 'Skip Factor Analysis',
            data: data?.data ? Object.values(data.data!) : [],
            backgroundColor: [
              tailwindConfig().theme.colors.indigo[500],
              tailwindConfig().theme.colors.sky[400],
              tailwindConfig().theme.colors.indigo[800],
            ],
            hoverBackgroundColor: [
              tailwindConfig().theme.colors.indigo[600],
              tailwindConfig().theme.colors.sky[500],
              tailwindConfig().theme.colors.indigo[900],
            ],
            borderWidth: 0,
          },
        ],
      });
    }
  }, [isSuccess, isError, isFetching]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-sm rounded-md border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100 text-base">Watch Factor Analysis</h2>
        <p className="text-slate-500 xs">
          This data analysis provides insights into the distribution of video views across different quarters of a video.
        </p>
      </header>

      {!isFetching && !isError && isSuccess && Object.keys(chartData).length > 0 && (
        <DoughnutChart
          data={chartData}
          width={250}
          height={250}
          callbacks={{
            label: function (tooltipItem: any) {
              const value = tooltipItem.raw as any;
              return Math.ceil(value).toString() + '%';
            },
          }}
        />
      )}
      <div className="mt-[27px]" />
    </div>
  );
}
