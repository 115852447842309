import { baseQueryWithReauth } from './base-slice';
import { PaginatedList } from '@core/constants/constants';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { ActionResult, ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { Subscription, SubscriptionStatus, CompanyBaseSubscription, SubscriptionPlan } from '@core/types';
import { toQueryString } from '@/core/utils/utils';

const descriptionTags = ['Subscriptions'] as readonly TagDescription<'Subscriptions'>[] & string[];

export const subscriptionSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.Subscription,
  endpoints: (builder) => ({
    getSubscriptionStatuses: builder.query<ServiceResult<SubscriptionStatus[], ApiError>, any>({
      query: () => `${BaseEndpoints.Subscription}/status`,
      providesTags: descriptionTags,
    }),
    getCompanySubscriptions: builder.query<ServiceResult<PaginatedList<Subscription[]>, ApiError>, any>({
      query: (params) => ({
        url: `${BaseEndpoints.Subscription}/company/${params.companyId}?${toQueryString(params)}`,
      }),
      providesTags: descriptionTags,
    }),
    getSubcriptionUsers: builder.query({
      query: ({ subscriptionId }) => ({
        url: `${BaseEndpoints.Subscription}/${subscriptionId}/users`,
      }),
      providesTags: descriptionTags,
    }),
    getCompanyBaseSubcriptions: builder.query<ServiceResult<PaginatedList<CompanyBaseSubscription[]>, ApiError>, any>({
      query: (params) => ({
        url: `${BaseEndpoints.Subscription}/company/base/${params.companyId}?${toQueryString(params)}`,
      }),
      providesTags: descriptionTags,
    }),
    createPackageSubscription: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({ body: payload, method: ApiMethods.POST, url: `${BaseEndpoints.Subscription}/package` }),
      invalidatesTags: descriptionTags,
    }),
    activatePackageSubscription: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({ method: ApiMethods.PUT, url: `${BaseEndpoints.Subscription}/${payload.id}/activate` }),
      invalidatesTags: descriptionTags,
    }),
    createCompanyBaseSubscription: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Subscription}/base/company`,
      }),
      invalidatesTags: descriptionTags,
    }),
    activateCompanyBaseSubscription: builder.mutation({
      query: (args) => ({
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.Subscription}/base/${args.id}/activate`,
      }),
      invalidatesTags: descriptionTags,
    }),
    getSubscriptions: builder.query<
      ServiceResult<PaginatedList<SubscriptionPlan[]>, ApiError>,
      { companyId: string; page?: number; limit?: number }
    >({
      query: (args: any) => ({
        params: { page: args.page, limit: args.limit },
        url: `/${BaseEndpoints.Subscription}/company-access/base?companyId=${args.companyId}`,
      }),
      providesTags: descriptionTags,
    }),
    cancelCompanyBaseSubscriptionImmediately: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Subscription}/base/company/cancel-immediately`,
      }),
      invalidatesTags: descriptionTags,
    }),
    cancelCompanyBaseSubscriptionRenewal: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Subscription}/base/company/cancel`,
      }),
      invalidatesTags: descriptionTags,
    }),
  }),
});

export const {
  useGetSubcriptionUsersQuery,
  useGetSubscriptionStatusesQuery,
  useGetCompanySubscriptionsQuery,
  useGetCompanyBaseSubcriptionsQuery,
  useLazyGetCompanyBaseSubcriptionsQuery,
  useCreatePackageSubscriptionMutation,
  useActivatePackageSubscriptionMutation,
  useActivateCompanyBaseSubscriptionMutation,
  useCreateCompanyBaseSubscriptionMutation,
  useGetSubscriptionsQuery,
  useCancelCompanyBaseSubscriptionImmediatelyMutation,
  useCancelCompanyBaseSubscriptionRenewalMutation,
} = subscriptionSlice;
