import { tailwindConfig } from '@/core/utils/utils';
import { useContext, useEffect, useState } from 'react';
import DoughnutChart from '@/components/Charts/Doughnut';
import { AnalyticsTokenContext } from '../../../providers/AnalyticsTokenProvider';
import { useGetCustomVideoDailyTrendQuery, useGetCustomVideoDeviceUsageQuery } from '@/features/state/slices/api/video-analytics-slice';

export default function DayTrend() {
  const [chartData, setChartData] = useState({});
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const { isFetching, isError, isSuccess, data } = useGetCustomVideoDailyTrendQuery(analyticsAuth);

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setChartData({
        labels: Object.keys(data.data!),
        datasets: [
          {
            label: 'Day Trend Analysis',
            data: Object.values(data.data!),
            backgroundColor: [
              tailwindConfig().theme.colors.sky[400],
              tailwindConfig().theme.colors.rose[500],
              tailwindConfig().theme.colors.emerald[500],
              tailwindConfig().theme.colors.amber[500],
              tailwindConfig().theme.colors.violet[500],
              tailwindConfig().theme.colors.cyan[500],
              tailwindConfig().theme.colors.teal[500],
            ],
            hoverBackgroundColor: [
              tailwindConfig().theme.colors.sky[500],
              tailwindConfig().theme.colors.rose[600],
              tailwindConfig().theme.colors.emerald[600],
              tailwindConfig().theme.colors.amber[600],
              tailwindConfig().theme.colors.violet[600],
              tailwindConfig().theme.colors.cyan[600],
              tailwindConfig().theme.colors.teal[600],
            ],
            borderWidth: 0,
          },
        ],
      });
    }
  }, [isSuccess, isError, isFetching]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-sm rounded-md border border-slate-200 dark:border-slate-700">
      <header className="px-5 pt-4  border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100 text-base">Day Trend Analysis</h2>
        <p className="text-slate-500 xs">
          This visualization shows valuable insights into the popularity and engagement patterns of the video content over the course of a
          week.
        </p>
      </header>

      {!isFetching && !isError && isSuccess && Object.keys(chartData).length > 0 && (
        <DoughnutChart data={chartData} width={250} height={250} />
      )}
    </div>
  );
}
