import { DropdownOption } from '@/core/types';
import Transition from '../Transtition/Transition';
import { useState, useRef, useEffect } from 'react';

type Props = {
  value?: string | null;
  error?: boolean | null;
  options: DropdownOption[];
  onSelect: (option: DropdownOption) => void;
};

function DropdownClassic({ onSelect, options = [] }: Props) {
  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);
  const [selected, setSelected] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (selected) {
      const selectedValue = options?.find((o) => o.id == selected)!;
      setDisplayText(selectedValue?.value);
    } else if (options?.length > 0) {
      setDisplayText(options[0]?.value);
    }
  }, [options]);

  const handleSelect = (option: DropdownOption, index: number) => {
    if (onSelect) {
      onSelect(option);
    }

    setSelected(option.id);
    setDropdownOpen(false);
    setDisplayText(option.value);
  };

  useEffect(() => {
    const clickHandler = ({ target }: any) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        style={{ borderRadius: 0 }}
        aria-expanded={dropdownOpen}
        className="flex h-[43px] justify-between min-w-60 bg-white border-slate-500  shadow-sm font-normal text-sm inline-flex items-center justify-center border border-transparent leading-5 shadow-sm transition duration-150 ease-in-out"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div className="flex p-3 w-full items-center justify-between">
          <span className="text-slate-500">
            <span>{displayText}</span>
          </span>

          <svg className="shrink-0 ml-1 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
            <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
          </svg>
        </div>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600 dark:text-slate-300"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {options.map((option, index) => {
            return (
              <button
                key={option.id}
                className={`flex items-center w-full hover:bg-slate-50 hover:dark:bg-slate-700/20 py-1 px-3 cursor-pointer ${
                  option.id === selected && 'text-blue-500'
                }`}
                onClick={() => {
                  handleSelect(option, index);
                }}
              >
                <svg
                  className={`shrink-0 mr-2 fill-current text-blue-500 ${option.id !== selected && 'invisible'}`}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                >
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
                <span>{option.value}</span>
              </button>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}

export default DropdownClassic;
