import { ApiError, ServiceResult } from '@core/index';
import CreditService from '@core/services/CreditService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const videoCreditLocalSlice = createSlice({
  name: 'videoCredit',

  initialState: {
    credit: 0,
    error: '',
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVideoCredit.fulfilled, (state, action) => {
      state.loading = false;
      state.error = action?.payload.error?.message ?? '';
      state.credit = action?.payload.data?.creditAmount ?? 0;
    });
  },
});
export const fetchVideoCredit = createAsyncThunk(
  'videoCredit/getVideoCredit',
  async ({ companyId, accessToken }: { companyId: number | string; accessToken: string | undefined }) => {
    try {
      const response = await CreditService.getUserCreditBalance(companyId, accessToken);

      return response;
    } catch (e: any) {
      return {
        data: null,
        error: { message: e.toString() },
      };
    }
  },
);
