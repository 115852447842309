import { baseQueryWithReauth } from './base-slice';
import { AssignedPackage } from '@core/types';
import { BaseEndpoints } from '@core/enums/enums';
import { ApiError, ServiceResult } from '@core/index';
import { PaginatedList } from '@core/constants/constants';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['AssignedPackage'] as readonly TagDescription<'AssignedPackage'>[] & string[];

export const assignedPackageSlice = createApi({
  reducerPath: 'assigned-packages',
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    getAssignedPackages: builder.query<ServiceResult<PaginatedList<AssignedPackage[]>, ApiError>, any>({
      query: (args: any) =>
        `/${BaseEndpoints.VideoPackage}/company/${args.companyId}/assigned?limit=${args.limit}&page=${args.page}&searchTerm=${args.searchTerm}`,
      providesTags: descriptionTags,
    }),
  }),
});
export const { useGetAssignedPackagesQuery } = assignedPackageSlice;
