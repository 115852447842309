import axios, { AxiosError } from 'axios';
import { VideoCredit } from '@core/types';
import { BaseService } from './BaseService';
import { getDomain } from '@core/utils/utils';
import { ApiError, ServiceResult } from '..';

const API_URL = getDomain();

class CreditService extends BaseService {
  constructor() {
    super({
      baseURL: `${API_URL}`,
    });
  }
  async getUserCreditBalance(companyId: number | string, accessToken: string | undefined): Promise<ServiceResult<VideoCredit, ApiError>> {
    let result: ServiceResult<VideoCredit, ApiError> = {
      success: false,
    };

    try {
      const response = await axios.get<ServiceResult<VideoCredit, ApiError>>(`${API_URL}/credits/company/${companyId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      const { response } = error as AxiosError<any>;

      result.error = response?.data.error;

      return result;
    }
  }
}

const service = new CreditService();

export { service as default, service as AuthService };
