import AppChip from '@/components/AppChip';
import { SubscriptionStatus } from '@/core/enums/enums';
import { CompanyBaseSubscription, Subscription, TableData } from '@/core/types';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CancelIcon from '@mui/icons-material/Cancel';
import RenewIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import MoreVertIcon from '@mui/icons-material/MoreVert';
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const packageSubscriptionHeaders: TableData<Subscription>[] = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'name',
    label: 'Name',
    render: (item) => (
      <Tooltip title={item?.package?.packageName} arrow>
        <Typography noWrap variant="subtitle1">
          {item?.package?.packageName}
        </Typography>
      </Tooltip>
    ),
  },
  {
    key: 'amount',
    label: 'Amount',
    align: 'left',
    render: (item) => (
      <Typography variant="subtitle1" noWrap sx={{ width: 'fit-content' }}>
        {formatter.format(item?.subscriptionAmount)}
      </Typography>
    ),
  },
  {
    key: 'status',
    label: 'Status',
    align: 'left',
    render: (item) => {
      switch (item.status) {
        case SubscriptionStatus.ACTIVE:
          return <AppChip color="success">{item.subscriptionStatus.name}</AppChip>;
        case SubscriptionStatus.FAILED:
          return <AppChip color="error">{item.subscriptionStatus.name}</AppChip>;
        case SubscriptionStatus.EXPIRED:
          return <AppChip color="warning">{item.subscriptionStatus.name}</AppChip>;
        case SubscriptionStatus.INCOMPLETE:
          return <AppChip color="info">{item.subscriptionStatus.name}</AppChip>;
        default:
          return <AppChip>Unknown</AppChip>;
      }
    },
  },
  {
    key: 'period',
    label: 'Period',
    align: 'left',
    render: (item) => {
      // format subscription periods
      let subscriptionPeriods = item.period?.split('-');

      // adjust subscription periods to accepted date format
      const subscriptionStartDate = subscriptionPeriods![0].trim().replaceAll('th', '');
      const subscriptionEndDate = subscriptionPeriods![1].trim().replaceAll('th', '');
      return (
        <Typography variant="subtitle1" noWrap sx={{ width: 'fit-content' }}>
          {moment(subscriptionStartDate).format('LL')} - {moment(subscriptionEndDate).format('LL')}
        </Typography>
      );
    },
  },
  // {
  //   key: 'billingCount',
  //   label: 'Billing Count',
  //   align: 'left',
  //   render: (item) => <Box>{item?.billingCount}</Box>,
  // },
  {
    key: 'created',
    label: 'Created',
    align: 'left',
    render: (item) => <Box>{moment(item?.createdAt).format('LL')}</Box>,
  },
  {
    key: 'expires',
    label: 'Expires',
    align: 'left',
    render: (item) => <Box>{moment(item?.nextBillDate).format('LL')}</Box>,
  },

  //   {
  //     key: 'actions',
  //     label: 'Actions',
  //     align: 'left',
  //     render: (item, theme, refetch) => <Box></Box>,
  //   },
];

export const customSubscriptionHeaders: TableData<CompanyBaseSubscription>[] = [
  // {
  //   key: 'id',
  //   label: 'ID',
  // },
  {
    key: 'name',
    label: 'Name',
    render: (item) => (
      <Tooltip title={item?.subscriptionName} arrow>
        <Typography noWrap variant="subtitle1">
          {item?.subscriptionName}
        </Typography>
      </Tooltip>
    ),
  },
  {
    key: 'amount',
    label: 'Amount',
    align: 'left',
    render: (item) => (
      <Typography variant="subtitle1" noWrap sx={{ width: 'fit-content' }}>
        {formatter.format(item?.subscriptionAmount)}
      </Typography>
    ),
  },
  {
    key: 'status',
    label: 'Status',
    align: 'left',
    render: (item) => {
      switch (item.status) {
        case SubscriptionStatus.ACTIVE:
          return <AppChip color="success">{item.subscriptionStatus.name}</AppChip>;
        case SubscriptionStatus.FAILED:
          return <AppChip color="error">{item.subscriptionStatus.name}</AppChip>;
        case SubscriptionStatus.EXPIRED:
          return <AppChip color="warning">{item.subscriptionStatus.name}</AppChip>;
        case SubscriptionStatus.INCOMPLETE:
          return <AppChip color="info">{item.subscriptionStatus.name}</AppChip>;
        default:
          return <AppChip>Unknown</AppChip>;
      }
    },
  },

  {
    key: 'created',
    label: 'Created',
    align: 'left',
    render: (item) => <Box>{moment(item?.createdAt).format('LL')}</Box>,
  },
  {
    key: 'expires',
    label: 'Expires',
    align: 'left',
    render: (item) => <Box>{moment(item?.nextBillDate).format('LL')}</Box>,
  },

  // {
  //   key: 'actions',
  //   label: 'Actions',
  //   align: 'left',
  //   render: (item, theme, refetch) => <Box></Box>,
  // },

  {
    key: 'actions',
    label: 'Actions',
    align: 'left',
    render: (item) => {
      const handleMoreActions = () => {
        // const navigate = useNavigate();

        //navigate('/subscription-details');
        //<Navigate to="subscriptions/create" />;
        Swal.fire({
          icon: 'info',
          title: `No more Actions available at this time`,
          text: 'More actions will be available with more updates',
        });
      };

      const renderActionButton = () => {
        switch (item.status) {
          case SubscriptionStatus.ACTIVE:
            return (
              <>
                <Tooltip title="Cancel Subscription">
                  <IconButton>
                    <CancelIcon color="error" />
                  </IconButton>
                </Tooltip>
                <IconButton onClick={handleMoreActions}>
                  <MoreVertIcon />
                </IconButton>
              </>
            );
          case SubscriptionStatus.FAILED:
          case SubscriptionStatus.EXPIRED:
            return (
              <>
                <Tooltip title="Renew Subscription">
                  <IconButton>
                    <RenewIcon />
                  </IconButton>
                </Tooltip>
                <IconButton onClick={handleMoreActions}>
                  <MoreVertIcon />
                </IconButton>
              </>
            );
          case SubscriptionStatus.INCOMPLETE:
            return (
              <>
                <Tooltip title="Contact Administrator">
                  <IconButton>
                    <ErrorIcon />
                  </IconButton>
                </Tooltip>
                <IconButton onClick={handleMoreActions}>
                  <MoreVertIcon />
                </IconButton>
              </>
            );
          default:
            return (
              <>
                <Tooltip title="Unknown Status, Contact Administrator">
                  <IconButton>
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
                <IconButton onClick={handleMoreActions}>
                  <MoreVertIcon />
                </IconButton>
              </>
            );
        }
      };

      return <>{renderActionButton()}</>;
    },
  },
];
