import { Box } from '@mui/material';
import { toast } from 'react-hot-toast';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '@core/utils/utils';
import AppButton from '@/components/AppButton';
import Center from '@/components/Center/Center';
import Success from '@/assets/images/success.png';
import { InputField } from '@/components/AppInput';
import ModalBasic from '@/components/AppModal/ModalBasic';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useInviteSalesAssociateMutation } from '@features/state/slices/api/company-slice';

const AddUserButton = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [inviteSalesAssociate] = useInviteSalesAssociateMutation();

  const handleModalClose = () => {
    setEmailError(null);
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleConfirmModalOpen = () => {
    handleModalClose();
    setOpenConfirm(true);
  };

  const handleConfirmModalClose = () => {
    setOpenConfirm(false);
  };

  const sendInvite = async () => {
    if (email?.trim() === '') {
      setEmailError('Email address is required.');
      return;
    }

    dispatch(toggleLoading());
    try {
      const { error, success } = await inviteSalesAssociate({
        email,
        companyId: auth?.user?.id,
      }).unwrap();

      if (!success || error) {
        toast.error(error?.message!);
      }

      handleConfirmModalOpen();
    } catch (e: any) {
      toast.error(e.data.error.message);
    }
    dispatch(toggleLoading());
  };

  return (
    <>
      <AppButton onClick={handleModalOpen}>
        <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
          <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
        </svg>
        <span className="hidden xs:block ml-2">Add User</span>
      </AppButton>
      <ModalBasic
        isOpen={isModalOpen}
        setIsOpen={handleModalClose}
        title="New Sales Associate"
        subtitle="Get ready to welcome your new Sales Associate! Please enter their email address below, and we'll send them an invite to join your company's mobile app."
      >
        <Box pl={2} pr={5} pb={3}>
          <InputField required placeholder="Enter email address" onChange={(e) => setEmail(e.target.value)} />
          {emailError && <span className="text-red-500 text-xs mt-3 ml-1">{emailError}</span>}
          <Box mt={2} mb={2} className="float-right">
            <AppButton onClick={sendInvite}>Send Invite</AppButton>
          </Box>
        </Box>
      </ModalBasic>

      <ModalBasic width="2/6" isOpen={openConfirm} setIsOpen={handleConfirmModalClose} title="Invitation Sent">
        <Box pl={2} pr={5} pb={3}>
          <Center>
            <img src={Success} height={150} width={150} alt="" />
            <p className="text-slate-500 text-center">
              Invite successfully sent to <b>{email}</b>. Check your pending users to see the invitation status
            </p>
          </Center>
          <Box mt={2} mb={2} className="float-right">
            <AppButton onClick={handleConfirmModalClose}>Close</AppButton>{' '}
          </Box>
        </Box>
      </ModalBasic>
    </>
  );
};

export default AddUserButton;
