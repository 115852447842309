import { useState } from 'react';
import Box from '@mui/material/Box';
import AuthLayout from '@/core/layouts/Auth';
import Logo from '@assets/images/small-logo.png';
import ResetPassword from './components/ResetPassword';
import VerifyEmailCode from './components/VerifyEmailCode';
import SendRecoveryEmail from './components/SendRecoveryEmail';
import { Helmet } from 'react-helmet-async';

export default function ForgetPassword() {
  const [activeStep, setActiveStep] = useState(0);

  const [steps, setSteps] = useState([
    {
      index: 1,
      component: SendRecoveryEmail,
    },
    {
      index: 2,
      component: VerifyEmailCode,
    },
    {
      index: 3,
      component: ResetPassword,
    },
  ]);

  const onNext = () => {
    if (activeStep == steps.length - 1) {
      return;
    }
    setActiveStep((prevState) => prevState + 1);
  };

  const currentStep = steps[activeStep];

  const StepComponent = currentStep.component as any;

  return (
    <AuthLayout allowBackNavigation>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <div className="px-4 pt-2 pb-8">
        <div className="max-w-md mx-auto w-full">
          <div className="relative">
            <div className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700" aria-hidden="true"></div>
            <ul className="relative flex justify-between w-full">
              {steps.map((step, index) => (
                <li>
                  {index <= activeStep ? (
                    <div className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-blue-500 text-white">
                      {step.index}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400">
                      {step.index}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="max-w-sm mx-auto w-full px-4 py-8">
        <StepComponent onNext={onNext} />
      </div>
    </AuthLayout>
  );
}
