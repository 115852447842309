import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import Routes from '@/features/router/routes';
import AppButton from '@/components/AppButton';
import Center from '@/components/Center/Center';
import NotFoundVector from '@/assets/images/404.png';
import { Box, Typography, Container } from '@mui/material';

const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`,
);
type Props = {};
const NotFoundPage: React.FunctionComponent<Props> = (props: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Status - 404</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Center>
            <Box textAlign="center">
              <img alt="404" height={350} src={NotFoundVector} />
              <Typography variant="h2" sx={{ my: 2 }}>
                The page you were looking for doesn't exist.
              </Typography>
              <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ mb: 4 }}>
                It's on us, we moved the content to a different page. The search below should help!
              </Typography>
            </Box>
            <AppButton onClick={() => navigate(`/${Routes.App.Dashboard}`)}>Return Home</AppButton>
          </Center>
        </Container>
      </MainContent>
    </>
  );
};

export default NotFoundPage;
