import { Fragment, useState } from 'react';
import { toast } from 'react-hot-toast';
import AppChip from '@/components/AppChip';
import { useDispatch } from 'react-redux';
import { getMomentInTime } from '@core/utils/utils';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import DeleteConfirmModal from '@features/shared/DeleteConfirmModal';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useDeletePendingInviteMutation, useUpdatePendingInviteMutation } from '@features/state/slices/api/pending-invites.slice';
import { Checkbox, IconButton, TableCell, TableRow, Tooltip, useTheme } from '@mui/material';

type Props = {
  email: string;
  refetch: any;
};

export default function CancelPendingUserButton({ email, refetch }: Props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [cancelPendingInvite] = useUpdatePendingInviteMutation();

  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);

  const toggleConfirmCancel = () => {
    setConfirmCancel((prev) => !prev);
  };

  const cancelPendingUserInvite = async () => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await cancelPendingInvite({
        email,
        statusId: 2,
      }).unwrap();

      if (!success) {
        toast.error('Unable to cancel invite');
        return;
      }

      toast.success('Invite has been cancelled');

      if (refetch) {
        refetch();
      }
    } catch (err: any) {
      toast.error('Failed to cancel invite: ', err);
    }
    dispatch(toggleLoading());
  };
  return (
    <Fragment>
      <DeleteConfirmModal
        open={confirmCancel}
        closeModal={toggleConfirmCancel}
        title={"Cancel this user's invite?"}
        handleAction={cancelPendingUserInvite}
        confirmationText={'This will cancel the invite and prevent a user from signing up with this email'}
      />
      <Tooltip title={'Cancel Invite'} arrow>
        <IconButton
          onClick={toggleConfirmCancel}
          sx={{
            '&:hover': {
              background: theme.colors.error.lighter,
            },
            p: 0.3,
            borderRadius: '50%',
            color: theme.palette.error.main,
          }}
          size={'small'}
        >
          <DoNotDisturbAltIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}
