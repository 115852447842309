import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useAuth } from '@core/utils/utils';
import BuyCredits from '@/components/BuyCredits';
import { useDispatch, useSelector } from 'react-redux';
import ModalBasic from '@/components/AppModal/ModalBasic';
import { RootState } from '@features/state/reducers/combinedReducers';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { fetchVideoCredit } from '@features/state/slices/local/video-credit-slice';

export default function CreditHeaderButton() {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [open, setOpen] = useState(false);
  const { credit, error } = useSelector((state: RootState) => state.videoCredit);

  const theme = useTheme();

  useEffect(() => {
    dispatch(fetchVideoCredit({ companyId: auth?.user?.id!, accessToken: auth.token?.accessToken }));
  }, [auth]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSuccessfulTransaction = () => {
    handleClose();
    dispatch(fetchVideoCredit({ companyId: auth?.user?.id!, accessToken: auth.token?.accessToken }));
  };

  return (
    <Box sx={{ mx: 0.5 }} component="span">
      <Box display={'flex'} alignItems={'center'}>
        {/* <Typography fontSize={13} variant="subtitle1" component={'span'} mr={1}>
          Total credits:
          <span>&nbsp;{credit}</span>
        </Typography> */}
        {/* DEPRECATED Credit System */}
        {/* <div className=" mr-1 ml-1 w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full">
          <Tooltip arrow placement="top" title="Buy credits">
            <div className="relative inline-flex">
              <button
                className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full `}
                aria-haspopup="true"
                onClick={handleOpen}
              >
                <span className="sr-only">Credits</span>
                <svg className="h-5 w-5" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14 36c-2.21 0-3.98 1.79-3.98 4s1.77 4 3.98 4 4-1.79 4-4-1.79-4-4-4zM2 4v4h4l7.19 15.17-2.7 4.9c-.31.58-.49 1.23-.49 1.93 0 2.21 1.79 4 4 4h24v-4H14.85c-.28 0-.5-.22-.5-.5 0-.09.02-.17.06-.24L16.2 26h14.9c1.5 0 2.81-.83 3.5-2.06l7.15-12.98c.16-.28.25-.61.25-.96a2 2 0 0 0-2-2H10.43l-1.9-4H2zm32 32c-2.21 0-3.98 1.79-3.98 4s1.77 4 3.98 4 4-1.79 4-4-1.79-4-4-4z"
                    fill="#64748b"
                    className="fill-000000"
                  ></path>
                  <path d="M0 0h48v48H0z" fill="none"></path>
                </svg>
              </button>
            </div>
          </Tooltip>
        </div> */}
        {/* DEPRECATED Credit System */}
      </Box>
      <ModalBasic
        isOpen={open}
        width="w-2/6"
        setIsOpen={handleClose}
        title="Purchase Credits"
        subtitle="These credits can be used to access premium content, unlock exclusive features, and enjoy enhanced services."
      >
        <BuyCredits onSuccess={onSuccessfulTransaction} onClose={handleClose} />
      </ModalBasic>
    </Box>
  );
}
