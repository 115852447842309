import { AxiosError } from 'axios';
import { VideoCredit } from '@core/types';
import { BaseService } from './BaseService';
import { getDomain } from '@core/utils/utils';
import { ActionResult, ApiError, ServiceResult } from '..';

const API_URL = getDomain();

class RequestService extends BaseService {
  constructor() {
    super({
      baseURL: `${API_URL}`,
    });
  }
  async approvePackageRequest(payload: { requestId: number }): Promise<ServiceResult<ActionResult, ApiError>> {
    let result: ServiceResult<ActionResult, ApiError> = {
      success: false,
    };

    try {
      const response = await this.api.post<ServiceResult<ActionResult, ApiError>>(`/requests/package/approve`, payload);

      return response.data;
    } catch (error) {
      const { response } = error as AxiosError<any>;

      result.error = response?.data.error;

      return result;
    }
  }
}

const service = new RequestService();

export { service as default, service as RequestService };
