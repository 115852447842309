import { useState, ChangeEvent, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, Grid, Container, Box } from '@mui/material';

import TransitionWrapper from '@/components/TransitionWrapper';
import PackageDisplay from './components/Packages/PackageDisplay';
import CustomVideosDisplay from './components/CustomVideos/CustomVideosDisplay';
import { Outlet } from 'react-router';
import Routes from '@/features/router/routes';
import { Helmet } from 'react-helmet-async';

export enum TabValues {
  PACKAGES = 1,
  CUSTOM_VIDEOS = 2,
}

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
);

function ManageVideos() {
  const [currentTab, setCurrentTab] = useState<any>(Routes.CustomVideos);

  const tabs = [
    { value: Routes.CustomVideos, label: 'My Custom Videos' },
    // { value: Routes.VideoPackages, label: 'My Packages' },
  ];

  const baseUrl = window.location.origin;

  const handleTabsChange = (event: ChangeEvent<{}>, value: number): void => {
    setCurrentTab(value);
  };

  useEffect(() => {
    let value = Routes.CustomVideos;

    // if (window.location.pathname.includes(`${Routes.VideoPackages}`)) {
    //   value = Routes.VideoPackages;
    // }
    setCurrentTab(value);
  }, []);

  return (
    <TransitionWrapper>
      <Helmet>
        <title>My Content</title>
      </Helmet>
      <Box sx={{ minWidth: '100%' }}>
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <TabsWrapper
              value={currentTab}
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              onChange={handleTabsChange}
            >
              {tabs.map((tabItem) => (
                <Tab
                  key={tabItem.value}
                  value={tabItem.value}
                  label={tabItem.label}
                  onClick={() => {
                    const url = `${baseUrl}/${Routes.AppRoot}/${Routes.MyContent}/${tabItem.value}`;

                    window.location.replace(url);
                  }}
                />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </TransitionWrapper>
  );
}

export default ManageVideos;
