import { baseQueryWithReauth } from './base-slice';
import { ActiveUserAnalytics, AnalyticsCount, ContentRequestAnalytics, UserGrowthAnalysis } from '@core/types';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['Analytics'] as readonly TagDescription<'Analytics'>[] & string[];

export const analyticsSlice = createApi({
  reducerPath: BaseEndpoints.Analytics,
  baseQuery: baseQueryWithReauth,
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    getCompanyAnalytics: builder.query<ServiceResult<any, ApiError>, any>({
      query: (args: { companyId: string; token: string }) => ({
        method: ApiMethods.GET,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
        url: `/${BaseEndpoints.Analytics}/company/${args.companyId}/analytics`,
      }),
      providesTags: descriptionTags,
    }),
    getPackgeRequestCount: builder.query<ServiceResult<AnalyticsCount, ApiError>, any>({
      query: (args: { companyId: string }) => ({
        method: ApiMethods.GET,

        url: `/${BaseEndpoints.Analytics}/company/${args.companyId}/package/request/count`,
      }),
      providesTags: descriptionTags,
    }),
    getCreditRequestCount: builder.query<ServiceResult<AnalyticsCount, ApiError>, any>({
      query: (args: { companyId: string }) => `/${BaseEndpoints.Analytics}/company/${args.companyId}/credit/request/count`,
      providesTags: descriptionTags,
    }),
    getActiveSubscriptionCount: builder.query<ServiceResult<AnalyticsCount, ApiError>, any>({
      query: (args: { companyId: string }) => `/${BaseEndpoints.Analytics}/company/${args.companyId}/subscription/count`,
      providesTags: descriptionTags,
    }),
    getActiveUsers: builder.query<ServiceResult<ActiveUserAnalytics, ApiError>, any>({
      query: (args: { companyId: string }) => `/${BaseEndpoints.Analytics}/company/${args.companyId}/get-active-users`,
      providesTags: descriptionTags,
    }),
    getUserGrowthAnalysis: builder.query<ServiceResult<UserGrowthAnalysis, ApiError>, any>({
      query: (args: { companyId: string }) => `/${BaseEndpoints.Analytics}/company/${args.companyId}/user-growth-analysis`,
      providesTags: descriptionTags,
    }),
    getRequestContentAnalysis: builder.query<ServiceResult<ContentRequestAnalytics, ApiError>, any>({
      query: (args: { companyId: string }) => `/${BaseEndpoints.Analytics}/company/${args.companyId}/get-content-request`,
      providesTags: descriptionTags,
    }),
  }),
});
export const {
  useGetActiveUsersQuery,
  useGetPackgeRequestCountQuery,
  useGetCreditRequestCountQuery,
  useLazyGetCompanyAnalyticsQuery,
  useGetUserGrowthAnalysisQuery,
  useGetRequestContentAnalysisQuery,
  useGetActiveSubscriptionCountQuery,
} = analyticsSlice;
