export class Endpoints {
  static readonly UploadVideo = "/videos/upload";
  static readonly StreamVideo = "/videos/stream";
  static readonly GetCompanyVideos = "/videos/company";
  static readonly AuthenticateAdmin = "/auth/admin";
  static readonly RegisterCompany = "/auth/company/create";
  static readonly PaymentMethods = "/payment-method";
  static readonly Company = "/company";
  static readonly Subscriptions = "/subscription";
  static readonly Packages = "/packages";
  static readonly PackageRequest = "/request/package"
  static readonly Notifications = "/notification";
  static readonly PendingInvites = "/pending-invite"
}
