import moment from 'moment';
import { Retry } from '@/components/Retry';
import DatePicker from '@/components/DatePicker';
import Center from '@/components/Center/Center';
import { PaginatedList, Query } from '@core/constants/constants';
import DayTrend from './components/DayTrendAnalysis';
import LineChart from '../../components/AnalyticsLineChart';
import { RefreshButton } from '@/components/RefreshButton';
import { AnalyticsCard } from '../../components/AnalyticsCard';
import { CustomVideoTrend } from './components/CustomVideoTrend';
import SkipFactorAnalysis from './components/SkipFactorAnalysis';
import { Fragment, useContext, useEffect, useState } from 'react';
import DeviceUsageAnalysis from './components/DeviceUsageAnalysis';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  dividerClasses,
  Paper,
  Card,
  useMediaQuery,
  Dialog,
  List,
  ListItem,
  Button,
  ListItemButton,
} from '@mui/material';
import { AnalyticsHeaderItem } from '../../components/AnalyticsHeaderItem';
import AnalyticsVideoViewersTable from '../../components/AnalyticsViewers';
import { AnalyticsTokenContext } from '../../providers/AnalyticsTokenProvider';
import { CustomVideoAnalyticViewerEntity, VideoAnalyticsStatistics, VideoAnalyticsViewerEntity } from '@core/types';
import {
  useGetVideoStatisticsQuery,
  useGetCustomVideoTrendQuery,
  useGetBatchCustomVideoViewersQuery,
} from '@features/state/slices/api/video-analytics-slice';
import { useAuth } from '@/core/utils/utils';
import { VideoType } from '@/core/enums/enums';
import Calendar from '../../components/Calendar';
import { SearchBar } from '@/components/SearchBar';
import AppTablePagination from '@/components/AppTablePagination';
import CustomVideoAnalyticItem from './components/CustomVideoAnalyticItem';
import MenuIcon from '@mui/icons-material/Menu';
export default function CustomVideoAnalytics() {
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  // DEPRECATED Credit System
  // const { isSuccess, isError, isFetching, data } = useVerifyCustomVideoAccessQuery({ companyId: analyticsAuth?.companyId });

  return (
    <div className={`space-y-10 h-screen `}>
      <div className="space-y-10 h-screen ">
        <AnalyticsHeaderDisplay />

        <AnalyticsChartMetric />

        <AnalyticsVideoTrend />
        {/* <AnalyticsVideoViewers /> */}
        <CustomVideosPerformaceAnalytic />
        <Box height={50} />
      </div>
    </div>
  );
}

function AnalyticsHeaderDisplay() {
  const [query, setQuery] = useState<any>({
    endDate: null,
    startDate: null,
    videoType: VideoType.CUSTOM,
  });

  const [loading, setLoading] = useState(true);
  const [errorData, setError] = useState<any>(null);
  const [dateDisplay, setDateDisplay] = useState('');
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const [analyticsData, setAnalyticsData] = useState<VideoAnalyticsStatistics[] | undefined>();
  const currentDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(currentDate.getDate() - 6);

  const defaultEndDate = currentDate;

  const { isError, isFetching, data, isSuccess, refetch } = useGetVideoStatisticsQuery(
    { ...analyticsAuth, ...{ query } },
    { refetchOnMountOrArgChange: true },
  );

  const totalWatchTime = data?.data?.find((x) => x.key == 'totalWatchCount');
  const [selectedAnalyticMetric, setSelectedAnalyticMetric] = useState<VideoAnalyticsStatistics | null | undefined>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Detect mobile screen size
  const isMobile = useMediaQuery('(max-width:600px)');

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const handleSelectMetric = (metric: VideoAnalyticsStatistics) => {
    setSelectedAnalyticMetric(metric);
    closeDialog(); // Close the dialog when a metric is selected
  };
  useEffect(() => {
    if (!isFetching && !isError && isSuccess && data) {
      console.log('ANAYC ', data.data);
      setAnalyticsData(data.data);

      if (data?.data?.length != null && data?.data?.length > 0) {
        setSelectedAnalyticMetric(data?.data![0]);
      }

      if (data.error) {
        setError(data.error);
      }

      setLoading(false);
    }
  }, [isFetching, isError, isSuccess]);

  const handleDateChange = (dates: string[]) => {
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setDateDisplay(`on ${moment(startDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate: startDate,
        });
      } else {
        setDateDisplay(`between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`);
        setQuery({
          ...query,
          startDate,
          endDate,
        });
      }
    }
  };
  useEffect(() => {
    handleDateChange([defaultStartDate.toISOString(), defaultEndDate.toISOString()]);
  }, []);

  return (
    <Fragment>
      <Typography mt={2} mb={5} fontSize={28} align="center">
        {totalWatchTime !== undefined
          ? `Your sales associates watched your custom videos ${totalWatchTime?.value} times ${dateDisplay}`
          : 'No custom video views yet! Create your custom videos on the Sales Assist Mobile App to further engage your sales associates and clients.'}
      </Typography>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box />
        <Box display="flex">
          <DatePicker
            sx={{ ml: 1, mr: 1 }}
            onDateChange={handleDateChange}
            defaultEndDate={defaultEndDate}
            defaultStartDate={defaultStartDate}
          />
          <RefreshButton onRefresh={refetch} />
        </Box>
      </Box>

      {/* <AnalyticsCard>
        <Box mb={8}>
          {analyticsData?.length != null && !loading && !errorData && (
            <Box>
              <Box display="flex">
                {analyticsData!.map((analytic, index) => (
                  <AnalyticsHeaderItem
                    index={index}
                    currentAnalyticMetric={analytic}
                    labelSuffix={analytic.labelSuffix}
                    onSelect={setSelectedAnalyticMetric}
                    selectedAnalyticMetric={selectedAnalyticMetric}
                  />
                ))}
              </Box>

              <Grid item xs={12} md={6} lg={12}>
                {selectedAnalyticMetric?.key == 'popularWatchDay' ? (
                  <Calendar
                  // events={selectedAnalyticMetric.dataPoints.map((x) => ({
                  //   eventColor: 'indigo',
                  //   eventName: 'Popular day to watch videos',
                  //   eventStart: new Date(x.date as string),
                  // }))}
                  />
                ) : (
                  <LineChart
                    title={selectedAnalyticMetric?.title}
                    sx={{ borderRadius: 0, boxShadow: 'none' }}
                    subheader={selectedAnalyticMetric?.subtitle}
                    chartData={[
                      {
                        type: 'area',
                        fill: 'gradient',
                        name: selectedAnalyticMetric?.title,
                        data: selectedAnalyticMetric?.dataPoints.map((x) => x?.values),
                      },
                    ]}
                    chartLabels={selectedAnalyticMetric?.dataPoints.map((x) => '')}
                  />
                )}
              </Grid>
            </Box>
          )}
        </Box>
        <Center>{loading && <CircularProgress />}</Center>
        <Center>{errorData && <Retry refetch={refetch} />}</Center>
      </AnalyticsCard> */}

      <AnalyticsCard>
        <Box mb={8}>
          {analyticsData?.length != null && !loading && !errorData && (
            <Box>
              {/* Show button to open dialog on mobile */}
              {isMobile ? (
                <Box display="flex" justifyContent="center" mb={2} mt={3}>
                  <Button variant="contained" color="primary" onClick={openDialog} startIcon={<MenuIcon />}>
                    Select Metric
                  </Button>
                </Box>
              ) : (
                // Make the analytics header items scrollable for mid-sized screens
                <Box display="flex" overflow="auto" className="scrollbar">
                  {analyticsData!.map((analytic, index) => (
                    <AnalyticsHeaderItem
                      index={index}
                      key={index}
                      currentAnalyticMetric={analytic}
                      labelSuffix={analytic.labelSuffix}
                      onSelect={setSelectedAnalyticMetric}
                      selectedAnalyticMetric={selectedAnalyticMetric}
                    />
                  ))}
                </Box>
              )}

              {/* Show selected metric's header on mobile */}
              {isMobile && selectedAnalyticMetric && (
                <Box mt={3}>
                  <AnalyticsHeaderItem
                    index={analyticsData!.indexOf(selectedAnalyticMetric)}
                    currentAnalyticMetric={selectedAnalyticMetric}
                    labelSuffix={selectedAnalyticMetric?.labelSuffix}
                    onSelect={setSelectedAnalyticMetric}
                    selectedAnalyticMetric={selectedAnalyticMetric}
                  />
                </Box>
              )}

              {/* Show the chart or calendar for the selected metric */}
              <Grid item xs={12} md={6} lg={12}>
                {selectedAnalyticMetric?.key === 'popularWatchDay' ? (
                  <Calendar />
                ) : (
                  <LineChart
                    title={selectedAnalyticMetric?.title}
                    subheader={selectedAnalyticMetric?.subtitle}
                    chartData={[
                      {
                        type: 'area',
                        fill: 'gradient',
                        name: selectedAnalyticMetric?.title,
                        data: selectedAnalyticMetric?.dataPoints.map((x) => x?.values),
                      },
                    ]}
                    chartLabels={selectedAnalyticMetric?.dataPoints.map(() => '')}
                  />
                )}
              </Grid>
            </Box>
          )}
        </Box>

        <Center>{loading && <CircularProgress />}</Center>
        <Center>{errorData && <Retry refetch={refetch} />}</Center>

        {/* Dialog for mobile to select an analytics metric */}
        {isMobile && analyticsData && !loading && !errorData ? (
          <Dialog open={dialogOpen} onClose={closeDialog}>
            <List>
              {analyticsData!.map((analytic, index) => (
                <ListItemButton key={index} onClick={() => handleSelectMetric(analytic)}>
                  <Typography>{analytic.title}</Typography>
                </ListItemButton>
              ))}
            </List>
          </Dialog>
        ) : (
          <></>
        )}
      </AnalyticsCard>
    </Fragment>
  );
}

function AnalyticsChartMetric() {
  return (
    <Grid container spacing={2} sx={{ borderRadius: 1 }}>
      <Grid item xs={12} sm={4}>
        <SkipFactorAnalysis />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DayTrend />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DeviceUsageAnalysis />
      </Grid>
    </Grid>
  );
}

function AnalyticsVideoTrend() {
  const [loading, setLoading] = useState(true);
  const [errorData, setError] = useState<any>(null);
  const [chartSeries, setChartSeries] = useState<any>([]);
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const { isFetching, isError, isSuccess, data, refetch } = useGetCustomVideoTrendQuery(analyticsAuth);

  useEffect(() => {
    if (!isFetching && !isError && isSuccess && data) {
      setChartSeries([
        {
          name: 'This year',
          data: data.data?.map((x) => x.currentYear),
        },
        {
          name: 'Last year',
          data: data.data?.map((x) => x.previousYear),
        },
      ]);

      if (data.error) {
        setError(data.error);
      }

      setLoading(false);
    }
  }, [isFetching, isError, isSuccess]);

  return (
    <Box>
      {chartSeries.length > 0 && <CustomVideoTrend refetch={refetch} chartSeries={chartSeries} sx={{ height: '100%', mt: 4, mb: 4 }} />}
      <Center>{loading && <CircularProgress />}</Center>
      <Center>{errorData && <Retry refetch={refetch} />}</Center>
    </Box>
  );
}

function CustomVideosPerformaceAnalytic() {
  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    endDate: '',
    startDate: '',
    searchTerm: '',
  });

  const [loading, setLoading] = useState(true);
  const [errorData, setError] = useState<any>(null);
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const [customVideoViews, setCustomVideoViews] = useState<PaginatedList<CustomVideoAnalyticViewerEntity[] | undefined>>();
  const currentDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(currentDate.getDate() - 6);

  const defaultEndDate = currentDate;
  const { data, isFetching, isError, isSuccess, refetch } = useGetBatchCustomVideoViewersQuery(
    { ...analyticsAuth, ...{ query } },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (!isFetching && !isError && isSuccess && data) {
      console.log('VIEWER DATA', data);
      setCustomVideoViews(data.data);

      if (data.error) {
        setError(data.error);
      }

      setLoading(false);
    }
  }, [isFetching, isError, isSuccess]);

  const handleDateChange = (dates: string[]) => {
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setQuery({
          ...query,
          startDate,
          endDate: startDate,
        });
      } else {
        setQuery({
          ...query,
          startDate,
          endDate,
        });
      }
    }
  };

  // useEffect(() => {
  //   handleDateChange([defaultStartDate.toISOString(), defaultEndDate.toISOString()]);
  // }, []);
  const handleSearch = (searchTerm: string) => {
    const queryData = {
      searchTerm,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    const queryData = {
      page: pageNumber,
      limit: pageLimit,
    };

    setQuery({
      ...query,
      ...queryData,
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Box />

        <Box display="flex" alignItems="flex-end">
          <div className="mr-2">
            <SearchBar onChange={handleSearch} />
          </div>

          {/* <DatePicker sx={{ ml: 1 }} onDateChange={handleDateChange} defaultEndDate={defaultEndDate} defaultStartDate={defaultStartDate} /> */}
          <RefreshButton onRefresh={refetch} />
        </Box>
      </Box>

      <Paper sx={{ width: '100%' }} elevation={0}>
        <Card>
          <Box p={4}>
            <Typography fontSize={20}>Cummulative Custom Video Performance</Typography>
            <p className="mt-2 text-slate-500">
              This section on the analytics page that showcases the Performance of your Custom Videos ordered by views within the entire
              lifetime of the video. You can click the video for a more detailed analysis.
            </p>
          </Box>
          <Box p={2} sx={{ backgroundColor: 'white' }}>
            <Box>
              {!loading && !isError && isSuccess && (
                <Box>
                  {customVideoViews?.items?.map((customVideo: CustomVideoAnalyticViewerEntity) => (
                    <CustomVideoAnalyticItem metric={customVideo} />
                  ))}
                </Box>
              )}
            </Box>
            <AppTablePagination totalPages={customVideoViews?.meta.totalPages ?? 1} onChange={handlePaginationChanged} />
          </Box>

          <Center>{loading && <CircularProgress />}</Center>
          <Center>{errorData && <Retry refetch={refetch} />}</Center>
        </Card>
      </Paper>
    </Box>
  );
}
