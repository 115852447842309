import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { AppInput } from '@/components/Styled';
import Logo from '@assets/images/small-logo.png';
import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SetPasswordSchema } from '@core/constants/schemas';
import PasswordStrengthBar from 'react-password-strength-bar';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TransitionWrapper from '@/components/TransitionWrapper';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { determineBorderColor, useAuth } from '@core/utils/utils';
import { setAuth } from '@features/state/slices/local/auth.slice';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useChangeCompanyPasswordMutation } from '@features/state/slices/api/auth-slice';
import { Box, Card, Grid, Button, InputLabel, Typography, FormControl, FormHelperText } from '@mui/material';
import AppButton from '@/components/AppButton';
import { InputField } from '@/components/AppInput';
import Routes from '@/features/router/routes';

function SetPassword() {
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SetPasswordSchema),
  });

  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [setCompanyPasswordMutation] = useChangeCompanyPasswordMutation();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const toggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const setCompanyPassword = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const result = await setCompanyPasswordMutation({
        ...data,
        ...{ id: auth!.user!.id },
      }).unwrap();

      if (!result.success && result.error) {
        const message = result.error?.message as string;

        toast.error(message);

        dispatch(toggleLoading());
        return;
      }

      const token = result.data!;

      const { accessToken } = token;

      const user = jwt_decode<any>(accessToken);

      const authDto = {
        token,
        user: user.user,
        role: user.role,
      };

      dispatch(setAuth(authDto));

      navigate(`/app/dashboard`);
    } catch (err: any) {
      toast.error(err.toString());
    }
    dispatch(toggleLoading());
  };

  if (auth?.user?.hasInitialPassword) {
    return <Navigate to={`/${Routes.App.Dashboard}`} replace state={{ from: location }} />;
  }

  return (
    <TransitionWrapper>
      <Helmet>
        <title>Set Password</title>
      </Helmet>
      <Box sx={{ p: 2 }}>
        <img src={Logo} height={100} width={80} style={{ objectFit: 'contain' }} />
      </Box>

      <Grid mt={4} container spacing={0} alignItems="center" justifyContent="center" style={{ maxHeight: '90vh' }}>
        <Grid item xs={12} lg={4} md={4} sm={4}>
          <Box>
            <Card sx={{ p: 4 }}>
              <Box mb={5}>
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-4">Set Password</h2>

                <p className="text-sm text-slate-500">
                  Thank you for joining us! To enhance the security of your account, please take a moment to set your initial password..
                </p>
              </Box>
              <Box mt={1}>
                <div className="relative">
                  <InputField
                    errors={errors?.oldPassword}
                    value={watch('oldPassword')}
                    placeholder="Current Password"
                    type={showCurrentPassword ? 'text' : 'password'}
                    onChange={(e) => setValue('oldPassword', e.target.value)}
                  />
                  <button
                    type="button"
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={toggleCurrentPassword}
                  >
                    {showCurrentPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </button>
                </div>
                <label className="block text-xs mt-1 text-rose-500 mb-1 h-5" htmlFor="email">
                  {errors?.oldPassword?.message as any}
                </label>
              </Box>
              <Box mt={1}>
                <div className="relative">
                  <InputField
                    placeholder="New Password"
                    errors={errors?.newPassword}
                    value={watch('newPassword')}
                    type={showNewPassword ? 'text' : 'password'}
                    onChange={(e) => setValue('newPassword', e.target.value)}
                  />
                  <button
                    type="button"
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={toggleNewPassword}
                  >
                    {showNewPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </button>
                </div>

                <label className="block text-xs mt-1 text-rose-500 mb-1 h-5" htmlFor="email">
                  {errors?.newPassword?.message as any}
                </label>
              </Box>

              <Box mt={1}>
                <div className="relative">
                  <InputField
                    errors={errors?.confirmPassword}
                    value={watch('confirmPassword')}
                    placeholder="Confirm New Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={(e) => setValue('confirmPassword', e.target.value)}
                  />
                  <button
                    type="button"
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={toggleConfirmPassword}
                  >
                    {showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </button>
                </div>
                <label className="block text-xs mt-1 text-rose-500 mb-1 h-5" htmlFor="email">
                  {errors?.confirmPassword?.message as any}
                </label>
              </Box>
              <Box mt={1}>
                <AppButton className="float-right" onClick={handleSubmit(setCompanyPassword)}>
                  Set Password
                </AppButton>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </TransitionWrapper>
  );
}

export default SetPassword;
