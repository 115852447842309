import { Country, Province } from '@/core/types';
import { ApiError, ServiceResult } from '@/core';
import { baseQueryWithReauth } from './base-slice';
import { ApiMethods, BaseEndpoints } from '@/core/enums/enums';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['Service'] as readonly TagDescription<'Service'>[] & string[];

export const serviceSlice = createApi({
  reducerPath: 'services',
  tagTypes: descriptionTags,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getCountries: builder.query<ServiceResult<Country[], ApiError>, any>({
      query: (params) => ({
        method: ApiMethods.GET,
        url: `/${BaseEndpoints.Service}/get-countries`,
      }),
    }),
    getProvincesByCountry: builder.query<ServiceResult<Province[], ApiError>, any>({
      query: (params: any) => ({
        method: ApiMethods.GET,
        url: `/${BaseEndpoints.Service}/get-provinces/${params.iso}`,
      }),
    }),
  }),
});
export const { useGetCountriesQuery, useGetProvincesByCountryQuery } = serviceSlice;
