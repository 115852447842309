import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useRejectPackageRequestMutation } from '@features/state/slices/api/package-request.slice';
import DeleteConfirmModal from '@features/shared/DeleteConfirmModal';

const RejectButton = ({ onReject, requestId }: { requestId: any; onReject: any }) => {
  const theme = useTheme();

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const toggleConfirmDelete = () => {
    setConfirmDelete((prev) => !prev);
  };

  const [rejectPackageRequest, { isSuccess }] = useRejectPackageRequestMutation();

  const dispatch = useDispatch();

  const rejectRequest = async () => {
    dispatch(toggleLoading());
    const payload = { requestId };

    try {
      await rejectPackageRequest(payload).unwrap();

      if (isSuccess) {
        onReject();
        toast.success('Package request successfully rejected');
      } else {
        toast.error('Unable to reject');
      }
    } catch (e: any) {
      toast.error(e.data.error.message);
    }
    dispatch(toggleLoading());
  };
  return (
    <>
      <DeleteConfirmModal
        open={confirmDelete}
        handleAction={rejectRequest}
        closeModal={toggleConfirmDelete}
        title={'Delete this request?'}
        confirmationText={"This will cancel the user's request for this package."}
      />
      <Tooltip title={'Reject Request'} arrow>
        <IconButton
          sx={{
            '&:hover': {
              background: theme.colors.error.lighter,
            },
            p: 0.3,
            borderRadius: '50%',
            color: theme.palette.error.main,
          }}
          color="inherit"
          size="small"
          onClick={toggleConfirmDelete}
        >
          <DeleteTwoToneIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default RejectButton;
