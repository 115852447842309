import { DateQuery } from '@/core/types';
import { Retry } from '@/components/Retry';
import Center from '@/components/Center/Center';
import DatePicker from '@/components/DatePicker';
import { CircularProgress, Box, Typography } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import LineChart from '@/components/Charts/LineChart05';
import { RefreshButton } from '@/components/RefreshButton';
import { hexToRGB, tailwindConfig } from '@/core/utils/utils';
import { AnalyticsTokenContext } from '../../../providers/AnalyticsTokenProvider';
import { useGetVideoTrendQuery } from '@/features/state/slices/api/video-analytics-slice';

function VideoTrend() {
  const [query, setQuery] = useState<DateQuery>({
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(true);
  const [errorData, setError] = useState<any>(null);
  const { analyticsAuth } = useContext(AnalyticsTokenContext);
  const [analyticsData, setAnalyticsData] = useState<any | undefined>();

  const { isError, isFetching, isSuccess, data, refetch } = useGetVideoTrendQuery({ ...analyticsAuth, ...{ query } });

  useEffect(() => {
    if (isSuccess && !isFetching && !isError) {
      setAnalyticsData({
        labels: data.data?.dates.map((x) => ''),
        datasets: [
          {
            label: 'Total Watch Time',
            data: data.data?.totalWatchTimeData,
            borderColor: tailwindConfig().theme.colors.indigo[500],
            fill: true,
            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
            pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
            pointBorderWidth: 0,
            pointHoverBorderWidth: 0,
            clip: 20,
          },
          // Yellow line
          {
            label: 'View Count',
            data: data.data?.viewCountData,
            borderColor: tailwindConfig().theme.colors.amber[400],
            borderDash: [4, 4],
            fill: false,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.amber[400],
            pointHoverBackgroundColor: tailwindConfig().theme.colors.amber[400],
            pointBorderWidth: 0,
            pointHoverBorderWidth: 0,
            clip: 20,
          },
          // gray line
          {
            label: 'Skip Factor',
            data: data.data?.skipFactorData,
            borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[500])}, 0.25)`,
            fill: false,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[500])}, 0.25)`,
            pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[500])}, 0.25)`,
            pointBorderWidth: 0,
            pointHoverBorderWidth: 0,
            clip: 20,
          },
          {
            label: 'Engagement Rate',
            data: data.data?.engagementRateData,
            borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.rose[500])}, 0.25)`,
            fill: false,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.rose[500])}, 0.25)`,
            pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.rose[500])}, 0.25)`,
            pointBorderWidth: 0,
            pointHoverBorderWidth: 0,
            clip: 20,
          },
        ],
      });
    }

    if (data?.error) {
      setError(data.error);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [isError, isFetching, isSuccess]);

  const handleDateChange = (dates: string[]) => {
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setQuery({
          startDate,
        });
      } else {
        setQuery({
          startDate,
          endDate,
        });
      }
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box />
        <Box display="flex">
          {/* <DatePicker sx={{ ml: 1, mr: 1 }} onDateChange={handleDateChange} /> */}
          <RefreshButton onRefresh={refetch} />
        </Box>
      </Box>
      <div className="flex flex-col col-span-full xl:col-span-8 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
        <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700 ">
          <Typography fontSize={20}>Video Analytics Trend</Typography>

          <p className="mt-2 text-slate-500">
            This feature is a graphical representation that showcases the trends and patterns of video analytics over time. It provides an
            overview of how key metrics, such as total watch time, skip factor, view count, and engagement rate, have evolved and fluctuated
            throughout a specific period.
          </p>
        </header>

        {analyticsData && !loading && <LineChart data={analyticsData} width={800} height={300} />}

        <Center>{loading && <CircularProgress />}</Center>
        <Center>{errorData && <Retry refetch={refetch} />}</Center>
      </div>
    </div>
  );
}

export default VideoTrend;
