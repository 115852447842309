import 'react-phone-input-2/lib/style.css';

import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import AppButton from '@/components/AppButton';
import Center from '@/components/Center/Center';
import { InputField } from '@/components/AppInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileBox, StyledBadge } from '@/components/Styled';
import { SalesAssociateSchema } from '@core/constants/schemas';
import { formatPhoneNumber, useAuth } from '@core/utils/utils';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { CloudUploadFill } from '@styled-icons/bootstrap/CloudUploadFill';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { SalesAssociateContext } from '../../../providers/SalesAssociateProvider';
import { Box, Avatar, Divider, Typography, FormControl, FormHelperText } from '@mui/material';
import { useGetAssociateQuery, useUpdateCompanyDetailsMutation } from '@features/state/slices/api/company-slice';

export default function UserForm() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const uploadInputRef = useRef<any>(null as any);
  const [displayUrl, setDisplayUrl] = useState<string | null>('');
  const [displayPhoto, setDisplayPhoto] = useState<File | Blob | null>(null);

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SalesAssociateSchema),
  });

  const { salesAssociate } = useContext(SalesAssociateContext);

  const [updateCompanyDetails] = useUpdateCompanyDetailsMutation();

  const handleSave = async (data: any) => {
    dispatch(toggleLoading());

    const details = {
      ...data,
      phoneNumber: formatPhoneNumber(data.phoneNumber),
    };

    const formData = new FormData();
    for (const key in details) {
      formData.append(key, details[key]);
    }

    if (displayPhoto) {
      formData.append('displayPhoto', displayPhoto);
    }

    try {
      const { error, success } = await updateCompanyDetails({
        details: formData,
        companyId: user?.id,
        userId: salesAssociate!.id,
      }).unwrap();

      if (success || !error) {
        dispatch(toggleLoading());

        toast.success('User information saved');
      } else {
        dispatch(toggleLoading());

        toast.error('Unable to save user information');
      }
    } catch (err: any) {
      dispatch(toggleLoading());

      toast.error('Failed to save user information: ', err);
    }
  };

  const handleReferenceClick = () => uploadInputRef.current && uploadInputRef.current.click();

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const photo = e?.target?.files![0];
    const objectURL = URL.createObjectURL(photo);

    setDisplayPhoto(photo);
    setDisplayUrl(objectURL);
  };

  useEffect(() => {
    if (salesAssociate?.profilePic) {
      setDisplayUrl(salesAssociate!.profilePic!);
    }

    setValue('email', salesAssociate?.email);
    setValue('blocked', salesAssociate?.blocked);
    setValue('lastName', salesAssociate?.lastName);
    setValue('username', salesAssociate?.username);
    setValue('firstName', salesAssociate?.firstName);
    setValue('phoneNumber', salesAssociate?.phoneNumber);
    setValue('canUseCredits', salesAssociate?.canUseCredits);
  }, []);

  return (
    <div className="grow">
      <div className="col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-md border border-slate-200 dark:border-slate-700 mt-3">
        <div className="p-3">
          <div className="flex">
            <div className="mt-4 ml-4">
              <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold">Update Sales Associate</h2>
              <Typography variant="subtitle1">
                Easily edit and manage Sales Associate details from the admin portal for streamlined data maintenance.
              </Typography>
            </div>
          </div>
        </div>
        <Center>
          <ProfileBox>
            <StyledBadge
              variant="dot"
              overlap="circular"
              sx={{
                cursor: 'pointer',
                '& .MuiBadge-badge': {
                  color: salesAssociate?.isOnline ? '#44b700' : '#F6C21A',
                  backgroundColor: salesAssociate?.isOnline ? '#44b700' : '#F6C21A',
                },
              }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Avatar alt="photoURL" src={displayUrl!} sx={{ height: 300, width: 300 }} />
            </StyledBadge>

            {
              <div className="d-flex p-3">
                <Fragment>
                  <input type="file" accept="image/*" ref={uploadInputRef} style={{ display: 'none' }} onChange={(e) => handleUpload(e)} />
                  <AppButton onClick={() => handleReferenceClick()}>
                    Upload &nbsp;
                    <CloudUploadFill size="20" />
                  </AppButton>
                </Fragment>

                <Box ml={2} className="d-md-block text-start">
                  <div className="fw-normal">Choose Image</div>
                  <div style={{ marginBottom: 5 }} className="text-gray small mb-10">
                    JPG, GIF or PNG. Max size of 800K
                  </div>
                </Box>
              </div>
            }
          </ProfileBox>
        </Center>
        <Divider sx={{ mt: 2, mb: 2, background: '#bdbdbd', width: '100%' }} />

        <div className="p-5">
          <Typography variant="subtitle1" mb={1} sx={{ fontSize: 14 }}>
            Personal Information
          </Typography>
          <div className="space-y-7">
            <div className="flex space-x-4">
              <div className="w-1/3">
                <InputField value={watch('firstName')} onChange={(e) => setValue('firstName', e.target.value)} />
              </div>
              <div className="w-1/3">
                <InputField value={watch('lastName')} onChange={(e) => setValue('lastName', e.target.value)} />
              </div>
              <div className="w-1/3">
                <FormControl variant="outlined" size="medium" fullWidth>
                  <PhoneInput
                    country={'us'}
                    value={salesAssociate?.phoneNumber}
                    containerStyle={{
                      height: 37,
                      width: '100%',
                    }}
                    inputStyle={{ height: 37, width: '100%', borderRadius: 2 }}
                    onChange={(phone) => {
                      setValue('phoneNumber', phone);
                    }}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.phoneNumber?.message as string}</FormHelperText>
                </FormControl>
              </div>
            </div>

            <div className="flex space-x-4">
              <div className="w-1/2">
                <InputField value={watch('username')} onChange={(e) => setValue('username', e.target.value)} />
              </div>
              <div className="w-1/2">
                <InputField value={watch('email')} onChange={(e) => setValue('email', e.target.value)} />
              </div>
            </div>
          </div>
          <div className="flex space-x-4 mt-5">
            <div className="w-1/2">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={watch('canUseCredits')}
                  onChange={(e) => setValue('canUseCredits', e.target.checked)}
                  className="dark:bg-slate-900/30 border focus:ring-0 focus:ring-offset-0 dark:disabled:bg-slate-700/30 dark:disabled:border-slate-700 dark:disabled:hover:border-slate-700"
                />
                <span className="ml-3 text-slate-500">Allow this user to utilize credits?</span>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={watch('blocked')}
                  onChange={(e) => setValue('blocked', e.target.checked)}
                  className="dark:bg-slate-900/30 border focus:ring-0 focus:ring-offset-0 dark:disabled:bg-slate-700/30 dark:disabled:border-slate-700 dark:disabled:hover:border-slate-700"
                />
                <span className="ml-3 text-slate-500">Block this user from accessing their account?</span>
              </div>
            </div>
          </div>
          <div className="float-right mt-4 mb-4">
            <AppButton onClick={handleSubmit(handleSave)}>Save Changes</AppButton>
          </div>
          <br></br> <br></br>
          <br></br> <br></br>
        </div>
      </div>
    </div>
  );
}
