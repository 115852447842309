import { Card, Box, Typography, InputLabel, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// import 'video-player-for-react/dist/index.css';
import { Shimmer } from 'react-shimmer';
import { useGetVideoByIdQuery } from '@features/state/slices/api/video-slice';
import VideoPlayer from '@components/VideoPlayer/VideoPlayer';
import { CustomVideo, Video } from '@core/types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { BackButton } from '@components/BackButton';
import { useGetCompanyCustomVideosQuery } from '@features/state/slices/api/custom-video-slice';

const CustomVideoDetails = () => {
  const [searchParams] = useSearchParams();
  // const videoId = searchParams.get('v');
  // console.log(`Video ID: ${videoId}`);
  // const [isVideoReady, setIsVideoReady] = useState(false);
  // const { id } = useParams();
  const { state } = useLocation();

  // const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useGetCompanyCustomVideosQuery({
  //   id,
  //   videoId,
  // });

  const [video, setVideo] = useState<CustomVideo | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setVideo(state?.video as CustomVideo);
  }, []);

  return (
    <Card sx={{ padding: 3 }}>
      <Box>
        <BackButton />
      </Box>
      <Box display={'flex'}>
        <Box sx={{ width: '100%' }}>
          {Object.keys(video ?? {}).length > 0 ? (
            <Box position={'relative'}>
              <VideoPlayer
                video={video}
                videoType={'full'}
                onReady={(e) => {
                  // setIsVideoReady(true);
                }}
              />
              {/* 
                TODO Uncomment WatermarkCode 
            {!isFetching && Object.keys(video ?? {}).length > 0 && !isError && isVideoReady &&<Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '10%',
                height: '10%',
                backgroundColor: 'rgba(255, 0, 0, 0.5)', // Red box with some transparency
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff' // For any text inside the box
              }}>
      
              </Box>
              } */}
            </Box>
          ) : (
            <Shimmer width={1200} height={600} />
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default CustomVideoDetails;
